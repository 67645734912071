import React from 'react';
import { multilineJSX } from 'react-frontend-utils'

import { PP } from '../models/PP'
import { DateUtils } from 'react-frontend-utils'

/**
 * This class represents a PoolPass Membership, received from a JsonMembership
 */
export class Membership {
    
    id;                                         //may not be null
    address;                                    //string
    primaryPhone = "";                          //string
    secondaryPhone = "";                        //string
    email = "";                                 //string
    guestPasses = 0;                            //integer
    suspended = false;                          //boolean
    joinDate;                               
    expirationDate = null;                      //may be null for never expires
    notes = "";                                 //default empty string
    type = "";                                  //default empty string
    emergencyContact = "";                      //default empty string
    
    lastTransaction;    
    
    
 
    constructor(userJson) {
        
        if (userJson) {
            this.id = userJson.id;
            this.address = userJson.address;
            this.primaryPhone = userJson.primaryPhone;
            this.secondaryPhone = userJson.secondaryPhone;
            this.email = userJson.email;
            this.guestPasses = userJson.guestPasses;
            this.suspended = userJson.suspended;
            
            if (userJson.joinDate)
                this.joinDate = DateUtils.parseJsonDate(userJson.joinDate); 
            
            if (userJson.expirationDate)
                this.expirationDate = DateUtils.parseJsonDate(userJson.expirationDate);
                     
            this.notes = userJson.notes;
            this.type = userJson.type;
            this.emergencyContact = userJson.emergencyContact;
                 
            this.lastTransaction = userJson.lastTransaction;
   
        }       
    }
    
    //Create a new Membership JSON from an ID
    static createNewJson(id) {
        
        const newMembership = {id: id,
                               guestPasses: PP.initialGuestPasses(),
                               expirationDate: PP.initialExpirationDate()
                               };
        
        return newMembership;
    }
    
    
    expiresIn = () => {
        
        if (!this.expirationDate)
            return Number.MAX_SAFE_INTEGER;
        
        const now = DateUtils.startOfToday();
        
        return Math.floor((this.expirationDate.valueOf() - now.valueOf())/(1000 * 3600 * 24));  //days expired        
    }
  
  
    expirationString = () => {
        
        if (!this.expirationDate)
            return "Never";
        
        const daysExpired = this.expiresIn();
        if (daysExpired < 0)
            return "Expired";
        
        return daysExpired + " days";
        
    }
    
    isIdNumeric = () => {
        return /^[0-9]*$/.test(this.id);    // true if we only contain numeric
    }
    
    
    
}


//Returns a JSX format of membership data suitable for use on a tooltip on hover over a Membership item
export const membershipTooltip = (membership) => {
    if (!membership)
        return "";

    return (<div>
                <div><b>ID: </b>{membership.id}</div> 
                <div><b>Address: </b>{multilineJSX(membership.address, true)}</div> 
                <div><b>Primary Phone: </b>{membership.primaryPhone}</div> 
                <div><b>Alt Phone: </b>{membership.secondaryPhone}</div> 
                <div><b>Email: </b>{membership.email}</div>
                <div><b>Type: </b>{membership.type}</div>
                <div><b>Emergency Contact: </b>{membership.emergencyContact}</div>
                <div><b>Membership Since: </b>{membership.joinDate.getFullYear()}</div>
                <div style={{marginTop: 5}}/>
                <div><b>Notes: </b>{multilineJSX(membership.notes, true)}</div>
            </div>

           );  
};
  