import React, { Component } from 'react';
import { TextField, Tooltip, IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CreateIcon from '@material-ui/icons/Create';
import PrintIcon from '@material-ui/icons/Print';

import { PP } from '../models/PP'
import { CalendarPicker, Permissions } from 'react-frontend-utils'

/**
 * The LogTableToolbar sits above the Log table. The caller must pass in props:
 *  a function called "tableHasSelectedRows" which is true when a row is selected
 *  a function called "onSearchChange" when the search field text, which accepts two search strings (name and membership)
 *  a function called "onCheckedInSwitchChanged" when the checked in switch changes state
 *  a function called "foundRecords" which is called whenever the search field text changes to see if the search text field should go red (nothing found)
 *  a function called "onCheckInButtonPress" which is called whenever the check in button is pressed
 *  a function called "onDailyLogButtonPress" which is called when the daily log button is pressed
 */

export default class LogTableToolbar extends Component {

    styles = {
        textfield: {
            marginBottom: 10,
            marginLeft: 10,
            width: 200
        },
        switchLabel: {
            fontSize: 12
        }
     
    }

    state = {
        searchString: "",
    }
 
     _busyComponent;
     _searchChangedCallback;
     _manualCallback;
     _downloadCallback;
     _deleteCallback;
     _foundRecords;
     _dailyLogCallback;
    
    constructor(props) {
        super(props);
        this._busyComponentGetter = props.busyComponent;
        this._searchChangedCallback = props.onSearchChange;
        this._manualCallback = props.onManualLogRequested;
        this._deleteCallback = props.onDeleteRequested;
        this._downloadCallback = props.onDownloadRequested;
        this._foundRecords = props.foundRecords;
        this._dailyLogCallback = props.onDailyLogButtonPress;
    }
 
    _searchFieldChanged = (event) => {
        
        const searchString = event.target.value;
        this.setState({searchString: searchString})
        this._searchChangedCallback(searchString); 
    }
    
    
    _toggleCheckedIn = (event) => {
        this._isCheckedInFilterCallback(!this.state.showCheckedIn)
        this.setState((prevState) => ({showCheckedIn: !prevState.showCheckedIn}));
    }
    

    render() {
        const filterColor = this._foundRecords() ? 'primary' : 'secondary';

        const canCreateManual = PP.user.hasPermissionTo(Permissions.CHECK_IN);
        const canDelete = PP.user.hasPermissionTo(Permissions.BULK_OPERATION)
        const canDownload = PP.user.hasPermissionTo(Permissions.VIEW_DATABASE);

        return (
            <div style={{width: '100%', display: 'flex', alignItems: 'end', gap: 5, justifyContent: 'flex-end'}}>
                  
                    <div style={{flexGrow: 1, marginBottom: 10, marginLeft: 10}}>
                        {this._busyComponentGetter()}
                    </div>
                  
                    <Tooltip title={"Manual Log Entry"}>
                         <IconButton disabled={!canCreateManual} onClick={this._manualCallback}>
                            <CreateIcon/>
                        </IconButton>                
                    </Tooltip> 

                    <Tooltip title={"Print Daily Check-In Log"}>
                        <div>
                            <CalendarPicker dateChanged={this._dailyLogCallback} icon={<PrintIcon/>} label="Select Daily Log Day"/>             
                        </div>
                    </Tooltip>
                    
                    <div style={{width: 5}}/>

                    <Tooltip title={"Export and Download Log as a .csv file"}>
                         <IconButton disabled={!canDownload} onClick={this._downloadCallback}>
                            <CloudDownloadIcon/>
                        </IconButton>                
                    </Tooltip>

                    <Tooltip title={"Delete all Log Entries older than Selected Date"}>
                        <div>
                            <CalendarPicker dateChanged={this._deleteCallback} icon={<DeleteIcon/>} disabled={!canDelete} label="Delete Log Entries Older Than"/>             
                        </div>           
                    </Tooltip>
                
                <Tooltip title={"Filter the table by any matching text"}>
                    <TextField label="Text Search" type="search" value={this.state.searchString} onChange={this._searchFieldChanged} color={filterColor} margin='dense' style={this.styles.textfield} InputLabelProps={{ shrink: true }} />            
                </Tooltip>
                
        
            </div>
        );
    }
}

