

export class GroupInfo {
    
    id;
    name;
    userCount;
    attributes = {};
    
    constructor(groupJson) {
        
        if (groupJson) {
            this.id = groupJson.id;
            this.name = groupJson.name;
            this.userCount = groupJson.userCount;
            
            this.attributes.maxUsers = groupJson.attributes.maxUsers;
            this.attributes.isSuspended = groupJson.attributes.isSuspended;
            this.attributes.isPublic = groupJson.attributes.isPublic;
            this.attributes.info = groupJson.attributes.info ? groupJson.attributes.info : "";
            this.attributes.extendedName = groupJson.attributes.extendedName ? groupJson.attributes.extendedName : "";
            this.attributes.address = groupJson.attributes.address ? groupJson.attributes.address : "";
            this.attributes.url = groupJson.attributes.url ? groupJson.attributes.url : "";
            this.attributes.accountNo = groupJson.attributes.accountNo ? groupJson.attributes.accountNo : "";
            this.attributes.isoCurrency = groupJson.attributes.isoCurrency;
            this.attributes.marketEnabled = groupJson.attributes.marketEnabled;
            this.attributes.checkInDisabled = groupJson.attributes.checkInDisabled;
            this.attributes.ePassEnabled = groupJson.attributes.ePassEnabled;
            this.attributes.bookingEnabled = groupJson.attributes.bookingEnabled;
            this.attributes.fullService = groupJson.attributes.fullService;
        }
        
    }
    
    shortInfo = () => {
        
        if (this.attributes.info.length > 40) 
            return this.attributes.info.substring(0, 37) + "...";
        else
            return this.attributes.info;
        
    }
    
    
}
    