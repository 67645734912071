import React from 'react';
import { withCookies } from 'react-cookie';

import { Typography } from '@material-ui/core'

import { PP } from '../models/PP'
import { Member } from '../models/Member'
import { Membership, membershipTooltip } from '../models/Membership'
import { RestComponent } from 'react-frontend-utils' 
import { multilineJSX, StyledTooltip } from 'react-frontend-utils'
import { DataTable, DataTableComponent } from 'react-frontend-utils'
import MemberListToolbar from '../components/MemberListToolbar'
import { DateUtils, Email, Permissions } from 'react-frontend-utils'
import { ThemeColors } from '../Theme'

/**
 * The MemberList tab shows a list of all Members, with various options to perform such as check in and manage. 
 * On mounting of the component, the list of all Memberships and Members is fetched from the server. 
 * A hashmap of Membership names to Memberships are created to allow lookup of Membership data from Member data.
 * 
 * The caller MUST pass in a onManageMember function to be called with two arguments (membership, member) when the member row is double clicked
 * 
 */




//Returns a JSX format of member data suitable for use on a tooltip on hover over the Member name column
const memberTooltip = (member) => {
    if (!member)
        return "";
    
    const actionArray = Member.actions.filter((action, index) => member.actions & (1 << index));

    return (<div>
                <div><b>Name: </b>{member.name()}</div> 
                <div><b>Birthdate: </b>{PP.dateFormat(member.birthDate)}</div>
                <div><b>Relation: </b>{member.relation}</div> 
                <div><b>Actions: </b></div>     
                {actionArray.map((action, index) => {return <div key={index}>&nbsp;&nbsp;&nbsp;{action.name}</div>;})}
                <div/>
                <div><b>Notes: </b>{multilineJSX(member.notes, true)}</div>
            </div>
            
           );
           
};



export class MemberListTab extends RestComponent {
  
    //An array describing each column, generate dynamically to refresh on state change

    columns = () => { return  [
        //Membership column - data is an object that contains the membershipID and the membership object
        //Sorting is done comparing the membershipID alphabetically
        //Rendering is the Membership ID, with the style colored if suspended, and a tooltip containing membership data
        {name: "membershipID", label: "Membership ID",   
            options: {filter: true, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.firstCellProps,
                    sortCompare: (order) => {return (obj1, obj2) => {let m1 = obj1.data.membershipID;
                                                                     let m2 = obj2.data.membershipID;  
                                                                     if (m1 === m2) {  //same Membership, sort by id
                                                                         let b1 = obj1.data.memberID;
                                                                         let b2 = obj2.data.memberID;
                                                                         return (b1 > b2 ? 1 : -1);
                                                                     }
                                                                     return (m1 > m2 ? 1 : -1) * (order === 'asc' ? 1 : -1);
                                                                    };},
                    customBodyRender: (val) => { let text = val ? val.membershipID : null; 
                                                 if (text.length > 30)
                                                    text = text.substring(0, 27) + "...";
                                                
                                                 let style = null;  
                                                 const membership = val? val.membership : null;
                                                 if (membership && membership.suspended) 
                                                       style= {color: 'white', backgroundColor: 'red'};       

                                                   return (
                                                           <StyledTooltip title={membershipTooltip(membership)}>
                                                                <div style={{...style, paddingLeft: 2, borderRadius: 2}}>{text}</div>
                                                           </StyledTooltip>
                                                          ); 
                                                 }
            }
        },
        //Member ID column - data is an object that contains the memberID and a boolean "suspended"
        //Sorting is done comparing the memberID numerically
        //Rendering is the Member ID, with the style colored if suspended
        {name: "memberID", label: "Member ID",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps,
                      sortCompare: (order) => {return (obj1, obj2) => {let id1 = obj1.data.memberID;
                                                                       let id2 = obj2.data.memberID;                                                                        
                                                                       return (id1 - id2) * (order === 'asc' ? 1 : -1);
                                                                      };},
                      customBodyRender: (val) => { const text = val ? val.memberID : null; 
                                                   let style = null;      
                                                   if (val && val.isSuspended) 
                                                       style= {color: 'white', backgroundColor: 'red'};       

                                                   return (<div style={{...style, paddingLeft: 2, borderRadius: 2}}>{text}</div>); 
                                                 }
                    } 
        },
        //Address column - data is the address
        {name: "address", label: "Address",
            options: {display: this.state.isWide, filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps, customBodyRender: DataTable.longerStringCellRenderer}
        },
        //Membership type column - data is the type
        {name: "membershipType", label: "Membership Type",
            options: {display: this.state.isWide, filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps, customBodyRender: DataTable.longerStringCellRenderer}
        },
        
        
        //Name column - data is an object that contains the member object
        //Sorting is done comparing the member's name (last, first) alphabetically
        //Rendering is the member's name (last, first) and a tooltip containing member data
        {name: "name", label: "Name",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps,
                      sortCompare: (order) => {return (obj1, obj2) => {let b1 = obj1.data.member.nameReversed();
                                                                       let b2 = obj2.data.member.nameReversed();  
                                                                       if (b1 === b2)
                                                                            return 0;
                                                                       return (b1 > b2 ? 1 : -1) * (order === 'asc' ? 1 : -1);
                                                                      };},
                      customBodyRender: (val) => { const member = val? val.member : null;
                                                   const name = member ? member.nameReversed(true) : null;  //short name
                                                   return (
                                                    <StyledTooltip title={memberTooltip(member)}>
                                                        <div>{name}</div>
                                                    </StyledTooltip>
                                                   ); 
                                                 }
            }
        },
        //Age column - data is the age in years, display on non-mobile
        //Rendering is the age string, which may show -- depending on the year
        {name: "age", label: "Age", display: !this.state.isMobile,
            options: {display: !this.state.isMobile,  filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps,
                      customBodyRender: (val) => {return val.getFullYear() <= 1900 ? "--" : DateUtils.calculateAge(val);}
            }
        },
        //Barcode column - data is the barcode string
        {name: "barcode", label: "Barcode",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps}
        },
        //Entry Passes column - data is the entry pass value, which may be -1 for unlimited
        //Sorting is done comparing the entry pass value, with -1 as the largest
        //Rendering is the entry passes value, or "unlimited" if -1.  Colored yellow if value is zero
        {name: "entryPasses", label: "Entry Passes",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps,
                      sortCompare: (order) => {return (obj1, obj2) => {let val1 = obj1.data;
                                                                       let val2 = obj2.data;
                                                                       if (val1 < 0) 
                                                                            val1 = Number.MAX_SAFE_INTEGER;
                                                                       if (val2 < 0) 
                                                                            val2 = Number.MAX_SAFE_INTEGER;
                                                                       return (val1 - val2) * (order === 'asc' ? 1 : -1);
                                                                      };
                                              },
                      customBodyRender: (val) => {let text = String(val);
                                                  let style = null;
                                                  if (val < 0) {
                                                      text = "Unlimited";
                                                  }
                                                  else if (val === 0) 
                                                      style= {backgroundColor: 'yellow'};       

                                                  return (<div style={{...style, paddingLeft: 2, borderRadius: 2}}>{text}</div>); 
                                                  }


                     }      
        },
        //Guest passes column - data is the number of guest passes
        {name: "guestPasses", label: "Guest Passes",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps}
        },
        //Last Check In column - data is an object containing a Date, which may be null for never
        //Sorting is done comparing the date
        //Rendering is the checkInTimeFormat applied to the date, or "Never" if null
        {name: "lastCheckIn", label: "Last Check In",
            options: {display: !this.state.isMobile, filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps,
                        sortCompare: (order) => {return (obj1, obj2) => {let val1 = obj1.data.date;
                                                                         let val2 = obj2.data.date;                                                                      
                                                                          return (val1 - val2) * (order === 'asc' ? 1 : -1);
                                                                         };
                        },
                        customBodyRender: (val) => {const date = val ? val.date : null;
                                                    const text = date ? PP.checkInTimeFormat(date) : "Never";
                                                    return (
                                                         <div>{text}</div>                                              
                                                    ); 
                         }
                     }
        },
        //Expires column - data is the days until expiration, -1 for expired, or null for never
        //Sorting is done comparing the days value
        //Rendering is the days value, "Expired" and colored yellow if -1.  "Never" for null
        {name: "expires", label: "Expires",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.lastCellProps,                            
                      customBodyRender: (val) => {let text;
                                                  let style = null;
                                                  if (val < 0) {
                                                       text = "Expired";
                                                       style= {backgroundColor: 'yellow'};       
                                                  }
                                                  else if (val === Number.MAX_SAFE_INTEGER) 
                                                       text =  "Never";
                                                  else
                                                       text = String(val) + " days";

                                                  return (<div style={{...style, paddingLeft: 2, borderRadius: 2}}>{text}</div>); 
                                                 }

                      }      
        }

    ];};



         
    _searchNameText = "";           //the currently displayed search name text
    _searchOtherText = "";          //the currently displayed search other text
    _showOnlyCheckedIn = false      //show only checked in Members
    _showOnlyFirst = false;         //show only the first Member in the Membership
    _showOnlyLimited = false;       //show only the limited Memebrs
    _filterActionsValue = 0;        //the bitwise Actions to filter on, 0 is none
    _lastRowClick = null;           //time of the last row click
    _onManageMember;                //callback function when double clicking on a row
    _onCheckInMember;               //callback function when checking in member
    
   

    //Called by the custom Toolbar, to determine what color it should be (if no found records, go red
    searchFoundRecords = () => {
       if (this.state.tableData.length === 0) //no records loaded, don't color the text to indicate search failed
           return true; 
       
       return this.state.filteredTableData.length !== 0;
    }
    
    tableHasSelectedRows = () => {
        return this.state.selectedRow >= 0;
    }
    
    canCheckIn = () => {
        
        if (!PP.user.hasPermissionTo(Permissions.CHECK_IN))
            return false;
        
        if (this.state.selectedRow < 0)
            return false;
        
        const row = this.state.filteredTableData[this.state.selectedRow];
        
        //selected row not in filtered data
        if (row === undefined)
            return false;
        
        return true;
    }
    
    
    checkInButtonPressed = () => {
       
        if (!this.canCheckIn())
            return;
        
        const row = this.state.filteredTableData[this.state.selectedRow];
        
        const membershipID = row.membershipID.membershipID;
        const memberID = row.memberID.memberID;
            
        console.log("Member Check In: selected row: " + this.state.selectedRow + " Member: " + membershipID + "-" + memberID);

        this._onCheckInMember(membershipID, memberID);
            
    }
    
    emailButtonPressed = () => {
        const filteredMembershipEmails = this.state.filteredTableData.map(row => row.membershipID.membership.email);
        Email.sendEmailToMemberships(filteredMembershipEmails);
    }

    constructor(props) {
        super(props);
        this._onManageMember = props.onManageMember;
        this._onCheckInMember = props.onCheckInMember;
        
        this.state.members = [];           //array of Members, from last fetch
        this.state.memberships = [];       //array of Memberships, from last fetch
    
        this.state.mm_map = null;          //map of Membership references (keys) to Memberships
        
        this.state.fetchToGo = 0;          //how many fetches remain before we can remove the busy marker
        this.state.selectedRow =  -1;       //the currently selected row in the table
        
        this.state.tableData = [];         //all the table data
        this.state.filteredTableData = []; //filtered data from search, filters
        
        this.state.filteredMembershipCounts = null;   //object that has counts for the various Membership counts  
        this.state.filteredMemberCounts = null;     //object that has counts for the various Membership counts  
                
        this.state.tableOptions =  {onRowSelectionChange: ((currentRowsSelected) => this._rowClick(currentRowsSelected)),
                                    customToolbar: () => { return ( <MemberListToolbar 
                                                                        onSearchChange={this.searchFieldChanged} 
                                                                        onCheckedInSwitchChanged={this.checkedInFieldChanged}
                                                                        onOnlyFirstSwitchChanged={this.onlyFirstFieldChanged}
                                                                        onLimitedSwitchChanged={this.limitedFieldChanged}
                                                                        onActionFilterSwitchCallback={this.actionFilterSwitchChanged}
                                                                        foundRecords={this.searchFoundRecords}
                                                                        tableHasSelectedRows={this.tableHasSelectedRows}
                                                                        onCheckInButtonPress={this.checkInButtonPressed}
                                                                        canCheckIn={this.canCheckIn}
                                                                        onEmailButtonPress={this.emailButtonPressed}/> 
                                                                   ); 
                                                          },
                                    setRowProps: (row, dataIndex, rowIndex) => this._setRowProps(row, dataIndex, rowIndex)
                                    };
        
        
    }
    
    
    _getMembership = (member) => {
        return this.state._mm_map.get(member.membershipRef);        
    }
    
    /**
     * When the page loads, fetch all members and memberships for the current database
     */
    componentDidMount() {
        super.componentDidMount();
        this._updateSize();
        window.addEventListener("resize", this._updateSize);
        
        if (PP.selectedDatabase) {
        
            this.setBusy(true);
            this.state.fetchToGo = 2;  //two fetches remain
     
            //fetch all memberships and members
            this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/memberships", {}, this._fetchMembershipsCallback, this._fetchErrorCallback); 
            this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/members", {}, this._fetchMembersCallback, this._fetchErrorCallback); 
        }
    }
    
    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener("resize", this._updateSize);
    }

    //callback when window changes size
    _updateSize = () => {
        this.setState({ isMobile: window.innerWidth < 960, isWide: window.innerWidth > 1280 });
    }
    
    //Called by the search field from the Custom toolbar, when the search field text changes
    searchFieldChanged = (searchNameText, searchOtherText) => {
        this._searchNameText = searchNameText;
        this._searchOtherText = searchOtherText;
        this._filterTableData();
    }
    
    checkedInFieldChanged = (isCheckedInSwitchState) => {
        this._showOnlyCheckedIn = isCheckedInSwitchState;
        this._filterTableData();
    }
    
    onlyFirstFieldChanged = (onlyFirstSwitchState) => {
        this._showOnlyFirst = onlyFirstSwitchState;
        this._filterTableData();
    }
    
    limitedFieldChanged = (limitedSwitchState) => {
        this._showOnlyLimited = limitedSwitchState;
        this._filterTableData();
    }
    
    actionFilterSwitchChanged = (filterValue) => {
        this._filterActionsValue = filterValue;
        this._filterTableData();                  
    }
    
    
    _setRowProps = (row, dataIndex, rowIndex) => {
        return DataTable.getRowStyle(this.state.selectedRow, dataIndex, rowIndex, ThemeColors.selectedColor, ThemeColors.tableAlternatingRowsGray);
    }
   
   
    //Callback when a row is clicked
    _rowClick = (currentRowsSelected) => {
     
        const row = currentRowsSelected.length > 0 ? currentRowsSelected[0].dataIndex : -1;
        
        if (row >= 0) {
            const membershipID = this.state.filteredTableData[row].membershipID.membershipID;
            const memberID = this.state.filteredTableData[row].memberID.memberID;
            console.log("Member List: selected row: " + row + " Member: " + membershipID + "-" + memberID);

            //See if it's a double click (same row, last click less than 300 ms ago) 
            const selectTime = performance.now();
            if (this._lastRowClick && row === this.state.selectedRow) {  //same row
                
                if (selectTime - this._lastRowClick < 500) {
                    console.log("Member List: double click on row: " + row);
                    this._onManageMember(membershipID, memberID);
                    return;
                }
                //Set the current time of the click
                this._lastRowClick = selectTime;
                return;
            }
            
            //Set the current time of the click
            this._lastRowClick = selectTime;
        }
       
        this.setState( {selectedRow: row} );
       
        
    }
    
    //Callback for fetching Memberships - JSON response is an array of Membership objects
    _fetchMembershipsCallback = (response) => {
        if (response) {            
            this.state.memberships = response.map((membership) => new Membership(membership));
        }
        this._fetchDone();
    }

    //Callback for fetching Members - JSON response is an array of Member objects
    _fetchMembersCallback = (response) => {
        if (response) {            
            this.state.members = response.map((member) => new Member(member));
        }
        this._fetchDone();
    }
    
    
    _fetchDone = () => {
        
        this.state.fetchToGo -= 1;
        
        if (this.state.fetchToGo === 0) {  //both done
            this.setBusy(false);
            
            this.state.mm_map = new Map();
            this.state.memberships.forEach(membership => this.state.mm_map.set(membership.id, membership));
            
            this._updateTableData();
        }
        
    }
    
    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.setBusy(false);
    }
     
     
    _calcCounts = (tableData) => {
        
        const countedMembership = new Set();  

        let totalMemberships = 0;
        let totalMembershipsSuspended = 0;
        let totalExpired = 0;
        let guestPassCount = 0;
        
        let totalMembers = 0;
        let entryPassCount = 0;
        let totalMembersSuspended = 0;
        let totalMembersLimited = 0;
        
        for (let entry of tableData) {  
            
            //If we don't have Membership data for this entry yet
            if (!countedMembership.has(entry.membershipID.membershipID)) {
                
                totalMemberships++;
                if (entry.membershipID.membership.suspended)
                    totalMembershipsSuspended++;
                
                if (entry.expires < 0)
                    totalExpired++;
                
                guestPassCount += entry.guestPasses;   
                countedMembership.add(entry.membershipID.membershipID);
            }
            
            totalMembers++;
            if (entry.memberID.isSuspended)
                totalMembersSuspended++;
            
            if (entry.memberID.isLimited)
                totalMembersLimited++;
            
            if (entry.entryPasses > 0)
                entryPassCount += entry.entryPasses;
            
        } 
        this.setState({filteredMembershipCounts: {total: totalMemberships, suspended: totalMembershipsSuspended, expired: totalExpired, guestPasses: guestPassCount}});
        this.setState({filteredMemberCounts: {total: totalMembers, suspended: totalMembersSuspended, limited: totalMembersLimited, entryPasses: entryPassCount}});
        
    }

    //Called when fetch of memberships and members are done, creates the table data, and sets filteredTableData equal to the table data
    _updateTableData = () => {
        
        const hasFirstMember = new Set();  
        
        const tableData = this.state.members.map(member => {
        
            if (!this.state.mm_map)
                return [];

            const membership = this.state.mm_map.get(member.membershipRef);
            if (!membership) {
                console.log("No Membership in mm_map for Member: " + member.membershipRef);
                return [];
            }
            
            //If the Set does not yet contain the Membership ID, then this is the first member in the Membership (Members were sorted from lowest ID to highest)
            const isFirst = !hasFirstMember.has(member.membershipRef);

            //Add to the set, so the next member in the Membership is not first
            hasFirstMember.add(member.membershipRef);

            //For each member, create a row with all the column data
            return {membershipID: {membershipID: member.membershipRef, membership: membership, memberID: member.id},
                    memberID: {memberID: member.id, isSuspended: member.suspended, isFirst: isFirst, isLimited: member.limited},
                    address: membership.address,
                    membershipType: membership.type,
                    name: {member: member}, 
                    age: member.birthDate,
                    barcode: member.barcode,
                    entryPasses: member.entryPasses,
                    guestPasses: membership.guestPasses,
                    lastCheckIn: {date: member.lastCheckIn()},
                    expires: membership.expiresIn()
                   }; 
        });
        
        //Sort the table first by Membership ID, then by Member ID
        tableData.sort((a, b) => {
           if (a.membershipID.membershipID === b.membershipID.membershipID) 
                return a.membershipID.memberID - b.membershipID.memberID; 
            else 
                return a.membershipID.membershipID > b.membershipID.membershipID ? 1 : -1;
            
        });
         


        this.setState({tableData: tableData, filteredTableData: tableData});  //set the data, and filtered data = data
        this._calcCounts(tableData);
    }


    //Creates filteredTableData by filtering the tableData
    _filterTableData = () => {
        
        if (this._searchNameText.length === 0 && this._searchOtherText.length === 0 && !this._showOnlyCheckedIn && !this._showOnlyFirst && !this._showOnlyLimited && this._filterActionsValue === 0) {
            this.setState({filteredTableData: this.state.tableData});
            this._calcCounts(this.state.tableData);
            return;
        }
        
        const filteredTableData = this.state.tableData.filter(row => { 
            
            //Match on Member Name
            let memberNameMatch = true;  //default - match on empty string
            if (this._searchNameText.length !== 0)
                memberNameMatch = row.name.member.name().toLowerCase().includes(this._searchNameText.toLowerCase());
            
            //Match on Membership Fields
            let membershipFieldMatch = true;  //default - match on empty string
            if (this._searchOtherText.length !== 0) {
                const searchText = this._searchOtherText.toLowerCase();
                const membership = row.membershipID.membership;
                membershipFieldMatch = membership.id.toLowerCase().includes(searchText) ||
                                       membership.address.toLowerCase().includes(searchText) ||
                                       membership.email.toLowerCase().includes(searchText) ||
                                       membership.type.toLowerCase().includes(searchText) ||
                                       membership.primaryPhone.toLowerCase().includes(searchText) ||
                                       membership.secondaryPhone.toLowerCase().includes(searchText) ||
                                       membership.notes.toLowerCase().includes(searchText);
      
            }
            
            //Match on Checked in
            let checkedInMatch = true;  //default - match on switch off
            if (this._showOnlyCheckedIn) {
                checkedInMatch = row.name.member.isCheckedIn;
            }
            
             
            let limitedMatch = true;    //default - match on switch off
            if (this._showOnlyLimited) {
                limitedMatch = row.name.member.limited;
            }
            
            
            //Match on only first
            let onlyFirstMatch = true; //default - match on switch off
            if (this._showOnlyFirst) {
                onlyFirstMatch = row.memberID.isFirst;
            }
            
            //Match on Actions
            let actionsMatch = true;  //default - match on zero actions
            if (this._filterActionsValue > 0)
                actionsMatch = (row.name.member.actions & this._filterActionsValue) === this._filterActionsValue;
            
            
            return memberNameMatch && membershipFieldMatch && checkedInMatch && limitedMatch && onlyFirstMatch && actionsMatch;
            
        });

        this.setState({filteredTableData: filteredTableData});
        this._calcCounts(filteredTableData);
    }
        
    render() {
        
        const labelStyle = {paddingLeft: 2, fontWeight: 500}; 
        const valueStyle = {textAlign: 'right', paddingLeft: 15, paddingRight: 2};
                
        return (
            <div>
                {this.getConfirmAlertComponent()}
                            
                <DataTableComponent title={this.getBusyComponent('left')} data={this.state.filteredTableData} columns={this.columns()} options={this.state.tableOptions} hoverColor={ThemeColors.tableHover}/> 
                        
                {this.state.filteredMembershipCounts && this.state.filteredMemberCounts && this.state.filteredTableData.length > 0 ? 
                    <div style={{display: 'flex', marginTop: 20}}>

                       <div>    
                           <Typography variant="body2" style={{borderBottom: '1px solid lightGray', fontWeight: 500}}>Membership Totals</Typography>   

                           <table>
                                <tbody>
                                    <tr>
                                        <td><Typography variant="body2" style={labelStyle}>Total</Typography></td>
                                        <td><Typography variant="body2" style={valueStyle}>{this.state.filteredMembershipCounts.total}</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography variant="body2" style={labelStyle}>Suspended</Typography></td>
                                        <td><Typography variant="body2" style={valueStyle}>{this.state.filteredMembershipCounts.suspended}</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography variant="body2" style={labelStyle}>Expired</Typography></td>
                                        <td><Typography variant="body2" style={valueStyle}>{this.state.filteredMembershipCounts.expired}</Typography></td>
                                    </tr>
                                     <tr>
                                        <td><Typography variant="body2" style={labelStyle}>Virtual Guest Passes</Typography></td>
                                        <td><Typography variant="body2" style={valueStyle}>{this.state.filteredMembershipCounts.guestPasses}</Typography></td>
                                    </tr>
                                </tbody>
                           </table>

                       </div>

                       <div style={{marginLeft: 25}}>    
                           <Typography variant="body2" style={{borderBottom: '1px solid lightGray', fontWeight: 500}}>Member Totals</Typography>   
                           
                           <table>
                                <tbody>
                                    <tr>
                                        <td><Typography variant="body2" style={labelStyle}>Total</Typography></td>
                                        <td><Typography variant="body2" style={valueStyle}>{this.state.filteredMemberCounts.total}</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography variant="body2" style={labelStyle}>Suspended</Typography></td>
                                        <td><Typography variant="body2" style={valueStyle}>{this.state.filteredMemberCounts.suspended}</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography variant="body2" style={labelStyle}>Limited</Typography></td>
                                        <td><Typography variant="body2" style={valueStyle}>{this.state.filteredMemberCounts.limited}</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography variant="body2" style={labelStyle}>Entry Passes</Typography></td>
                                        <td><Typography variant="body2" style={valueStyle}>{this.state.filteredMemberCounts.entryPasses}</Typography></td>
                                    </tr>
                                </tbody>
                           </table>
                           
                       </div>   

                   </div> : null
                }

            </div>

        );

    }
}


export default withCookies(MemberListTab);