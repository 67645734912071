import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withStyles } from '@material-ui/core/styles';

import { IconButton, Tooltip, Grid, Typography, Paper, TextField, Button, FormControl, FormControlLabel, Checkbox } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import RefreshIcon from '@material-ui/icons/Refresh';
import CloseIcon from '@material-ui/icons/Close';
import BlockIcon from '@material-ui/icons/Block';

import { ThemeColors, ThemeIcons } from '../Theme'
import { PP } from '../models/PP'
import { Membership, membershipTooltip } from '../models/Membership'
import { InvoiceRequest, RecurFrequency, InvoiceRequestStatus, InvoiceRequestMode, InvoiceRequestOptions, InvoiceRequestLineItemQuanInterpretation, InvoiceRequestLineItem } from '../models/InvoiceRequest'

import { DataTable, DataTableComponent, Permissions } from 'react-frontend-utils'
import { ManageNumericField, ManageDecimalField, ManageDateField } from 'react-frontend-utils'

import { RestComponent, Email, DateUtils, TextEntryPopover, StyledTooltip, ManageBitwiseCheckboxes } from 'react-frontend-utils'




const MAX_INVOICE_LINE_ITEMS = 10;

const MAX_DAYS_DUE = 3 * 365;  //3 years


const lineItemsTooltip = (items, note) => {
    
    const style = {
        border: '1px solid gray',
        borderCollapse: 'collapse',
        textAlign: 'left',
        padding: 5,
        marginBottom: 8
    };
    
    return <div>
               Line Items
               <table style={style}>
                    <tbody>
                        {items.map((item, index) => {return item.tooltip(index, style);} )}
                    </tbody>
               </table>
               {note ? <div> Recipient Note:
                            <div style={{borderTop: '1px solid gray'}}>{note}</div>
                       </div>
                     : null}
            </div>;
};

const actionItemsTooltip = (items) => {
    
    const style = {
        textAlign: 'left'
    };
    
    return <div style={style}>
                {items.emailOnPay ? <div>{"▸  Email: " + items.emailOnPay}</div> : null}
                {items.expDateOnPay ? <div>{"▸  Set Expiration Date to: " + PP.dateFormat(items.expDateOnPay)}</div> : null}
                {items.unsuspendOnPay ? <div>▸  Unsuspend Membership</div> : null}
                {items.incGuestOnPay ? <div>{"▸  Increment Guest Passes by: " + items.incGuestOnPay}</div> : null}                               
                {items.incExpOnPay ? <div>{"▸  Increment Expiration Date by: " + items.incExpOnPay + " days"}</div> : null}                               
            </div>;
};

const actionItemsCount = (items) => {
    let count = 0;
    
    if (items.emailOnPay)
        count++;
    if (items.expDateOnPay)
        count++;
    if (items.unsuspendOnPay)
        count++;
    if (items.incGuestOnPay)
        count++;
    if (items.incExpOnPay)
        count++;
    
    return count;
};


const StatTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#FFFFFFFF',
        maxWidth: 500,
        fontSize: theme.typography.pxToRem(12)
    }
}))(Tooltip);

const statusTooltip = (val, style) => {
   
   let recurTip = "";
   if (!InvoiceRequestStatus.isComplete(val.statusEnum) && RecurFrequency.doesRecur(val.recurEnum))
       recurTip = " Recurs " + val.recurEnum.label + ".";
   
   const tooltip = val.statusEnum.tooltip + recurTip;
   
    return <div style={{...style, padding: 5}}>
               {tooltip}
            </div>;
};

const dateItem = (date) => {
    
    if (date == null)
        return <div></div>;
    
    return (
        <StyledTooltip title={PP.billingTimeFormat(date)}>
            <div>{PP.dateFormat(date)}</div>
        </StyledTooltip>
    ); 
};


export class RequestTab extends RestComponent {
  
    styles = {
        paperLabel: {
            marginLeft: 15,
            color: 'blue',
            fontSize: '12pt',
            flexGrow: 1
        },
        gridStyle: {
            marginLeft: 20, 
            marginRight: 20,
            display: 'flex',
            alignContent: 'center'
        },
        submitButton: {
            marginTop: 30,
            marginBottom: 20,
            display: 'flex', 
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        lineItem: {
            marginLeft: 5,
            marginBottom: 8,
            flexGrow: 1
        }
    };
  
       
    
  
    //An array describing each column, generate dynamically to refresh on state change
    columns = () => { return  [
        
        //Date column - data is an the InvoiceRequest Object
        //Sorting is done comparing the date
        //Rendering is the checkInTimeFormat applied to the date
        {name: "invoiceRequest", label: "Created",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.firstCellProps,
                        customBodyRender: (val) => dateItem(val.createDate),
                        sortCompare: (order) => {return (obj1, obj2) => {let p1 = obj1.data.createDate;
                                                                         let p2 = obj2.data.createDate;                                                                      
                                                                            return (p1 > p2 ? 1 : -1) * (order === 'asc' ? 1 : -1);
                                                                        };}
                     }
        },
        
        {name: "status", label: "Status",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps, 
                        customBodyRender: (val) => {   return <div> 
                                                            <StatTooltip title={statusTooltip(val, InvoiceRequestStatus.style(val.statusEnum))} >
                                                                <div style={InvoiceRequestStatus.style(val.statusEnum)}>
                                                                    <span style={{fontSize: 18}}>{RecurFrequency.doesRecur(val.recurEnum) ? "↻ " : ""}</span>
                                                                    {val.statusEnum.label}
                                                                </div>
                                                            </StatTooltip>

                                                       </div>;},
                        
                        sortCompare: (order) => {return (obj1, obj2) => {let p1 = obj1.data.statusEnum.ordinal;
                                                                         let p2 = obj2.data.statusEnum.ordinal;                                                                      
                                                                            return (p1 > p2 ? 1 : -1) * (order === 'asc' ? 1 : -1);
                                                                        };}
                     }
        },
                
        {name: "subject", label: "Subject",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps, customBodyRender: DataTable.longStringCellRenderer}}, 
  
        {name: "recipient", label: "Recipient",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps,
                        customBodyRender: (val) => {
                            
                            let recipient = val.recipientDescription();
                                                      
                            if (val.options) { //at least one
                                recipient += " (Options: ";
                                let opts = [];
                                if (val.forSuspended()) {
                                    opts.push("Suspended");
                                }
                                if (val.forExpired()) {
                                    opts.push("Expired");
                                }
                                if (val.forHasBalance()) {
                                    opts.push("Has Balance");
                                }
                                recipient += opts.join(", ");
                                recipient += ")";
                                
                            }
                            
                            return DataTable.longStringCellRenderer(recipient);
                         },
                         sortCompare: (order) => {return (obj1, obj2) => {let p1 = obj1.data.recipientDescription();
                                                                          let p2 = obj2.data.recipientDescription();                                                                      
                                                                            return (p1 > p2 ? 1 : -1) * (order === 'asc' ? 1 : -1);
                                                                          };}
                    }
        },
                
        
        {name: "lineItems", label: "Line Items",
            options: {filter: false, sort: false, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps,
                        customBodyRender: (val) => {return (
                                                        <StyledTooltip title={lineItemsTooltip(val.lineItems, val.note)}>
                                                            <div style={{border: '1px solid gray', paddingLeft: 2, borderRadius: 2}}>{val.lineItems.length + " Line Item" + (val.lineItems.length > 1 ? "s" : "") + "..."}</div>
                                                        </StyledTooltip>
                                                    );}                    
                     }
        },
  
        {name: "comment", label: "Comment",
            options: {filter: false, display: this.state.isWide, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps, customBodyRender: DataTable.longStringCellRenderer}},
        
        {name: "actions", label: "On Pay Actions",
            options: {filter: false, sort: false, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps,
                        customBodyRender: (val) => {const count = actionItemsCount(val);
                                                    if (count === 0)
                                                        return <div>None</div>;
                            
                                                    return (
                                                        <StyledTooltip title={actionItemsTooltip(val)}>
                                                            <div style={{border: '1px solid gray', paddingLeft: 2, borderRadius: 2}}>{count + " Action" + (count > 1 ? "s" : "") + "..."}</div>
                                                        </StyledTooltip>
                                                    );}                    
                     }
        },
        
        {name: "nextSendDate", label: "Scheduled",
            options: {filter: false, display: !this.state.isMobile, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps,
                        customBodyRender: val => dateItem(val)                       
                     }
        },
        
        {name: "lastSentDate", label: "Last Sent",
            options: {filter: false, display: !this.state.isMobile, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps,
                        customBodyRender: val => dateItem(val)                       
                     }
        },
            
        {name: "lastActivity", label: "Last Activity",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.lastCellProps, customBodyRender: DataTable.longStringCellRenderer}},
    


    ];};


    _optionsRef = React.createRef();    //reference for the options ManageCheckbox widget
    
    _lastRowClick = null;        //time of the last row click
    _onDisplayInvoicesByIR;     //callback function when double clicking on a row
    _fetchCount = 0;            //when creating new Invoice, we fetch Memberships and Types.  This count determines when both fetches are done, and we can render
   
    
    tableHasSelectedRows = () => {
        return this.state.selectedRow >= 0;
    }
    
    
    //True if a row can be modified
    canModify = () => {
        if (!this.tableHasSelectedRows())
            return false;
        
        const row = this.state.tableData[this.state.selectedRow];
        
        //selected row not in filtered data
        if (row === undefined)
            return false;
        
        return true;
    }
    
    
    
    constructor(props) {
        super(props);
        this._onDisplayInvoicesByIR = props.onDisplayInvoicesByIR;
        
        this.state.invoiceRequests = [];    //array of InvoiceRequests, from last fetch
        
        this.state.selectedRow =  -1;       //the currently selected row in the table
        this.state.newInvoiceRequestOpen = false;       //display the new Invoice form, instead of the table
        
        this.state.tableData = [];         //all the table data
        
        
        //Fields below are used when creating a new Invoice Request
        this.state.membershipTypes = [];    //fetched types from database, array of type names
        this.state.selectedMembershipType = null;   //currently selected type
        
        this.state.memberships = [];        //fetched JsonMemberships array from database
        this.state.selectedMembership = null;       //currently selected Membership
        
        this.state.subjectField = "";
        this.state.mode = InvoiceRequestMode.ALL.ordinal;
        this.state.recipientEmailField = "";        
        this.state.options = 0;
        
        this.state.lineItems = [];      //Array of InvoiceRequestLineItem objects, one for each line in the new invoice
       
        this.state.scheduleDate = null;
        this.state.dueDate = null;
        this.state.recur = RecurFrequency.NEVER.ordinal;
        this.state.note = "";
        this.state.comment = "";
        this.state.expDateOnPay = null;
        this.state.emailOnPay = null;
        this.state.unsuspendOnPay = false;
        this.state.incGuestOnPay = 0;
        this.state.incExpOnPay = 0;
        
        this.state.voidCommentOpen = false;
               
        this.state.tableOptions =  {onRowSelectionChange: ((currentRowsSelected) => this._rowClick(currentRowsSelected)),
                                    customToolbar: () => {return null; },
                                    setRowProps: (row, dataIndex, rowIndex) => this._setRowProps(row, dataIndex, rowIndex)
                                    };
        
        
    }
    
    
    
    /**
     * When the page loads, refresh the table
     */
    componentDidMount() {
        super.componentDidMount();
        this._updateSize();
        window.addEventListener("resize", this._updateSize);
        
        this._refreshTable();

    }
    
    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener("resize", this._updateSize);
    }

    //callback when window changes size
    _updateSize = () => {
        this.setState({ isMobile: window.innerWidth < 960, isWide: window.innerWidth > 1280 });
    }
    
    
    _setRowProps = (row, dataIndex, rowIndex) => {
        return DataTable.getRowStyle(this.state.selectedRow, dataIndex, rowIndex, ThemeColors.selectedColor, ThemeColors.tableAlternatingRowsGray);
    }
   
   
    //Callback when a row is clicked
    _rowClick = (currentRowsSelected) => {
     
        const row = currentRowsSelected.length > 0 ? currentRowsSelected[0].dataIndex : -1;
        
        if (row >= 0) {
            const id = this.state.tableData[row].invoiceRequest.id;
            console.log("Invoice Request List: selected row: " + row + " Request id: " + id);

            //See if it's a double click (same row, last click less than 300 ms ago) 
            const selectTime = performance.now();
            if (this._lastRowClick && row === this.state.selectedRow) {  //same row
                
                if (selectTime - this._lastRowClick < 500) {
                    console.log("Invoice Request List: double click on row: " + row);
                    this._onDisplayInvoicesByIR(id);
                    return;
                }
                //Set the current time of the click
                this._lastRowClick = selectTime;
                return;
            }
            
            //Set the current time of the click
            this._lastRowClick = selectTime;
        }
       
        this.setState( {selectedRow: row} );
       
        
    }
    
    
    //Callback when all Membership types are fetched, response is array of type Strings, select the first in the list
    _fetchMembershipTypesCallback = (response) => {
        if (response) {    
            const selectedType = response.length > 0 ? response[0] : null;
            this.setState({membershipTypes: response, selectedMembershipType: selectedType});
        } 
        this.decrementBusy();
        this._fetchCount--;
        
        //If no more fetches ongoing, open the new invoice request
        if (this._fetchCount === 0) {
            this.setState({newInvoiceRequestOpen: true});
        }
        
    }

    //Callback when all Memberships are fetched, response is array of JsonMemberships, select the first in the list
    _fetchMembershipsCallback = (response) => {
        if (response) {    
            const memberships = response.map(m => new Membership(m));
            const selectedMembership = memberships.length > 0 ? memberships[0] : null;
            this.setState({memberships: memberships, selectedMembership: selectedMembership});
        } 
        this.decrementBusy();
        this._fetchCount--;
        
        //If no more fetches ongoing, open the new invoice request
        if (this._fetchCount === 0) {
            this.setState({newInvoiceRequestOpen: true});
        }
    }
    
    _refreshTable = () => {
        if (PP.selectedDatabase) {
        
            this.incrementBusy();
   
            //fetch all Invoice Requests
            this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/billing/requests", {}, this._fetchInvoiceRequestsCallback, this._fetchErrorCallback); 
        }
    }
    
    //Callback for fetching Invoice Requests - JSON response is an array of InvoiceRequest objects
    _fetchInvoiceRequestsCallback = (response) => {
        if (response) {            
            this.state.invoiceRequests = response.map((ir) => new InvoiceRequest(ir));
        }            
        this._updateTableData();
        this.decrementBusy();
    }

    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.setBusy(false);
    }
     
    
    //Clear all state for the new Invoice form
    _clearNewInvoice = () => {
        this.setState({subjectField: "", 
                       mode: InvoiceRequestMode.ALL.ordinal, 
                       recipientEmailField: "", 
                       selectedMembershipType: null, 
                       selectedMembership: null, 
                       lineItems: [], 
                       dueDate: null, 
                       scheduleDate: null, 
                       recur: RecurFrequency.NEVER.ordinal, 
                       note: "", 
                       comment: "",
                       emailOnPay: null,
                       expDateOnPay: null,
                       unsuspendOnPay: false,
                       incGuestOnPay: 0,
                       incExpOnPay: 0
                    });
        
    }
     
     
    //User selects to create a new invoice 
    _openNewInvoiceRequest = () => {
            
        this._fetchCount = 2;    
            
        //fetch all membership types
        this.incrementBusy();
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/membership/types", {}, this._fetchMembershipTypesCallback); 

        //fetch all memberships
        this.incrementBusy();
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/memberships", {}, this._fetchMembershipsCallback); 

    } 
     
     
    _canStop = () => {
        if (!this.tableHasSelectedRows())
            return false;
        
        const row = this.state.tableData[this.state.selectedRow];
        const ir = row.invoiceRequest;
        
        return ir.status === InvoiceRequestStatus.SCHEDULED;
    }
    
    _canVoid = () => {
        if (!this.tableHasSelectedRows())
            return false;
        
        const row = this.state.tableData[this.state.selectedRow];
        const ir = row.invoiceRequest;
        
        return ir.status === InvoiceRequestStatus.SENT || ir.status === InvoiceRequestStatus.STOPPED || 
               (ir.status === InvoiceRequestStatus.SCHEDULED && ir.cycle > 0);
    }
    
    
    _askStopInvoiceRequest = () => {
        if (!this.tableHasSelectedRows())
            return;
        
        const row = this.state.tableData[this.state.selectedRow];
        const ir = row.invoiceRequest;

        this.showConfirmAlert("Confirm", 
                              "Stop scheduling the Invoice Request \"" + ir.subject + "\"?",
                              'black',
                              "Cancel", 
                              () => this._stopInvoice(ir.id),
                              "Stop",
                              'red');
    }
    
    _stopInvoice = (id) => {
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/billing/requests/" + id + "/stop", 
                             {method: "POST"}, 
                             this._refreshTable,
                             this._fetchErrorCallback); 
    }
    
    _askVoidInvoiceRequest = (comment) => {
        if (!this.tableHasSelectedRows())
            return;
        
        const row = this.state.tableData[this.state.selectedRow];
        const ir = row.invoiceRequest;

        this.showConfirmAlert("Confirm", 
                              "Void all open invoices for Invoice Request \"" + ir.subject + "\"? This operation cannot be cancelled.",
                              'black',
                              "Cancel", 
                              () => this._voidInvoiceRequest(ir.id, comment),
                              "Void All",
                              'red');
    }
    
    _voidInvoiceRequest = (id, comment) => {
        
        this.setState({voidCommentOpen: false});
        this.incrementBusy();
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/billing/requests/" + id + "/void", 
                             {method: "POST", body: JSON.stringify({comment: comment})}, 
                             this._voidInvoiceRequestResponse,
                             this._fetchErrorCallback); 
    }
    
    _voidInvoiceRequestResponse = (response) => {
               
        this.decrementBusy();
        this.showConfirmAlert("Request to Void Submitted", "Check the Invoices list in a few minutes to check the status", 'green');
    }
    
    
    //User requests to delete an invoice
    _askDeleteInvoiceRequest = () => {
        if (!this.tableHasSelectedRows())
            return;
        
        const row = this.state.tableData[this.state.selectedRow];
        const ir = row.invoiceRequest;
        
        if (!InvoiceRequestStatus.isComplete(ir.status)) {
            
            if (ir.status === InvoiceRequestStatus.SCHEDULED)          
                this.showConfirmAlert("Cannot Delete", "You must first stop a Scheduled Invoice Request before deleting it", 'black');
            
            else
                this.showConfirmAlert("Cannot Delete", "Please wait until the Invoice Request has completed processing before deleting it", 'black');
                
            return;
        }
        
        this.showConfirmAlert("Confirm", 
                              "Delete Invoice Request \"" + ir.subject + "\"?",
                              'black',
                              "Cancel", 
                              () => this._deleteInvoice(ir.id),
                              "Delete",
                              'red');
    } 
    
    _deleteInvoice = (id) => {
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/billing/requests/" + id, 
                             {method: "DELETE"}, 
                             this._refreshTable,
                             this._fetchErrorCallback); 
    }
    
    
     
     //User presses Submit button on the new Invoice Request form
    _askSubmitInvoice = () => {
                    
        if (this.state.newInvoiceRequestOpen) {
            //Check required information
            this.showConfirmAlert("Confirm", 
                                    "Submit the Invoice Request for processing?",
                                    'red',
                                    "Cancel", 
                                    this._submitNewInvoice,
                                    "Submit",
                                    'black');
        }
    } 
     
    _submitNewInvoice = () => {
        
        let options = this._optionsRef.current ? this._optionsRef.current.getValue() : 0;        
        let expDateOnPay = this.state.expDateOnPay;
        let unsuspendOnPay = this.state.unsuspendOnPay;
        let incGuestOnPay = this.state.incGuestOnPay;
        let incExpOnPay = this.state.incExpOnPay;
        let recipient;
        
        switch (this.state.mode) {
          
            case InvoiceRequestMode.TYPE.ordinal:
                recipient = this.state.selectedMembershipType;
                break;
                                                     
            case InvoiceRequestMode.MEMBERSHIP.ordinal:
                recipient = this.state.selectedMembership.id;
                break;

            case InvoiceRequestMode.OTHER.ordinal:
                recipient = this.state.recipientEmailField;
                
                //For other, these can't be selected
                options = 0;    
                expDateOnPay = null;
                unsuspendOnPay = false;
                incGuestOnPay = 0;
                incExpOnPay = 0;
                break;    
            
            default:
                recipient = null;
                break;
        }
          
        
        const newInvoiceRequest = InvoiceRequest.create(this.state.subjectField,
                                                        this.state.mode,
                                                        recipient, 
                                                        options, 
                                                        this.state.lineItems, 
                                                        this.state.scheduleDate,
                                                        this._getDaysDue(),
                                                        this.state.recur,
                                                        this.state.note, 
                                                        this.state.comment,
                                                        expDateOnPay,
                                                        this.state.emailOnPay,
                                                        unsuspendOnPay,
                                                        incGuestOnPay,
                                                        incExpOnPay);

        this.incrementBusy();

        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/billing/requests", 
                             {method: "POST", body: JSON.stringify(newInvoiceRequest)}, 
                             this._submitInvoiceCallback,
                             this._fetchErrorCallback); 
        
    }
    
    _submitInvoiceCallback = (response) => {
                
        this.decrementBusy();        
        this._clearNewInvoice();
        this.setState({newInvoiceRequestOpen: false});
        this._refreshTable();  //pull in the change to the table
        
    }
    

    //Called when fetch is done, creates the table data
    _updateTableData = () => {
        
        
        const tableData = this.state.invoiceRequests.map(ir => {

            //For each ir, create a row with all the column data
            return {invoiceRequest: ir,
                    status: {statusEnum: ir.status, recurEnum: ir.recur},
                    subject: ir.subject,
                    recipient: ir,
                    lineItems: {lineItems: ir.lineItems, note: ir.note},
                    daysDue: ir.daysDue,
                    comment: ir.comment,
                    nextSendDate: ir.nextSendDate,
                    lastSentDate: ir.lastSentDate,
                    lastActivity: ir.lastActivity,
                    actions: {emailOnPay: ir.emailOnPay, expDateOnPay: ir.expDateOnPay, unsuspendOnPay: ir.unsuspendOnPay, incGuestOnPay: ir.incGuestOnPay, incExpOnPay: ir.incExpOnPay}
                   }; 
        });
        
        //Sort the table by ID, descending
        tableData.sort((a, b) => {
           return a.invoiceRequest.id < b.invoiceRequest.id ? 1 : -1;           
        });
         
        this.setState({tableData: tableData, selectedRow: -1});  

    }


    _closeNewInvoiceRequest = () => {
        this._clearNewInvoice();
        this.setState({newInvoiceRequestOpen: false});
    }
    
    //Adds a new line item to the end of the list
    _addNewLineItem = () => {
        
        const lineItem = InvoiceRequestLineItem.create();  //blank-default
        const items = this.state.lineItems;
        items.push(lineItem);
        this.setState({lineItems: items});  
    }

    //Removes a line item with the specified index
    _removeLineItem = (index) => {
        const items = this.state.lineItems;      
        items.splice(index, 1);   //remove the index'th element
        this.setState({lineItems: items});  
    }
    
    //Updates a field in the line item for the specified index. The field is the name of the field in the InvoiceRequestLineItem object
    _updateLineItem = (index, field, val) => {

        const items = this.state.lineItems;
        items[index][field] = val;
        this.setState({lineItems: items});  
    }
    
    
    _dateFieldInputError = (label, error) => {
        this.showConfirmAlert("Error in " + label, error, 'red');
    }
    


    _getDaysDue = () => {
        
        if (this.state.dueDate === null)
            return null;
        
        const scheduleDate = this.state.scheduleDate ? this.state.scheduleDate : DateUtils.startOfToday();
        const elapsedMillis = (this.state.dueDate - scheduleDate);     
        const daysDue = parseInt(elapsedMillis/(1000 * 3600 * 24));
        return daysDue;
    }

    //Called when the Invoice schedule date is changed, update the days Due field
    _schedDateChanged = (json, dateString) => {
        
        let date = null;
        if (dateString) {
            date = DateUtils.parseJsonDate(dateString);
            date.setHours(0, 0, 0, 0);  //zero time component 
        }
        
        this.setState({scheduleDate: date}); 
    }
    
    //Called when the Invoice due date is changed, update the days Due field
    _dueDateChanged = (json, dateString) => {     
        let date = null;
        if (dateString) {
            date = DateUtils.parseJsonDate(dateString);
            date.setHours(0, 0, 0, 0);  //zero time component 
        }
        this.setState({dueDate: date});   
    }
    
    //Called when the Action expiration date is changed 
    _expDateChanged = (json, dateString) => {
        this.setState({expDateOnPay: dateString});
    }
    
    
    //-----------------------------Returns the JSX for the line item specified by index--------------------------------------
    _getLineItemWidget = (index) => {
    
        const options = [{title: InvoiceRequestLineItemQuanInterpretation.NORMAL.label, ordinal: 0, tooltip: "Line Item Total = Unit Price x Quantity", quanTitle: "Quantity"}, 
                         {title: InvoiceRequestLineItemQuanInterpretation.PER_MEMBER.label, ordinal: 1, tooltip: "Line Item Total = Unit Price x Number of Members", quanTitle: "Quantity"}, 
                         {title: InvoiceRequestLineItemQuanInterpretation.OVERDUE_BAL.label, ordinal: 2, tooltip: "Line Item Total = Percent x Overdue Balance", quanTitle: "Percent"}]; 


        const lineItem = this.state.lineItems[index];  //This is the InvoiceRequestLineItem object for this index
        
        const quanInterpEnumOrdinal = lineItem.quanInterp;  //The ordinal of the InvoiceRequestLineItemQuanInterpretation
        const quanTitle = options[quanInterpEnumOrdinal].quanTitle;
       
        return (        
            <div key={index} style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', padding: 5, paddingBottom: 5}}>
                                                          
                <Typography variant="body2" style={{fontSize: 18, fontWeight: 'bold', color: 'blue', marginBottom: 8}}>{String(index+1).padStart(2, "0")}</Typography>

                <TextField label="Line Item Description" value={lineItem.name}
                            onChange={(event) => this._updateLineItem(index, "name", event.target.value)} 
                            variant="outlined" style={{minWidth: '30%', ...this.styles.lineItem}} size="small" InputLabelProps={{ shrink: true }} />
                <Tooltip title={options[quanInterpEnumOrdinal].tooltip}>
                    <Autocomplete
                        size='small'
                        value={options[quanInterpEnumOrdinal]}
                        onChange={(event, newValue) => {this._updateLineItem(index, "quanInterp",  newValue.ordinal);}}
                        options={options}
                        style={{maxWidth: 220, minWidth: 160, ...this.styles.lineItem}}
                        getOptionLabel={(option) => option.title}
                        blurOnSelect
                        openText="InvoiceRequestLineItemQuanInterpretation"
                        disableClearable
                        renderInput={(params) => <TextField {...params} label="Quantity Type" variant="outlined" InputLabelProps={{ shrink: true }} />}
                    />
                </Tooltip>
                
                {quanInterpEnumOrdinal === InvoiceRequestLineItemQuanInterpretation.PER_MEMBER.ordinal ? null :

                    <ManageNumericField textAlign='right' hideButtons={true} minValue={0} hasInfinity={false} 
                                            json="quantity" label={quanTitle} initialValue={1}
                                            style={{width: 120, ...this.styles.lineItem}} autoAccept={true}
                                            onFieldChange={(json, val) => this._updateLineItem(index, json, val)}/>
                }
  
                <div style={{display: 'flex'}}>
                
                    {quanInterpEnumOrdinal === InvoiceRequestLineItemQuanInterpretation.OVERDUE_BAL.ordinal ? null :
                
                        <ManageDecimalField textAlign='right' decimalPlaces={2} hideButtons={true} minValue={0} hasInfinity={false} 
                                            json="unitPrice" label={"Unit Price (" + PP.currency + ")"} autoAccept={true} initialValue={0}
                                            style={{width: 120, ...this.styles.lineItem}}
                                            onFieldChange={(json, val) => this._updateLineItem(index, json, val)}/>
                    }
           
                    <Tooltip title={"Remove Invoice Line Item" + (index+1)}>
                        <IconButton edge="end" onClick={() => this._removeLineItem(index)} style={{marginTop: -4, flexGrow: 1}} enterTouchDelay={0}>
                            <CloseIcon style={{color: ThemeColors.cancelGray}} />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        );
    }
    
    //-------------------------------------------------------------------------------------------------------------------------

        
    render() {
        
        const validEmail = this.state.mode === InvoiceRequestMode.OTHER.ordinal ? Email.validateEmail(this.state.recipientEmailField) : true;
                      
                
        const modeOptions = [{title: InvoiceRequestMode.ALL.label, ordinal: 0}, 
                             {title: InvoiceRequestMode.TYPE.label, ordinal: 1}, 
                             {title: InvoiceRequestMode.MEMBERSHIP.label, ordinal: 2}, 
                             {title: InvoiceRequestMode.OTHER.label, ordinal: 3}];
                         
        const recurOptions = [  {title: RecurFrequency.NEVER.label, ordinal: 0},
                                {title: RecurFrequency.WEEKLY.label, ordinal: 1},
                                {title: RecurFrequency.BIWEEKLY.label, ordinal: 2},
                                {title: RecurFrequency.MONTHLY.label, ordinal: 3},
                                {title: RecurFrequency.BIMONTHLY.label, ordinal: 4},
                                {title: RecurFrequency.TRIMONTHLY.label, ordinal: 5},
                                {title: RecurFrequency.SEMIANNUALY.label, ordinal: 6},
                                {title: RecurFrequency.ANNUALLY.label, ordinal: 7},
                                {title: RecurFrequency.BIENNIALLY.label, ordinal: 8}
                            ];
            
        const recurText = RecurFrequency.fromOrdinal(this.state.recur).description;             
    
            
        let recipientWidget;
        switch (this.state.mode) {
            case InvoiceRequestMode.ALL.ordinal:
                recipientWidget = null;
                break;
                
            case InvoiceRequestMode.TYPE.ordinal:
                recipientWidget = <Autocomplete
                                        size='small'
                                        value={this.state.selectedMembershipType}
                                        onChange={(event, newValue) => {this.setState({selectedMembershipType: newValue});}}
                                        options={this.state.membershipTypes}
                                        blurOnSelect
                                        openText="MembershipType"
                                        disableClearable
                                        renderInput={(params) => <TextField {...params} label="Membership Type" variant="outlined" InputLabelProps={{ shrink: true }} />}
                                    />
                break;
                
            case InvoiceRequestMode.MEMBERSHIP.ordinal:
                recipientWidget = <div style={{display: 'flex', alignItems: 'center'}}>      
                                        <Autocomplete
                                            size='small'
                                            value={this.state.selectedMembership}
                                            onChange={(event, newValue) => {this.setState({selectedMembership: newValue});}}
                                            options={this.state.memberships}
                                            getOptionLabel={(option) => option.id}
                                            fullWidth
                                            blurOnSelect
                                            openText="Memberships"
                                            disableClearable
                                            renderInput={(params) => <TextField {...params} label="Membership ID" variant="outlined" InputLabelProps={{ shrink: true }} />}
                                        />
                                        <StyledTooltip title={membershipTooltip(this.state.selectedMembership)}>
                                            <Typography variant="body2" style={{fontSize: 16, fontWeight: 'bold', color: 'blue', marginLeft: 8}}>ⓘ</Typography>
                                        </StyledTooltip>    
                                    </div>
                break;
                
            case InvoiceRequestMode.OTHER.ordinal:
                recipientWidget =  <Tooltip title="Enter the email address of the recipient">
                                        <TextField label="Recipient Email" value={this.state.recipientEmailField}
                                                   onChange={(event) => this.setState({recipientEmailField: event.target.value})} 
                                                   variant="outlined" fullWidth size="small" InputLabelProps={{ shrink: true }} />
                                    </Tooltip>
                break;
            
            default:
                recipientWidget = <div>Unknown Request Mode</div>;
                break;
        }
        
        const canIssue = PP.user.hasPermissionTo(Permissions.ISSUE_INVOICES);

        const canStop= this._canStop() && canIssue;
        const canVoid = this._canVoid() && canIssue;
        
        const daysDue = this._getDaysDue();
        
        let datesOk = false;
        let dueDateText = "";
        let dueDateColor = 'blue';
        let sendDate = this.state.scheduleDate ? "the scheduled send date " + PP.dateFormat(this.state.scheduleDate) :  "today";

        if (daysDue !== null) { 
            
            if (daysDue < 1) {
                dueDateText = "Invalid due date: must be at least one day after " + sendDate;
                dueDateColor = 'red';
            }
            else if (daysDue > MAX_DAYS_DUE) {
                dueDateText = "Invalid due date: must not be more than " + MAX_DAYS_DUE + " days after " + sendDate;
                dueDateColor = 'red';
            }
            else if (this.state.scheduleDate !== null && this.state.scheduleDate < DateUtils.startOfToday()) {
                dueDateText = "Invalid scheduled send date: must not be in the past";
                dueDateColor = 'red';
            }
            else {
                dueDateText = "Invoice will be due in " + daysDue + " day" + (daysDue !== 1 ? "s" : "") + " after " + sendDate;
                datesOk = true;
            }
        }
            
        const hasMembership = this.state.mode !== InvoiceRequestMode.OTHER.ordinal;
        
        //Validate form fields entered before allowing submit
        const canSubmit = this.state.subjectField.trim().length > 0 &&
                                this.state.lineItems.length > 0 &&   
                                datesOk &&
                                validEmail;
                
 
                
        return (
            <div>

                {this.getConfirmAlertComponent()}
                
                 <TextEntryPopover isOpen={this.state.voidCommentOpen} showSkip={false} multiline={true} title="Void Comment/Reason" 
                                 okCallback={(text) => this._askVoidInvoiceRequest(text)} 
                                 cancelCallback={() => this.setState({voidCommentOpen: false})}/>

    
                { this.state.newInvoiceRequestOpen ? 
                    //----------------- New Invoice Request Form -----------------------}
                    <Paper style={{padding: 20, marginLeft: this.state.isMobile ? 5 : 30, marginRight: this.state.isMobile ? 5 : 30}}>

                        <div>

                            <div style={{display: 'flex', height: 40}}>
                                <Typography variant="body2" style={this.styles.paperLabel}>Create New Invoice Request</Typography> 

                                <Tooltip title="Cancel">
                                    <IconButton edge="end" disabled={this.state.groupToEdit === null} onClick={this._closeNewInvoiceRequest} style={{marginTop: -4, marginRight: 4}}>
                                        <CancelIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            
                            <Grid container direction='row' style={{marginTop: 10}} spacing={2} >

                                <Grid item xs={12}>
                                    <Tooltip title="A brief description of the purpose for this Invoice Request. Not visible to invoice recipients.">
                                        <TextField label="Title/Subject" value={this.state.subjectField}
                                                   onChange={(event) => this.setState({subjectField: event.target.value})} 
                                                   variant="outlined" fullWidth size="small" InputLabelProps={{ shrink: true }} />
                                    </Tooltip>
                                </Grid>
                                 
                                <Grid item xs={6}>
                                
                                    <Autocomplete
                                        size='small'
                                        value={modeOptions[this.state.mode]}
                                        onChange={(event, newValue) => {this.setState({mode: newValue.ordinal});}}
                                        options={modeOptions}
                                        getOptionLabel={(option) => option.title}
                                        fullWidth
                                        blurOnSelect
                                        openText="Recipient Mode"
                                        disableClearable
                                        renderInput={(params) => <TextField {...params} label="Recipient Mode" variant="outlined" InputLabelProps={{ shrink: true }} />}
                                    />
                                </Grid>
                                <Grid item xs={6} >
                                    {recipientWidget}
                                </Grid>
                                
                                {hasMembership ?
                                    <Grid item xs={12} >
                                        <ManageBitwiseCheckboxes json="options" label="Options" labels={InvoiceRequestOptions} ref={this._optionsRef} 
                                                                 initialValue={this.state.options} isReadOnly={!hasMembership}/>   
                                    </Grid> : null
                                }
                                
                                <Grid item xs={12} >
                                    <div style={{marginBottom: 10}}>
                                        <Tooltip title="Add a Invoice Line Item">
                                            <IconButton disabled={this.state.lineItems.length >= MAX_INVOICE_LINE_ITEMS} edge="end" onClick={this._addNewLineItem} style={{display: 'flex', marginBottom: -20, marginLeft: 'auto', marginRight: 4}} enterTouchDelay={0}>
                                                <AddCircleOutlineIcon style={{color: ThemeColors.addColor}} />
                                            </IconButton>
                                        </Tooltip>
                                        <Typography variant="body2" style={{fontSize: 12, color: 'gray', marginLeft: 5}}>Line Items</Typography>                
                                        <div style={{border: '1px solid #CCCCCC', borderRadius: '4px', padding: 10}}>

                                            {this.state.lineItems.map((item, index) => {return this._getLineItemWidget(index);})}
                                        </div>
                                    </div>
                                </Grid>
                                
                                <Grid item xs={12}>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <ManageDateField hasNever={false} json={"scheduledDate"} style={{maxWidth: 200, marginRight: 20}} maxYear={DateUtils.currentYear()+3} minYear={2020} autoAccept={true} label="Scheduled Send Date" onFieldChange={this._schedDateChanged} onParseError={this._dateFieldInputError} dateFormat={PP.getDateFormat()} calendarColor={ThemeColors.calendarColor}/>                                        
                                        <ManageDateField hasNever={false} json={"dueDate"} style={{maxWidth: 200, marginRight: 20}} maxYear={DateUtils.currentYear()+3} minYear={2020} autoAccept={true} label="Due Date" onFieldChange={this._dueDateChanged} onParseError={this._dateFieldInputError} dateFormat={PP.getDateFormat()} calendarColor={ThemeColors.calendarColor}/>                                        
                                        <Autocomplete
                                            size='small'
                                            style={{maxWidth: 220, marginLeft: 'auto'}}
                                            value={recurOptions[this.state.recur]}
                                            onChange={(event, newValue) => {this.setState({recur: newValue.ordinal});}}
                                            options={recurOptions}
                                            getOptionLabel={(option) => option.title}
                                            fullWidth
                                            blurOnSelect
                                            openText="Recurrence"
                                            disableClearable
                                            renderInput={(params) => <TextField {...params} label="Recurrence" variant="outlined" InputLabelProps={{ shrink: true }} />}
                                        />
                                    </div>                        
                                    <div style={{display: 'flex', alignItems: 'center', marginTop: 5, marginBottom: 15}}>
                                        <Typography variant="body2" style={{fontSize: 14, color: dueDateColor, }}>{dueDateText}</Typography>
                                        <Typography variant="body2" style={{fontSize: 14, color: 'blue', marginLeft: 'auto'}}>{recurText}</Typography>
                                    </div>
                                </Grid>
                                
                                <Grid item md={6} xs={12}>
                                    <Tooltip title="Note to the recipient, visible on the invoice.">
                                        <TextField label="Invoice Note" value={this.state.note} multiline={true} rows={3}
                                                   onChange={(event) => this.setState({note: event.target.value})}
                                                   inputProps={{ maxLength: 250 }}
                                                   variant="outlined" fullWidth size="small" InputLabelProps={{ shrink: true }} />
                                    </Tooltip>
                                </Grid>
                                
                                <Grid item md={6} xs={12}>
                                    <Tooltip title="Internal comment. Not visible to invoice recipients.">
                                        <TextField label="Internal Comment" value={this.state.comment} multiline={true} rows={3}
                                                   onChange={(event) => this.setState({comment: event.target.value})} 
                                                   variant="outlined" fullWidth size="small" InputLabelProps={{ shrink: true }} />
                                    </Tooltip>
                                </Grid>
                                
                                <Grid item xs={12} >
                                    <div style={{marginTop: 10}}>
                                        <Typography variant="body2" style={{fontSize: 12, color: 'gray', marginLeft: 5}}>Actions When Invoice Paid</Typography>                
                                        <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', border: '1px solid #CCCCCC', borderRadius: '4px', paddingLeft: 10, paddingRight: 10, paddingTop: 10}}>

                                            <Tooltip title="An status email will be sent to this address each time an invoice from this request is paid">
                                                <TextField label="Send Email To" value={this.state.emailOnPay}
                                                           onChange={(event) => this.setState({emailOnPay: event.target.value})} 
                                                           variant="outlined" fullWidth style={{width: 280, maxWidth: '50%', marginRight: 40, marginBottom: 10}} size="small" InputLabelProps={{ shrink: true }} />
                                            </Tooltip>

                                            {hasMembership ? 
                                                <ManageDateField hasNever={true} neverText="Unchanged" initialValue={this.state.expDateOnPay} neverButtonText="Clear" json={"expDate"} style={{maxWidth: 400, marginRight: 40, marginBottom: 10}} maxYear={DateUtils.calendarMaxYear()} autoAccept={true} label="New Expiration Date" onFieldChange={this._expDateChanged} onParseError={this._dateFieldInputError} calendarColor={ThemeColors.calendarColor} />                                        
                                                : null}
                                                
                                            {hasMembership ? 
                                                <Fragment>
                                                    <FormControl component="fieldset" >  
                                                        <FormControlLabel style={{marginRight: 40, marginBottom: 10}} control={<Checkbox checked={this.state.unsuspendOnPay} 
                                                                            onChange={() => this.setState((prevState) => ({unsuspendOnPay: !prevState.unsuspendOnPay}))} 
                                                                            color="primary"/>} label="Unsuspend Membership"/>
                                                    </FormControl>

                                                    <ManageNumericField textAlign='right' hideButtons={true} minValue={0} hasInfinity={false} 
                                                                        json="incGuest" label="Increment Guest Passes" initialValue={this.state.incGuestOnPay}
                                                                        style={{width: 200, marginBottom: 10, marginRight: 40}} autoAccept={true}
                                                                        onFieldChange={(json, val) => this.setState({incGuestOnPay: val})}/>

                                                    <ManageNumericField textAlign='right' hideButtons={true} minValue={0} hasInfinity={false} 
                                                                        json="incExp" label="Increment Expiration Date (days)" initialValue={this.state.incExpOnPay}
                                                                        style={{width: 240, marginBottom: 10}} autoAccept={true}
                                                                        onFieldChange={(json, val) => this.setState({incExpOnPay: val})}/>                
                                                </Fragment>               
                                                : null}
                                            
                                        </div>
                                    </div>
                                </Grid>
                                
                                
                            </Grid> 
                            
                           
                            <Button onClick={this._askSubmitInvoice} variant="contained" fullWidth={false} color='primary' style={this.styles.submitButton} disabled={!canSubmit}>Submit Request</Button>

                            {this.getBusyComponent('center')}
                        </div>

                    </Paper>
                
                    :

                    //----------------- Invoice Request Table and Controls -----------------------

                    <div>  

                        <div style={{display: 'flex', float: 'right', marginTop: 15}}>
                            <Tooltip title={"Refresh the Table"}>
                                <IconButton disabled={false} edge="end" onClick={this._refreshTable} style={{marginTop: -4}} enterTouchDelay={0}>
                                    <RefreshIcon style={{color: 'gray'}} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={"Create New Invoice Request"}>
                                <IconButton disabled={!canIssue} edge="end" onClick={this._openNewInvoiceRequest} style={{marginTop: -4}} enterTouchDelay={0}>
                                    <AddCircleOutlineIcon style={{color: canIssue ? ThemeColors.addColor : 'lightGray'}} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={"Stop Scheduling Invoice Request"}>
                                <IconButton disabled={!canStop} edge="end" onClick={this._askStopInvoiceRequest} style={{marginTop: -4}}>
                                    <ThemeIcons.StopIcon style={{color: canStop ? ThemeColors.errorRed : 'lightGray'}} />
                                </IconButton>
                            </Tooltip>  
                            <Tooltip title={"Void all unpaid Invoices from this Invoice Request"}>
                                <IconButton disabled={!canVoid} edge="end" onClick={() => {this.setState({voidCommentOpen: true});}} style={{marginTop: -4}}>
                                    <BlockIcon style={{color: canVoid ? ThemeColors.errorRed : 'lightGray'}} />
                                </IconButton>
                            </Tooltip> 
                            <Tooltip title={"Delete Invoice Request"}>
                                <IconButton disabled={!this.tableHasSelectedRows() || !canIssue} edge="end" onClick={this._askDeleteInvoiceRequest} style={{marginTop: -4, marginRight: 4}}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>     
                        </div>                          

                        <DataTableComponent title={this.getBusyComponent('left')} data={this.state.tableData} columns={this.columns()} options={this.state.tableOptions} hoverColor={ThemeColors.tableHover}/>       

                    </div>
                }
                
            </div>

        );

    }
}



export default withCookies(RequestTab);

