import React from 'react';
import { withCookies } from 'react-cookie';
import NewWindow from 'react-new-window'

import { RestComponent } from 'react-frontend-utils' 
import { IconButton, Tooltip, Grid, Typography, Paper, List, Divider, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction } from '@material-ui/core'

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import BlockIcon from '@material-ui/icons/Block';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PrintIcon from '@material-ui/icons/Print';

import { PP } from '../models/PP'
import { Account, AccountRequest } from '../models/Account'
import { Order } from '../models/Order'
import { TransactionType } from '../models/Transaction'
import { ThemeColors } from '../Theme'

import { multilineJSX, StyledTooltip, Permissions } from 'react-frontend-utils'
import { DataTable, DataTableComponent, BasicTableToolbar, TextEntryPopover } from 'react-frontend-utils'
import AdjustBalancePopover from '../components/AdjustBalancePopover'
import { ManagedField } from '../components/ManagedField'

/**
 * The AccountList tab shows a list of all Accounts.
 * On mounting of the component, the list of all Accounts is fetched from the server. 
 * 
 * Props passed:
 * 
 * selectedAccount: if defined, the account to select after fetching the list of all Accounts
 * onShowAccountInTransactions: callback passing the account id, to switch to the Transactions tab and auto-fill the Account ID
 * 
 */



//Returns a JSX format of account data suitable for use on a tooltip on hover over the Account column
const accountTooltip = (account) => {
    if (!account)
        return "";
        
    return (<div>
                <div><b>Ticket Number: </b>{account.id}</div> 
                <div><b>Name: </b>{multilineJSX(account.name, true)}</div> 
                <div><b>Email: </b>{multilineJSX(account.email, true)}</div> 
                <div/>
            </div>
            
           );  
};




const PRINT_DELAY = 3000; //ms

export class AccountTab extends RestComponent {
    
    
    _manageAccountFields = [
    
        {component: "TextField", json: "id", label: "Ticket Number",  ref: React.createRef(), initialValue: (account) => { return account.id; } },
        {component: "Switch", color: 'green', json: "active", label: "Enabled", editable: true, tooltip: "Switch on to enable deposit, purchasing, and redemptions", ref: React.createRef(), initialValue: (account) => { return account.active; } },
        {component: "TextField", json: "name", label: "Name", editable: true, ref: React.createRef(), initialValue: (account) => { return account.name; } },
        {component: "EmailField", json: "email", label: "Email", editable: true, ref: React.createRef(), initialValue: (account) => { return account.email; } },
        {component: "TextField", json: "referDate", label: "Last Email Inquiry",  tooltip: "The last time ticket information was sent to the ticket holder's email address", ref: React.createRef(), initialValue: (account) => { return account.referDate ? PP.billingTimeFormat(account.referDate) : "Never"; } },
        {component: "Decimal", json: "balance", label: "Funds Balance (" + PP.currency + ")",  decimalPlaces: 2, ref: React.createRef(), initialValue: (account) => { return account.balance; } },
        {component: "Numeric", json: "pendingTransations", label: "Pending Transactions", hideButtons: true, ref: React.createRef(), initialValue: (account) => { return account.pendingTransactions; } }
    ];



  
    //An array describing each column, generate dynamically to refresh on state change. 

    columns = () => { return  [
        //Create column - data is an object containing a Date
        //Sorting is done comparing the date
        //Rendering is the checkInTimeFormat applied to the date, or "Never" if null
        {name: "createDate", label: "Created",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.firstCellProps,
                        sortCompare: (order) => {return (obj1, obj2) => {let val1 = obj1.data.date;
                                                                         let val2 = obj2.data.date;                                                                      
                                                                          return (val1 - val2) * (order === 'asc' ? 1 : -1);
                                                                         };
                        },
                        customBodyRender: (val) => {const date = val.date;
                                                    const text = PP.checkInTimeFormat(date);
                                                    return (
                                                         <Tooltip title={PP.billingTimeFormat(date)}>
                                                            <div>{text}</div>
                                                        </Tooltip>                                                                   
                                                    ); 
                         }
                     }
        },
        //Account ID column - data is an object that contains the account ID and the Account object
        //Sorting is done comparing the id alphabetically
        //Rendering is the ID and a tooltip containing account details
        {name: "accountID", label: "Ticket",   
            options: {filter: true, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps,
                    sortCompare: (order) => {return (obj1, obj2) => {let p1 = obj1.data.accountID;
                                                                     let p2 = obj2.data.accountID;                                                                      
                                                                     return (p1 > p2 ? 1 : -1) * (order === 'asc' ? 1 : -1);
                                                                    };},
                    customBodyRender: (val) => { let text = val ? val.accountID : null; 
                                                    
                                                 text = text.substring(0, 13) + "...";  //UUID is 36 characters
                                                
                                                 const account = val ? val.account : null;
                                                 let style = (account && account.active) ? {} : {color: 'gray'};  

                                                 return (
                                                        <StyledTooltip title={accountTooltip(account)}>
                                                            <div style={style}>{text}</div>
                                                        </StyledTooltip>
                                                       ); 
                                                 }
            }
        },
        //Name column - data is the account name
        {name: "name", label: "Name",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps, customBodyRender: DataTable.longStringCellRenderer}}, 
        
        {name: "email", label: "Email", 
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps, customBodyRender: DataTable.longStringCellRenderer}}, 
           
        {name: "locked", label: "PIN", 
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.lastCellProps}} 
        
   
    ];};


    styles = {
        paper: {
            width: '100%',
            height: '100%'
        },
        paperLabel: {
            marginLeft: 15,
            color: 'blue',
            fontSize: '12pt',
            flexGrow: 1
        },
        divider: {
            marginLeft: 10,
            marginRight: 10
        }
    };

         
    _searchText = "";               //the currently displayed search text
    _showOnlyEnabled = false
    _refFieldsNeedUpdate = false;
    _onShowAccountInTransactions;
    _toolbarRef = React.createRef();

    //Called by the custom Toolbar, to determine what color it should be (if no found records, go red
    searchFoundRecords = () => {
       if (this.state.tableData.length === 0) //no records loaded, don't color the text to indicate search failed
           return true; 
       
       return this.state.filteredTableData.length !== 0;
    }
    
    tableHasSelectedRows = () => {
        return this.state.selectedRow >= 0;
    }
    
    

    constructor(props) {
        super(props);
        
        this._onShowAccountInTransactions = props.onShowAccountInTransactions;
       
        this.state.accounts = [];           //array of Accounts, from last fetch
        this.state.selectedRow =  -1;       //the currently selected row in the table
        this.state.selectedAccount = null;
        
        this.state.newAccountPromptOpen = false;
        this.state.adjustBalancePopoverOpen = false;
        
        this.state.tableData = [];          //all the table data
        this.state.filteredTableData = [];  //filtered data from search, filters
        
        this.state.printTicketPopupOpen = false;
        this.state.qrImage = null;
        
        this.state.tableOptions =  {
                                    onRowSelectionChange: ((currentRowsSelected) => this._rowClick(currentRowsSelected)),
                                     customToolbar: () => { return ( <BasicTableToolbar 
                                                                        ref={this._toolbarRef}
                                                                        onSearchChange={this.searchFieldChanged} 
                                                                        foundRecords={this.searchFoundRecords}                                                                                                                                               onCheckedInSwitchChanged={this.checkedInFieldChanged}
                                                                        onEnabledSwitchChanged={this.enabledSwitchFieldChanged}
                                                                        tableHasSelectedRows={this.tableHasSelectedRows}
                                                                        searchTooltip="Filter the table by any matching part of a Ticket number, name, and email"
                                                                        enabledTooltip="Filter the table by only enabled Tickets"/> 
                                                                    ); },
                                    setRowProps: (row, dataIndex, rowIndex) => this._setRowProps(row, dataIndex, rowIndex)
                                    };
        
        
    }
    
   
    
    /**
     * When the page loads, fetch all members and memberships for the current database.  If there is a selected account, immediately select it
     */
    componentDidMount() {
        super.componentDidMount();

        if (PP.selectedDatabase) {
            this._fetchAccounts(this.props.selectedAccount);
        }
    }
    

    componentDidUpdate() {
        
        //If we need to refresh the reference fields, switch out of another screen
        if (this._refFieldsNeedUpdate) {
            //Lookup the ReactComponent and set its text value to the initial text value for each Membership field         
            ManagedField.setFields(this._manageAccountFields, this.state.selectedAccount);
            this._refFieldsNeedUpdate = false;
            this.forceUpdate();
        }
    }

    
    
    //Called by the search field from the Custom toolbar, when the search field text changes
    searchFieldChanged = (searchText) => {
        this._searchText = searchText;
        this._filterTableData();
    }
    
    enabledSwitchFieldChanged = (enabledSwitchState) => {
        this._showOnlyEnabled = enabledSwitchState;
        this._filterTableData();
    }
    
    _setRowProps = (row, dataIndex, rowIndex) => {
        return DataTable.getRowStyle(this.state.selectedRow, dataIndex, rowIndex, ThemeColors.selectedColor, ThemeColors.tableAlternatingRowsGray);
    }
   
   
    //Callback when a row is clicked
    _rowClick = (currentRowsSelected) => {
     
        const row = currentRowsSelected.length > 0 ? currentRowsSelected[0].dataIndex : -1;
        
        if (row >= 0) {
            const account = this.state.filteredTableData[row].accountID.account;
            console.log("Account List: selected row: " + row + " Account: " + account.id);
            this.setState({selectedRow: row});
            this._selectAccount(account.id);
        }
        else {
            this.setState( {selectedAccount: null, selectedRow: row} );   
            this._clear();
        }
    }
    
    //clear all fields
    _clear = () => {
        this.setState({selectedAccount: undefined});
        this.setBusy(false);
               
        ManagedField.setFields(this._manageAccountFields, null);
    }
    
    
    _gotoTransactions = () => {
        this._onShowAccountInTransactions(this.state.selectedAccount.id);
    }
    
    _selectAccount = (accountID) => { 
        this.setBusy(true);
     
        //fetch account inquiry
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/marketplace/accounts/" + accountID, {}, this._fetchAccountDetailCallback, this._fetchErrorCallback); 
    }
     
    _fetchAccountDetailCallback = (response) => {
        this.setBusy(false);

        if (response) {
            const detailedAccount = new Account(response);
            this.setState({selectedAccount: detailedAccount});             
            ManagedField.setFields(this._manageAccountFields, detailedAccount);
        }
    }
    
    
    
    _fetchAccounts = (selectedAccount) => {
        this.setBusy(true);
     
        //fetch all accounts
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/marketplace/accounts", {}, (response) => this._fetchAccountsCallback(response, selectedAccount), this._fetchErrorCallback); 
    }
    
    //Callback for fetching Products - JSON response is an array of Account objects
    _fetchAccountsCallback = (response, selectedAccount) => {
        if (response) {            
            this.state.accounts = response.map((account) => new Account(account));
            if (selectedAccount)
                this._selectAccount(selectedAccount);  //select an account
        }
        this.setBusy(false);
        this._updateTableData();
    }

    _sendAccountEmail = (response) => {
        this.setBusy(true);
     
        this.secureJSONFetch("/patron/databases/" + PP.selectedDatabase + "/account?email=", 
                             {method: "POST"}, this._sendAccountEmailCallback, this._fetchErrorCallback, encodeURIComponent(this.state.selectedAccount.email)); 
   
    }
   
    _sendAccountEmailCallback = () => {
        this.showConfirmAlert("Success", "Email sent to " + this.state.selectedAccount.email, 'green');
        this.setBusy(false);
    }
    
   
    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.setBusy(false);
    }
    
    
    _printTicket = () => {
        
        if (this.state.printTicketPopupOpen) {
            this.showConfirmAlert("Already Open", "A ticket to print is already open in another browser window", 'red');
            return; 
        }
        
        if (this.state.selectedAccount) {
            this.setBusy(true);
            
            const qrVal = this.state.selectedAccount.id;
        
            //Fetch the QR code image
            this.secureJSONFetch("/patron/qrImage?value=" + qrVal, {}, this._fetchQRCodeCallback, this._fetchErrorCallback); 
        } 
    }
     
     
    _fetchQRCodeCallback = (response) => {
        if (response) {
            this.setBusy(false);
            const qrCodeBase64Image = response;
            this.setState({qrImage: qrCodeBase64Image, printTicketPopupOpen: true});  //open the popup
        }
    } 
     
     _adjustBalancePromptOkCallback = (balanceChange, comment) => {
            
        if (balanceChange) {  //nonzero change
            
            const transactionType = balanceChange > 0 ? TransactionType.ADJUST_ADD : TransactionType.ADJUST_SUB;
            
            const order = new Order(this.state.selectedAccount.id, null, transactionType, 
                                    comment, null, null, Math.abs(balanceChange));
        
            this.incrementBusy();
            this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/marketplace/transactions/order?location=" + PP.getLocation(),
                                {method: "POST", body: JSON.stringify(order)}, 
                                (response) => {this._selectAccount(this.state.selectedAccount.id);}, this._fetchErrorCallback); 
            
        }
        this.setState({adjustBalancePopoverOpen: false});
     }
     
    _newAccountPromptOkCallback = (text) => {
        
        if (text) {  //some text - the name
        
            console.log("Add new Ticket: " + text);
            const newAccount = new AccountRequest(text, "", null);
                    
            this.incrementBusy();
            this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/marketplace/accounts",
                                {method: "POST", body: JSON.stringify(newAccount)}, 
                                (response) => {this._newAccountCallback(response);}, this._fetchErrorCallback); 
            
            
        }
        this.setState({newAccountPromptOpen: false}); //close prompt

    }
    
    _newAccountCallback = (response) => {
        
        if (response) {
            const newAccount = new Account(response);
            this.state.accounts.unshift(newAccount);
            this._selectAccount(newAccount.id);

            setTimeout(() => this._updateTableData(), 1);  //defer, to allow re-render
            this.state.selectedRow = 0;
            this.forceUpdate();  //because something in our state changed 
        }
        this.decrementBusy();

    }
    
    
    _confirmDeleteAccount = () => {
        if (this.state.selectedAccount) {
            this.showConfirmAlert("Confirm", 'Really Delete Ticket "' + this.state.selectedAccount.id + '"?',
                                  'black', "Cancel", () => this._deleteAccount(this.state.selectedAccount.id), "Delete", 'red');  
            }    
        }
        
    
    _deleteAccount = (accountID) => {
        
        this.incrementBusy();
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/marketplace/accounts/" + accountID,
                            {method: "DELETE"}, 
                            this._deleteAccountCallback, this._fetchErrorCallback); 
     
    }
    
    _deleteAccountCallback = (response) => {
        
        this.setState( {selectedRow: -1, selectedProduct: null} );  //deselect rows
        this._clear();
        this._fetchAccounts();  //refresh
    }
    

    //Called when fetch of accounts is done, creates the table data, and sets filteredTableData equal to the table data
    _updateTableData = () => {
        
        const tableData = this.state.accounts.map(account => {

            //For each account, create a row with all the column data, list is already sorted by create date
            return {createDate: {date: account.createDate},
                    accountID: {accountID: account.id, account: account},
                    name: account.name,
                    email: account.email,
                    locked: account.hasPin            
                   }; 
        });
        
        //Clear table toolbar settings
        this._toolbarRef.current.reset();

        this.setState({tableData: tableData, filteredTableData: tableData});  //set the data, and filtered data = data
         
    }


    //Creates filteredTableData by filtering the tableData
    _filterTableData = () => {
        
        if (this._searchText.length === 0 && !this._showOnlyEnabled) {
            this.setState({filteredTableData: this.state.tableData});
            return;
        }
        
        const filteredTableData = this.state.tableData.filter(row => { 
            
            //Match on String Fields
            let textMatch = true;  //default - match on empty string
            if (this._searchText.length !== 0) {
                const searchText = this._searchText.toLowerCase();
                const account = row.accountID.account;
                
                textMatch = account.id.toLowerCase().includes(searchText) ||
                            account.name.toLowerCase().includes(searchText) ||
                            account.email.toLowerCase().includes(searchText); 
            }
            
            //Match on Enabled
            let enabledMatch = true;  //default - match on switch off
            if (this._showOnlyEnabled) {
                enabledMatch = row.accountID.account.active;
            }

            return textMatch && enabledMatch;
            
        });

        this.setState({filteredTableData: filteredTableData});
    }
        
        
    //When a field is changed, find the field in the array, and clear its background to indicate change succeeded
    _accountFieldChangedCallback = (response, json, changedOriginalID) => {
        this.decrementBusy();

        const changedField = this._manageAccountFields.find(field => field.json === json);
        if (changedField) {
            changedField.ref.current.accept();  //accepted change            
        }
              
        //There is no response for this patch, so just refetch
        this._fetchAccounts();
        this._selectAccount(this.state.selectedAccount.id);
        this.forceUpdate();  //because something in our state changed 
        
    }
        
    _fieldInputError = (label, error) => {
        this.showConfirmAlert("Error in " + label, error, 'red');
    }
    
    _fieldChangedErrorCallback = (error) => {
        this.decrementBusy();
        this.showConfirmAlert("Error", error, 'red');
    }
    
    //When a Account field is changed, create a JSON object with the key of the field and the new value. Then PATCH it to the server.
    _accountFieldChanged = (json, value) => {                
        
        let objValue = value;
        
        console.log("Update field: " + json + " to " + objValue);
       
        const body = {[json]: objValue, lastTransaction: this.state.selectedAccount.lastTransaction};
        
        this.incrementBusy();
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/marketplace/accounts/" + this.state.selectedAccount.id,
                            {method: "PATCH", body: JSON.stringify(body)}, 
                             (response) => {this._accountFieldChangedCallback(response, json, this.state.selectedAccount.id);}, this._fieldChangedErrorCallback); 
    }
    
    

        
    //Create the React Component for the field in the ManageProductFields array
    _getComponentForField = (field, index, onFieldChange) => {
        
        const canEdit = this.state.selectedAccount && field.editable && PP.user.hasPermissionTo(Permissions.FULL_MARKETPLACE_CONTROL);  //these are editable if there's an account, its editable, and we have permission
        
         //See if it's a generic one we can handle
        const reactComponent = ManagedField.getComponentForField(field, index, onFieldChange, this._fieldInputError, !canEdit);  
        
        if (!reactComponent) {  //if not, specific to this class
             return (<div>Unsupported Component</div>);
        }
        
        return reactComponent;
    }
  
        
    render() {
        
        const emailDisabled = !this.state.selectedAccount || !this.state.selectedAccount.email;
        const email = emailDisabled ? "Ticket holder's email address" : this.state.selectedAccount.email;

        //these icon buttons should be disabled when no account is selected or the user doesn't have permission
        const disableIconButton = !this.state.selectedAccount || !PP.user.hasPermissionTo(Permissions.FULL_MARKETPLACE_CONTROL);    
        const canAddTicket = PP.user.hasPermissionTo(Permissions.FULL_MARKETPLACE_CONTROL);
    
        return (
            <div>
                {this.getConfirmAlertComponent()}
                
                {this.state.printTicketPopupOpen ? 
                
                    <NewWindow title="Print Ticket" name="Print Ticket" 
                               onUnload={() => { this.setState({printTicketPopupOpen: false}); }}
                               onBlock={ () => { this.showConfirmAlert("Error", "Disable popup blockers", 'red'); }}
                               onOpen={ (window) => {setTimeout(() => {window.print();}, PRINT_DELAY);}}
                               features={{width: 1000, height: 768}}>
                              
                         <div style={{margin: 20}}>
                            <Typography style={{display: 'flex', justifyContent: 'center'}} variant="h6">
                                You can print the Ticket below and present it at your facility.
                            </Typography>  
                            <img style={{display: 'block', marginLeft: 'auto', marginRight: 'auto', marginTop: 40, marginBottom: 60}} 
                                 src={this.state.qrImage} alt={"qrCode"} width={200} height={200}/>

                            <Typography style={{display: 'flex', justifyContent: 'center'}} variant="h6">{this.state.selectedAccount.name}</Typography>  
                            <Typography style={{display: 'flex', marginTop: 40, justifyContent: 'center', color: 'gray'}} variant="body2">
                                Keep this ticket as you would cash.  Tampering with this ticket’s unique QR code or using the ticket in any other way than its intended purpose violates the Patron Portal terms and conditions.
                            </Typography> 
                        </div>   
                    </NewWindow>
                    : null 
                }
                
                <AdjustBalancePopover isOpen={this.state.adjustBalancePopoverOpen} okCallback={this._adjustBalancePromptOkCallback} 
                                        cancelCallback={() => this.setState({adjustBalancePopoverOpen: false})}/>
              
                <TextEntryPopover isOpen={this.state.newAccountPromptOpen} showSkip={false} multiline={false} title="Ticket Holder's Name" 
                                 okCallback={this._newAccountPromptOkCallback} cancelCallback={() => this.setState({newAccountPromptOpen: false})}/>
    
                            
                 <Grid container direction="row" spacing={4}>
                    
                    { /*----------------------------  LIST LEFT  ----------------------------------------*/ null }

                    
                    <Grid item md={7} xs={12} style={{justifyContent: 'center'}}>    
                        <DataTableComponent title={this.getBusyComponent('left')} data={this.state.filteredTableData} columns={this.columns()} options={this.state.tableOptions} hoverColor={ThemeColors.tableHover}/>       
                    </Grid>

                    { /*----------------------------  ACCOUNT RIGHT ----------------------------------------*/ null }

        
                    <Grid item md={5} xs={12} style={{justifyContent: 'center'}}>    
                        <Paper>                           
                            <div style={{display: 'flex', height: 40}}>

                                <Typography variant="body2" style={this.styles.paperLabel}>Ticket</Typography>   

                                <Tooltip title={"Email \"" + email + "\" with details on all Tickets they hold"}>
                                      <IconButton disabled={emailDisabled} edge="end" onClick={this._sendAccountEmail} style={{marginTop: -4}}>
                                          <MailOutlineIcon style={{color: emailDisabled ? 'lightGray' : ThemeColors.mailColor}} />
                                      </IconButton>
                                </Tooltip>
                                <Tooltip title={"Switch to Transactions tab with this Ticket number"}>
                                      <IconButton disabled={!this.state.selectedAccount} edge="end" onClick={this._gotoTransactions} style={{marginTop: -4}}>
                                          <ReceiptIcon style={{color: this.state.selectedAccount ?ThemeColors.transactionColor : 'lightGray'}} />
                                      </IconButton>
                                </Tooltip>     
                                <Tooltip title={"Print this Ticket"}>
                                      <IconButton disabled={!this.state.selectedAccount} edge="end" onClick={this._printTicket} style={{marginTop: -4}}>
                                          <PrintIcon/>
                                      </IconButton>
                                </Tooltip>  
                                <Tooltip title={"Adjust Stored Balance"}>
                                      <IconButton disabled={disableIconButton} edge="end" onClick={() => {this.setState({adjustBalancePopoverOpen: true})}} style={{marginTop: -4}}>
                                          <MonetizationOnIcon style={{color: !disableIconButton ? ThemeColors.moneyGreen : 'lightGray'}} />
                                      </IconButton>
                                 </Tooltip>
                                 <Tooltip title={"Add New Ticket"}>
                                      <IconButton disabled={!canAddTicket} edge="end" onClick={() => {this.setState({newAccountPromptOpen: true})}} style={{marginTop: -4}}>
                                          <AddCircleOutlineIcon style={{color: canAddTicket ? ThemeColors.addColor : 'lightGray'}} />
                                      </IconButton>
                                 </Tooltip>
                                 <Tooltip title={"Delete Ticket"}>
                                      <IconButton disabled={disableIconButton} edge="end" onClick={this._confirmDeleteAccount} style={{marginTop: -4, marginRight: 4}}>
                                          <DeleteIcon />
                                      </IconButton>
                                 </Tooltip>      
                                 
                                 
                     
                           </div>
                           
                            <div style={{padding: 10}}>                            
                               { this._manageAccountFields.map((field, index) => this._getComponentForField(field, index, this._accountFieldChanged)) }  

                                <Typography variant="body2" style={{fontSize: 12, color: 'gray', marginLeft: 5, marginTop: 10}}>Redeemables</Typography>

                                <div style={{border: '1px solid #CCCCCC', borderRadius: '4px', padding: 10, minHeight: 40}}>
                                    {this.state.selectedAccount && this.state.selectedAccount.redeemables ? 
                                        <List dense>
                                            {this.state.selectedAccount.redeemables.map((redeemable, index) =>
                                                (<div key={index}>
                                                    {index > 0 ? <Divider style={this.styles.divider}/> : null}
                                                    <ListItem>                                
                                                        <ListItemText primary={redeemable.redeemable.productID + " " + redeemable.product.name}
                                                                      secondary={"Available: " + (redeemable.redeemable.remainingString())}/>                
                                                           
                                                        {redeemable.redeemable.unavailableReason ? 
                                                            <ListItemSecondaryAction>
                                                                <Tooltip title={multilineJSX("Currently Unavailable: " + redeemable.redeemable.unavailableReason, true)}>
                                                                   <ListItemIcon>
                                                                       <BlockIcon fontSize='small' style={{color: 'red', marginRight: 40}}/>
                                                                   </ListItemIcon>
                                                                </Tooltip>
                                                            </ListItemSecondaryAction>
                                                          : null}
             
                                                    </ListItem>
                                                 </div>)                       
                                            )}
                                        </List> : null}
                                </div>
                            </div>
                                                     
                        </Paper>
                    </Grid>
                </Grid>
                    
            </div>

        );

    }
}


export default withCookies(AccountTab);


