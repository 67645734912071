import React from 'react';

import { FormControlLabel, Checkbox, Typography, Tooltip } from '@material-ui/core'

import { Permissions, EmployeeOnlyPermissions, PermissionDescriptions } from 'react-frontend-utils';


export function PermissionCheckboxes(props) {
    
        
    const checkboxes = [];
    Object.keys(Permissions).forEach(key => {
        if (props.isEmployee || !EmployeeOnlyPermissions.includes(key))  // show checkbox for employees, or if not an employee, show only if its not in the EmployeeOnlyPermissions
            checkboxes.push({name: key, label: key.replaceAll("_", " "), isSelected: props.selected.includes(key), tooltip: PermissionDescriptions[key]});        
    });

    
    
    return (
        <div>
            {checkboxes.map((checkbox, index) => 
                <div>
                    {checkbox.name === "PRINT" ? <hr style={{marginRight: 15}}/> : null}
                    <Tooltip key={index} title={checkbox.tooltip ? checkbox.tooltip : ""}>
                        <FormControlLabel key={index}
                                          control={(<Checkbox size="medium" color='primary' disabled={props.disabled} checked={checkbox.isSelected} onChange={(event) => props.checkboxChanged(checkbox.name, event.target.checked)} />)} 
                                            labelPlacement="end"
                                            label={(<Typography variant="body2" style={{fontSize: 12, marginTop: 2}} color="textSecondary">{checkbox.label}</Typography>)}
                                        />
                    </Tooltip>   
                </div>
            )}
        </div>
    );
    
    
    
    
    
    
}