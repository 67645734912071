import React, { Component } from 'react';

import { Container, Divider, Popover, Typography, Button, IconButton, Paper, Tooltip, Grid, FormControlLabel, Checkbox } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel';

import { ImageFunctions } from '../utils/Image';

/**
 * Presents a popup to select one or more Members from a Membership. Must pass the following props:
 * 
 * onContinue: callback function that takes a Set containing the Member ids selected, which will contain at least one
 * onClose: callback that indicates user closed/cancelled the dialog
 * members: array of Member objects in the Membership
 * membership: the Membership to select Members from
 * isOpen: true to show the popup
 * isMobile: true for smaller browsers
 */
export default class MultiMemberSelector extends Component {

    styles = {
        paperLabel: {
            color: 'blue',
            fontSize: '12pt',
            flexGrow: 1,
            marginLeft: 10
        },
        divider: {
            marginLeft: 10,
            marginRight: 10
        },
        checkbox: {
            transform: 'scale(' + 1.5 + ')',
            color: 'blue'
        }
    };

    _onClose;
    _onContinue;
    _membersSelected = new Set();
    
    constructor(props) {
        super(props);
        this._onContinue = props.onContinue;
        this._onClose = props.onClose;
    }
   
    _selectMember = (member, isSelected) => {
        if (isSelected) 
            this._membersSelected.add(member.id);
        else
            this._membersSelected.delete(member.id);
        
        this.forceUpdate();
    }
   
    _closeButtonPressed = () => {
        this._onClose();
        this._membersSelected = new Set();  //clear all for next time
        this.forceUpdate();
    }
    
    _continue = () => {
        this._onContinue(this._membersSelected);
        this._membersSelected = new Set();  //clear all for next time
        this.forceUpdate();
    }
    
    _selectAll = () => {
        this.props.members.forEach(member => this._membersSelected.add(member.id));         
        this.forceUpdate(); 
    }    
        
    
    render() {
        
        if (!this.props.isOpen)
            return null;
        
        const numMembersSelected = this._membersSelected.size;
        
        let width = window.innerWidth * (this.props.isMobile ? 0.95 : 0.8);  //98% when small, otherwise 80% the window


        return (

            <Popover open={this.props.isOpen} 
                anchorReference="anchorPosition"
                anchorPosition={{left: window.innerWidth/2, top: window.innerHeight/2}}
                transformOrigin={{vertical: 'center', horizontal: 'center'}} >
                
                <Container style={{marginBottom: 20, width: width}}>
                           
                    <div style={{display: 'flex', justifyContent: 'spaceBetween', marginBottom: 10}}>
                        <Typography variant="h6">Select Members</Typography>   
                        <Tooltip title="Close">
                            <IconButton edge="end"onClick={this._closeButtonPressed} style={{marginLeft: 'auto', marginTop: -6}}>
                                <CancelIcon />
                            </IconButton>
                        </Tooltip>   
                    </div>
   
                    <Paper>                           
                        <div style={{minHeight: 100}}>

                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <Typography variant="body2" style={this.styles.paperLabel}>{"Membership: " + this.props.membership.id}</Typography> 
                                <Button onClick={this._selectAll} style={{marginTop: -6, color: 'blue', textDecoration: 'underline', textTransform: 'capitalize'}}>Select All</Button>
                            </div>
                            
                            <div style={{margin: 15}}/>
   
              
                            {this.props.members.map((member, index) => 
                                 
                                <div key={index}>

                                    <Divider style={this.styles.divider}/>

                                     <div style={{margin: 10}}>
                                        <Grid container direction="row" justify='center' alignItems='center' spacing={2}>

                                            <Grid item xs={4}>    
                                                {ImageFunctions.memberImageURL(member, false, 4.0, 1.0)}
                                            </Grid>

                                            <Grid item xs={7}>
                                                <Typography variant="body2" style={{color: member.suspended ? 'red' : 'black'}}>{member.shortName()}</Typography>
                                            </Grid>

                                            
                                            <Grid item xs={1}>
                                                <div style={{display: 'flex', justifyContent: 'right'}}>
                                                    <FormControlLabel control={
                                                        <Checkbox checked={this._membersSelected.has(member.id)} color="primary" style={this.styles.checkbox}
                                                                  onChange={(event) => this._selectMember(member, event.target.checked)} />
                                                    }/>
                                                </div>
                                            </Grid>

                                        </Grid>
                                    </div>
                                </div>
                            )}
                            

                        </div>
                    </Paper>  
                    
                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: 10, marginTop: 20}}>
                        <Button disabled={numMembersSelected === 0} onClick={this._continue} fullWidth variant="contained" 
                                 color="primary" style={{color: 'white', maxWidth: 300}}>Continue</Button>
                    </div>

                </Container>

            </Popover>
        );
        
        
    }


}
