
import { DateUtils } from 'react-frontend-utils'
import store from 'store'




export const BarcodeSuggestionType = {  
    NEXT_NUMERIC: 'Next Numeric',
    RANDOM_NUMERIC: 'Random Numeric',
    RANDOM_ALPHANUMERIC: 'Random Alphanumeric',
    
    isOneOf: (type) => {
        switch (type) {
            case BarcodeSuggestionType.NEXT_NUMERIC:
            case BarcodeSuggestionType.RANDOM_NUMERIC:
            case BarcodeSuggestionType.RANDOM_ALPHANUMERIC:
                return true;
            default:
                return false;
        }
    }
 
};

Object.freeze(BarcodeSuggestionType);


export const RecentCheckInWarnMode = {  
    NONE: 'Never',
    THIS_LOCATION: 'This Location',
    ANY_LOCATION: 'Any Location',
    
    isOneOf: (type) => {
        switch (type) {
            case RecentCheckInWarnMode.NONE:
            case RecentCheckInWarnMode.THIS_LOCATION:
            case RecentCheckInWarnMode.ANY_LOCATION:
                return true;
            default:
                return false;
        }
    }
 
};

Object.freeze(BarcodeSuggestionType);



//Forward declaration, to use it within PP twice
function getLocation() {
    const location = store.get("location");

    if (!location) {
        return navigator.platform + " Device";  //default location if not set
    }   
    else {
        return location;
    }
};


/**
 * Holds the global variables for the frontend
 */
export const PP = {
    
    user: null,                                     //object of type User, the currently logged in user or null if none
    selectedDatabase: null,                         //String identifying the currently selected database, or null if none
    currency: "???",                                //String identifying the currency, ISO currency value
    printCapabilities: [],                          //Array of Kinds, indentifying the printing capabilities: EPASS, CARD, PDF
    checkInDisabled: false,                         //can be set to true after loading Home
    attendedMarketplacePurchaseEnabled: false,      //can be set to true after loadingHome
    
    getLocation: () => {return getLocation();},
          
    setLocation: (location) => {
        store.set("location", location);    
    },
    
    viewingStatsLocation: getLocation(),   //default to our current location, then leave set for the session
    viewingStatsAggregate: null,           //will be set to initial value on stats load

    getLastDatabase: () => {
        return store.get("database");
    },
    
    setLastDatabase: (database) => {
        store.set("database", database);    
    },
    
    getDateFormat: () => {
        const fmt = store.get("dateFormat");
        if (!fmt)
            return DateUtils.DateFormatType.ISO8601;
        else
            return fmt;    
    },
    
    setDateFormat: (format) => {
        if (!DateUtils.DateFormatType.isOneOf(format)) {
            console.log("Invalid DateFormatType: " + format);
            return;
        }
        store.set("dateFormat", format);    
    },
    
    soundEffectsEnabled: () => {
        const disabled = store.get("soundEffectsDisabled");  //use disabled for "falsy" values to be true
        if (!disabled)
            return true;
        
        return disabled === 'true' ? false : true;
    },
    
    enableSoundEffects: (enable) => {
        store.set("soundEffectsDisabled", enable ? 'false' : 'true');           
    },
    
    checkOutEnabled: () => {
        const enabled = store.get("checkOutEnabled");
        if (!enabled)
            return false;
        
        return enabled === 'true' ? true : false;
    },
    
    enableCheckOut: (enable) => {
        store.set("checkOutEnabled", enable ? 'true' : 'false');           
    },
    
    guestRegisterEnabled: () => {
        const enabled = store.get("guestRegister");
        if (!enabled)
            return false;
        
        return enabled === 'true' ? true : false;
    },
    
    enableGuestRegister: (enable) => {
        store.set("guestRegister", enable ? 'true' : 'false');           
    },
    
    multiMemberCheckinEnabled: () => {
        const enabled = store.get("multiMemberCheckin");
        if (!enabled)
            return false;
        
        return enabled === 'true' ? true : false;
    },
    
    enableMultiMemberCheckin: (enable) => {
        store.set("multiMemberCheckin", enable ? 'true' : 'false');           
    },
    
    
    virtualGuestPassHidden: () => {
        const hidden = store.get("virtualGuestPassHidden");
        if (!hidden)
            return false;
        
        return hidden === 'true' ? true : false;
    },
    
    setVirtualGuestPassHidden: (enable) => {
        store.set("virtualGuestPassHidden", enable ? 'true' : 'false');           
    },
    
    addressAndAgeHidden: () => {
        const hidden = store.get("addressAndAgeHidden");
        if (!hidden)
            return false;
        
        return hidden === 'true' ? true : false;
    },
    
    
    setAddressAndAgeHidden: (enable) => {
        store.set("addressAndAgeHidden", enable ? 'true' : 'false');           
    },
    
    
    notesHidden: () => {
        const hidden = store.get("notesHidden");
        if (!hidden)
            return false;
        
        return hidden === 'true' ? true : false;
    },
    
    
    setNotesHidden: (enable) => {
        store.set("notesHidden", enable ? 'true' : 'false');           
    },
   
    
    barcodeSuggestionType: () => {
        const type = store.get("barcodeSuggestionType");
        if (!type)
            return BarcodeSuggestionType.NEXT_NUMERIC;
        else
            return type;  
    },
    
    setBarcodeSuggestionType: (type) => {
        if (!BarcodeSuggestionType.isOneOf(type)) {
            console.log("Invalid BarcodeSuggestionType: " + type);
            return;
        }
        store.set("barcodeSuggestionType", type); 
    },
    
    barcodeSuggestionDigits: () => {
        const digits = store.get("barcodeSuggestionDigits");
        if (!digits)
            return 5;
        else
            return digits;  
    },
    
    setBarcodeSuggestionDigits: (digits) => {
        if (digits < 1)
            return null;
        store.set("barcodeSuggestionDigits", digits); 
    },
    
    //Get time as date string, or null for never.  Default = never    
    initialExpirationDate: () => {
        const date = store.get("initialExpirationDate");
        if (!date)
            return null;  //Never
        else
            return date;  
    },
    
    //Set time as date string, or null for never
    setInitialExpirationDate: (date) => {
        store.set("initialExpirationDate", date); 
    },
    
    
    initialGuestPasses: () => {
        const val = store.get("initialGuestPasses");
        if (!val)
            return 0;
        else
            return val;  
    },
    
    setInitialGuestPasses: (val) => {
        store.set("initialGuestPasses", val); 
    },
    
    initialEntryPasses: () => {
        const val = store.get("initialEntryPasses");
        if (val == null)
            return -1;  //unlimited
        else
            return val;  
    },
    
    setInitialEntryPasses: (val) => {
        store.set("initialEntryPasses", val); 
    },
    
    dateFormat: (date) => {
        return DateUtils.dateFormat(date, PP.getDateFormat());
    },
    
    checkInTimeFormat: (date) => {
        return DateUtils.timeFormat(date, PP.getDateFormat());
    },
    
    billingTimeFormat: (date) => {
        return new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'long' }).format(date)
    },
    
    
    parseDate: (dateString) => {
        return DateUtils.parseDate(dateString, PP.getDateFormat());
    },
    
    exampleDateString: () => {
        return DateUtils.exampleDateString(PP.getDateFormat());        
    },
        
    recentCheckInWarnMode: () => {
        const mode = store.get("recentCheckInWarnMode");
        if (!mode)
            return RecentCheckInWarnMode.ANY_LOCATION;
        else
            return mode;  
    },
    
    setRecentCheckInWarnMode: (mode) => {
        if (!RecentCheckInWarnMode.isOneOf(mode)) {
            console.log("Invalid RecentCheckInWarnMode: " + mode);
            return;
        }
        store.set("recentCheckInWarnMode", mode); 
    },
    
    recentCheckInWarnTime: () => {  //hours
        const val = store.get("recentCheckInWarnTime");
        if (!val)
            return 1;  //1 hour default
        else
            return val;  
    },
    
    setRecentCheckInWarnTime: (val) => {
        store.set("recentCheckInWarnTime", val); 
    }
    
    
};
