import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withCookies } from 'react-cookie';

import { IconButton, Tooltip, Grid, Typography, Paper } from '@material-ui/core'

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

import { PP } from '../models/PP'
import { ThemeColors } from '../Theme'
import { RestComponent, DateUtils, ImageUtils, Currency, DragAndDrop, ManageBitwiseCheckboxes, TextEntryPopover, WebcamCapture, ImageCropper } from 'react-frontend-utils'
import { ImageFunctions, PRODUCT_IMAGE_NATIVE_WIDTH, PRODUCT_IMAGE_NATIVE_HEIGHT } from '../utils/Image';

import { Product, ProductAttributes, DaysOfWeek } from '../models/Product'
import { multilineJSX, StyledTooltip } from 'react-frontend-utils'
import { DataTable, DataTableComponent, BasicTableToolbar, Permissions } from 'react-frontend-utils'
import { ManagedField } from '../components/ManagedField'
import { ProductInfoTooltip } from '../components/ProductListEntry'

import { AudioPlay } from '../utils/Audio'


/**
 * The ProductList tab shows a list of all Products.
 * On mounting of the component, the list of all Products is fetched from the server. 
 * 
 * One prop is passed:
 * 
 * selectedProduct: if defined, the product to select after fetching the list of all Product
 */



//Returns a JSX format of product data suitable for use on a tooltip on hover over the Product column
const productTooltip = (product) => {
    if (!product)
        return "";
        
    return (<div>
                <div><b>ID: </b>{product.id}</div> 
                <div><b>Name: </b>{multilineJSX(product.name, true)}</div> 
                <div><b>Category: </b>{multilineJSX(product.category, true)}</div> 
                <div/>
                <div><b>Description: </b>{multilineJSX(product.info, true)}</div> 
            </div>
            
           );  
};



const RedeemableWarningTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'red',
        color: 'white',
        maxWidth: 500,
        fontSize: theme.typography.pxToRem(15),
        border: '2px solid black'
    }
}))(Tooltip);


const validRedeemTooltip = <div>
                                <div>Settings specifying when the product can be redeemed:</div>
                                    <ul>
                                        <li>Start Date: valid for redeem at midnight on this date and any date after</li>
                                        <li>End Date: valid for redeem through 11:59PM on this date</li>
                                        <li>Start Time: on and valid day, redeemable after this time</li>
                                        <li>End Time: on any valid day, redeemable before this time</li>
                                        <li>Day of Week: redeemable on this day, if checked</li>
                                    </ul>
                                    <div style={{fontStyle: 'italic'}}>Note: if End Time is before Start Time, then the valid time wraps over midnight</div>  
                            </div>;


class AttributesCheckboxes extends ManageBitwiseCheckboxes {
    
    //Override - some bits depend on others
    checkboxChanged(bitPosition, oldBit) {
        
        let newValue = this.state.currentValue;
        
        if (oldBit === 0)   
            newValue |= (1 << bitPosition);  //bit was 0, flip to 1
        else 
            newValue &= ~(1 << bitPosition);  //bit was 1, flip to 0
                  
        if (bitPosition === Product.attributeBitPosition("UNLIMITED REDEEM") && oldBit === 0) { //unlimited redeem flipped to 1, redeemable must also be set, and no guest pass
            newValue |= (1 << Product.attributeBitPosition("REDEEMABLE")); 
            newValue &= ~(1 << Product.attributeBitPosition("ADDS GUEST PASS"));          
        }
        
        
        if (bitPosition === Product.attributeBitPosition("REDEEMABLE") && oldBit !== 0) { //redeemable flipped to 0, unlimited redeemable and guest pass must also be clear
            newValue &= ~(1 << Product.attributeBitPosition("UNLIMITED REDEEM")); 
            newValue &= ~(1 << Product.attributeBitPosition("ADDS GUEST PASS"));          
        }
            
        if (bitPosition === Product.attributeBitPosition("REQUIRES MEMBERSHIP") && oldBit !== 0) { //require membership flipped to 0, guest pass must also be clear
            newValue &= ~(1 << Product.attributeBitPosition("ADDS GUEST PASS"));            
        }
                   
        if (bitPosition === Product.attributeBitPosition("ADDS GUEST PASS") && oldBit === 0) { //adds guest pass flipped to 1, multiple restrictions:
            newValue |= (1 << Product.attributeBitPosition("REDEEMABLE")); //must be redeemable, auto-redeemer will increase pass count
            newValue |= (1 << Product.attributeBitPosition("REQUIRES MEMBERSHIP")); //obviously we need a Membership to add passes to
            newValue &= ~(1 << Product.attributeBitPosition("ADMITTANCE")); //can't admit anyone
            newValue &= ~(1 << Product.attributeBitPosition("UNLIMITED REDEEM")); //this can't be an unlimited redeemable
        }
                   
                   
        if (bitPosition === Product.attributeBitPosition("ADMITTANCE") && oldBit === 0) { //admittance filled to 1, can't have add guest pass
            newValue &= ~(1 << Product.attributeBitPosition("ADDS GUEST PASS")); //this can't be an unlimited redeemable            
        }
                   
        this.setState({currentValue: newValue});    
        this._changed(this._json, newValue);
    }
};

export class ProductTab extends RestComponent {
    
    
    _manageProductFields = [

        {component: "TextField", json: "id", label: "ID",  editable: PP.user.hasPermissionTo(Permissions.FULL_MARKETPLACE_CONTROL), ref: React.createRef(), tooltip: "Product UPC barcode or other unique identifier", initialValue: (product) => { return product.id; } },
        {component: "TextField", json: "name", label: "Name",  editable: PP.user.hasPermissionTo(Permissions.FULL_MARKETPLACE_CONTROL), ref: React.createRef(), initialValue: (product) => { return product.name; } },
        {component: "TextField", json: "category", label: "Category",  editable: PP.user.hasPermissionTo(Permissions.FULL_MARKETPLACE_CONTROL), ref: React.createRef(), initialValue: (product) => { return product.category; } },
        {component: "TextArea", json: "info", lines: 2, label: "Description",  editable: PP.user.hasPermissionTo(Permissions.FULL_MARKETPLACE_CONTROL), ref: React.createRef(), initialValue: (product) => { return product.info; } },
        {component: "AttributesCheckboxes", json: "attributes", label: "Attributes", editable: PP.user.hasPermissionTo(Permissions.FULL_MARKETPLACE_CONTROL), checkboxLabels: ProductAttributes, ref: React.createRef(), initialValue: (product) => { return product.attributes; } },
        {component: "Switch", color: PP.user.hasPermissionTo(Permissions.FULL_MARKETPLACE_CONTROL) ? 'green' : 'gray', json: "enabled", label: "Purchasing Enabled", editable: PP.user.hasPermissionTo(Permissions.FULL_MARKETPLACE_CONTROL), tooltip: "Switch on to enable purchases. Does not affect redemptions.", ref: React.createRef(), initialValue: (product) => { return product.enabled; } },
        {component: "Numeric", json: "inventory", label: "Inventory", editable: PP.user.hasPermissionTo(Permissions.FULL_MARKETPLACE_CONTROL), hasNegative: true, hasInfinity: true, ref: React.createRef(), initialValue: (product) => { return product.inventory; } },
        {component: "Numeric", json: "reorder", infinityText: "Never", label: "Reorder Quantity", editable: PP.user.hasPermissionTo(Permissions.FULL_MARKETPLACE_CONTROL), minValue: 0, hasNegative: true, hasInfinity: true, ref: React.createRef(), initialValue: (product) => { return product.reorder; } },
        {component: "Numeric", json: "maxQuantity", infinityText: "Unlimited", hasInfinity: true, minValue: 1, hasNegative: true, hideButtons: true, label: "Max Buy Quantity", editable: PP.user.hasPermissionTo(Permissions.FULL_MARKETPLACE_CONTROL), tooltip: "The maximum purchase quantity per transaction.", ref: React.createRef(), initialValue: (product) => { return product.maxQuantity; } },
        {component: "Numeric", json: "minQuantity", label: "Min Buy Quantity",  editable: PP.user.hasPermissionTo(Permissions.FULL_MARKETPLACE_CONTROL), minValue: 1, hideButtons: true, hasNegative: false, hasInfinity: false, ref: React.createRef(), initialValue: (product) => { return product.minQuantity; } },
        {component: "Decimal", json: "cost", label: "Cost", editable: PP.user.hasPermissionTo(Permissions.FULL_MARKETPLACE_CONTROL), decimalPlaces: 2, minValue: 0, ref: React.createRef(), initialValue: (product) => { return product.cost; } },
        {component: "Decimal", json: "tax", label: "Tax %",  editable: PP.user.hasPermissionTo(Permissions.FULL_MARKETPLACE_CONTROL), decimalPlaces: 2,  minValue: 0, ref: React.createRef(), initialValue: (product) => { return product.tax; } },
        {component: "Decimal", json: "discount", label: "Discount %", editable: PP.user.hasPermissionTo(Permissions.FULL_MARKETPLACE_CONTROL), decimalPlaces: 1,  minValue: 0, ref: React.createRef(), initialValue: (product) => { return product.discount; } },
        {component: "Div", json: "div", label: "Valid Redeem Days and Times", editable: PP.user.hasPermissionTo(Permissions.FULL_MARKETPLACE_CONTROL), tooltip: validRedeemTooltip}, 
        {component: "Calendar", json: "startDate", hasNever: true, neverText: "Any", label: "Start Date",  editable: PP.user.hasPermissionTo(Permissions.FULL_MARKETPLACE_CONTROL), ref: React.createRef(), initialValue: (product) => { return product.startDate ? PP.dateFormat(product.startDate) : "Any"; }},
        {component: "Calendar", json: "endDate", hasNever: true, neverText: "Any", label: "End Date",  editable: PP.user.hasPermissionTo(Permissions.FULL_MARKETPLACE_CONTROL), ref: React.createRef(), initialValue: (product) => { return product.endDate ? PP.dateFormat(product.endDate) : "Any"; } },
        {component: "TimeOfDay", json: "startTime", hasNever: true, neverText: "Any", label: "Start Time",  editable: PP.user.hasPermissionTo(Permissions.FULL_MARKETPLACE_CONTROL), ref: React.createRef(), initialValue: (product) => { return product.startTime ? DateUtils.toFriendlyTimeString(product.startTime) : "Any"; } },
        {component: "TimeOfDay", json: "endTime", hasNever: true, neverText: "Any", label: "End Time",  editable: PP.user.hasPermissionTo(Permissions.FULL_MARKETPLACE_CONTROL), ref: React.createRef(), initialValue: (product) => { return product.endTime ? DateUtils.toFriendlyTimeString(product.endTime) : "Any"; } },
        {component: "Checkboxes", json: "dayOfWeek", label: "Days of the Week", editable: PP.user.hasPermissionTo(Permissions.FULL_MARKETPLACE_CONTROL), checkboxLabels: DaysOfWeek, ref: React.createRef(), initialValue: (product) => { return product.dayOfWeek; } },
        {component: "RelativeTime", json: "afterPurchase", hasNever: true, neverText: "Any", label: "Within Time after Purchase",  editable: PP.user.hasPermissionTo(Permissions.FULL_MARKETPLACE_CONTROL), ref: React.createRef(), initialValue: (product) => { return product.afterPurchase ? DateUtils.relativeTimeFormat(product.afterPurchase) : "Any"; } },
        {component: "RelativeTime", json: "afterFirstRedeem", hasNever: true, neverText: "Any", label: "Within Time after First Redeemed",  editable: PP.user.hasPermissionTo(Permissions.FULL_MARKETPLACE_CONTROL), ref: React.createRef(), initialValue: (product) => { return product.afterFirstRedeem ? DateUtils.relativeTimeFormat(product.afterFirstRedeem) : "Any"; } }
    ];

  
    //An array describing each column, generate dynamically to refresh on state change. Note we always use the product object
    //for each column because the products may change as they are edited on the right, so this will update the table dynamically

    columns = () => { return  [
        //Product ID column - data is an object that contains the product ID and the Product object
        //Sorting is done comparing the id alphabetically
        //Rendering is the ID and a tooltip containing prodict details
        {name: "productID", label: "Product ID",   
            options: {filter: true, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.firstCellProps,
                    sortCompare: (order) => {return (obj1, obj2) => {let p1 = obj1.data.productID;
                                                                     let p2 = obj2.data.productID;                                                                      
                                                                     return (p1 > p2 ? 1 : -1) * (order === 'asc' ? 1 : -1);
                                                                    };},
                    customBodyRender: (val) => { let text = val ? val.productID : null; 
                                                 if (text.length > 30)
                                                    text = text.substring(0, 27) + "...";
                                                
                                                 const product = val ? val.product : null;
                                                 let style = (product && product.enabled) ? {} : {color: 'gray'};  

                                                 return (
                                                        <StyledTooltip title={productTooltip(product)}>
                                                            <div style={style}>{text}</div>
                                                        </StyledTooltip>
                                                       ); 
                                                 }
            }
        },
        //Name column - data is the product name
        {name: "name", label: "Name",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps, customBodyRender: DataTable.longStringCellRenderer}}, 
        
        //Category column - data is the product category
        {name: "category", label: "Category", 
            options: {display: this.state.isWide, filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps, customBodyRender: DataTable.longStringCellRenderer}}, 
           
        //Inventory column - data is the product inventory
        {name: "inventory", label: "Inventory",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps,
                      sortCompare: (order) => {return (obj1, obj2) => {let i1 = obj1.data.inventory;
                                                                       let i2 = obj2.data.inventory;                                                                      
                                                                     return (i1 > i2 ? 1 : -1) * (order === 'asc' ? 1 : -1);
                                                                       };},
                      customBodyRender: (val) => {let text = String(val.inventory);
                                                let style = null;
                                                if (val.inventory == null) {
                                                    text = "Unlimited";
                                                }
                                                else if (val.reorder != null && val.inventory <= val.reorder) 
                                                    style= {backgroundColor: 'yellow'};       

                                                return (<div style={style}>{text}</div>); 
                                                }}
        },
      
        //Cost column - data is the product cost
        {name: "cost", label: "Unit Cost", 
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps,
                      customBodyRender: (val) => (<div style={{textAlign: 'right'}}>{Currency.round(val) + " " + PP.currency}</div>)}}, 
       
        //Tax column - data is the product tax
        {name: "tax", label: "Tax",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps,
                      customBodyRender: (val) => (<div style={{textAlign: 'right'}}>{val.toFixed(2)}%</div>)}}, 

        
        //Discount column - data is the product discount
        {name: "discount", label: "Discount", 
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.lastCellProps,
                      customBodyRender: (val) => (<div style={{textAlign: 'right'}}>{val.toFixed(1)}%</div>)}}

        
   
    ];};


    styles = {
        paper: {
            width: '100%',
            height: '100%'
        },
        paperLabel: {
            marginLeft: 15,
            color: 'blue',
            fontSize: '12pt',
            flexGrow: 1
        }
    };

         
    _searchText = "";               //the currently displayed search text
    _showOnlyEnabled = false
    _refFieldsNeedUpdate = false;
    _toolbarRef = React.createRef();

    //Called by the custom Toolbar, to determine what color it should be (if no found records, go red
    searchFoundRecords = () => {
       if (this.state.tableData.length === 0) //no records loaded, don't color the text to indicate search failed
           return true; 
       
       return this.state.filteredTableData.length !== 0;
    }
    
    tableHasSelectedRows = () => {
        return this.state.selectedRow >= 0;
    }
    
    

    constructor(props) {
        super(props);
       
        this.state.products = [];           //array of Products, from last fetch
        this.state.selectedRow =  -1;       //the currently selected row in the table
        this.state.selectedProduct = null;
        this.state.selectedProductRedeemables = [];
        
        this.state.newProductPromptOpen = false;
        
        this.state.tableData = [];          //all the table data
        this.state.filteredTableData = [];  //filtered data from search, filters
        
        this.state.cropperOpen = false;                                 //true to show the cropper page instead
        this.state.imageToCrop = null;
        this.state.webcamOpen = false;
   
        
        this.state.tableOptions =  {
                                    onRowSelectionChange: ((currentRowsSelected) => this._rowClick(currentRowsSelected)),
                                    customToolbar: () => { return ( <BasicTableToolbar 
                                                                        ref={this._toolbarRef}
                                                                        onSearchChange={this.searchFieldChanged} 
                                                                        foundRecords={this.searchFoundRecords}                      
                                                                        onCheckedInSwitchChanged={this.checkedInFieldChanged}
                                                                        onEnabledSwitchChanged={this.enabledSwitchFieldChanged}
                                                                        tableHasSelectedRows={this.tableHasSelectedRows}
                                                                        searchTooltip="Filter the table by any matching part of a Product's id, name, description, and category"
                                                                        enabledTooltip="Filter the table by only enabled Products"/> 
                                                                   ); 
                                                          },
                                    setRowProps: (row, dataIndex, rowIndex) => this._setRowProps(row, dataIndex, rowIndex)
                                    };
        
        
    }
    
   
    
    /**
     * When the page loads, fetch all members and memberships for the current database
     */
    componentDidMount() {
        super.componentDidMount();
        this._updateSize();
        window.addEventListener("resize", this._updateSize);
        window.addEventListener("paste", this._paste);

        if (PP.selectedDatabase) {
            this._fetchProducts(this.props.selectedProduct);
        }
    }
    
    componentWillUnmount() {
        window.removeEventListener("resize", this._updateSize);
        window.removeEventListener("paste", this._paste);
    }
    
    componentDidUpdate() {
        
        //If we need to refresh the reference fields, switch out of another screen
        if (this._refFieldsNeedUpdate) {
            //Lookup the ReactComponent and set its text value to the initial text value for each Membership field         
            ManagedField.setFields(this._manageProductFields, this.state.selectedProduct);
            this._refFieldsNeedUpdate = false;
            this.forceUpdate();
        }
    }

    //callback when window changes size
    _updateSize = () => {
        this.setState({ isMobile: window.innerWidth < 960, isWide: window.innerWidth > 1280 });
    }
    
    _paste = (event) => {
        if (this.state.selectedProduct)
            ImageUtils.handlePasteImage(event, this._imageCaptured);
    }
    
    //Called by the search field from the Custom toolbar, when the search field text changes
    searchFieldChanged = (searchText) => {
        this._searchText = searchText;
        this._filterTableData();
    }
    
    enabledSwitchFieldChanged = (enabledSwitchState) => {
        this._showOnlyEnabled = enabledSwitchState;
        this._filterTableData();
    }
    
    _setRowProps = (row, dataIndex, rowIndex) => {
        return DataTable.getRowStyle(this.state.selectedRow, dataIndex, rowIndex, ThemeColors.selectedColor, ThemeColors.tableAlternatingRowsGray);
    }
   
   
    //Callback when a row is clicked
    _rowClick = (currentRowsSelected) => {
     
        const row = currentRowsSelected.length > 0 ? currentRowsSelected[0].dataIndex : -1;
        
        if (row >= 0) {
            const product = this.state.filteredTableData[row].productID.product;
            console.log("Product List: selected row: " + row + " Product: " + product.id);
            this.setState({selectedRow: row});
            this._selectProduct(product);
        }
        else {
            this.setState( {selectedProduct: null, selectedRow: row} );   
            this._clear();
        }
    }
    
    //clear all fields
    _clear = () => {
        this.setState({selectedProduct: undefined, selectedProductRedeemables: []});
        this.setBusy(false);
               
        ManagedField.setFields(this._manageProductFields, null);
    }
    
    
    _selectProduct = (product) => { 
        this.setState({selectedProduct: product});             
        ManagedField.setFields(this._manageProductFields, product);
        this._fetchAccountRedeemables(product);
    }
     
    
    
    
    _fetchProducts = (selectedProductID) => {
        this.setBusy(true);
     
        //fetch all products
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/marketplace/products", 
                             {}, (response) => this._fetchProductsCallback(response, selectedProductID), this._fetchErrorCallback); 
    }
    
    //Callback for fetching Products - JSON response is an array of Product objects
    _fetchProductsCallback = (response, selectedProductID) => {
        if (response) {            
            this.state.products = response.map((product) => new Product(product));
            if (selectedProductID) {
                
                //Find the product id
                const selectedProduct = this.state.products.find((product) => selectedProductID === product.id)
                if (selectedProduct)
                    this._selectProduct(selectedProduct);
            }
        }
        this.setBusy(false);
        this._updateTableData();
    }

   
    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.setBusy(false);
    }
     
     
    _fetchAccountRedeemables = (product) => {
         
        this.setBusy(true);

        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/marketplace/products/" + product.id + "/redeemables", 
                                {}, this._fetchProductRedeemablesCallback, this._fetchErrorCallback); 
        
    } 
     
    _fetchProductRedeemablesCallback = (response) => {
        this.setBusy(false);
        if (response) {
            this.setState({selectedProductRedeemables: response});
        }
    }
     
    _newProductPromptOkCallback = (text) => {
        
        if (text) {  //some text
        
            console.log("Add new Product: " + text);
            const newProduct = Product.createNewJson(text, "New Product");
                    
            this.incrementBusy();
            this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/marketplace/products",
                                {method: "POST", body: JSON.stringify(newProduct)}, 
                                (response) => {this._newProductCallback(response);}, this._fetchErrorCallback); 
            
            
        }
        this.setState({newProductPromptOpen: false}); //close prompt

    }
    
    _newProductCallback = (response) => {
        
        if (response) {
            const newProduct = new Product(response);
            this.state.products.push(newProduct);
            this._selectProduct(newProduct);

            setTimeout(() => this._updateTableData(), 1);  //defer, to allow re-render
            this.state.selectedRow = -1;
            this.forceUpdate();  //because something in our state changed 
        }
        this.decrementBusy();

    }
    
    
    _askDeleteProduct = () => {

        const numRe = this.state.selectedProductRedeemables.length;
            
        if (numRe > 0) {

            const warning = numRe + " Ticket" + (numRe > 1 ? "s have" : " has") + " remaining redeemables for this Product! Deleting the Product " +
                             "will prevent customers from ever redeeming it. Make sure this is really what you want to do.";

            //Check required information
            this.showConfirmAlert("Warning", 
                                warning,
                                'red',
                                "Cancel", 
                                this._askDeleteAgain,
                                "Proceed",
                                'red');
        }
        else 
            this._askDeleteAgain();
        
    }
            
    _askDeleteAgain = () => {       
            
        const product = this.state.selectedProduct;
        
        if (product) {
            //Check required information
            this.showConfirmAlert("Confirm", 
                                    "Really delete Product " + product.id + " named \"" + product.name + "\" permanently?",
                                    'red',
                                    "Cancel", 
                                    this._deleteProduct,
                                    "Delete",
                                    'red');
        }
    }
    
    
    _deleteProduct = () => {
        
        if (this.state.selectedProduct) {
            this.incrementBusy();
            this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/marketplace/products/" + this.state.selectedProduct.id,
                                {method: "DELETE"}, 
                                this._deleteProductCallback, this._fetchErrorCallback); 
        }
        
    }
    
    _deleteProductCallback = (response) => {
        
        this.setState( {selectedRow: -1, selectedProduct: null} );  //deselect rows
        this._clear();
        this._fetchProducts();  //refresh
    }
    

    //Called when fetch of products is done, creates the table data, and sets filteredTableData equal to the table data
    _updateTableData = () => {
        
        const tableData = this.state.products.map(product => {

            //For each product, create a row with all the column data
            return {productID: {productID: product.id, product: product},
                    name: product.name,
                    category: product.category,
                    inventory: {inventory: product.inventory, reorder: product.reorder},
                    cost: product.cost,
                    tax: product.tax,
                    discount: product.discount                 
                   }; 
        });
        
        //Sort the table by product ID
        tableData.sort((a, b) => {
           return a.productID.productID > b.productID.productID ? 1 : -1;           
        });
         
        //Clear table toolbar settings
        this._toolbarRef.current.reset();

        this.setState({tableData: tableData, filteredTableData: tableData});  //set the data, and filtered data = data
        
    }


    //Creates filteredTableData by filtering the tableData
    _filterTableData = () => {
        
        if (this._searchText.length === 0 && !this._showOnlyEnabled) {
            this.setState({filteredTableData: this.state.tableData});
            return;
        }
        
        const filteredTableData = this.state.tableData.filter(row => { 
            
            //Match on String Fields
            let textMatch = true;  //default - match on empty string
            if (this._searchText.length !== 0) {
                const searchText = this._searchText.toLowerCase();
                const product = row.productID.product;
                
                textMatch = product.id.toLowerCase().includes(searchText) ||
                            product.name.toLowerCase().includes(searchText) ||
                            product.info.toLowerCase().includes(searchText) ||
                            product.category.toLowerCase().includes(searchText);
      
            }
            
            //Match on Checked in
            let enabledMatch = true;  //default - match on switch off
            if (this._showOnlyEnabled) {
                enabledMatch = row.productID.product.enabled;
            }

            return textMatch && enabledMatch;
            
        });

        this.setState({filteredTableData: filteredTableData});
    }
        
        
    //When a field is changed, find the field in the array, and clear its background to indicate change succeeded
    _productFieldChangedCallback = (response, json, changedOriginalID) => {
        this.decrementBusy();

        const changedField = this._manageProductFields.find(field => field.json === json);
        if (changedField) {
            changedField.ref.current.accept();  //accepted change            
        }
              
        //If we have a valid response, check the id - if we haven't switched Products, then update (the response contains the Product ID and the changed field, and the last transaction)
        if (response) {
   
            if (changedOriginalID !== this.state.selectedProduct.id)  //different product
                return;

            const updatedProduct = new Product(response);
            
            //Update the product, replacing the changed field    
            this.state.selectedProduct[json] = updatedProduct[json];  
            this.state.selectedProduct.lastTransaction = updatedProduct.lastTransaction;  //update the last transaction value

            setTimeout(() => this._updateTableData(), 1);  //defer, to allow re-render
            this.forceUpdate();  //because something in our state changed 

        }
    }
        
    _fieldInputError = (label, error) => {
        this.showConfirmAlert("Error in " + label, error, 'red');
    }
    
    _fieldChangedErrorCallback = (error) => {
        this.decrementBusy();
        this.showConfirmAlert("Error", error, 'red');
    }
    
    //When a Product field is changed, create a JSON object with the key of the field and the new value. Then PATCH it to the server.
    _productFieldChanged = (json, value) => {                
        
        let objValue = value;
        
        //Handle special cases where the field value is not the json value
        if (json === "afterPurchase" || json === "afterFirstRedeem") {
            objValue = !value ? null : DateUtils.parseRelativeTimeString(value);
        }
        
        // Handle 12 hour time
        if (json === "startTime" || json === "endTime")
            objValue = !value ? null : DateUtils.parseTimeString(value);    // back to 24 hour time
                  
        console.log("Update field: " + json + " to " + objValue);
       
        const body = {[json]: objValue, lastTransaction: this.state.selectedProduct.lastTransaction};
        
        this.incrementBusy();
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/marketplace/products/" + this.state.selectedProduct.id,
                            {method: "PATCH", body: JSON.stringify(body)}, 
                             (response) => {this._productFieldChangedCallback(response, json, this.state.selectedProduct.id);}, this._fieldChangedErrorCallback); 
    }
    
    //Read data from the file as base64, and set it as the image to crop
    _showCropper = (file) => {
        
        const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
 
        if (!acceptedImageTypes.includes(file['type'])) {
            this.showConfirmAlert("Error", "File is not a valid image type", 'red');
            return;
        }
  
        ImageUtils.readImageFile(file, (dataURL) => {
            this.setState({showCropper: true, imageToCrop: dataURL});            
        });
       
    }

      
    //Callback when a file is dropped on the member image
    _productImageDropped = (fileDropped) => {
        this._showCropper(fileDropped);
    }
    
    //Callback when the file is selected
    _productImageFileSelected = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            console.log("Selected image file " + file.name);
            this._showCropper(file);
        }
        event.target.value = null;  //allow picking of the same value again

    }
    
    //Callback when the Cropper "Done" button is pressed, PATCH the new image to the server, and close the Cropper
    _imageCropped = (image) => {
                   
        if (image) {
            let imageB64 = ImageUtils.scaleImage(image, PRODUCT_IMAGE_NATIVE_WIDTH, PRODUCT_IMAGE_NATIVE_HEIGHT);
            this._productFieldChanged("imageBase64", imageB64);
        }
        
        this._refFieldsNeedUpdate = true;  //update ref fields, because they were unmounted when we switched to cropper
        this.setState({showCropper: false, imageToCrop: null});  //hide cropper
    }
    
    //Callback when the Webcam "Done" button is pressed, send the image to the Cropper
    _imageCaptured = (image) => {
        if (image) {
            this.setState({showCropper: true, imageToCrop: image, webcamOpen: false});
        }
        else { //cancel pressed
            this._refFieldsNeedUpdate = true;  //update ref fields, because they were unmounted when we switched to webcam
            this.setState({webcamOpen: false});  
        }
    }
    
    
    //Callback when the user preses the Remove Image button, PATCH a null image to the server after confirming
    _removeImage = () => {

        const doDelete = () => { this._productFieldChanged("imageBase64", null); };          
        this.showConfirmAlert("Confirm", "Really delete Product Image?", 'black', "Cancel", doDelete, "Delete", 'red');   

    }
    
    
    
        
    //Create the React Component for the field in the manageProductFields array
    _getComponentForField = (field, index, onFieldChange) => {
        
        const canEdit = this.state.selectedProduct && field.editable;  //these are editable if there's an product and editable

         //See if it's a generic one we can handle
        const reactComponent = ManagedField.getComponentForField(field, index, onFieldChange, this._fieldInputError, !canEdit);
        
        if (!reactComponent) {  //if not, specific to this class
             switch (field.component) {
                case "AttributesCheckboxes":
                    return (<AttributesCheckboxes key={index} json={field.json} style={{marginTop: 15}} label={field.label} labels={field.checkboxLabels} onChange={onFieldChange} ref={field.ref} isReadOnly={!canEdit}/>);
                default: 
                    return (<div>Unsupported Component</div>);
            }
        }
        
        return reactComponent;
    }
 
    _getManagedField = (jsonName) => {
        const field = this._manageProductFields.find(field => field.json === jsonName);
        
        //Special case - we have to get the currency after we have the value in PP
        if (jsonName === "cost")
            field.label = "Cost (" + PP.currency + ")"; 
        
        return this._getComponentForField(field, jsonName, this._productFieldChanged);
    }
        
    render() {
        
        let divIndex;
        for (divIndex=0; divIndex < this._manageProductFields.length; divIndex++) {
            if (this._manageProductFields[divIndex].json === "div")
                break;
        }
        
        
        //If currently in crop mode, just render the Cropper component
        if (this.state.showCropper) {
            
            return (<ImageCropper image={this.state.imageToCrop} 
                                  aspectRatio={1.0} 
                                  onDone={this._imageCropped} 
                                  cancelIconColor={ThemeColors.darkRed}/>);
        }
        
         //If currently in webcam mode, just render the webcam component
        if (this.state.webcamOpen) {
            return (<WebcamCapture onDone={this._imageCaptured} onPreCapture={() => AudioPlay.playCameraShutter()} 
                                   width={PRODUCT_IMAGE_NATIVE_WIDTH} height={PRODUCT_IMAGE_NATIVE_HEIGHT}
                                   cancelIconColor={ThemeColors.darkRed}/>);
        }
        
        const hasImage = this.state.selectedProduct && this.state.selectedProduct.imageBase64;
                
        const isUnlimitedRedeem  = this.state.selectedProduct && this.state.selectedProduct.unlimitedRedeem();
        
        const numRe = this.state.selectedProductRedeemables.length;
        const warning = numRe + " Ticket" + (numRe > 1 ? "s have" : " has") + " remaining redeemables for this Product! Changing the product " +
                        "may affect whether these customers can still redeem it as originally expected when they purchased it. Make sure you " +
                        "understand what impact changing this product will have on these Tickets. Note: you can safely change a Product if you are sure " +
                        "it cannot be redeemed any longer.";
                
                
        const viewProductsOnly = !PP.user.hasPermissionTo(Permissions.FULL_MARKETPLACE_CONTROL);        
                
        return (
            <div>
                {this.getConfirmAlertComponent()}
                
                  <TextEntryPopover isOpen={this.state.newProductPromptOpen} showSkip={false} multiline={false} title="New Product ID" 
                                 okCallback={this._newProductPromptOkCallback} cancelCallback={() => this.setState({newProductPromptOpen: false})}/>
    
                            
                 <Grid container direction="row" spacing={4}>
                    
                    { /*----------------------------  LIST LEFT  ----------------------------------------*/ null }

                    
                    <Grid item md={8} xs={12} style={{justifyContent: 'center'}}>    
                        <DataTableComponent title={this.getBusyComponent('left')} data={this.state.filteredTableData} columns={this.columns()} options={this.state.tableOptions} hoverColor={ThemeColors.tableHover}/>       
                    </Grid>

                    { /*----------------------------  PRODUCT RIGHT ----------------------------------------*/ null }

        
                    <Grid item md={4} xs={12} style={{justifyContent: 'center'}}>    
                        <Paper>                           
                            <div style={{display: 'flex'}}>

                                <Typography variant="body2" style={this.styles.paperLabel}>Product</Typography>   

                                {this.state.selectedProductRedeemables.length > 0 ? 
                                    <RedeemableWarningTooltip title={warning}>
                                        <IconButton disabled={false} edge="end" style={{marginTop: -4}}>
                                            <WarningIcon style={{color: 'red'}} />
                                        </IconButton>
                                    </RedeemableWarningTooltip> : null}

                                <ProductInfoTooltip title={this.state.selectedProduct ? this.state.selectedProduct.describe() : ""} enterTouchDelay={0}>
                                    <IconButton disabled={false} edge="end" style={{marginTop: -4}}>
                                        <InfoIcon style={{color: this.state.selectedProduct ? 'blue' : 'lightGray'}} />
                                    </IconButton>
                                </ProductInfoTooltip>
                                <Tooltip title={"Add New Product"}>
                                    <IconButton disabled={viewProductsOnly} edge="end" onClick={() => {this.setState({newProductPromptOpen: true})}} style={{marginTop: -4}} enterTouchDelay={0}>
                                        <AddCircleOutlineIcon style={{color: viewProductsOnly ? 'lightGray' : ThemeColors.addColor}} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={"Delete Product"}>
                                    <IconButton disabled={!this.state.selectedProduct || viewProductsOnly} edge="end" onClick={this._askDeleteProduct} style={{marginTop: -4, marginRight: 4}}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>                                 
                     
                           </div>
                           
                            <div style={{padding: 10}}>
                            
                                <Grid container direction="row" spacing={2}>
                                    <Grid item lg={6} xs={12} style={{justifyContent: 'left'}}>
                                        {this._getManagedField("id")}
                                        {this._getManagedField("name")}
                                        {this._getManagedField("category")}   
                                        {this._getManagedField("info")}   
                                    </Grid>
                                    <Grid item lg={6} xs={12} style={{display: 'flex', justifyContent: 'center'}}>                                      
                                        <div style={{marginTop: 15}}>
                                            <DragAndDrop handleDrop={this._productImageDropped}>
                                                <Tooltip title={"Drag and Drop New Image Below or Paste from Clipboard"} placement="top-start">
                                                    {ImageFunctions.productImageURL(this.state.selectedProduct)}
                                                </Tooltip>
                                            </DragAndDrop>
                                            <span style={{display: 'flex',  justifyContent: 'space-evenly', marginTop: 20, marginLeft: 8}}>
                                            
                                                <Tooltip title="Capture from Webcam">
                                                    <IconButton disabled={!this.state.selectedProduct || viewProductsOnly} onClick={() => this.setState({webcamOpen: true})} >
                                                        <CameraAltIcon fontSize="medium" style={{color: this.state.selectedProduct && !viewProductsOnly ? '#7570ff' : 'lightGray'}} />
                                                    </IconButton>
                                                </Tooltip>
                                                
                                                {viewProductsOnly ? null :
                                                    <input accept="image/*" id="fileInput" style={{display: 'none'}} type="file" onChange={this._productImageFileSelected}/>                                      
                                                }
                                                <Tooltip title="Load from Image File">
                                                    <label htmlFor="fileInput">
                                                        <IconButton disabled={!this.state.selectedProduct || viewProductsOnly} component="span">
                                                            <DescriptionIcon style={{color: this.state.selectedProduct && !viewProductsOnly ? '#87854a' : 'lightGray'}}/>
                                                        </IconButton>
                                                    </label>
                                                </Tooltip>
                                                
                                                <Tooltip title="Remove Image">
                                                    <IconButton disabled={!hasImage || viewProductsOnly} onClick={this._removeImage} >
                                                        <ClearOutlinedIcon style={{color: hasImage && !viewProductsOnly ? ThemeColors.darkRed : 'lightGray'}}/>
                                                    </IconButton>
                                                </Tooltip>
                                            
                                            </span>
                                        </div>
                                    </Grid>
                                </Grid>
                                
                                {this._getManagedField("enabled")}   

                                <Grid container direction="row" spacing={2}>
                                    <Grid item md={4} xs={12} style={{justifyContent: 'left'}}>
                                        {this._getManagedField("cost")}
                                    </Grid>
                                    <Grid item md={4} xs={12} style={{justifyContent: 'left'}}>
                                        {this._getManagedField("tax")}
                                    </Grid>
                                    <Grid item md={4} xs={12} style={{justifyContent: 'left'}}>
                                        {this._getManagedField("discount")}
                                    </Grid>
                                </Grid>
                            
                                {this._getManagedField("attributes")}  
                                <div style={{marginTop: 10}}/>
                                {this._getManagedField("inventory")}
                                {this._getManagedField("reorder")}
                                
                               <Grid container direction="row" spacing={2} alignItems='center'>
                                        <Grid item md={7} xs={12} style={{justifyContent: 'left'}}>
                                            {this._getManagedField("maxQuantity")}
                                        </Grid>
                                        <Grid item md={5} xs={12} style={{justifyContent: 'left'}}>
                                            {this._getManagedField("minQuantity")}
                                        </Grid>
                                </Grid>


                                {this._getManagedField("div")}
                                {this._getManagedField("startDate")}
                                {this._getManagedField("endDate")}
                                {this._getManagedField("startTime")}
                                {this._getManagedField("endTime")}
                                {this._getManagedField("dayOfWeek")}
                             
                                <div style={{ display: isUnlimitedRedeem ? "block" : "none" }}>
                                    {this._getManagedField("afterPurchase")}
                                    {this._getManagedField("afterFirstRedeem")}                                  
                                </div>
                               
                            </div>  

                                                     
                        </Paper>
                    </Grid>
                </Grid>
                    
            </div>

        );

    }
}


export default withCookies(ProductTab);

