import { withCookies } from 'react-cookie';

import { AbstractStatisticsTab } from './AbstractStatisticsTab';
import { MembershipStat } from '../../models/MembershipStat'

import { ThemeColors } from '../../Theme'

import { PP } from '../../models/PP'

export class MembershipStatsTab extends AbstractStatisticsTab {
  
    
  
    _options = {
      
        chart: {...this._standardChartOptions, stacked: false},
        colors: [ThemeColors.membersBlue, ThemeColors.membershipsGreen], 
        fill: {
            type: "gradient",
            gradient: {
              shadeIntensity: 0.9,
              opacityFrom: [0.9, 0.9],
              opacityTo: [0.9, 0.9]
            }
        },
        stroke: {
            curve: 'stepline',
            width: 1
        },
        dataLabels: {
            enabled: false         
        },
        xaxis: {
            type: 'datetime',
            labels: {
                datetimeUTC: false,
                datetimeFormatter: this._datetimeFormatter(true)
            }
        },
        tooltip: {
            x: {
                format: this._dateFormat(true)
            },
            y: {
                formatter: (value) => { return value.toFixed(0); }  //Format as integer
            }
        }
    }

    constructor(props) {
        super(props, false); 
        this._chartOptions = this._options;
        this._chartType = "area";
        this._chartFetchPath = "/ppcs/databases/" + PP.selectedDatabase + "/stats/memberships";
        this._chartDataHandlerCallback = this._processChartData;
        this._hasLocation = false;
        this._hasAggregate = false;
        this._hasTime = true;
        this._hasTimeRange = true;
        this._minZoomTime = 2;  //hours
    }
    
    _processChartData = (response) => {
        
        let membershipData = response.map(stat => new MembershipStat(stat));
        
        if (membershipData.length === 0) {  //no data
            this.setState({chartData: null});
            return;
        }
        
        //Add the stat for the current time
        const nowStat = MembershipStat.fromLast(membershipData[membershipData.length - 1]);
        membershipData.push(nowStat);
        
        const membershipSeriesData = membershipData.map(stat => [stat.time, stat.memberships]);
        const memberSeriesData = membershipData.map(stat => [stat.time, stat.members]);
                
        const series = [
            {
              name: "Members",
              data: memberSeriesData
            }, 
            {
              name: "Memberships",
              data: membershipSeriesData
            } 
        ];
      
        this.setState({chartData: series});
    }
    
    
}
  
  
export default withCookies(MembershipStatsTab);