import React, { Component } from 'react';
import { Container, Popover, Box, Button, Typography,FormControlLabel, Checkbox } from '@material-ui/core'

import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { Member } from '../models/Member'

/**
 * 
 Popover for Actions selection.  Brings up a popup allowing the user to select one or more of the Member's Actions. Once at least one action is
 checked, the OK button becomes visible. The Bitwise value of those selected are passed back to the callback function as a value.

 *  a function called "okCallback" which takes a integer value (bitwise) of the Actions selected, called when the Ok button is pressed
 *  a function called "cancelCallback" which is called when the Cancel button is pressed
 *  a string called "label" which is the title of the callback
 *  
*/ 

export default class ActionSelectPopover extends Component {

    styles = {
        button: {
            marginLeft: 10,
            marginRight: 10
        },
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
    };

 
    _okCallback;
    _cancelCallback;
        
    state = {
        bitwiseValue: 0,
        okButtonEnabled: false
    }
    
    constructor(props) {
        super(props);
        this._okCallback = props.okCallback;
        this._cancelCallback = props.cancelCallback;
    }
   
    
    _okButtonPressed = () => {
        this._okCallback(this.state.bitwiseValue);
    }
    
    _cancelButtonPressed = () => {
        this._cancelCallback();
    }
    
    
    _checkboxChanged = (bitPosition, oldBit) => {
        
        let newValue = this.state.bitwiseValue;
        
        if (oldBit === 0)   
            newValue |= (1 << bitPosition);  //bit was 0, flip to 1
        else 
            newValue &= ~(1 << bitPosition);  //bit was 1, flip to 0
         
        this.setState({bitwiseValue: newValue, okButtonEnabled: newValue > 0});    
    }
    
    render() {
        
        let maxWidth = window.innerWidth * 0.5;  //half the window, up to 350
        if (maxWidth > 350)
            maxWidth = 350;
            
        const checkboxes = Member.actions.map((label, index) => {return {label: label, bitPosition: index, bitVal: this.state.bitwiseValue & (1 << index)}});

        return (
            <Popover open={this.props.isOpen} 
                anchorReference="anchorPosition"
                anchorPosition={{left: window.innerWidth/2, top: window.innerHeight/2}}
                transformOrigin={{vertical: 'center', horizontal: 'center'}}  >
                <Box style={{margin: 20, width: maxWidth}}>
                    <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>{this.props.label}</Typography>  
                    <div style={{marginTop: 10}}/>

                     {checkboxes.map((checkbox, index) => 
                            <FormControlLabel key={index}
                                              control={(<Checkbox size="medium" color='primary' 
                                                        checkedIcon={this.props.deselect ? <IndeterminateCheckBoxIcon style={{color: 'gray'}}/> : <CheckBoxIcon/>}
                                                        checked={checkbox.bitVal > 0} 
                                                        onChange={() => this._checkboxChanged(checkbox.bitPosition, checkbox.bitVal)} 
                                                        />)} 
                                              labelPlacement="end"
                                              label={(<Typography variant="body2" style={{fontSize: 12, marginTop: 2}} color="textSecondary">{checkbox.label.name}</Typography>)}
                             />
                        )
                    }

                    <div style={{marginTop: 10}}/>

                    <Container style={this.styles.container}>    
                        <Button onClick={this._okButtonPressed} disabled={!this.state.okButtonEnabled} color="primary" variant="outlined" style={this.styles.button}>OK</Button>
                        <Button onClick={this._cancelButtonPressed} variant="outlined" style={this.styles.button}>Cancel</Button>
                    </Container>
                </Box>
            </Popover>
        );
        
        
    }


}