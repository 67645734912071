import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';

import { Paper, Tooltip, Grid, Typography, Button, FormControl, FormControlLabel, Checkbox, TextField, RadioGroup, Radio, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle  } from '@material-ui/core'
import PublishIcon from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import Autocomplete from '@material-ui/lab/Autocomplete';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';

import { RestComponent, ManageNumericField, ManageDateField, ManageTimeField } from 'react-frontend-utils' 
import ActionSelectPopover from '../components/ActionSelectPopover'
import ImportExportTooltip, { importExportHelp } from '../components/ImportExportTooltip'

import { DateUtils, TextEntryPopover, Permissions } from 'react-frontend-utils'
import { PP, BarcodeSuggestionType } from '../models/PP'
import { ThemeColors } from '../Theme'

import RestrictionsSubpage from './RestrictionsSubpage'


export class OpsTab extends RestComponent {
  

    styles = {
        paper: {
            width: '100%',
            height: '100%'
        },
        textfield: {
            margin: 5,
            marginBottom: 10
        },
        paperLabel: {
            marginLeft: 15,
            color: 'gray',
            fontSize: '9pt'
        },
        form: {
            marginLeft: 15,
            marginTop: 15
        },
        fullWidthButton: {
            margin: 20
        }
    }

    _guestPassesText = "0";
    _entryPassesText = "0";
    _expDateVal = undefined;
    _doSetActions = true;
    
    _autoCheckOutRef = React.createRef();
    _autoResetGuestPassRef = React.createRef();
    _autoResetGuestPassValueRef = React.createRef();
    
    _renameLocationTo;
    _renameTypeTo;
    _importExportHelpTooltip = importExportHelp();

    constructor(props) {
        super(props);
        this.state.updateGuestPassesSuspendedIgnored = true;
        this.state.updateEntryPassesSuspendedMembershipsIgnored = true;
        this.state.updateEntryPassesSuspendedMembersIgnored = true;
        this.state.updateEntryPassesUnlimitedIgnored = true;
        this.state.sendEPassesSuspendedMembershipsIgnored = true;
        this.state.sendEPassesExpiredMembershipsIgnored = true;
        this.state.updateExpDateNever = true;
        this.state.selectedLocation = null;
        this.state.selectedLocationValue = null;
        this.state.locations = [];
        this.state.renameLocationPromptOpen = false;
        this.state.renameTypePromptOpen = false;
        this.state.showActionsPopup = false;
        this.state.selectedBarcodeSuggestion = PP.barcodeSuggestionType();
        this.state.minBarcodeSuggestDigits = PP.barcodeSuggestionDigits();
        this.state.barcodeStartingValue = 0;
        this.state.selectedMembershipType = null;
        this.state.selectedMembershipValue = null;
        this.state.membershipTypes = [];
        this.state.autoCheckOut = undefined;  //not yet known
        this.state.autoResetGuestPass = undefined;
        this.state.autoResetGuestPassStoredValue = 0;
        this.state.autoResetGuestPassValue = 0;
        this.state.manageRestrictionsSubpageOpen = false;
    }
    
    /**
     * When the page loads, fetch locations
     */
    componentDidMount() {

        super.componentDidMount();      
        if (PP.selectedDatabase) {
            this._fetchLocations();
            this._fetchMembershipTypes();
            this._fetchAutoCheckOut();
            this._fetchAutoResetGuestPass();
        }
    }
    
    
    _fetchLocations = () => {
        this.incrementBusy();
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/locations", {}, this._fetchLocationsDoneCallback, this._onError);       
    }
    
    //Set the locations state for the locations pulldown
    _fetchLocationsDoneCallback = (response) => {
        this.decrementBusy();
        
        let selectedLoc = null;
        if (response.length > 0) {
            if (this._renameLocationTo && response.includes(this._renameLocationTo))  //if from previous rename, set that value
                selectedLoc = this._renameLocationTo;
            else
                selectedLoc = response[0];  //otherwise set the first one
        }        
        
        this.setState({locations: [...response], selectedLocation: selectedLoc});
    }
    
    
    _fetchAutoCheckOut = () => {
        this.incrementBusy();
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/bulk/autoCheckOut", {}, this._fetchAutoCheckOutDoneCallback, this._onError);       
    }
    
    
    //Set the locations state for the locations pulldown
    _fetchAutoCheckOutDoneCallback = (response) => {
        this.decrementBusy();
        
        if (response)
            this.setState({autoCheckOut: response});
        else
            this.setState({autoCheckOut: null});
    }
    
    _fetchAutoResetGuestPass = () => {
        this.incrementBusy();
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/bulk/autoGuestReset", {}, this._fetchAutoGuestResetCallback, this._onError);       
    }
    
    
    _fetchAutoGuestResetCallback = (response) => {
        this.decrementBusy();
        
        if (response) {
            const val = response.value ? response.value : 0;
            this.setState({autoResetGuestPass: response.time, autoResetGuestPassValue: val, autoResetGuestPassStoredValue: val});
        }
        else
            this.setState({autoResetGuestPass: null, autoResetGuestPassValue: 0, autoResetGuestPassStoredValue: 0});
    }
    
    _renameLocation = () => {
        
        if (this.state.selectedLocation == null)
            return;
        this.setState({renameLocationPromptOpen: true});  //open the prompt for new name
    }
    
    _renameLocationOkCallback = (text) => {  //user pressed ok on the prompt
        
        if (text != null) {  //some text
            
            this._renameLocationTo = text;
        
            console.log("Rename Location " + this.state.selectedLocation + " to: " + this._renameLocationTo);
                    
            const endPath = "?existingLocation=" + encodeURIComponent(this.state.selectedLocation) + "&newLocation=" + encodeURIComponent(this._renameLocationTo);
                    
            this.incrementBusy();
            this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/locations",
                                {method: "POST"}, 
                                this._locationUpdatedCallback, this._onError, endPath); 
            
            
        }
        this.setState({renameLocationPromptOpen: false}); //close prompt
    }
    
    //Rename succeeded
    _locationUpdatedCallback = () => {
        this.decrementBusy();
        
        //Now refetch the location list - and select the renameTo value
        this._fetchLocations();
    }
    
    
    _getDeleteLocationPrompt = () => {
       
        const title = "Select from where to delete location \"" + this.state.selectedLocation + "\":";
       
        return (           
            <Dialog open={this.state.promptDeleteLocationOpen} onClose={() => {this.setState({promptDeleteLocationOpen: false})}}>

                <DialogTitle>
                    <span>Delete Location</span>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <span style={{color: 'black'}}>{title}</span>
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button variant="outlined" onClick={() => {this._confirmDeleteLocationAction(false);}} style={{color: 'black'}}>From Statistics</Button>
                    <Button variant="outlined" onClick={() => {this._confirmDeleteLocationAction(true);}} style={{color: 'black'}}>From Statistics and Logs</Button>
                    <Button variant="outlined" onClick={() => {this.setState({promptDeleteLocationOpen: false})}} style={{color: 'black'}}>Cancel</Button>
                </DialogActions>

            </Dialog>
        );
    }
    
    _confirmDeleteLocationAction = (includeLogs) => {
        
        this.setState({promptDeleteLocationOpen: false});
        const includeString = includeLogs ? " Log entries for the location will also be deleted." : "";
        const message = "Do you really want to delete all check-in and occupancy statistics for location: \"" +  this.state.selectedLocation + "\"?" + includeString;
        
        this.showConfirmAlert("Confirm",  message, 'black', "Cancel", () => this._doDeleteLocation(includeLogs), "Delete", 'red');   
 
    }
    
    _doDeleteLocation = (includeLogs) => {
         const endPath = "?existingLocation=" + encodeURIComponent(this.state.selectedLocation) + "&includeLogs=" + (includeLogs ? "true" : "false");
                    
            this.incrementBusy();
            this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/locations",
                                {method: "DELETE"}, 
                                this._locationUpdatedCallback, this._onError, endPath);   //refresh after
            
    }
    
    _deleteLocation = () => {
        
        if (this.state.selectedLocation == null)
            return;
        this.setState({promptDeleteLocationOpen: true});  //open the prompt for delete
    }
    
    
    
    _setAutoCheckOut = (enable) => {
       
       if (this._autoCheckOutRef.current) {
           
           let queryParam = "";
           if (enable) {
               queryParam = "?time=" + this._autoCheckOutRef.current.get();
           } 
                       
           this.incrementBusy();
           this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/bulk/autoCheckOut" + queryParam, {method: "POST"}, this._setAutoCheckOutCallback, this._onError);       
    
       }
       
    }
    
    _setAutoCheckOutCallback = (response) => {
        this.decrementBusy();
        this._fetchAutoCheckOut();  //refetch to update
    }
    
    
    _setAutoGuestReset = (enable) => {
       
       if (this._autoResetGuestPassRef.current) {
           
           let queryParam = "";
           if (enable) {
               queryParam = "?time=" + this._autoResetGuestPassRef.current.get() + "&value=" + this.state.autoResetGuestPassValue;
           } 
                       
           this.incrementBusy();
           this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/bulk/autoGuestReset" + queryParam, {method: "POST"}, this._setAutoGuestResetCallback, this._onError);       
    
       }
       
    }
    
    _setAutoGuestResetCallback = (response) => {
        this.decrementBusy();
        this._fetchAutoResetGuestPass();  //refetch to update
    }
    
    
    
    
    _fetchMembershipTypes = () => {
        this.incrementBusy();
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/membership/types", {}, this._fetchMembershipTypesDoneCallback, this._onError);       
    }
    
    //Set the locations state for the locations pulldown
    _fetchMembershipTypesDoneCallback = (response) => {
        this.decrementBusy();
        
        let selectedType = null;
        if (response.length > 0) {
            if (this._renameTypeTo && response.includes(this._renameTypeTo))  //if from previous rename, set that value
                selectedType = this._renameTypeTo;
            else
                selectedType = response[0];  //otherwise set the first one
        }        
        
        this.setState({membershipTypes: [...response], selectedMembershipType: selectedType});
    }
    
    _renameMembershipType = () => {
        
        if (this.state.selectedMembershipType === null)
            return;
        this.setState({renameTypePromptOpen: true});  //open the prompt for new name
    }
    
    _renameMembershipTypeOkCallback = (text) => {  //user pressed ok on the prompt
        
        if (text != null) {  //some text
            
            this._renameTypeTo = text;
        
            console.log("Rename MembershipType " + this.state.selectedMembershipType + " to: " + this._renameTypeTo);
                    
            const endPath = "?existingType=" + encodeURIComponent(this.state.selectedMembershipType) + "&newType=" + encodeURIComponent(this._renameTypeTo);
                    
            this.incrementBusy();
            this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/bulk/membershiptypes",
                                {method: "POST"}, 
                                this._membershipTypeUpdatedCallback, this._onError, endPath); 
            
            
        }
        this.setState({renameTypePromptOpen: false}); //close prompt
    }
    
    //Rename succeeded
    _membershipTypeUpdatedCallback = () => {
        this.decrementBusy();
        
        //Now refetch the location list - and select the renameTo value
        this._fetchMembershipTypes();
    }
    
    _deleteAllMembershipsOfType = () => {

        const type = this.state.selectedMembershipType;
        this.showConfirmAlert("Confirm", "Do you really want to delete all Memberships with type \"" + type + "\"?", 'black', "Cancel", () => this._doDeleteAllConfirmAgain("type", true, type), "Delete", 'red');   
    }
  
    _deleteAll = (only, membership) => {
        
        const onlyString = only ? only : "";
        const membershipString = membership ? " Memberships?" : " Members?";
        this.showConfirmAlert("Confirm", "Do you really want to delete all " + onlyString + membershipString, 'black', "Cancel", () => this._doDeleteAllConfirmAgain(only, membership), "Delete", 'red');   
 
    }

    _doDeleteAllConfirmAgain = (only, membership, type = null) => {
        this.showConfirmAlert("Confirm Delete", "Are you sure? This operation is not reversible!", 'black', "Cancel", () => this._doDeleteAll(only, membership, type), "Delete", 'red');   
    }
    
    _doDeleteAll = (only, membership, type) => {
        const onlyParam = only ? "?only=" + only : "";
        
        const typeParam = type != null ? "&type=" +  encodeURIComponent(type) : "";

        this.incrementBusy();
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/bulk/delete" + onlyParam + typeParam,
                                {method: "POST"}, 
                                (response) => this._deleteResponse(response, membership), this._onError); 
    }
    
    _deleteResponse = (response, membership) => {
        this.decrementBusy();
        if (response) {
            const membershipString = membership ? " Memberships" : " Members";
            this.showConfirmAlert("Operation Complete", "Deleted " + response + membershipString);
        }
        
    }
  
  
    _onError = (error) => {
        this.setBusy(false);
        this.showConfirmAlert("Error", error, 'red');
        this.closeProgressAlert();
     }

    _onUpdatedProgress = (isDone, status) => {

         if (!isDone)  {
            const split = status.split(";");
            const progressValue = split[0];
            const message = progressValue < 0 ? ("Error: " + split[1]) : split[1];
            const altMessage = split[2] ? split[2] : "";
            const color = progressValue < 0 ? 'red' : 'black';
            const cancelButtonText = progressValue < 0 ? "Cancel" : null;
            
            this.updateProgressAlert(progressValue, message, altMessage, color, color, cancelButtonText);
         }
    }
  
    _csvFileSelectedForValidate = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            this._uploadCSVFile(event.target.files[0], true);
        }
        event.target.value = null;  //allow picking of the same value again
    }
  
    _csvFileSelected = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            this._uploadCSVFile(event.target.files[0], false);
        }
        event.target.value = null;  //allow picking of the same value again
    }
    
    
    _uploadCSVFile = (file, forValidate) => {
        
        console.log("Uploading " + file.name);

        const expDate = PP.initialExpirationDate() ? ("expirationDate=" + PP.initialExpirationDate() + "&") : "";

        this.secureFileUpload("/ppcs/databases/" + PP.selectedDatabase + "/bulk/upload?" + expDate +
                             "initialGuestPasses=" + PP.initialGuestPasses() + "&" +
                             "initialEntryPasses=" + PP.initialEntryPasses() +  
                             (forValidate ? "&validateOnly=true" : ""),
                              file, this._onUpdatedProgress, this._onError);


        this.showProgressAlert("Processing CSV Upload", "Uploading...");
    }
    
    _download = () => {
        this.incrementBusy();
        const filename = "Member Export from " + PP.selectedDatabase + " on " + DateUtils.downloadTimeString() + ".csv";
        
        this.secureFileDownload("/ppcs/databases/" + PP.selectedDatabase + "/bulk/download", filename, this._downloadResponse, this._onError); 
    }
    
    
    _downloadResponse = () => {
        this.decrementBusy();
        console.log("Download complete");
    }
    
    
    _guestPassesChanged = (json, val) => {
        this._guestPassesText = val;
        this.forceUpdate();  //because set button may need to be dimmed
    }
  
  
    _updateAllGuestPasses = (increment) => {
        const str = increment ? "increment" : "update";
        this.showConfirmAlert("Confirm", "Do you really want to " + str + " guest passes?", 'black', "Cancel", () => this._doUpdateAllGuestPasses(increment), 'Proceed', 'red');   
    }
  
    _doUpdateAllGuestPasses = (increment) => {
        
        const valString = "?value=" + this._guestPassesText + "&";
        const incrementString = "increment=" + (increment ? "true" : "false") + "&";
        const ignoreString = "ignoreSuspended=" + this.state.updateGuestPassesSuspendedIgnored;
        
        this.incrementBusy();
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/bulk/updateGuestPasses" + valString + incrementString + ignoreString,
                                {method: "POST"}, 
                                this._updateGuestPassResponse, this._onError); 
    }
  
    _updateGuestPassResponse = (response) => {
        this.decrementBusy();
        if (response) {
            this.showConfirmAlert("Operation Complete", "Updated " + response + " Memberships");
        }
        
    }
    
    _suspendAll = (suspend) => {
        
        const str = suspend ? "Suspend" : "Reinstate";
        this.showConfirmAlert("Confirm", "Do you really want to " + str + " all Memberships?", 'black', "Cancel", () => this._doSuspendAll(suspend), str, 'red');   
 
    }
    
    
    _doSuspendAll = (suspend) => {
        
        const setString = "?set=" + (suspend ? "true" : "false"); 
        
        this.incrementBusy();
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/bulk/suspend" + setString,
                                {method: "POST"}, 
                                this._suspendResponse, this._onError); 
    }
  
    _suspendResponse = (response) => {
        this.decrementBusy();
        if (response) {
            this.showConfirmAlert("Operation Complete", "Updated " + response + " Memberships");
        }
        
    }
    
     _entryPassesChanged = (json, val) => {
        this._entryPassesText = val;
        this.forceUpdate();  //because increment button may need to be dimmed
    }
    
    _autoResetGuestPassValueChanged = (event) => {
        const val = event.target.value;
        if (val < 0)
            return;
        this.setState({autoResetGuestPassValue: val});
    }
    
    _updateAllEntryPasses = (increment) => {
        const str = increment ? "increment" : "update";
        this.showConfirmAlert("Confirm", "Do you really want to " + str + " entry passes?", 'black', "Cancel", () => this._doUpdateAllEntryPasses(increment), 'Proceed', 'red');   
    }
    
    _doUpdateAllEntryPasses = (increment) => {
        
        const valString = "?value=" + this._entryPassesText + "&";
        const incrementString = "increment=" + (increment ? "true" : "false") + "&";
        const ignore1String = "ignoreSuspendedMemberships=" + this.state.updateEntryPassesSuspendedMembershipsIgnored + "&";
        const ignore2String = "ignoreSuspendedMembers=" + this.state.updateEntryPassesSuspendedMembersIgnored + "&";
        const ignore3String = "ignoreUnlimited=" + this.state.updateEntryPassesUnlimitedIgnored;
        
        this.incrementBusy();
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/bulk/updateEntryPasses" + valString + incrementString + 
                            ignore1String + ignore2String + ignore3String,
                            {method: "POST"}, 
                            this._updateEntryPassResponse, this._onError); 
    }
  
    _updateEntryPassResponse = (response) => {
        this.decrementBusy();
        if (response) {
            this.showConfirmAlert("Operation Complete", "Updated " + response + " Members");
        }      
    }
    
    
    _expDateParseError = (label, error) => {
        this.showConfirmAlert("Error in Expiration Date Field", error, 'red');
        this._expDateVal = undefined;
        this.forceUpdate();  //because apply button may need to be dimmed
    }
    
    _expDateChanged = (json, val) => {
        this._expDateVal = val;
        this.forceUpdate();  //because apply button may need to be dimmed
    }
    
    
    _autoParseError = (label, error) => {
        this.showConfirmAlert("Error in " + label, error, 'red');
    }
    
    _updateAllExpDates = () => {
        this.showConfirmAlert("Confirm", "Do you really want to update expiration dates?", 'black', "Cancel", this._doUpdateAllExpDates, 'Proceed', 'red');   
    }
    
    _doUpdateAllExpDates = () => {
        if (this._expDateVal === undefined) {
            this.showConfirmAlert("Error in Expiration Date Field", "Enter a valid date", 'red');
            return;
        }
        
        const dateString = this._expDateVal ? "?date=" + this._expDateVal + "&" : "?";
        
        const ignoreString = "ignoreNeverExpired=" + this.state.updateExpDateNever;
        
        this.incrementBusy();
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/bulk/updateExpirationDates" + dateString + ignoreString, 
                            {method: "POST"}, 
                            this._updateExpResponse, this._onError); 
        
    }
    
    _updateExpResponse = (response) => {
        this.decrementBusy();
        if (response) {
            this.showConfirmAlert("Operation Complete", "Updated " + response + " Memberships");
        }      
    }
    
    
    
    _checkOutAll = (zero) => {
        
        const zeroString = zero ? " and Zero Occupancy at all Locations " : "";
        this.showConfirmAlert("Confirm", "Check out all Members" + zeroString + "?", 'black', "Cancel", () => this._doCheckOutAll(zero), "OK", 'red');   
 
    }

 
    
    _doCheckOutAll = (zero) => {
        const zeroParam = zero ? "?zeroOccupancy=true" : "";
             
        this.incrementBusy();
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/bulk/checkOut" + zeroParam,
                                {method: "POST"}, 
                                this._checkOutResponse, this._onError); 
    }
    
    
    _sendAllEPasses = () => {
        this.showConfirmAlert("Confirm", "Send ePasses?", 'black', "Cancel", this._doSendAllEPasses, 'Proceed', 'red');   
    }
    
    _doSendAllEPasses = (ignoreInvalid = false) => {
        
        const ignore1String = "?ignoreSuspendedMemberships=" + this.state.sendEPassesSuspendedMembershipsIgnored + "&";
        const ignore2String = "ignoreExpiredMemberships=" + this.state.sendEPassesExpiredMembershipsIgnored;
        const ignore3String = ignoreInvalid ? "&ignoreInvalid=true" : "";
                      
        this.incrementBusy();
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/bulk/sendEPasses" + ignore1String + ignore2String + ignore3String,
                            {method: "POST"}, 
                            this._sendEPassResponse, this._onPassSendError); 
    }
    
    
    _sendEPassResponse = (response) => {
        this.decrementBusy();
        if (response) {
            this.showConfirmAlert("Send Request Submitted", "Emails to " + response + " Memberships will be sent over the next few minutes");
        }      
    }
    
    _onPassSendError = (error) => {
        this.decrementBusy();
        const message = <span>
                            {error}
                            <div style={{marginTop: 20}}>Do you want to send anyway, skipping any Memberships with invalid emails?</div>
                        </span>;
                        
        this.showConfirmAlert("Error", message, 'red', "Cancel", () => this._doSendAllEPasses(true), "Continue Anyway");   
    }
    
    
    
    _checkOutResponse = (response) => {
        this.decrementBusy();
        if (response) {
            this.showConfirmAlert("Operation Complete", "Checked out " + response + " Members");
        }
        
    }
  
  
    
    _changeActions = (doSet) => {
        this._doSetActions = doSet;
        this.setState({showActionsPopup: true});  //show the popup, for either set or clear
    }
  
  
    _actionsSelectedCallback = (val) => {
        
        this.setState({showActionsPopup: false});
              
        const queryString = this._doSetActions ? "set?value=" + val : "clear?value=" + val;
        
        this.incrementBusy();
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/bulk/actions/" + queryString,
                                {method: "POST"}, 
                                this._actionsResponse, this._onError); 
    }
    
    _actionsResponse = (response) => {
        this.decrementBusy();
        if (response) {
            this.showConfirmAlert("Operation Complete", "Updated actions for " + response + " Members");
        }
    }
    
    _selectBarcodeSuggestion = (event) => {
        const type = event.target.value;
        this.setState({selectedBarcodeSuggestion: type});
    };
    
    _barcodeSuggestionDigitsChanged = (event) => {
        const digits = event.target.value;
        if (digits < 1)
            return;
        this.setState({minBarcodeSuggestDigits: digits});
    }
    
    _barcodeStartingValueChanged = (event) => {
        const digits = event.target.value;
        if (digits < 0)
            return;
        this.setState({barcodeStartingValue: digits});
    }
    
    
    _updateAllBarcodes = (overwrite) => {
        this.showConfirmAlert("Confirm", "Do you really want to update barcodes?", 'black', "Cancel", () => this._doUpdateAllBarcodes(overwrite), 'Proceed', 'red');   
    }
    
    _doUpdateAllBarcodes = (overwrite) => {
        
        const typeString = "?type=" + this.state.selectedBarcodeSuggestion + "&";
        const digitsString = "digits=" + this.state.minBarcodeSuggestDigits + "&";
        const startString = "startValue=" + this.state.barcodeStartingValue + "&";
        const overwriteString = "overwrite=" + (overwrite ? "true" : "false");
                        
        this.secureFetchWithProgress("/ppcs/databases/" + PP.selectedDatabase + "/bulk/barcodes" + typeString + digitsString + startString + overwriteString,
                                    {method: "POST"}, 
                                    this._onUpdatedProgress, this._onError);


        this.showProgressAlert("Updating Barcodes", "Preparing...");
    }
  
    _clearAllBarcodes = () => {
        this.showConfirmAlert("Confirm", "Do you really want to clear all barcodes?", 'black', "Cancel", this._doClearAllBarcodes, 'Clear All', 'red');   
    }
  
    _doClearAllBarcodes = () => {
        this.incrementBusy();
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/bulk/barcodes",
                                {method: "DELETE"}, 
                                this._clearBarcodesResponse, this._onError); 
    }
    
    _clearBarcodesResponse = (response) => {
        this.decrementBusy();
        this.showConfirmAlert("Operation Complete", "Cleared all Barcodes");
    }
    
    _manageRestrictions = () => {
        this.setState({manageRestrictionsSubpageOpen: true});
    }
    
    _closeManageRestrictions = () => {
        this.setState({manageRestrictionsSubpageOpen: false});        
    }
    
    
    //For Testing only
    _genTestStats = (type) => {
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/stats/checkins/generate",
                                {method: "POST"}, 
                                null, this._onError); 
    }
    
    
    //Split a JsonTimeString with Zone to a friendly time string and zone, formatted for html
    static renderTime = (prefix, timestring) => {
        
        if (!timestring) {
            return <Typography variant="button" align='center' style={{display: 'flex', justifyContent: 'center', fontSize: 16, color: 'red', marginLeft: 5, marginRight: 5, marginTop: 5}}>Disabled</Typography>
        }
        
        const timesplit = timestring.split(/[\s]+/);  //split by whitespaces)
        
        const time = DateUtils.toFriendlyTimeString(timesplit[0].replace("_", " "));

        return (
            <div>
                <Typography variant="button" align='center' style={{display: 'flex', justifyContent: 'center', fontSize: 16, color: 'green', marginLeft: 5, marginRight: 5, marginTop: 5}}>{prefix + time}</Typography>
                <Typography variant="button" align='center' style={{display: 'flex', justifyContent: 'center', fontSize: 10, color: 'green', marginLeft: 5, marginRight: 5}}>{"Timezone: " + timesplit[1]}</Typography>
            </div>);
    }
  
  
   render() {
        
        const isNotObserver = !PP.user.isObserver();
        const canTest = PP.user.hasPermissionTo(Permissions.DEVELOP);
        
        const autoCheckOutTimeDisplay = OpsTab.renderTime("Auto Check out at ", this.state.autoCheckOut);

        const autoResetGuestPassDisplay = OpsTab.renderTime("Reset to " + this.state.autoResetGuestPassStoredValue + " pass" + (this.state.autoResetGuestPassStoredValue != 1 ? "es" : "") + " at ", this.state.autoResetGuestPass)
        
               
        if (this.state.manageRestrictionsSubpageOpen) {
            return <RestrictionsSubpage backToOperationsTab={this._closeManageRestrictions} locations={this.state.locations}/>;
        }       
               
               
        return (
            <div>
    
                <TextEntryPopover isOpen={this.state.renameLocationPromptOpen} showSkip={false} multiline={false} title="New Location Name" 
                                 okCallback={this._renameLocationOkCallback} cancelCallback={() => this.setState({renameLocationPromptOpen: false})}/>
    
                <TextEntryPopover isOpen={this.state.renameTypePromptOpen} showSkip={false} multiline={false} title="New Membership Type" 
                                 okCallback={this._renameMembershipTypeOkCallback} cancelCallback={() => this.setState({renameTypePromptOpen: false})}/>
    
    
                {this.getConfirmAlertComponent()}
                {this.getProgressAlertComponent()}
                {this._getDeleteLocationPrompt()}
    
                <ActionSelectPopover isOpen={this.state.showActionsPopup} initialValue={0} 
                                     label={(this._doSetActions ? "Select Actions to Set on All Members" : "Select Actions to Clear on All Members")}
                                     deselect={!this._doSetActions}
                                     okCallback={this._actionsSelectedCallback} 
                                     cancelCallback={() => this.setState({showActionsPopup: false})} />
                    
                {this.state.isBusy ? this.getBusyComponent('center', {marginBottom: 20}) : 
                            (<div style={{paddingBottom: 60}}/>)}
             
                    
                <Grid container direction="row" spacing={2}>


                    {isNotObserver ? 
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Paper style={this.styles.paper}>
                            <Typography variant="body2" style={this.styles.paperLabel}>Location</Typography>   

                            <Grid container direction='column' alignContent='center' spacing={2} style={{marginTop: 20, marginBottom: 20}}>
                                
                                <Grid item style={{marginLeft: 20, marginRight: 20}}>
                                    <Autocomplete
                                        size='small'
                                        value={this.state.selectedLocation}
                                        onChange={(event, newValue) => {this.setState({selectedLocation: newValue});}}
                                        inputValue={this.state.selectedLocationValue}
                                        onInputChange={(event, newValue) => { this.setState({selectedLocationValue: newValue}); }}                       
                                        options={this.state.locations}
                                        style={{ width: 250}}
                                        blurOnSelect
                                        openText="Select Location"
                                        disableClearable
                                        renderInput={(params) => <TextField {...params} label="Location" variant="outlined" InputLabelProps={{ shrink: true }} />}
                                      />
                                </Grid>
                                
                                <Grid item style={{marginLeft: 20, marginRight: 20}}>
                                    <Tooltip title="Rename selected location to a new name">
                                        <Button onClick={this._renameLocation} disabled={this.state.selectedLocation == null} fullWidth variant="outlined" component="label">
                                            Rename
                                        </Button>
                                    </Tooltip>
                                </Grid>
                                <Grid item style={{marginLeft: 20, marginRight: 20}}>
                                    <Tooltip title="Delete selected location and associated statistics (and optionally logs)">
                                        <Button onClick={this._deleteLocation}  color="secondary" disabled={this.state.selectedLocation == null} fullWidth variant="outlined" component="label">
                                            Delete
                                        </Button>
                                    </Tooltip>
                                </Grid>
                                
                                <Grid item style={{marginLeft: 20, marginRight: 20, marginTop: 40}}>
                                    <Tooltip title="Manage restrictions by location">
                                        <Button onClick={this._manageRestrictions} fullWidth variant="outlined" component="label">
                                            Manage Restrictions...
                                        </Button>
                                    </Tooltip>
                                </Grid>
                               
                            </Grid>
                        </Paper>
                    </Grid> : null}


                    {isNotObserver ? 
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Paper style={this.styles.paper}>
                            <Typography variant="body2" style={this.styles.paperLabel}>Check Out Members</Typography>   

                            <Grid container direction='column' alignContent='center' spacing={2} style={{marginTop: 20, marginBottom: 20}}>
                                
                                <Grid item style={{marginLeft: 20, marginRight: 20}}>
                                    <Tooltip title="Check Out All Members">
                                        <Button onClick={() => this._checkOutAll(false)} fullWidth variant="outlined" component="label" startIcon={<ExitToAppIcon />}>
                                            Check Out All
                                        </Button>
                                    </Tooltip>
                                </Grid>
                                
                                <Grid item style={{marginLeft: 20, marginRight: 20}}>
                                    <Tooltip title="Check Out all Members and Zero Occupancy at all Locations">
                                        <Button onClick={() => this._checkOutAll(true)} fullWidth variant="outlined" component="label" startIcon={<ExitToAppIcon />}>
                                            Check Out / Zero
                                        </Button>
                                    </Tooltip>
                                </Grid>
                                
                            </Grid>
                                
                            <Typography variant="body2" style={this.styles.paperLabel}>Automatic Check Out / Zero Occupancy</Typography>   
                            {autoCheckOutTimeDisplay}
                            
                            <Grid container direction='column' alignContent='center' spacing={2} style={{marginTop: 10, marginBottom: 20}}>
                              
                                {isNotObserver ?
                                    <Fragment>
                                        <Grid item style={{marginLeft: 20, marginRight: 20}}>
                                            <ManageTimeField hasNever={false} json="autoCheckOut" label="Set New Time" ref={this._autoCheckOutRef} 
                                                             initialValue="00:00" onParseError={this._autoParseError} autoAccept={true} clockColor={ThemeColors.clockColor} />         
                                        </Grid>
                                    
                                        <Grid item style={{marginLeft: 20, marginRight: 20}}>
                                            <div style={{display: 'flex', gap: 20, justifyContent: 'center'}}>

                                                <Tooltip title="Set a new automatic check out / zero occupancy time">
                                                    <Button onClick={() => this._setAutoCheckOut(true)} fullWidth variant="outlined" component="label">
                                                            Set
                                                        </Button>
                                                    </Tooltip>
                                                <Tooltip title="Disable automatic check out / zero occupancy">
                                                    <Button onClick={() => this._setAutoCheckOut(false)} fullWidth variant="outlined" component="label">
                                                        Disable
                                                    </Button>
                                                </Tooltip>
                                            </div>
                                        </Grid>
                                    
                                    </Fragment>
                                    
                                    : null
                                }

                                                               
                            </Grid> 
                        </Paper>
                    </Grid> : null}
                    
                    
                    {isNotObserver ?
                     <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Paper style={this.styles.paper}>
                            <Typography variant="body2" style={this.styles.paperLabel}>Send all Memberships ePasses</Typography>   
                      
                                <Grid container direction='column' alignContent='center' spacing={2} style={{marginTop: 5, marginBottom: 20}}>
                                
                                    <Grid item>
                                        <div style={{justifyContent: 'left', paddingLeft: 20, paddingRight: 10}}>

                                            <Typography variant="subtitle1" style={{color: 'gray'}}>Ignore:</Typography>
                                            <div>
                                                <Tooltip title="When checked, suspended Memberships will not receive ePasses">

                                                    <FormControl component="fieldset" style={this.styles.form} >  
                                                        <FormControlLabel control={<Checkbox checked={this.state.sendEPassesSuspendedMembershipsIgnored} 
                                                                                  onChange={() => this.setState((prevState) => ({sendEPassesSuspendedMembershipsIgnored: !prevState.sendEPassesSuspendedMembershipsIgnored}))} 
                                                                                  color="primary"/>} label="Suspended Memberships"/>
                                                    </FormControl>
                                                </Tooltip>
                                            </div>

                                            <div>
                                                <Tooltip title="When checked, expired Memberships will not receive ePasses">

                                                    <FormControl component="fieldset" style={this.styles.form} >  
                                                        <FormControlLabel control={<Checkbox checked={this.state.sendEPassesExpiredMembershipsIgnored} 
                                                                                  onChange={() => this.setState((prevState) => ({sendEPassesExpiredMembershipsIgnored: !prevState.sendEPassesExpiredMembershipsIgnored}))} 
                                                                                  color="primary"/>} label="Expired Memberships"/>
                                                    </FormControl>
                                                </Tooltip>                                   
                                            </div>

                                        </div>
                                    </Grid>    
                                    
                                    <Grid item>
                                        <div style={{display: 'flex', justifyContent: 'center'}}>
                                            <Button disabled={!PP.printCapabilities.includes("EPASS")} variant="outlined" onClick={this._sendAllEPasses} startIcon={<PhoneIphoneIcon style={{color: ThemeColors.epassColor}}/>}>Send Epasses</Button>         
                                        </div>
                                    </Grid>

                                 
                                </Grid>
                                
                        </Paper>
                    </Grid> : null}  


                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Paper style={this.styles.paper}>
                        
                            <div style={{display: 'flex'}}>
                                <Typography variant="body2" style={this.styles.paperLabel}>Member Data</Typography> 
                                                        
                                <ImportExportTooltip title={this._importExportHelpTooltip}>
                                     <Typography variant="body2" style={{marginLeft: 8, fontSize: 12, fontWeight: 'bold', color: 'blue'}}>ⓘ</Typography>
                                </ImportExportTooltip>
                            </div>
                            <Grid container direction='column' alignContent='center' spacing={2} style={{marginTop: 20, marginBottom: 20}}>
                                
                                {isNotObserver ?
                                <Grid item style={{marginLeft: 20, marginRight: 20}}>
                                    <Tooltip title="Upload and Import new Members from a .csv file">
                                        <Button fullWidth variant="outlined" component="label" startIcon={<PublishIcon />}>
                                            Import Members
                                            <input accept="text/csv" style={{display: 'none'}} type="file" onChange={this._csvFileSelected}/>
                                        </Button>
                                    </Tooltip>
                                </Grid> : null}
                                                               
                                <Grid item style={{marginLeft: 20, marginRight: 20}}>
                                    <Tooltip title="Export and Download Member data to a .csv file">
                                        <Button fullWidth onClick={this._download} variant="outlined" component="label" startIcon={<GetAppIcon />}>
                                            Export Members
                                        </Button>
                                    </Tooltip>
                                </Grid>
                                
                                {isNotObserver ?
                                <Grid item style={{marginLeft: 20, marginRight: 20, marginTop: 40}}>
                                    <Tooltip title="Validate an importable .csv file (no database changes performed)">
                                        <Button fullWidth variant="outlined" component="label" startIcon={<PlaylistAddCheckIcon />}>
                                            Validate Import File
                                            <input accept="text/csv" style={{display: 'none'}} type="file" onChange={this._csvFileSelectedForValidate}/>
                                        </Button>
                                    </Tooltip>
                                </Grid> : null}
                                

                            </Grid>
                        </Paper>
                    </Grid>
                    
                    {isNotObserver ?
                     <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Paper style={this.styles.paper}>
                            <Typography variant="body2" style={this.styles.paperLabel}>Update All Virtual Guest Passes</Typography>   
                      
                            <Grid container direction='column' alignContent='center' spacing={2} style={{marginTop: 5, marginBottom: 20}}>

                                <Grid item>

                                    <ManageNumericField autoCommit={true} fullWidth={false} hasInfinity={false} hasNegative={true} justify='left' 
                                                        commitTimeout={1} style={{marginTop: 15}} 
                                                        initialValue={this._guestPassesText} json="guestPassesField"
                                                        onFieldChange={this._guestPassesChanged} changedBackgroundColor='white'/>


                                </Grid>

                                <Grid item>
                                    <div style={{justifyContent: 'left', paddingLeft: 20, paddingRight: 10}}>
                                        <Typography variant="subtitle1" style={{color: 'gray'}}>Ignore:</Typography>

                                        <Tooltip title="When checked, updates will not apply to suspended Memberships">

                                            <FormControl component="fieldset" style={this.styles.form} >  
                                                <FormControlLabel control={<Checkbox checked={this.state.updateGuestPassesSuspendedIgnored} 
                                                                          onChange={() => this.setState((prevState) => ({updateGuestPassesSuspendedIgnored: !prevState.updateGuestPassesSuspendedIgnored}))} 
                                                                          color="primary"/>} label="Suspended Memberships"/>
                                            </FormControl>
                                        </Tooltip>
                                    </div>
                                </Grid>


                                <Grid item>
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                        <Button style={{marginRight: 20}} disabled={this._guestPassesText < 0} variant="outlined" onClick={() => this._updateAllGuestPasses(false)}>Set</Button>         
                                        <Button variant="outlined" disabled={parseInt(this._guestPassesText) === 0} onClick={() => this._updateAllGuestPasses(true)}>Increment</Button>    
                                    </div>
                                </Grid>


                            </Grid>
                            <Typography variant="body2" style={this.styles.paperLabel}>Automatic Guest Pass Reset</Typography>   
                            {autoResetGuestPassDisplay}

                            <Grid container direction='column' alignContent='center' spacing={2} style={{marginTop: 10, marginBottom: 20}}>
                              
                                {isNotObserver ?
                                    <Fragment>
                                        <Grid item style={{marginLeft: 20, marginRight: 20}}>
                                            <ManageTimeField hasNever={false} json="autoResetGuest" label="Set New Time" ref={this._autoResetGuestPassRef} 
                                                             initialValue="00:00" onParseError={this._autoParseError} autoAccept={true} clockColor={ThemeColors.clockColor} />         
                                        </Grid>
                                    
                                        <TextField
                                            label="Reset Value"
                                            type="number"
                                            style={{marginLeft: 30, marginRight: 'auto', maxWidth: 100}}
                                            value={this.state.autoResetGuestPassValue}
                                            onChange={this._autoResetGuestPassValueChanged}
                                            InputLabelProps={{ shrink: true }} 
                                         />
                                    
                                        <Grid item style={{marginLeft: 20, marginRight: 20, marginTop: 10}}>
                                            <div style={{display: 'flex', gap: 20, justifyContent: 'center'}}>

                                                <Tooltip title="Set a new time to reset guest passes">
                                                    <Button onClick={() => this._setAutoGuestReset(true)} fullWidth variant="outlined" component="label">
                                                            Set
                                                        </Button>
                                                    </Tooltip>
                                                <Tooltip title="Disable automatic guest pass reset">
                                                    <Button onClick={() => this._setAutoGuestReset(false)} fullWidth variant="outlined" component="label">
                                                        Disable
                                                    </Button>
                                                </Tooltip>
                                            </div>
                                        </Grid>

                                    </Fragment>
                                    
                                    : null
                                }

                                                               
                            </Grid> 
                                
                        </Paper>
                    </Grid> : null}  
                    
                    
                    {isNotObserver ?
                     <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Paper style={this.styles.paper}>
                            <Typography variant="body2" style={this.styles.paperLabel}>Update All Member Entry Passes</Typography>   
                      
                                <Grid container direction='column' alignContent='center' spacing={2} style={{marginTop: 5, marginBottom: 20}}>

                                    <Grid item>

                                        <ManageNumericField autoCommit={true} fullWidth={false} hasInfinity={true} hasNegative={false} justify='left' 
                                                            commitTimeout={1} style={{marginTop: 15}} 
                                                            initialValue={this._entryPassesText} json="entryPassesField"
                                                            onFieldChange={this._entryPassesChanged} changedBackgroundColor='white'/>
                                                            
                                                            
                                    </Grid>
                                    
                                    <Grid item>
                                        <div style={{justifyContent: 'left', paddingLeft: 20, paddingRight: 10}}>

                                            <Typography variant="subtitle1" style={{color: 'gray'}}>Ignore:</Typography>
                                            <div>
                                                <Tooltip title="When checked, updates will not apply to suspended Memberships">

                                                    <FormControl component="fieldset" style={this.styles.form} >  
                                                        <FormControlLabel control={<Checkbox checked={this.state.updateEntryPassesSuspendedMembershipsIgnored} 
                                                                                  onChange={() => this.setState((prevState) => ({updateEntryPassesSuspendedMembershipsIgnored: !prevState.updateEntryPassesSuspendedMembershipsIgnored}))} 
                                                                                  color="primary"/>} label="Suspended Memberships"/>
                                                    </FormControl>
                                                </Tooltip>
                                            </div>

                                            <div>
                                                <Tooltip title="When checked, updates will not apply to suspended Members">

                                                    <FormControl component="fieldset" style={this.styles.form} >  
                                                        <FormControlLabel control={<Checkbox checked={this.state.updateEntryPassesSuspendedMembersIgnored} 
                                                                                  onChange={() => this.setState((prevState) => ({updateEntryPassesSuspendedMembersIgnored: !prevState.updateEntryPassesSuspendedMembersIgnored}))} 
                                                                                  color="primary"/>} label="Suspended Members"/>
                                                    </FormControl>
                                                </Tooltip>                                   
                                            </div>
                                            
                                            <div>
                                                <Tooltip title="When checked, updates will not apply to Members with unlimited entry passes">

                                                    <FormControl component="fieldset" style={this.styles.form} >  
                                                        <FormControlLabel control={<Checkbox checked={this.state.updateEntryPassesUnlimitedIgnored} 
                                                                                  onChange={() => this.setState((prevState) => ({updateEntryPassesUnlimitedIgnored: !prevState.updateEntryPassesUnlimitedIgnored}))} 
                                                                                  color="primary"/>} label="Unlimited Pass Members"/>
                                                    </FormControl>
                                                </Tooltip>
                                            </div>

                                        </div>
                                    </Grid>    
                                    
                                    <Grid item>
                                        <div style={{display: 'flex', justifyContent: 'center'}}>
                                            <Button style={{marginRight: 20}} variant="outlined" onClick={() => this._updateAllEntryPasses(false)}>Set</Button>         
                                            <Button variant="outlined" disabled={this._entryPassesText < 1} onClick={() => this._updateAllEntryPasses(true)}>Increment</Button>    
                                        </div>
                                    </Grid>
                                    
                                 
                                </Grid>
                                
                        </Paper>
                    </Grid> : null}  
                    
                    {isNotObserver ?
                     <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Paper style={this.styles.paper}>
                            <Typography variant="body2" style={this.styles.paperLabel}>Update All Membership Expiration Dates</Typography>   
                      
                                <Grid container direction='column' alignContent='center' spacing={2} style={{marginTop: 5, marginBottom: 20}}>

                                    <Grid item>
                                        <div style={{marginTop: 15, marginLeft: 15, marginRight: 15}}>
                                            <ManageDateField hasNever={true} json="expDateField" maxYear={DateUtils.calendarMaxYear()}
                                                            onFieldChange={this._expDateChanged} 
                                                            onParseError={this._expDateParseError}
                                                            changedBackgroundColor='white'
                                                            dateFormat={PP.getDateFormat()}
                                                            calendarColor={ThemeColors.calendarColor}/>
                                                            
                                        </div>      
                                    </Grid>
                                    
                                    <Grid item>
                                        <div style={{justifyContent: 'left', paddingLeft: 20, paddingRight: 10}}>

                                            <Typography variant="subtitle1" style={{color: 'gray'}}>Ignore Memberships:</Typography>
                                            <Tooltip title="When checked, updates will not apply to Memberships that never expire">

                                                <FormControl component="fieldset" style={this.styles.form} >  
                                                    <FormControlLabel control={<Checkbox checked={this.state.updateExpDateNever} 
                                                                              onChange={() => this.setState((prevState) => ({updateExpDateNever: !prevState.updateExpDateNever}))} 
                                                                              color="primary"/>} label="That Never Expire"/>
                                                </FormControl>
                                            </Tooltip>
     
                                        </div>
                                    </Grid>    
                                    
                                    <Grid item>
                                        <div style={{display: 'flex', justifyContent: 'center'}}>
                                            <Button style={{marginRight: 20}} disabled={this._expDateVal === undefined} variant="outlined" onClick={this._updateAllExpDates}>Apply</Button>         
                                        </div>
                                    </Grid>
                                    
                                 
                                </Grid>
                                
                        </Paper>
                    </Grid> : null}  
                    
                    {isNotObserver ? 
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Paper style={this.styles.paper}>
                            <Typography variant="body2" style={this.styles.paperLabel}>Membership Type Operations</Typography>   

                            <Grid container direction='column' alignContent='center' spacing={2} style={{marginTop: 20, marginBottom: 20}}>
                                
                                <Grid item style={{marginLeft: 20, marginRight: 20}}>
                                    <Autocomplete
                                        size='small'
                                        value={this.state.selectedMembershipType}
                                        onChange={(event, newValue) => {this.setState({selectedMembershipType: newValue});}}
                                        inputValue={this.state.selectedMembershipValue}
                                        onInputChange={(event, newValue) => { this.setState({selectedMembershipValue: newValue}); }}                       
                                        options={this.state.membershipTypes}
                                        style={{ width: 250}}
                                        blurOnSelect
                                        openText="Select Existing Type"
                                        disableClearable
                                        renderInput={(params) => <TextField {...params} label="Type" variant="outlined" InputLabelProps={{ shrink: true }} />}
                                      />
                                </Grid>
                                
                                <Grid item style={{marginLeft: 20, marginRight: 20}}>
                                    <Tooltip title="Rename selected type to a new name">
                                        <Button onClick={this._renameMembershipType} disabled={this.state.selectedMembershipType == null} fullWidth variant="outlined" component="label">
                                            Rename
                                        </Button>
                                    </Tooltip>
                                </Grid>

                                <Grid item style={{marginLeft: 20, marginRight: 20}}>
                                    <Tooltip title="Delete All Memberships and their Members with this Membership type">
                                        <Button fullWidth color="secondary" variant="outlined" onClick={this._deleteAllMembershipsOfType} startIcon={<DeleteIcon/>}>
                                            Delete Memberships
                                        </Button>
                                    </Tooltip>
                                </Grid>
                               
                            </Grid>
                        </Paper>
                    </Grid> : null}
                    
                    
                    {isNotObserver ?
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Paper style={this.styles.paper}>
                            <Typography variant="body2" style={this.styles.paperLabel}>Suspend Memberships</Typography>   

                            <Grid container direction='column' alignContent='center' spacing={2} style={{marginTop: 20, marginBottom: 20}}>
                                
                                <Grid item style={{marginLeft: 20, marginRight: 20}}>
                                    <Tooltip title="Suspend All Memberships">
                                        <Button fullWidth variant="outlined" onClick={() => this._suspendAll(true)} startIcon={<NotInterestedIcon style={{color: 'red'}}/>}>
                                            Suspend All
                                        </Button>
                                    </Tooltip>
                                </Grid>
                                
                                <Grid item style={{marginLeft: 20, marginRight: 20}}>
                                    <Tooltip title="All suspended Memberships will be reinstated">
                                        <Button fullWidth variant="outlined" onClick={() => this._suspendAll(false)} startIcon={<RadioButtonUncheckedIcon style={{color: 'green'}}/>}>
                                            Reinstate All
                                        </Button>
                                    </Tooltip>
                                </Grid>
                                
                            </Grid>
                        </Paper>
                    </Grid> : null}
                    
                    
                    {isNotObserver ?
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Paper style={this.styles.paper}>
                            <Typography variant="body2" style={this.styles.paperLabel}>Change Member Actions</Typography>   

                            <Grid container direction='column' alignContent='center' spacing={2} style={{marginTop: 20, marginBottom: 20}}>
                                
                                <Grid item style={{marginLeft: 20, marginRight: 20}}>
                                    <Tooltip title="Set one or more actions on all Members">
                                        <Button fullWidth variant="outlined" onClick={() => this._changeActions(true)} startIcon={<CheckBoxIcon style={{color: 'blue'}}/>}>
                                            Set Actions
                                        </Button>
                                    </Tooltip>
                                </Grid>
                                
                                <Grid item style={{marginLeft: 20, marginRight: 20}}>
                                    <Tooltip title="Clear one or more actions on all Members">
                                        <Button fullWidth variant="outlined" onClick={() => this._changeActions(false)} startIcon={<IndeterminateCheckBoxIcon style={{color: 'gray'}}/>}>
                                            Clear Actions
                                        </Button>
                                    </Tooltip>
                                </Grid>
                                
                            </Grid>
                        </Paper>
                    </Grid> : null}
                    

                    {isNotObserver ?
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Paper style={this.styles.paper}>
                            <Typography variant="body2" style={this.styles.paperLabel}>Update All Barcodes</Typography>   

                            <FormControl component="fieldset" style={this.styles.form} >
                                    <RadioGroup aria-label="barcode suggestion" name="barcode suggestion" value={this.state.selectedBarcodeSuggestion} onChange={this._selectBarcodeSuggestion}>

                                        <FormControlLabel key={1} value={BarcodeSuggestionType.NEXT_NUMERIC} control={<Radio color="primary" />} label="Incrementing Number" />
                                        <FormControlLabel key={2} value={BarcodeSuggestionType.RANDOM_NUMERIC} control={<Radio color="primary" />} label={BarcodeSuggestionType.RANDOM_NUMERIC} />
                                        <FormControlLabel key={3} value={BarcodeSuggestionType.RANDOM_ALPHANUMERIC} control={<Radio color="primary" />} label={BarcodeSuggestionType.RANDOM_ALPHANUMERIC} />

                                    </RadioGroup>
                                </FormControl>  
                                <TextField
                                    label="Minimum Digits"
                                    type="number"
                                    style={{margin: 20}}
                                    value={this.state.minBarcodeSuggestDigits}
                                    onChange={this._barcodeSuggestionDigitsChanged}
                                    InputLabelProps={{ shrink: true }} 
                                 />
                                 <TextField
                                    label="Starting Value"
                                    type="number"
                                    disabled={this.state.selectedBarcodeSuggestion !== BarcodeSuggestionType.NEXT_NUMERIC}
                                    style={{margin: 20}}
                                    value={this.state.barcodeStartingValue}
                                    onChange={this._barcodeStartingValueChanged}
                                    InputLabelProps={{ shrink: true }} 
                                 />
                                <div style={{display: 'flex', justifyContent: 'center', marginBottom: 20}}>
                                    <Button style={{marginRight: 20}} variant="outlined" onClick={() => this._updateAllBarcodes(true)}>Update All</Button>         
                                    <Button variant="outlined" onClick={() => this._updateAllBarcodes(false)}>Update Empty</Button>    
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', marginBottom: 25}}>
                                    <Button variant="outlined" color="secondary" onClick={this._clearAllBarcodes}>Clear All</Button>    
                                </div>
                        </Paper>
                    </Grid> : null}
                    
                    
                    {isNotObserver ?
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Paper style={this.styles.paper}>
                            <Typography variant="body2" style={this.styles.paperLabel}>Bulk Delete</Typography>   

                            <Grid container direction='column' alignContent='center' spacing={2} style={{marginTop: 20, marginBottom: 20}}>
                                
                                <Grid item style={{marginLeft: 20, marginRight: 20}}>
                                    <Tooltip title="Delete All Memberships and their Members">
                                        <Button fullWidth color="secondary" variant="outlined" onClick={() => this._deleteAll(null, true)} startIcon={<DeleteIcon/>}>
                                            Delete All Memberships
                                        </Button>
                                    </Tooltip>
                                </Grid>
                              
                                <Grid item style={{marginLeft: 20, marginRight: 20}}>
                                    <Tooltip title="Delete Inactive Memberships (those with zero Members)">
                                        <Button fullWidth color="secondary" variant="outlined" onClick={() => this._deleteAll("inactive", true)} startIcon={<DeleteIcon/>}>
                                            Delete Inactive Memberships
                                        </Button>
                                    </Tooltip>
                                </Grid>
                              
                                <Grid item style={{marginLeft: 20, marginRight: 20}}>
                                    <Tooltip title="Delete Expired Memberships and their Members">
                                        <Button fullWidth color="secondary" variant="outlined" onClick={() => this._deleteAll("expired", true)} startIcon={<DeleteIcon/>}>
                                            Delete Expired Memberships
                                        </Button>
                                    </Tooltip>
                                </Grid>
                                
                                <Grid item style={{marginLeft: 20, marginRight: 20, marginTop: 20}}>
                                    <Tooltip title="Delete Limited Members">
                                        <Button fullWidth color="secondary" variant="outlined" onClick={() => this._deleteAll("limited", false)} startIcon={<DeleteIcon/>}>
                                            Delete Limited Members
                                        </Button>
                                    </Tooltip>
                                </Grid>
                            
                            </Grid>
                        </Paper>
                    </Grid> : null}
                    
                    {canTest ? 
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                           <Paper style={this.styles.paper}>
                               <Typography variant="body2" style={this.styles.paperLabel}>Testing (Superadmin only)</Typography>   

                               <Grid container direction='column' alignContent='center' spacing={2} style={{marginTop: 20, marginBottom: 20}}>

                                   <Grid item style={{marginLeft: 20, marginRight: 20}}>
                                       <Tooltip title="Generate Test Check-in Statistics">
                                           <Button fullWidth color="secondary" variant="outlined" onClick={() => this._genTestStats("checkin")} >
                                               Generate Test Stats
                                           </Button>
                                       </Tooltip>
                                   </Grid>               

                               </Grid>
                           </Paper>
                    </Grid> : null}
                    
                </Grid>
   
            </div>
        );

    }

  
};


export default withCookies(OpsTab);