import React from 'react';
import { withCookies } from 'react-cookie';
import Chart from "react-apexcharts";

import Autocomplete from '@material-ui/lab/Autocomplete';
import BarChartIcon from '@material-ui/icons/BarChart';
import { TextField, Paper, Tooltip, Typography, Button, Grid } from '@material-ui/core'

import { ThemeColors } from '../Theme'
import { PP } from '../models/PP'

import { MarketAnalysis } from '../models/MarketAnalysis'

import { RestComponent } from 'react-frontend-utils' 
import { DateUtils, Currency, ManageDateField } from 'react-frontend-utils'



/**
 * The Analysis tab retrieves daily-stats and product sales stats from the backend and displays them in various graphs
 * 
 */
export class AnalysisTab extends RestComponent {
  
    //options for the time range pulldown
    _timeOptions = [
        {label: "Last 7 days", startTime: DateUtils.startOfToday().valueOf() - (7 * DateUtils.MS_PER_DAY)},
        {label: "Last 30 days", startTime: DateUtils.startOfToday().valueOf() - (30 * DateUtils.MS_PER_DAY)},
        {label: "Last 60 days", startTime: DateUtils.startOfToday().valueOf() - (60 * DateUtils.MS_PER_DAY)}, 
        {label: "Last 90 days", startTime: DateUtils.startOfToday().valueOf() - (90 * DateUtils.MS_PER_DAY)},
        {label: "Month to Date", startTime: DateUtils.startOfMonth().valueOf()}, 
        {label: "Year to Date", startTime: DateUtils.startOfYear().valueOf()},
        {label: "Any", startTime: null}
    ];
    
    styles = {
        paper: {
            width: '100%',
            height: '100%'
        },
        paperLabel: {
            marginLeft: 15,
            marginRight: 15,
            marginBottom: 5,
            color: 'gray',
            fontSize: '9pt',
            flexGrow: 1
        }
    }
    

    _maxYear = (new Date()).getFullYear();  //this year
    _startDateFieldRef = React.createRef();
    _endDateFieldRef = React.createRef();
    
    _currencySymbol = Currency.symbolForISO(PP.currency);

    
    //The format to apply to time values
    _dateFormat = () => {
        
        switch (PP.getDateFormat()) {
            case DateUtils.DateFormatType.ISO8601:
                return "yyyy MMM-dd";

            case DateUtils.DateFormatType.US:
                return "MMM-dd yyyy"; 
            
            default:
                return "unhandled";
        }
    }
    
    
    _yearMonthFormat = () => {
        
        switch (PP.getDateFormat()) {
            case DateUtils.DateFormatType.ISO8601:
                return "yyyy MMM";

            case DateUtils.DateFormatType.US:
                return 'MMM yyyy'; 
                
            default:
                return "unhandled";
        }
    }
    
    _datetimeFormatter = () => {
        return {
            year: 'yyyy',
            month: this._yearMonthFormat(),
            day: "MMM-dd"
        };
    }
 
    
    _standardChartOptions = {
        stacked: false,
        zoom: {
            enabled: false
        },
        toolbar: {
            show: false
        },
        animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 600,
            animateGradually: {
                enabled: false
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
        }
    };
   
    _revenueChartOptions = {
      
        chart: this._standardChartOptions,
        colors: [ThemeColors.membershipsGreen, ThemeColors.membersBlue], 
        stroke: {
            curve: 'stepline',
            width: 2
        },
        markers: {
            size: 2,
            strokeWidth: 0
        },
        dataLabels: {
            enabled: false         
        },
        xaxis: {
            type: 'datetime',
            labels: {
                datetimeUTC: false,
                datetimeFormatter: this._datetimeFormatter(true)
            }
        },
        yaxis: {
            labels: {
                formatter: (value) => { return this._currencySymbol + Currency.round(value, 0); }
            }
        },
        tooltip: {
            x: {
                format: this._dateFormat()
            },
            y: {
                formatter: (value) => { return this._currencySymbol + Currency.round(value); } 
            }
        }
    };
    
    
    _redeemChartOptions = {
      
        chart: this._standardChartOptions,
        colors: [ThemeColors.redeemIndigo], 
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "90%"
            }
        },
        dataLabels: {
            enabled: false         
        },
        xaxis: {
            type: 'datetime',
            labels: {
                datetimeUTC: false,
                datetimeFormatter: this._datetimeFormatter(true)
            }
        },
        yaxis: {
            labels: {
                formatter: (value) => { return value.toFixed(0); }  //Format as integer
            }
        },
        tooltip: {
            x: {
                format: this._dateFormat()
            },
            y: {
                formatter: (value) => { return value.toFixed(0); }  //Format as integer
            }
        }
    };
    
    
    _productPieChartOptions = {
      
        chart: this._standardChartOptions,
        labels: [],
        colors: ThemeColors.pieChartColors,

        dataLabels: {
            formatter: function (val, opts) {
                return opts.w.config.series[opts.seriesIndex];  //use the count value itself, not the percent
            }
        }      
    }
    
    _productCategoryPieChartOptions = {
      
        chart: this._standardChartOptions,
        labels: [],
        colors: ThemeColors.pieChartColors

    }
   

    constructor(props) {
        super(props);
                      
        this.state.selectedTimeRange = "Any";
        this.state.selectedTimeRangeValue = "Any";
       
        this.state.startDateSearch = null;
        this.state.endDateSearch = null;
      
        this.state.hasSearched = false;  //has executed search (display button) at least once
                
        this.state.revenueChartData = null;
        this.state.redeemChartData = null;
        
        this.state.productPieData = null;        
        this.state.productCategoryPieData = null;
        this.state.productSalesTotal = 0;
    }
    
    
  
    
    _validateSearch = () => {
        
        const start = this.state.startDateSearch ? new Date(this.state.startDateSearch) : null;
        const end = this.state.endDateSearch ? new Date(this.state.endDateSearch) : null;
        
        if (start && end && start > end) {
            this.showConfirmAlert("Error", "From Date is after To Date", 'red');
            return false;
        }
        return true;       
    }
 
  
    _getSearchString = () => {
        let search = "";
        
        if (this.state.startDateSearch)
            search += "fromDate=" + this.state.startDateSearch + "&";
        if (this.state.endDateSearch)
            search += "toDate=" + this.state.endDateSearch + "T23:59:59&";  //add time to the end of the day
        
        if (search)
            search = "?" + search.substring(0, search.length-1);  //remove trailing &
        
        return search;
    }
  
    
    _search = () => {
        if (!this._validateSearch())
            return;

        const search = this._getSearchString();
        
        this.incrementBusy();       
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/marketplace/analysis" + search, {},
                             this._searchResponse, this._fetchErrorCallback); 
   
    }
    
    
    _searchResponse = (response) => {
        if (response) {        
            const analysis = new MarketAnalysis(response);
                 
            const depositSeries = []; 
            const purchaseSeries = []; 
            const redeemSeries = [];
                    
            let depositRunningSum = 0.0;
            let purchaseRunningSum = 0.0;
            for (let stat of analysis.dailyStats) {
                
                depositRunningSum += (stat.deposits + stat.adjustments);
                purchaseRunningSum += stat.purchases;
                
                depositSeries.push([stat.date, depositRunningSum]);
                purchaseSeries.push([stat.date, purchaseRunningSum]); 
                
                redeemSeries.push([stat.date, stat.redemptions]);
            }    
                
                
            const revenueChartData = [
                {
                  name: "Deposits, Refunds, Adjustments",
                  data: depositSeries
                },
                {
                  name: "Purchases",
                  data: purchaseSeries
                }
            ];
            
            const redeemChartData = [
                {
                    name: "Redemptions",
                    data: redeemSeries
                }
            ];
            
            //Create the product pie labels
            const productLabels = analysis.productsSold.map(product => product.name);  
            
            //Note in order to re-render properly the outermost items must be spread, apex charts needs to see the object changed
            this._productPieChartOptions = {...this._productPieChartOptions, labels: productLabels};
        
            //Specify the product pie data series
            const productPieData = analysis.productsSold.map(product => product.quantity);
            
            
            //Aggregate all the categories into a set to remove duplicates, any with null assign to a Deleted product category
            let productSalesTotal = 0; 
            const categories = new Map();
            analysis.productsSold.forEach(product => {
                
                productSalesTotal += product.quantity;
                
                let catName;
                if (product.category == null)
                    catName = "Deleted Products";
                else if (product.category === "")
                    catName = "Unspecified";
                else
                    catName = product.category;
                                    
                let count = 0;
                if (categories.has(catName)) {              
                    count = categories.get(catName);  //get current count for this category, if we have one (otherwise, 0)
                }
                
                count += product.quantity;  //add this product's quantity                  
                categories.set(catName, count);  //set the new quantity
                
            });
            
            const categoryLabels = Array.from(categories.keys());
            
             //Note in order to re-render properly the outermost items must be spread, apex charts needs to see the object changed
            this._productCategoryPieChartOptions = {...this._productCategoryPieChartOptions, labels: categoryLabels};
        
            
            const productCategoryPieData =  Array.from(categories.values());

            this.setState({revenueChartData: revenueChartData, redeemChartData: redeemChartData, 
                          productPieData: productPieData, productCategoryPieData: productCategoryPieData, 
                          productSalesTotal: productSalesTotal, hasSearched: true});        
        }
        this.decrementBusy();
    }
    
    
    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
    }
    
    
  
    
    //Called when user selects a new time range from the dropdown,
    //set the end date to today and start date to the date picked
    _timeRangeChanged = () => {
        
        const today = DateUtils.startOfToday();
        this._endDateFieldRef.current.change(PP.dateFormat(today), true);            

        const selected = this.state.selectedTimeRange;

        //Find the selected start time
        let startTime = null;
        for (const option of this._timeOptions) {
            
            if (selected === option.label) {
                startTime = option.startTime;            
                break;
            }
        }
        if (startTime === null) {
            this._startDateFieldRef.current.change("Any", true);            
            return;
        }
        
        
        const startDate = PP.dateFormat(new Date(startTime));           
        this._startDateFieldRef.current.change(startDate, true);            
    }
    
    
    _startDateChanged = (json, val) => {
        const today = DateUtils.startOfToday();

        if (val !== null && (new Date(val) > today)) {  
            this._startDateFieldRef.current.change(PP.dateFormat(today), true); 
            return;
        }
        
 
        this._startDateFieldRef.current.accept();
        this.setState({startDateSearch: val});  //ok, set our state value

    }
    
    //If the end date is set to null ("Today" button) or a date after today, adjust it to today
    _endDateChanged = (json, val) => {
       
        const today = DateUtils.startOfToday();

        if (val === null || (new Date(val) > today)) {  
            this._endDateFieldRef.current.change(PP.dateFormat(today), true); 
            return;
        }
             
        this._endDateFieldRef.current.accept();
        this.setState({endDateSearch: val});  //ok, set our state value
    }
    
    _dateParseError = (label, error) => {
        this.showConfirmAlert("Error in Date Field", error, 'red');
    }
    
        
    render() {
        
        const timeRangeComponent = <Autocomplete
                                    size='small'
                                    value={this.state.selectedTimeRange}
                                    onChange={(event, newValue) => { this.setState({selectedTimeRange: newValue}); }}
                                    inputValue={this.state.selectedTimeRangeValue}
                                    onInputChange={(event, newValue) => { this.setState({selectedTimeRangeValue: newValue}); }}
                                    onClose={() => {setTimeout(this._timeRangeChanged, 1); /*call after a slight delay to propagate state*/}}
                                    options={this._timeOptions.map(option => option.label)}
                                    blurOnSelect
                                    disableClearable
                                    openText="Select Time Range"
                                    renderInput={(params) => <TextField {...params} label="Time Range" variant="outlined" InputLabelProps={{ shrink: true }} />}
                                   />;


        const infoWidget =  <Typography variant="body2" style={{fontSize: 12, fontWeight: 'bold', color: 'blue', marginLeft: 8}}>ⓘ</Typography>;

        const revenueTooltip = "The green line shows accumulated revenue over time and includes funds deposited, offset by any adjustments, and less any refunds. " +
                               "The blue line shows accumualted sales (purchases) of products over time, including tax and discounts.";
                
        const redemptionTooltip = "Redemptions include instant purchases as well as redeeming products previously purchased on a ticket. Returns reduce the amount.";
        
        const productCategoriesTooltip = 'Category Sales shows the percentage of sales belonging to products with the specified category. Products with an empty category show as "Unspecified". ' + 
                                         'Products that have been deleted after they are sold are grouped in the "Deleted Products" category.';
        
        return (
            <div>
                {this.getConfirmAlertComponent()}
               

                <Paper style={this.styles.paper}>
                    <Typography variant="body2" style={this.styles.paperLabel}>Analyze Transactions</Typography>  
                    
                    <Grid container direction="row" spacing={2} style={{padding: 10}}>

                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            {timeRangeComponent}
                      
                            <ManageDateField hasNever={true} neverText="Any" json="startDateField" maxYear={this._maxYear}
                                            onFieldChange={this._startDateChanged} 
                                            onParseError={this._dateParseError}
                                            label="From" initialValue="Any"
                                            ref={this._startDateFieldRef}
                                            style={{marginTop: 10}}
                                            changedBackgroundColor='white'
                                            dateFormat={PP.getDateFormat()}
                                            calendarColor={ThemeColors.calendarColor}/>
                     
                            <ManageDateField hasNever={true} neverText="Today" json="endDateField" maxYear={this._maxYear}
                                            onFieldChange={this._endDateChanged} 
                                            onParseError={this._dateParseError}
                                            label="To" initialValue={PP.dateFormat(DateUtils.startOfToday())}
                                            ref={this._endDateFieldRef}
                                            style={{marginTop: 10}}
                                            changedBackgroundColor='white'
                                            dateFormat={PP.getDateFormat()}
                                            calendarColor={ThemeColors.calendarColor}/>
                        </Grid>
                    

                         <Grid item lg={3} md={4} sm={12} xs={12}>
                         
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                
                                <Tooltip title="Analyze transaction data for the specified time range">
                                   <Button fullWidth onClick={this._search} variant="outlined" color='primary' style={{marginLeft: 10, maxWidth: 200}} component="label" startIcon={<BarChartIcon />}>
                                       Analyze
                                   </Button>
                                </Tooltip>
                            </div>
                            
                        </Grid>
                
                    </Grid>
                 </Paper>
                
                {this.state.isBusy ? this.getBusyComponent('center', {marginTop: 20}) : (<div style={{paddingBottom: 60}}/>)}

                <div style={{marginTop: 10}}/>
                
                {this.state.hasSearched ? 
                    <div>
                        {this.state.revenueChartData ? 
                            <div style={{display: this.state.revenueChartData.length > 0 ? 'block' : 'none', cursor: "crosshair"}}>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <Typography variant="button" align='center' style={{fontSize: 16}}>Revenue and Sales</Typography>  
                                    <Tooltip title={revenueTooltip}>
                                        {infoWidget}
                                    </Tooltip>
                                </div>
                                <Chart
                                    options={this._revenueChartOptions}
                                    series={this.state.revenueChartData}
                                    type="line"
                                    height={window.innerHeight*0.40}
                                />  
                            </div>
                            : null
                        }
                        {this.state.redeemChartData ? 
                            <div style={{marginTop: 40, display: this.state.redeemChartData.length > 0 ? 'block' : 'none', cursor: "crosshair"}}>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <Typography variant="button" align='center' style={{fontSize: 16}}>Daily Redemptions</Typography>  
                                    <Tooltip title={redemptionTooltip}>
                                        {infoWidget}
                                    </Tooltip>
                                </div>
                                <Chart
                                    options={this._redeemChartOptions}
                                    series={this.state.redeemChartData}
                                    type="bar"
                                    height={window.innerHeight*0.30}
                                />  
                            </div>
                            : null
                        }
                        
                        {this.state.productPieData ?
                             <Grid container direction="row" spacing={4} style={{marginTop: 40}}>
                    
                                <Grid item md={6} xs={12}>
                                    <div >
                                         <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                             <Typography variant="button" align='center' style={{fontSize: 16}}>
                                                {"Products Sold (" + this.state.productSalesTotal + ")"}
                                             </Typography>  
                                         </div>
                                         <Chart
                                             options={this._productPieChartOptions}
                                             series={this.state.productPieData}
                                             type="pie"
                                             height={window.innerHeight*0.40}
                                         />  
                                     </div> 
                                </Grid>
                                
                                <Grid item md={6} xs={12}>
                                    <div >
                                         <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                             <Typography variant="button" align='center' style={{fontSize: 16}}>Sales by Category</Typography>  
                                             <Tooltip title={productCategoriesTooltip}>
                                                 {infoWidget}
                                             </Tooltip>
                                         </div>
                                         <Chart
                                             options={this._productCategoryPieChartOptions}
                                             series={this.state.productCategoryPieData}
                                             type="pie"
                                             height={window.innerHeight*0.40}
                                         />  
                                     </div> 
                                </Grid>
                            </Grid> 
                            : null
                        }
                        
                        
                    </div>
                : null}
                       
                <div style={{marginTop: 40}}/>

            </div>

        );

    }
}


export default withCookies(AnalysisTab);

