import { DateUtils } from 'react-frontend-utils'


export class Occupancy {
    
    time;               //Date
    occupancy;          //integer   
    
    constructor(userJson) {
        
        if (userJson) {
            this.time = DateUtils.parseJsonDate(userJson.time);
            this.occupancy = userJson.occupancy;
        }
    }
    
    
    static createNowStat(currentOccupancy) {
        
        const occ = new Occupancy(null);
        occ.time = new Date();
        occ.occupancy = currentOccupancy;
        
        return occ;
    }
}

