import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';

import { Grid, Paper, Typography, TextField, Button, Divider, Tooltip, FormControl, FormControlLabel, Checkbox} from '@material-ui/core'
import { List, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction, IconButton } from '@material-ui/core'
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CreateIcon from '@material-ui/icons/Create';
import CancelIcon from '@material-ui/icons/Cancel';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import TodayIcon from '@material-ui/icons/Today';

import noCheckInIcon from '../assets/noCheckIn.png'
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';

import { PP } from '../models/PP'
import { ThemeColors } from '../Theme'
import { GroupInfo } from '../models/Group' 
import { RestComponent, TextEntryPopover } from 'react-frontend-utils' 


/**
 *  The ManageGroupPage Page provides a list of all databases, only accessible to superAdmins.  New databases can be added,
 *  properties changed, and deleted.  Databases cannot be renamed through this interface. 
 *  
 *    
 */
export class ManageGroupPage extends RestComponent {
  
    
    styles = {
        paper: {
            width: '100%',
            height: '100%'
        },
        paperLabel: {
            marginLeft: 15,
            color: 'blue',
            fontSize: '12pt',
            flexGrow: 1
        },
        form: {
        },
        divider: {
            marginLeft: 10,
            marginRight: 10
        },
        textfield: {
            marginRight: 5,
            marginBottom: 10,
            float: 'right'
        },
        rightGridStyle: {
            marginLeft: 20, 
            marginRight: 20,
            display: 'flex',
            alignItems: 'center',
        },
        button: {
            maxWidth: '100%'        
        },
        marketplace: {
            display: 'block',
            border: '1px solid #CCCCCC', 
            borderRadius: '4px', 
            padding: 10
        },
        marketplaceTitle: {
            marginLeft: 5,
            color: 'gray',
            fontSize: '9pt',
            flexGrow: 1
        }
                
    }
  
  
    _editPaperRef = React.createRef();
    _databasePaperRef = React.createRef();
    
    constructor(props) {
        super(props);
        
        this.state.groupList = [];
        this.state.filteredList = [];
        this.state.groupToEdit = null;
        this.state.isNewGroup = false;
        
        //Editing values
        this.state.name = null;
        this.state.suspended = false;
        this.state.checkInDisabled = false;
        this.state.ePassEnabled = false;
        this.state.bookingEnabled = false;
        this.state.fullService = false;
        this.state.maxUsers = 1;
        this.state.info = "";
        this.state.extendedName = "";
        this.state.address = "";
        this.state.url = "";
        this.state.accountNo = "";
        this.state.isoCurrency = "";
        this.state.marketEnabled = false;
        
        this.state.confirmDeletePopoverOpen = false;
    }


    /**
     * When the page loads, fetch all users for the logged in user's databases
     */
    componentDidMount() {
        super.componentDidMount();
        this._fetchGroups();
    }
    
    
    _clearGroup = () => {
        this.setState({groupToEdit: null, 
                       isNewGroup: false, 
                       name: "", 
                       suspended: false, 
                       checkInDisabled: false,
                       ePassEnabled: false,
                       bookingEnabled: false,
                       fullService: false,
                       maxUsers: 0,
                       info: "",
                       extendedName: "",
                       address: "",
                       url: "",
                       accountNo: "",
                       isoCurrency: "",
                       marketEnabled: false});
    }
    
    _fetchGroups = (isNew = false) => {
        this.setBusy(true);  
        this._clearGroup();
        this.setState({groupList: [], filteredList: []}); 
        
        if (isNew)
            this.showConfirmAlert("New Database Notice", "To access the new database and add users, refresh this browser page", 'black');
        
        this.secureJSONFetch("/ppcs/groups", {}, this._fetchCallback, this._fetchError); 
    }
    
    _fetchCallback = (response) => {
        if (response) {
            
            const groupList = response.map((userJson) => new GroupInfo(userJson));
            this.setState({groupList: groupList, filteredList: groupList, groupToEdit: null, isNewGroup: false, name: "", maxUsers: 0, info: ""});  //re-render
        
            //Here we have to update our databases directly. The super admin always belongs to every group. However the Oauth token will not
            //reflect that until next login. Thus, we directly set all databases in the PP user so they can manipulate all databases
            PP.user.databases = groupList.map((group) => group.name);
            
        }
        this.setBusy(false);
    }

    _fetchError = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.setBusy(false);
    }
    
    _groupUpdated = (id) => {
        this.setBusy(true);
        this.secureJSONFetch("/ppcs/groups/" + id, {}, this._fetchSingleGroupCallback, this._fetchError);   
    }
    
    
    //Get the single updated group and replace it in our list, rather than refetching the entire list
    _fetchSingleGroupCallback = (response) => {
        this.setBusy(false);

        if (response) {
            const updatedGroup = new GroupInfo(response);
                        
            for (let i=0; i<this.state.groupList.length; i++) {

                if (this.state.groupList[i].id === updatedGroup.id) {  //replace the old group with the new one
                    this.state.groupList[i] = updatedGroup;
                    break;
                }
            }
            
            //If the filtered list is different (we are filtering) also replace in that list
            if (this.state.filteredList !== this.state.groupList) {
                for (let i=0; i<this.state.filteredList.length; i++) {

                    if (this.state.filteredList[i].id === updatedGroup.id) {  //replace the old group with the new one
                        this.state.filteredList[i] = updatedGroup;
                        break;
                    }
                }
            }
            this.forceUpdate();
        }
    }
    
    //Callback when a group was successfully deleted, remove it from our lists
    _groupDeleted = (deletedId) => {

        this.state.groupList = this.state.groupList.filter(group => group.id !== deletedId);
        
        //If the filtered list is different (we are filtering) also remove from that list
        if (this.state.filteredList !== this.state.groupList)
            this.state.filteredList = this.state.filteredList.filter(group => group.id !== deletedId);

        this._clearGroup();
        
        //Remap the superadmin's database list from the groupList so the deleted entry is removed
        PP.user.databases = this.state.groupList.map((group) => group.name);
        
        this.forceUpdate();
    }

    
    _filterFieldChanged = (event) => {
        let searchString = event.target.value;
        
        if (searchString)  { //has a search string   
            
            searchString = searchString.toLowerCase();
            this.state.filteredList = this.state.groupList.filter((group) => {

                return group.name.toLowerCase().includes(searchString) || 
                       group.attributes.extendedName.toLowerCase().includes(searchString) ||
                       group.attributes.address.toLowerCase().includes(searchString) ||
                       group.attributes.url.toLowerCase().includes(searchString) ||
                       group.attributes.accountNo.toLowerCase().includes(searchString) ||
                       group.attributes.info.toLowerCase().includes(searchString);         
            });
        }
        else
            this.state.filteredList = this.state.groupList;
        
        this.forceUpdate(); //re-render            
    }
    
    
     _addDatabase = () => {
        window.scrollTo(0, this._editPaperRef.current.offsetTop);  //jump to the edit database
        this._clearGroup();  //clear all fields
        this.setState({groupToEdit: {id: null, name: "New"}, isNewGroup: true, suspended: false, checkInDisabled: false, ePassEnabled: false, bookingEnabled: false, fullService: false, name: "", maxUsers: 1, isoCurrency: "USD"});   
    }
    
    _editDatabase = (databaseToEdit) => {
        window.scrollTo(0, this._editPaperRef.current.offsetTop);  //jump to the edit database
        this.setState({groupToEdit: databaseToEdit, 
                       isNewGroup: false, 
                       name: databaseToEdit.name, 
                       suspended: databaseToEdit.attributes.isSuspended,
                       checkInDisabled: databaseToEdit.attributes.checkInDisabled,
                       ePassEnabled: databaseToEdit.attributes.ePassEnabled,
                       bookingEnabled: databaseToEdit.attributes.bookingEnabled,
                       fullService: databaseToEdit.attributes.fullService,
                       maxUsers: databaseToEdit.attributes.maxUsers,
                       info: databaseToEdit.attributes.info,
                       extendedName: databaseToEdit.attributes.extendedName,
                       address: databaseToEdit.attributes.address,
                       url: databaseToEdit.attributes.url,
                       accountNo: databaseToEdit.attributes.accountNo,
                       isoCurrency: databaseToEdit.attributes.isoCurrency,
                       marketEnabled: databaseToEdit.attributes.marketEnabled});
    }
    
    _discardChanges = () => {
        window.scrollTo(0, this._databasePaperRef.current.offsetTop);  //jump to the database list
        this._clearGroup();
    }
    
   
    /**
     * Called when the database name field value changes - only fires when database is "new"
     * @param {type} event the TextField
     */
    _groupNameFieldChanged = (event) => {
        
        if (!this.state.isNewGroup) //should always be new but just in-case, we never want to rename a database
            return;
        
        const name = event.target.value;
        const filteredName = name.replace(/\W/g, '');  //remove all non alphanumeric characters except underscore
        
        this.setState({name: filteredName});
    }
    
    _maxUserFieldChanged = (event) => {
         let newVal = event.target.value;
         if (newVal < 1)
             newVal = 1;
         
         this.setState({maxUsers: newVal});
    }
    
    
    _checkInDisabledChanged = (event) => {
        this.setState({checkInDisabled: event.target.checked});        
    }
    
     _ePassEnabledChanged = (event) => {
        this.setState({ePassEnabled: event.target.checked});        
    }
    
    _bookingEnabledChanged = (event) => {
        this.setState({bookingEnabled: event.target.checked});
    }
    
     _fullServiceChanged = (event) => {
        this.setState({fullService: event.target.checked});        
    }
    
    _infoFieldChanged = (event) => {
        
        const info = event.target.value;        
        this.setState({info: info});
    }
    
    _extendedNameFieldChanged = (event) => {
        
        const extendedName = event.target.value;        
        this.setState({extendedName: extendedName});
    }
    
    _addressFieldChanged = (event) => {
        
        const address = event.target.value;        
        this.setState({address: address});
    }
    
    _urlFieldChanged = (event) => {
        
        const url = event.target.value;        
        this.setState({url: url});
    }
    
    _accountNoFieldChanged = (event) => {
        
        const account = event.target.value;        
        this.setState({accountNo: account});
    }


    _marketEnabledChanged = (event) => {
        const enabled = event.target.checked;
        this.setState({marketEnabled: enabled});
    }

    _currencyFieldChanged = (event) => {
        const currencyCode = event.target.value; 
        this.setState({isoCurrency: currencyCode});
    }

    
    //Post an update to add or change the group.  On success, refetch groups to update the list
    _updateGroup = () => {
        
        if (this.state.isoCurrency.length !== 3) {
            this.showConfirmAlert("Error", "ISO Currency must be a 3-letter code", 'red');
            return;
        }
        
        if (!this.state.accountNo) {
            this.showConfirmAlert("Error", "Missing account number", 'red');
            return;
        }
        
        
        let accountNumIsAllNumeric = /^\d+$/.test(this.state.accountNo);
        if (!accountNumIsAllNumeric) {
            this.showConfirmAlert("Error", "Account number must contain only digits 0-9", 'red');
            return;
        }
        
        if (this.state.isNewGroup) {
            this.showConfirmAlert("Creating New Database", 
                              "Confirm create database \"" + this.state.name + "\" with account number " + this.state.accountNo  + ". The name and account number cannot be changed later.",
                              'black',
                              "Cancel", 
                              this._doUpdateGroup,
                              "Create",
                              'green',);
        }
        else
            this._doUpdateGroup();
        
    }
    
    _doUpdateGroup = () => {
        
        
        const groupToChange = new GroupInfo();
        groupToChange.id = this.state.groupToEdit.id;
        groupToChange.name = this.state.name;
        
        groupToChange.attributes.isSuspended = this.state.suspended;
        groupToChange.attributes.checkInDisabled = this.state.checkInDisabled;
        groupToChange.attributes.ePassEnabled = this.state.ePassEnabled;
        groupToChange.attributes.bookingEnabled = this.state.bookingEnabled;
        groupToChange.attributes.fullService = this.state.fullService;
        groupToChange.attributes.info = this.state.info;
        groupToChange.attributes.maxUsers = this.state.maxUsers;
        groupToChange.attributes.extendedName = this.state.extendedName;
        groupToChange.attributes.address = this.state.address;
        groupToChange.attributes.url = this.state.url; 
        groupToChange.attributes.accountNo = this.state.accountNo;
        groupToChange.attributes.isoCurrency = this.state.isoCurrency;
        groupToChange.attributes.marketEnabled = this.state.marketEnabled;

        //If a new group is added, we can't immediately update it because the superadmin needs it added to their user profile, so they must refresh the browser
        let postSuccess;
        if (this.state.isNewGroup)
            postSuccess = () => {this.setBusy(false); 
                                 this.showConfirmAlert("New Database Notice", "To access the new database and add users, refresh this browser page", 'black');
                                 this._clearGroup();
                                };        
        else
            postSuccess = () => {this.setBusy(false); this._groupUpdated(groupToChange.id);};  //otherwise, avoid refetching everything and just update the group that was changed

        this.setBusy(true);

        //Post the change - refetch on success, or show error on error
        this.secureJSONFetch("/ppcs/groups", 
                             {method: "PUT", body: JSON.stringify(groupToChange)},
                             postSuccess,
                             this._fetchError);                         
    }
    
    _deleteTextEntryConfirm = (response) => {
        
        this.setState({confirmDeletePopoverOpen: false});
        
        if (response !== this.state.groupToEdit.name) {
            this.showConfirmAlert("Error", "You did not type the name correctly", 'red');
            return;
        }
        
        //Check required information
        this.showConfirmAlert("Final Confirmation", 
                              "Really delete database \"" + this.state.groupToEdit.name + "\" permanently? All data will be permanently destroyed!",
                              'red',
                              "Cancel", 
                              this._confirmDeleteGroup,   
                              "Delete",
                              'red');
        
    }
        
    //Post an delete to remove the group.  On success, refetch groups to update the list        
    _confirmDeleteGroup = () => {
        
        //Post the delete - refetch on success, or show error on error
        this.secureJSONFetch("/ppcs/groups/" + this.state.groupToEdit.name, 
                             {method: "DELETE"},
                             () => this._groupDeleted(this.state.groupToEdit.id),
                             this._fetchError);       
        
    }
    
    
    //Post terminate to the group.
    _confirmTerminateConnections = () => {
        
        //Post the delete - nothing on success, or show error on error
        this.secureJSONFetch("/ppcs/groups/" + this.state.groupToEdit.name + "/terminate", 
                             {method: "POST"},
                             null,
                             this._fetchError);       
        
    }

    
    _deleteGroupPrompt = () => {

        //Check required information
        this.showConfirmAlert("Confirm", 
                              "Really delete database \"" + this.state.groupToEdit.name + "\" permanently?",
                              'red',
                              "Cancel", 
                              () => {this.setState({confirmDeletePopoverOpen: true});},   //show the popup to confirm
                              "Delete",
                              'red');
    }
    
    _terminateConnectionsPrompt = () => {

        //Check required information
        this.showConfirmAlert("Confirm", 
                              "Forcibly terminate all user connections to \"" + this.state.groupToEdit.name + "\"?",
                              'red',
                              "Cancel", 
                              this._confirmTerminateConnections,
                              "Terminate",
                              'red');
    }

    
    render() {
        console.log(this.state.filteredList);
        const filterColor = this.state.filteredList.length > 0 ? 'primary' : 'secondary';

        return (
            <Fragment>

                {this.getConfirmAlertComponent()}


                <TextEntryPopover isOpen={this.state.confirmDeletePopoverOpen} showSkip={false} multiline={false} title="Confirm delete by typing the database name below" 
                                 okCallback={this._deleteTextEntryConfirm} cancelCallback={() => this.setState({confirmDeletePopoverOpen: false})}/>
    
                <Tooltip title="Filter on database name, extended name, address, url, account number, or info text">
                    <TextField label="Filter" onChange={this._filterFieldChanged} color={filterColor} margin='dense' style={this.styles.textfield} InputLabelProps={{ shrink: true }} />
                </Tooltip>
                
                <Grid container direction="row" spacing={2} >
                    
                    { /*---------------------------- LEFT GRID DATABASES -------------------------------------------*/ null }

                   <Grid item sm={6} xs={12}>

                     <Paper style={{...this.styles.paper, marginBottom: 0}} ref={this._databasePaperRef}>

                        <div style={{display: 'flex', height: 40}}>

                            <Typography variant="body2" style={this.styles.paperLabel}>{"Databases (" + this.state.filteredList.length + ")"}</Typography>  

                            <Tooltip title="Create a new database">
                                <IconButton edge="end" onClick={this._addDatabase} style={{marginTop: -4, marginRight: 4}}>
                                    <AddCircleOutlineIcon style={{color: ThemeColors.addColor}} />
                                </IconButton>
                            </Tooltip>

                        </div>

                        {this.getBusyComponent()}

                        <List dense>
                            {this.state.filteredList.map((database, index) =>
                                (<div key={index}>
                                    {index > 0 ? <Divider style={this.styles.divider}/> : <Divider/>  /*Smaller dividers except the first*/}
                                    <ListItem>       
                                        <ListItemText primary={database.name} 
                                                      secondary={(<div>
                                                                    <div>{"Users: " + database.userCount + "/" + database.attributes.maxUsers}</div>
                                                                    <div>{"Info: " + database.shortInfo()}</div>
                                                                  </div>)}
                                                      primaryTypographyProps={database.attributes.isSuspended ? {color: 'secondary'} : {color: 'primary'}}/>
                                                      
                                            { database.attributes.checkInDisabled ? 
                                                <Tooltip title="Check-in Disabled">
                                                    <ListItemIcon>
                                                        <img src={noCheckInIcon} alt="disabled check-in" width='20px'/>
                                                    </ListItemIcon>
                                                </Tooltip> : null } 
                                                      
                                            { database.attributes.ePassEnabled ? 
                                                <Tooltip title="ePasses Enabled">
                                                    <ListItemIcon>
                                                        <PhoneIphoneIcon style={{color: ThemeColors.epassColor}} />
                                                    </ListItemIcon>
                                                </Tooltip> : null } 
                                                
                                            { database.attributes.bookingEnabled ? 
                                                <Tooltip title="Booking Enabled">
                                                    <ListItemIcon>
                                                        <TodayIcon style={{color: ThemeColors.calendarColor}} />
                                                    </ListItemIcon>
                                                </Tooltip> : null }                                                   
                                            { 1 === 0 && /*deprecated*/ database.attributes.fullService ? 
                                                <Tooltip title="Full Service Client">
                                                    <ListItemIcon>
                                                        <PhoneInTalkIcon style={{color: 'green'}} />
                                                    </ListItemIcon>
                                                </Tooltip> : null }     

                                            { database.attributes.marketEnabled ? 
                                                <Tooltip title="Marketplace Enabled">
                                                    <ListItemIcon>
                                                        <ShoppingCartOutlinedIcon fontSize='small' style={{color: 'blue', marginRight: 40}}/>
                                                    </ListItemIcon>
                                                </Tooltip> : null }    
                                                                               
                                            <ListItemSecondaryAction>
                                                {database.name !== 'admin' ? (
                                                        <Tooltip title={"Edit " + database.name}>
                                                                <IconButton edge="end" onClick={() => {this._editDatabase(database);}}>
                                                                    <CreateIcon/>
                                                                 </IconButton>
                                                        </Tooltip>
                                                ) : null}
                                            </ListItemSecondaryAction>                                                   
                                    </ListItem>
                                 </div>)                       
                            )}
                        </List>
                    </Paper>

                </Grid>

                { /*---------------------------- RIGHT GRID EDIT DATABASE -------------------------------------------*/ null }


                <Grid item sm={6} xs={12}>
                    <Paper style={this.styles.paper} ref={this._editPaperRef} >
                        <div style={{display: 'flex', height: 40}}>

                            <Typography variant="body2" style={this.styles.paperLabel}>{this.state.groupToEdit ? (this.state.isNewGroup ? "Add New Database" : ("Edit \"" + this.state.groupToEdit.name + "\" Database")) : "Edit Database"}</Typography> 

                            <Tooltip title="Discard Changes">
                                <IconButton edge="end" disabled={this.state.groupToEdit === null} onClick={this._discardChanges} style={{marginTop: -4, marginRight: 4}}>
                                    <CancelIcon />
                                </IconButton>
                            </Tooltip>

                        </div>
                        
                        <Grid container direction='row' style={{marginTop: 10}} alignContent='center' spacing={3} >

                            {this.state.isNewGroup ?
                                <Grid item xs={12} style={this.styles.rightGridStyle}>
                                    <TextField label="Name" value={this.state.name} onChange={this._groupNameFieldChanged} variant="outlined" fullWidth={true} InputLabelProps={{ shrink: true }} />
                                </Grid>
                                : null
                            }

                            <Grid item xs={12} style={this.styles.rightGridStyle}>
                                <TextField
                                    label="Maximum Users"
                                    type="number"
                                    value={this.state.groupToEdit === null ? "" : this.state.maxUsers}
                                    disabled={this.state.groupToEdit === null}
                                    onChange={this._maxUserFieldChanged}
                                    InputLabelProps={{ shrink: true }} 
                                  />     
                            </Grid>
                            
                            <Grid item xs={12} style={this.styles.rightGridStyle}>                                                   
                                <Tooltip title="When suspended, no operations on database permitted. (New connections in the pool are disabled. Terminate all connections to suspend immediately)."> 
                                    <FormControl component="fieldset" style={{...this.styles.form, marginRight: 15}} >  
                                        <FormControlLabel disabled={true} control={<Checkbox checked={this.state.groupToEdit? this.state.suspended : false} color="secondary"/>} label="Suspended"/>
                                    </FormControl>
                                </Tooltip>
                                 <Tooltip title="Check-ins on database not permitted (delayed change)"> 
                                    <FormControl component="fieldset" style={{...this.styles.form, marginRight: 15}} >  
                                        <FormControlLabel disabled={this.state.groupToEdit === null} control={<Checkbox checked={this.state.groupToEdit? this.state.checkInDisabled : false} onChange={this._checkInDisabledChanged} color="secondary"/>} label="Check-In Disabled"/>
                                    </FormControl>
                                </Tooltip>
                                <Tooltip title="Administrator can enable ePasses for database in the Pass Portal (delayed change)"> 
                                    <FormControl component="fieldset" style={{...this.styles.form, marginRight: 15}} >  
                                        <FormControlLabel disabled={this.state.groupToEdit === null} control={<Checkbox checked={this.state.groupToEdit? this.state.ePassEnabled : false} onChange={this._ePassEnabledChanged} color="primary"/>} label="ePasses Enabled"/>
                                    </FormControl>
                                </Tooltip>
                                <Tooltip title="Booking Portal enabled (delayed change)"> 
                                    <FormControl component="fieldset" style={{...this.styles.form, marginRight: 15}} >  
                                        <FormControlLabel disabled={this.state.groupToEdit === null} control={<Checkbox checked={this.state.groupToEdit? this.state.bookingEnabled : false} onChange={this._bookingEnabledChanged} color="primary"/>} label="Booking Enabled"/>
                                    </FormControl>
                                </Tooltip>
                                
                                {null /**  Deprecated
                                <Tooltip title="Client is Full Service"> 
                                    <FormControl component="fieldset" style={{...this.styles.form, marginRight: 15}} >  
                                        <FormControlLabel disabled={this.state.groupToEdit === null} control={<Checkbox checked={this.state.groupToEdit? this.state.fullService : false} onChange={this._fullServiceChanged} color="primary"/>} label="Full Service"/>
                                    </FormControl>
                                </Tooltip>
                                 */}
                            </Grid>
                            
                             <Grid item xs={12} style={this.styles.rightGridStyle}>                                                   
                               
                            </Grid>

                            <Grid item xs={12} style={this.styles.rightGridStyle}>

                                <TextField label="Account Number" variant='outlined' fullWidth={true} value={this.state.accountNo}
                                            disabled={this.state.groupToEdit === null || !this.state.isNewGroup}
                                            onChange={this._accountNoFieldChanged}
                                            InputLabelProps={{ shrink: true }} />
                            </Grid>

                            <Grid item xs={12} style={this.styles.rightGridStyle}>

                                <TextField label="Extended Name (Public)" variant='outlined' fullWidth={true} value={this.state.extendedName}
                                            disabled={this.state.groupToEdit === null}
                                            onChange={this._extendedNameFieldChanged}
                                            InputLabelProps={{ shrink: true }} />
                            </Grid>
                            
                            <Grid item xs={12} style={this.styles.rightGridStyle}>

                                <TextField label="Address (Public)" variant='outlined' fullWidth={true} value={this.state.address}
                                            disabled={this.state.groupToEdit === null}
                                            onChange={this._addressFieldChanged}
                                            InputLabelProps={{ shrink: true }} />
                            </Grid>
                            
                            <Grid item xs={12} style={this.styles.rightGridStyle}>

                                <TextField label="URL (Public)" variant='outlined' fullWidth={true} value={this.state.url}
                                            disabled={true /*deprecated - now in marketplace settings*/}
                                            onChange={this._urlFieldChanged}
                                            InputLabelProps={{ shrink: true }} />
                            </Grid>
                            
                            <Grid item xs={12} style={this.styles.rightGridStyle}>
                                <TextField
                                    label="Info"
                                    variant='outlined'
                                    multiline
                                    fullWidth={true}
                                    rows={3}
                                    value={this.state.info}
                                    disabled={this.state.groupToEdit === null}
                                    onChange={this._infoFieldChanged}
                                    InputLabelProps={{ shrink: true }} 
                                  />
                            </Grid>
                            
                            <Grid item xs={12} style={this.styles.rightGridStyle}>
                                <div style={{display: 'block', width: '100%'}}>
                                    <Typography variant="body2" style={this.styles.marketplaceTitle}>Financial</Typography>  

                                    <div style={this.styles.marketplace}>

                                        <Tooltip title="Marketplace is enabled (when disabled, all marketplace functions are unavailable)"> 
                                            <FormControl component="fieldset" style={this.styles.form} >  
                                               <FormControlLabel disabled={this.state.groupToEdit === null} control={<Checkbox checked={this.state.groupToEdit? this.state.marketEnabled : false} onChange={this._marketEnabledChanged} color="primary"/>} label="Marketplace Enabled"/>
                                            </FormControl>
                                        </Tooltip>

                                        <TextField label="ISO Currency" style={{maxWidth: 120, marginTop: 15, display: 'block'}} value={this.state.isoCurrency}
                                                   disabled={this.state.groupToEdit === null}
                                                   onChange={this._currencyFieldChanged}
                                                   InputLabelProps={{ shrink: true }} />
                                                                             
                                    </div>            
                                </div>                                            
                            </Grid>
                            
                    
                            <Grid item xs={12} style={{...this.styles.rightGridStyle, marginTop: 30}}>
                                <Grid container direction='column' alignContent='center' spacing={3} >
                   
                        
                                    <Grid item>
                                        <Tooltip title={this.state.isNewGroup ? "Add database, add Postgres database if needed" : "Apply changes (public changes delayed until cache refresh)"}>         
                                            <Button onClick={this._updateGroup} variant="outlined" fullWidth={true} color='primary' disabled={this.state.groupToEdit === null} style={this.styles.button}>{this.state.isNewGroup ? "Add New Database" : "Apply Changes"}</Button>
                                        </Tooltip>
                                    </Grid>

                                    {this.state.isNewGroup ? null :
                                        <Grid item>                        
                                            <Button onClick={this._deleteGroupPrompt} variant="outlined" fullWidth={true} disabled={this.state.groupToEdit === null} color='secondary' style={this.styles.button}>Delete Database</Button>
                                        </Grid>
                                    }

                                    {this.state.isNewGroup ? null :
                                        <Grid item>
                                            <Tooltip title={"Terminates Postgres connections to " + (this.state.groupToEdit ? this.state.groupToEdit.name : " database ") + " for all Web servers, all Apps"}>         
                                                <Button onClick={this._terminateConnectionsPrompt} variant="outlined" fullWidth={true} color='secondary' disabled={this.state.groupToEdit === null} style={this.styles.button}>Terminate Connections</Button>
                                            </Tooltip>                   
                                        </Grid>
                                    }

                                    
                                </Grid>
                            </Grid>

                        </Grid>

                        <div style={{padding: 20}}/>
                        {this.getBusyComponent("center", {marginBottom: 15})}

                    </Paper>
                </Grid>
             </Grid>
             
            </Fragment>
        );

    }
    
}export default withCookies(ManageGroupPage);