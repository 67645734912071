import { DateUtils } from 'react-frontend-utils'


export class CheckInStat {
    
    time;               //Date
    members;            //integer   
    guests;             //integer
    patrons;            //integer
    
    constructor(userJson) {
        
        if (userJson) {
            this.time = DateUtils.parseJsonDate(userJson.time);
            this.members = userJson.members;
            this.guests = userJson.guests;
            this.patrons = userJson.patrons;
        }
    }
}

