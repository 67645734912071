import React from 'react';
import { withCookies } from 'react-cookie';

import { Tooltip, Grid, Typography, Paper, TextField, Button } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import GetAppIcon from '@material-ui/icons/GetApp';
import SearchIcon from '@material-ui/icons/Search';
import SettingsIcon from '@material-ui/icons/Settings';

import { ThemeColors } from '../Theme'
import { PP } from '../models/PP'
import { Membership, membershipTooltip } from '../models/Membership'
import { InvoiceRequest, invoiceRequestTooltip } from '../models/InvoiceRequest'
import { InvoiceStatus, InvoiceQueryResponse } from '../models/Invoice'

import { RestComponent, PopupMenu } from 'react-frontend-utils' 
import { multilineJSX, StyledTooltip } from 'react-frontend-utils'

import { DateUtils, Currency, TextEntryPopover, SummaryWidget, Permissions } from 'react-frontend-utils'
import { OpenInNewTab } from '../App'


const PREV_JUMP_AMOUNT = 100;

export class InvoiceTab extends RestComponent {
  
    styles = {
        paperLabel: {
            marginLeft: 15,
            marginRight: 15,
            marginBottom: 5,
            color: 'gray',
            fontSize: '9pt',
            flexGrow: 1
        },
        table: {
            borderCollapse: 'collapse',
            width: '100%',
            marginBottom: 20,
            marginTop: 10
        },
        tableHeader: {
            borderBottom: '2px solid ' + ThemeColors.appBarBackground,
            textAlign: 'left',
            padding: 5,
            paddingRight: 10,
            marginBottom: 8,
            fontSize: 13,
            color: ThemeColors.darkGray,
            fontWeight: 'normal',
            textTransform: 'uppercase'
        },
        tableData: {
            borderBottom: '1px solid lightGray',
            textAlign: 'left',
            fontSize: 13,
            padding: 5,
            paddingRight: 10
        },
        status: {
            margin: 'auto',
            alignContent: 'center',
            width: 60,
            padding: 2, 
            borderRadius: 2, 
            color: 'white', 
            textAlign: 'center'
        }
    };
    

    _pageIndex = 0; //requested page

    constructor(props) {
        super(props);
    
        this.state.invoiceRequests = [];    //array of InvoiceRequests, from last fetch
        this.state.selectedInvoiceRequest = null;
        this.state.cycles = [];
        this.state.selectedCycle = null;
               
        this.state.memberships = [];                //fetched JsonMemberships array from database
        this.state.selectedMembership = null;       //currently selected Membership
    
        this.state.invoiceQueryResponse = null;         //all the fetched invoices
    
        this.state.invoiceToVoid = null;
        this.state.invoiceToPay = null;
    }
    
    
    
    /**
     * When the page loads, immediately fetch the first page of invoices
     */
    componentDidMount() {
        super.componentDidMount();
        
        //fetch all memberships
        this.incrementBusy();
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/memberships", {}, this._fetchMembershipsCallback); 

        //fetch all Invoice Requests
        this.incrementBusy();
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/billing/requests", {}, this._fetchInvoiceRequestsCallback, this._fetchErrorCallback); 
    }
    
   
    
    //Callback for fetching Invoice Requests - JSON response is an array of InvoiceRequest objects
    _fetchInvoiceRequestsCallback = (response) => {
        if (response) {            
            this.state.invoiceRequests = response.map((ir) => new InvoiceRequest(ir));
        }            
        this.decrementBusy();
    }

    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.setBusy(false);
    }
    
    
    //Callback when all Memberships are fetched, response is array of JsonMemberships, select the first in the list
    _fetchMembershipsCallback = (response) => {
        if (response) {    
            const memberships = response.map(m => new Membership(m));
            this.setState({memberships: memberships});
        } 
        this.decrementBusy();
    }
    
    
    _selectNewInvoiceRequest = (event, newIR) => {
        
        const cycles = [];

        if (newIR) {
            for (let i = newIR.cycle; i >= 1; i--) {
               cycles.push(String(i));
            }
        }

        this.setState({selectedInvoiceRequest: newIR, cycles: cycles, selectedCycle: cycles[0]});
    }
  

    
     _getSearchString = (forDownload = false) => {
         
        let search = "";
        
        if (this.state.selectedMembership)
            search += "membershipID=" + this.state.selectedMembership.id + "&";
        if (this.state.selectedInvoiceRequest)
            search += "requestID=" + this.state.selectedInvoiceRequest.id + (this.state.selectedCycle ? ("&cycle=" + this.state.selectedCycle) : "") + "&";
    
        if (search)
            search = "?" + search.substring(0, search.length-1);  //remove trailing &
        
        else { //nothing to search, so page
            
            if (forDownload) //no paging on download though
                return "";
            
            search = "?index=" + this._pageIndex;
        }
            
        return search;
    }
  
    
    _search = () => {

        const search = this._getSearchString();

        this.incrementBusy();       
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/billing/invoices" + search, {},
                             this._searchResponse, this._fetchErrorCallback); 
   
    }
    
    
    _searchResponse = (response) => {
        if (response) {                    
            this.setState({invoiceQueryResponse: new InvoiceQueryResponse(response)});
        }
        this.decrementBusy();
    }
    
    _download = () => {

        const search = this._getSearchString(true);  //search, for download
        
        this.incrementBusy();
        const filename = "Invoices Export from " + PP.selectedDatabase + " on " + DateUtils.downloadTimeString() + ".csv";

        this.secureFileDownload("/ppcs/databases/" + PP.selectedDatabase + "/billing/invoices/download" + search, filename, this._downloadResponse, this._fetchErrorCallback); 
    }
    
    
    _downloadResponse = () => {
        this.decrementBusy();
        console.log("Download complete");
    }
    
    _prev = () => {  //go to the previous set
        
        if (this.state.invoiceQueryResponse) {
            this._pageIndex = this.state.invoiceQueryResponse.firstIndex - PREV_JUMP_AMOUNT;
            if (this._pageIndex < 0)
                this._pageIndex = 0;
            
            this._search();
        }
    }
    
    _next = () => {  //go to the previous set
        
        if (this.state.invoiceQueryResponse) {
            this._pageIndex = this.state.invoiceQueryResponse.firstIndex + this.state.invoiceQueryResponse.invoices.length;
    
            this._search();
        }
    }
    
    _downloadPDF = (invoice) => {
        console.log(invoice.pdfUrl);
        OpenInNewTab(invoice.pdfUrl);
    }
    
    _visitHostedPaymentPage = (invoice) => {
        console.log(invoice.payUrl);
        OpenInNewTab(invoice.payUrl);
    }
    
    _sendReminder = (invoice) => {
        
        this.incrementBusy();
     
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/billing/invoices/" + invoice.id + "/remind", 
                            {method: "POST"},
                            () => this._sendReminderResponse(invoice), this._fetchErrorCallback); 
 
    }
    
    _sendReminderResponse = (invoice) => { 
        this.decrementBusy();     
        this.showConfirmAlert("Success", "A reminder will be sent to " + invoice.customerEmail, 'green');
    }
    
    
    _askPayInvoice = (comment) => {
        
        if (!this.state.invoiceToPay)
            return;
        
        this.showConfirmAlert("Confirm", 
                              "Try Settling Invoice \"" + this.state.invoiceToPay.number + "\" by External Payment? All on-pay actions will be invoked. This operation cannot be cancelled.",
                              'black',
                              "Cancel", 
                              () => this._payInvoice(this.state.invoiceToPay, comment),
                              "Settle",
                              'red');
    }
    
    _payInvoice = (invoice, comment) => {
        
        this.setState({payCommentOpen: false});
        this.incrementBusy();
     
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/billing/invoices/" + invoice.id + "/pay", 
                            {method: "POST", body: JSON.stringify({comment: comment})},
                            () => this._payResponse(invoice), this._fetchErrorCallback); 
 
    }
    
    _payResponse = (invoice) => { 
        this.decrementBusy();
        this.showConfirmAlert("Request to Settle Submitted", "Please refresh the list in a few minutes to check the updated status", 'green');
    }
    
    
    
    _askVoidInvoice = (comment) => {
        
        if (!this.state.invoiceToVoid)
            return;
        
        if (!comment)
            comment = "";
        
        this.showConfirmAlert("Confirm", 
                              "Try Voiding Invoice \"" + this.state.invoiceToVoid.number + "\"? This operation cannot be cancelled.",
                              'black',
                              "Cancel", 
                              () => this._voidInvoice(this.state.invoiceToVoid, comment),
                              "Void",
                              'red');
    }
    
    _voidInvoice = (invoice, comment) => {
                
        this.setState({voidCommentOpen: false});
        this.incrementBusy();

        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/billing/invoices/" + invoice.id + "/void", 
                            {method: "POST", body: JSON.stringify({comment: comment})},
                            () => this._voidResponse(invoice), this._fetchErrorCallback); 
 
    }
    
    _voidResponse = (invoice) => { //on success, change the invoice status
        this.decrementBusy();
        this.showConfirmAlert("Request to Void Submitted", "Please refresh the list in a few minutes to check the updated status", 'green');
    }
    
    
    _getInvoiceActionItems = (invoice) => {
        const actionItems = [{label: "Download as PDF", selectCallback: () => {this._downloadPDF(invoice);}, icon: null},
                             {label: "Visit Hosted Payment Page", selectCallback: () => {this._visitHostedPaymentPage(invoice);}, icon: null}];
                         
       if (InvoiceStatus.canCancelOrPay(invoice.status) && PP.user.hasPermissionTo(Permissions.MANAGE_INVOICES)) {
            actionItems.push({label: "Send Reminder", selectCallback: () => {this._sendReminder(invoice);}, icon: null});
            actionItems.push({label: "Void Invoice", selectCallback: () => {this.setState({voidCommentOpen: true, invoiceToVoid: invoice});}, icon: null});
            actionItems.push({label: "Settle with Other Payment", selectCallback: () => {this.setState({payCommentOpen: true, invoiceToPay: invoice});}, icon: null});   
        }
        
        return actionItems;
    }
    
    
    
    //Render a row of the table with the specified invoice. In the invoice is null, render the header row
    _renderInvoiceRow = (invoice, key) => {
        
        //Render the header
        if (!invoice) {
            return (
                <tr key={key} style={this.styles.tableStyle}>
                    <th style={{...this.styles.tableHeader, textAlign: 'center', width: '60px'}}>Amount</th>
                    <th style={{...this.styles.tableHeader, textAlign: 'center'}}>Status</th>
                    <th style={this.styles.tableHeader}>Invoice Number</th>
                    <th style={this.styles.tableHeader}>Customer</th>
                    <th style={this.styles.tableHeader}>Subject</th>
                    <th style={{...this.styles.tableHeader, textAlign: 'right', paddingRight: '20px'}}>Cycle</th>
                    <th style={this.styles.tableHeader}>Due</th>
                    <th style={this.styles.tableHeader}>Issued</th>
                    <th style={this.styles.tableHeader}>Closed</th>
                    <th style={{...this.styles.tableHeader, paddingRight: 0, width: '30px'}}/>
                </tr>
            );
        }
        
        let subjectText = invoice.subject;
        if (subjectText.length > 30) {
            subjectText = <StyledTooltip title={multilineJSX(subjectText, true)}>
                                <div>{subjectText.substring(0, 27) + "..."}</div>
                          </StyledTooltip>;
        }
         
        let customer;
        if (invoice.membershipID) {
            customer = <StyledTooltip title={"Membership: " + invoice.membershipID}>
                                <div>{invoice.customerEmail}</div>
                        </StyledTooltip>;
        }
        else 
            customer = <div>{invoice.customerEmail}</div>;
        
        
        let status;
        if (invoice.actionComment) {
             status = <StyledTooltip title={"Comment: " + invoice.actionComment}>
                                <div>{invoice.status.label}</div>
                        </StyledTooltip>;
        }
        else 
            status = <div>{invoice.status.label}</div>;
        
        
        const timeCol = (time) => <td style={this.styles.tableData}>
                                    <StyledTooltip title={PP.billingTimeFormat(time)}>
                                        <div>{PP.dateFormat(time)}</div>
                                    </StyledTooltip>
                                  </td>;
        
        
        //Render the invoice in a row
        return (
            <tr key={key} style={this.styles.tableStyle}>
                <td style={{...this.styles.tableData, textAlign: 'right'}}>{Currency.symbolForISO(invoice.currency) + Currency.round(invoice.total)}</td>
                <td style={this.styles.tableData}>
                    <div style={{...this.styles.status, backgroundColor: invoice.status.backgroundColor}}>
                        {status}
                    </div>
                </td>
                <td style={this.styles.tableData}>{invoice.number}</td>
                <td style={this.styles.tableData}>{customer}</td>
                <td style={this.styles.tableData}>{subjectText}</td>
                <td style={{...this.styles.tableData, textAlign: 'right', paddingRight: '30px'}}>{invoice.cycle}</td>
                {timeCol(invoice.dueDate)}
                {timeCol(invoice.createDate)}
                {invoice.closedDate ? timeCol(invoice.closedDate) : <td style={this.styles.tableData}/>}
                <td style={{...this.styles.tableData, paddingRight: 0}}>
                    <PopupMenu menuIcon={(<SettingsIcon style={{fontSize: '20', color: ThemeColors.settingsGray}}/>)}  
                                        menuItems={this._getInvoiceActionItems(invoice)} 
                                        menuTooltipText={"Actions"}/>
                </td>
            </tr>
        );
    }
    
    render() {
        
        const membershipWidget = <div style={{display: 'flex', alignItems: 'center'}}>      
                                    <Autocomplete
                                        size='small'
                                        value={this.state.selectedMembership}
                                        onChange={(event, newValue) => {this.setState({selectedMembership: newValue});}}
                                        options={this.state.memberships}
                                        getOptionLabel={(option) => option.id}
                                        fullWidth
                                        blurOnSelect
                                        openText="Memberships"
                                        renderInput={(params) => <TextField {...params} label="Membership ID" variant="outlined" InputLabelProps={{ shrink: true }} />}
                                    />
                                    <StyledTooltip title={membershipTooltip(this.state.selectedMembership)}>
                                        <Typography variant="body2" style={{fontSize: 16, fontWeight: 'bold', color: this.state.selectedMembership ? 'blue' : 'lightGray', marginLeft: 8}}>ⓘ</Typography>
                                    </StyledTooltip>    
                                </div>
                                
        const invoiceRequestWidget = <div style={{display: 'flex', alignItems: 'center'}}>      
                                        <Autocomplete
                                            size='small'
                                            value={this.state.selectedInvoiceRequest}
                                            onChange={this._selectNewInvoiceRequest}
                                            options={this.state.invoiceRequests}
                                            getOptionLabel={(option) => option.subject}
                                            fullWidth
                                            blurOnSelect
                                            openText="InvoiceRequests"
                                            renderInput={(params) => <TextField {...params} label="Invoice Request" variant="outlined" InputLabelProps={{ shrink: true }} />}
                                        />
                                        <StyledTooltip title={invoiceRequestTooltip(this.state.selectedInvoiceRequest)}>
                                            <Typography variant="body2" style={{fontSize: 16, fontWeight: 'bold', color: this.state.selectedInvoiceRequest ? 'blue' : 'lightGray', marginLeft: 8, marginRight: 8}}>ⓘ</Typography>
                                        </StyledTooltip>  
                                        <Tooltip title="The Billing Cycle number for the selected Invoice Request">
                                            <Autocomplete
                                                size='small'
                                                value={this.state.selectedCycle}
                                                onChange={(event, newValue) => {this.setState({selectedCycle: newValue});}}
                                                options={this.state.cycles}
                                                getOptionLabel={(option) => option}
                                                fullWidth
                                                style={{maxWidth: 100}}
                                                blurOnSelect
                                                openText="Cycle"
                                                renderInput={(params) => <TextField {...params} label="Cycle" variant="outlined" InputLabelProps={{ shrink: true }} />}
                                            />
                                        </Tooltip>
                                </div>
                                
                                
        const qr = this.state.invoiceQueryResponse;
        
        const showing = (qr && qr.invoices.length > 0) ? "Displaying " + (qr.firstIndex + 1) + "-" + (qr.firstIndex + qr.invoices.length) + " of " + qr.totalInvoices : null;            
            
        const hasNext = qr !== null && qr.firstIndex >= 0 && (qr.firstIndex + qr.invoices.length < qr.totalInvoices);
        const hasPrev = qr !== null && qr.firstIndex > 0;
 
        const counts = qr ? qr.counts() : null;
               
        const currencySymbol = Currency.symbolForISO(PP.currency); //whatever the database is - if there are mixed currencies, what can we do?
        
        let lastUpdateMessage = ""; 
        if (qr && qr.lastUpdate)        
            lastUpdateMessage = "Lastest Stripe activity occurred on " + PP.checkInTimeFormat(qr.lastUpdate);
        
        return (                        
             <div>
                {this.getConfirmAlertComponent()}
                
                      
                <TextEntryPopover isOpen={this.state.voidCommentOpen} showSkip={false} multiline={true} title="Void Comment/Reason" 
                                 okCallback={(text) => this._askVoidInvoice(text)} 
                                 cancelCallback={() => this.setState({voidCommentOpen: false, invoiceToVoid: null})}/>

                <TextEntryPopover isOpen={this.state.payCommentOpen} showSkip={false} multiline={true} title="Description of Outside Payment" 
                                 okCallback={(text) => this._askPayInvoice(text)} 
                                 cancelCallback={() => this.setState({payCommentOpen: false, invoiceToPay: null})}/>



                <Paper style={{marginBottom: 10}}>
                    <Typography variant="body2" style={this.styles.paperLabel}>Search Invoices</Typography>  
                    
                    <Grid container direction="row" spacing={5} style={{padding: 10}}>

                        <Grid item md={4} sm={6} xs={12}>   
                            {invoiceRequestWidget}
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>   
                            {membershipWidget}
                        </Grid>
                        
                        <Grid item lg={3} md={4} sm={12} xs={12}>
                         
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <Tooltip title="Export and Download Invoices to a .csv file">
                                   <Button fullWidth onClick={this._download} variant="outlined" style={{marginRight: 10, maxWidth: 200}} component="label" startIcon={<GetAppIcon />}>
                                       Download
                                   </Button>
                                </Tooltip>

                                <Tooltip title="Search and Display Invoices below">
                                   <Button fullWidth onClick={this._search} variant="outlined" color='primary' style={{marginLeft: 10, maxWidth: 200}} component="label" startIcon={<SearchIcon />}>
                                       Display
                                   </Button>
                                </Tooltip>
                            </div>
                            
                        </Grid>
                
                    </Grid>
                 </Paper>
                
                {this.state.isBusy ? this.getBusyComponent('center', {marginTop: 20}) : (<div style={{paddingBottom: 60}}/>)}

                <div style={{marginTop: 15}}/>
                
                {qr ?
                     <div>   
                     
                        <Grid container direction="row" spacing={2} style={{padding: 10}}>
                            <Grid item sm={4} xs={6}>
                                <SummaryWidget label={"Collected (" + counts.paid.count + ")"} 
                                        value={currencySymbol + Currency.round(counts.paid.total)}
                                        tooltip="The totals of collected invoices (paid and settled) on this page"
                                        borderColor={ThemeColors.depositGreen}/>
                            </Grid>                  
                            <Grid item sm={4} xs={6}>
                                <SummaryWidget label={"Unpaid (" + counts.open.count + ")"} 
                                        value={currencySymbol + Currency.round(counts.open.total)}
                                        tooltip="The totals of unpaid invoices (open and overdue) on this page"
                                        borderColor={ThemeColors.transactionBlue}/>
                            </Grid>         
                            <Grid item sm={4} xs={6}>
                                <SummaryWidget label={"Overdue (" + counts.overdue.count + ")"} 
                                        value={currencySymbol + Currency.round(counts.overdue.total)}
                                        tooltip="The totals of overdue invoices on this page"
                                        borderColor={ThemeColors.overdueOrange}/>
                            </Grid>
                        </Grid>
                     
                        <table style={this.styles.table}>
                            <thead>
                               {this._renderInvoiceRow(null, 0) /*render header*/ }
                            </thead>
                            <tbody>
                                {qr.invoices.map((invoice, index) => this._renderInvoiceRow(invoice, index+1))}
                            </tbody>
                        </table>
                        <div style={{width: '100%', display: 'flex', alignItems: 'center'}}>
                            <Typography variant="body2" style={{color: ThemeColors.darkGray}}>{showing}</Typography> 
                            <div style={{marginLeft: 'auto'}}>
                                {hasPrev ? <Button onClick={this._prev} variant="outlined" color='primary' component="label" >
                                               Prev
                                           </Button>
                                           : null}

                                {hasNext ? <Button onClick={this._next} variant="outlined" color='primary' style={{marginLeft: 10}} component="label" >
                                               Next
                                           </Button>
                                           : null}
                            </div>            
                        </div>
                        <Typography variant="body2" style={{marginTop: 15, color: ThemeColors.darkGray, fontStyle: 'italic'}}>{lastUpdateMessage}</Typography> 
                        

                    </div>
                    
                    
                : null}
                
                
            </div>
        );
        
    }
}



export default withCookies(InvoiceTab);

