import React from 'react';
import { withCookies } from 'react-cookie';

import { Paper, Tooltip, Typography, Button, Grid } from '@material-ui/core'

import { ThemeColors } from '../Theme'
import { PP } from '../models/PP'
import { Account } from '../models/Account'
import { Product } from '../models/Product'
import { Return } from '../models/Return'
import { Refund } from '../models/Refund'
import { Transaction, TransactionType } from '../models/Transaction'
import { TransactionFunctions } from '../utils/TransactionFunctions'
import  ReturnRefundPopover from '../components/ReturnRefundPopover'

import { RestComponent } from 'react-frontend-utils' 
import { DataTable, DataTableComponent, BasicTableToolbar } from 'react-frontend-utils'
import { ManageTextField, DateRangeSelector } from 'react-frontend-utils'
import { DateUtils, Currency, TextEntryPopover, SummaryWidget, Permissions } from 'react-frontend-utils'
import { multilineJSX, StyledTooltip } from 'react-frontend-utils'

import GetAppIcon from '@material-ui/icons/GetApp';
import SearchIcon from '@material-ui/icons/Search';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import ClearIcon from '@material-ui/icons/Clear';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';

/**
 * The Transactions tab shows a table of searched Transactions and allows transactions csv download.
 * One prop is passed:
 * 
 * onAccountSelect:  callback function that takes an account ID, to switch to the Account Tab
 * onProductSelect:  callback function that takes a product id, to switch to the Product Tab
 * selectedAccountFromAccounts: accountID to auto-populate in the account ID search field, when switching from Account Tab
 */

function linkButtonStringCellRenderer(val, onClick, maxChars = 8) {  //reduce text size and render in a tooltip instead
    
    if (!val)
        return <div style={{fontStyle: 'italic', color: 'gray', textAlign: 'center'}}>Deleted</div>;
                                 
    let shortText = val;
    if (shortText.length > maxChars)
        shortText = val.substring(0, maxChars) + "...";

    return (
       <StyledTooltip title={multilineJSX(val, true)}>
           <Button style={{textTransform: 'none', display: 'flex', margin: 'auto', textDecoration: 'underline', color: 'blue', fontWeight: 'normal', padding: 0}} 
                  onClick={() => onClick(val)}>{shortText}</Button>
       </StyledTooltip>
    ); 
}

export class TransactionTab extends RestComponent {
  
    //options for the time range pulldown
    _timeOptions = [
        {label: "Today", startTime: DateUtils.startOfToday().valueOf()},
        {label: "Last 2 days", startTime: DateUtils.startOfToday().valueOf() - (2 * DateUtils.MS_PER_DAY)},
        {label: "Last 7 days", startTime: DateUtils.startOfToday().valueOf() - (7 * DateUtils.MS_PER_DAY)},
        {label: "Last 30 days", startTime: DateUtils.startOfToday().valueOf() - (30 * DateUtils.MS_PER_DAY)},
        {label: "Last 60 days", startTime: DateUtils.startOfToday().valueOf() - (60 * DateUtils.MS_PER_DAY)}, 
        {label: "Last 90 days", startTime: DateUtils.startOfToday().valueOf() - (90 * DateUtils.MS_PER_DAY)},
        {label: "Month to Date", startTime: DateUtils.startOfMonth().valueOf()}, 
        {label: "Year to Date", startTime: DateUtils.startOfYear().valueOf()},
        {label: "Any", startTime: null}
    ];
    
    styles = {
        paper: {
            width: '100%',
            height: '100%'
        },
        paperLabel: {
            marginLeft: 15,
            marginRight: 15,
            marginBottom: 5,
            color: 'gray',
            fontSize: '9pt',
            flexGrow: 1
        },
        pendingLabel: {
            marginRight: 15,
            marginTop: -8,
            marginBottom: 5,
            color: 'gray',
            fontSize: '9pt',
            flexGrow: 1,
            textAlign: 'right',
            fontStyle: 'italic'
        }
    }
    
    transactionColumnStyle = (type) => {
        
        const baseStyle = {paddingLeft: 2, borderRadius: 2, textAlign: 'center'};
        
        switch (type) {
            case TransactionType.PEND_DEPOS:
            case TransactionType.PEND_PURCH:
            case TransactionType.PEND_REFUND:
                return {...baseStyle, fontStyle: 'italic', color: 'gray'};
            
            case TransactionType.DEPOSIT:
                return {...baseStyle, color: 'white', backgroundColor: ThemeColors.depositGreen};

            case TransactionType.PURCHASE:
            case TransactionType.REDEEM:
                return {...baseStyle, color: 'white', backgroundColor: ThemeColors.transactionBlue};
            
            case TransactionType.RETURN:
                return {...baseStyle, color: 'white', backgroundColor: ThemeColors.returnPink};
            case TransactionType.REFUND:
                return {...baseStyle, color: 'white', backgroundColor: ThemeColors.refundRed};
            
            case TransactionType.ADJUST_ADD:
            case TransactionType.ADJUST_SUB:
                return {...baseStyle, color: 'white', backgroundColor: ThemeColors.adjustPurple};
                
            case TransactionType.CANCELLED:
                return {...baseStyle, color: 'white', backgroundColor: ThemeColors.cancelGray};
            
            default:
                return baseStyle;
        }      
    }
  
  
    //An array describing each column

    columns = [
        //Date column - data is an object containing a Date
        //Sorting is done comparing the date
        //Rendering is the checkInTimeFormat applied to the date
        {name: "date", label: "Date/Time",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.firstCellProps,
                        customBodyRender: (val) => {const text = PP.checkInTimeFormat(val);
                                                    return (
                                                         <Tooltip title={PP.billingTimeFormat(val)}>
                                                            <div>{text}</div>
                                                        </Tooltip>                                              
                                                    ); 
                         }
                     }
        },  
        {name: "transactionType", label: "Type",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps, 
                        customBodyRender: (val) => {return <div style={this.transactionColumnStyle(val)}>{val.label}</div>;},
                        
                        sortCompare: (order) => {return (obj1, obj2) => {let p1 = obj1.data.label;
                                                                         let p2 = obj2.data.label;                                                                      
                                                                            return (p1 > p2 ? 1 : -1) * (order === 'asc' ? 1 : -1);
                                                                        };}
                     }
        },
        {name: "account", label: "Ticket",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps, customBodyRender: (val) => linkButtonStringCellRenderer(val, this._accountClicked)}               
        },      
        {name: "attendant", label: "Attendant",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps, customBodyRender: DataTable.longStringCellRenderer}
        },
        {name: "location", label: "Location",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps, customBodyRender: DataTable.longStringCellRenderer}
        },
        {name: "membershipID", label: "Membership ID",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps, customBodyRender: DataTable.longStringCellRenderer}
        },
        {name: "productRef", label: "Product ID",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps,
                      customBodyRender: (val) => {  if (!val || !val.hasProduct)
                                                        return null;
                                                    
                                                    return linkButtonStringCellRenderer(val.reference, this._productClicked, 12);
                                                  },
                      sortCompare: (order) => {return (obj1, obj2) => {let p1 = obj1.data;
                                                                       let p2 = obj2.data;  
                                                                                                                                  
                                                                       if (!p1.hasProduct)
                                                                           return -1 * (order === 'asc' ? 1 : -1);
                                                                       else if (!p2.hasProduct)
                                                                           return 1 * (order === 'asc' ? 1 : -1);

                                                                        return (p1.reference > p2.reference ? 1 : -1) * (order === 'asc' ? 1 : -1);
                                              };},
                      }              
        },
        {name: "productName", label: "Product Name",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps, customBodyRender: DataTable.longStringCellRenderer}
        },
        {name: "purchQuan", label: "Purchased",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps, customBodyRender: DataTable.longStringCellRenderer}
        },
        {name: "redeemQuan", label: "Redeemed",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps, customBodyRender: DataTable.longStringCellRenderer}
        },        
        {name: "price", label: "Price / Amount",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.lastCellProps,
                      customBodyRender: (val) => (<div style={{textAlign: 'right'}}>{val != null ? Currency.round(val) : null}</div>)}               
        },
        {name: "discount", label: "Discount",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.lastCellProps,                
                      customBodyRender: (val) => (<div style={{textAlign: 'right'}}>{val != null ? val.toFixed(1) + "%" : null}</div>)}               
        },
        {name: "tax", label: "Tax",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.lastCellProps,                   
                       customBodyRender: (val) => (<div style={{textAlign: 'right'}}>{val != null ? val.toFixed(2) + "%" : null}</div>)}               
        },
        {name: "balChange", label: "Funds Change",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.lastCellProps,              
                       customBodyRender: (val) => (<div style={{color: val < 0 ? 'red' : 'black', textAlign: 'right'}}>{Currency.round(val)}</div>)}               
        },       
        {name: "comment", label: "Comment",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.lastCellProps, customBodyRender: DataTable.longStringCellRenderer}                   
        }      
    ];


    _maxYear = (new Date()).getFullYear();  //this year
   
    _toolbarRef = React.createRef();
    _accountSearchFieldRef = React.createRef();
         
    _searchText = "";           //the currently displayed search  text
    _onAccountSelect = null;    //function to call when account is selected (from ManageMarketplacePage)
    _onProductSelect = null;    //function to call when product is selected (from ManageMarketplacePage)
 
    _accountClicked = (val) => {
        this._onAccountSelect(val);
    }
    
    _productClicked = (val) => {
        this._onProductSelect(val);
    }

    //Called by the Toolbar, to determine what color it should be (if no found records, go red
    searchFoundRecords = () => {
       if (this.state.tableData.length === 0) //no records loaded, don't color the text to indicate search failed
           return true; 
       
       return this.state.filteredTableData.length !== 0;
    }
    
    tableHasSelectedRows = () => {
        return this.state.selectedRow >= 0;
    }
    

    constructor(props) {
        super(props);
                
        this._onAccountSelect = props.onAccountSelect;
        this._onProductSelect = props.onProductSelect;
        
        this.state.transactions = [];
        this.state.tableData = [];         //all the table data
        this.state.filteredTableData = []; //filtered data from search, filters
        
        this.state.selectedRow = -1;
        
        this.state.startDateSearch = null;
        this.state.endDateSearch = null;
        this.state.productSearch = null;
        this.state.accountSearch = null;
        
        this.state.hasSearched = false;  //has executed search (display button) at least once
        
        this.state.returnPopupOpen = false;
        this.state.maxQuantityToReturn = 0;
        
        this.state.refundPopupOpen = false;
        this.state.maxRefundable = 0;
        
        this.state.totals =  {deposits: 0,                           
                                purchases: 0,
                                returns: 0,   
                                redeems: 0,
                                openRedeems: 0,
                                despositAmounts: 0,
                                returnAmounts: 0,                
                                sales: 0,
                                taxes: 0,
                                adjustments: 0,
                                balanceChanges: 0};
        
        this.state.tableOptions =  {onRowSelectionChange: ((currentRowsSelected) => this._rowClick(currentRowsSelected)),
                                     customToolbar: () => { return ( <BasicTableToolbar 
                                                                        ref={this._toolbarRef}
                                                                        onSearchChange={this.searchFieldChanged} 
                                                                        foundRecords={this.searchFoundRecords}                                                                                                                                               onCheckedInSwitchChanged={this.checkedInFieldChanged}
                                                                        onEnabledSwitchChanged={this.enabledSwitchFieldChanged}
                                                                        tableHasSelectedRows={this.tableHasSelectedRows}
                                                                        searchTooltip="Filter the table by any matching part of a Transaction's Ticket, Product Name, Attendant, Location, Membership ID, or Comment"
                                                                        hideEnabledSwitch={true}/> 
                                                                   ); 
                                                          },
                                    selectToolbarPlacement: 'none',
                                    setRowProps: (row, dataIndex, rowIndex) => this._setRowProps(row, dataIndex, rowIndex)
                                    };
        
        
    }
    
    componentDidMount() {
        super.componentDidMount();
        if (this.props.selectedAccountFromAccounts)
            this._accountSearchFieldRef.current.change(this.props.selectedAccountFromAccounts, true);  //fill in the Account field and commit it
    }
    
    //Callback when a row is clicked
    _rowClick = (currentRowsSelected) => {
     
        const row = currentRowsSelected.length > 0 ? currentRowsSelected[0].dataIndex : -1;
        
        if (row >= 0) {
            const date = this.state.filteredTableData[row].date;
            console.log("Product List: selected row: " + row + " Transaction on: " + date);
            this.setState({selectedRow: row});
        }
        else {
            this.setState( {selectedRow: row} );   
        }
    }
    
    //Called by the search field from the Custom toolbar, when the search field text changes
    searchFieldChanged = (searchText) => {
        this._searchText = searchText;
        this._filterTableData();
    }
    
 
    _validateSearch = () => {
        
        const start = this.state.startDateSearch ? new Date(this.state.startDateSearch) : null;
        const end = this.state.endDateSearch ? new Date(this.state.endDateSearch) : null;
        
        if (start && end && start > end) {
            this.showConfirmAlert("Error", "From Date is after To Date", 'red');
            return false;
        }
        if (this.state.accountSearch && this.state.accountSearch.length !== Account.ACCOUNT_NUMBER_LEN) {
            this.showConfirmAlert("Error", "Invalid Ticket Number", 'red');
            return false;
        }
        if (this.state.productSearch && this.state.productSearch.length > Product.MAX_PRODUCT_ID_LEN) {
            this.showConfirmAlert("Error", "Invalid Product ID", 'red');
            return false;
        }
        return true;       
    }
 
  
    _getSearchString = () => {
        let search = "";
        
        if (this.state.accountSearch)
            search += "account=" + this.state.accountSearch + "&";
        if (this.state.productSearch)
            search += "productID=" + this.state.productSearch + "&";
        if (this.state.startDateSearch)
            search += "fromDate=" + this.state.startDateSearch + "&";
        if (this.state.endDateSearch)
            search += "toDate=" + this.state.endDateSearch + "T23:59:59&";  //add time to the end of the day
        
        if (search)
            search = "?" + search.substring(0, search.length-1);  //remove trailing &
        
        return search;
    }
  
    
    _search = () => {
        if (!this._validateSearch())
            return;

        const search = this._getSearchString();
        
        this.incrementBusy();       
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/marketplace/transactions" + search, {},
                             this._searchResponse, this._fetchErrorCallback); 
   
    }
    
    
    _searchResponse = (response) => {
        if (response) {        
            this.state.transactions = response.map((transaction) => new Transaction(transaction));
            this._updateTableData();
            
            //Calculate Totals
            let totals = {deposits: 0,                           
                          purchases: 0,
                          returns: 0,   
                          redeems: 0,
                          openRedeems: 0,
                          despositAmounts: 0,
                          returnAmounts: 0,                
                          sales: 0,
                          taxes: 0,
                          adjustments: 0,
                          balanceChanges: 0};
                      
            this.state.transactions.forEach(t => {
                
                if (TransactionType.contributesToBalanceChange(t.transactionType))  //don't include pending charges
                    totals.balanceChanges += t.balChange;
                
                let calc;
                switch (t.transactionType) {
                    case TransactionType.PURCHASE:
                        totals.purchases += t.purchQuan;
                        calc = TransactionFunctions.calculateTransactionTotals(t.price, t.purchQuan, t.discount, t.tax);           
                        totals.sales += calc.total;
                        totals.taxes += calc.tax;
                        //no redeems - purchase and redeems either cancel (instant) or redeems are zero (purchase a redeemable)
                        
                        //an instant redeemable will cancel here, but purchase of a redeemable will increase open redeems
                        totals.openRedeems += t.purchQuan - t.redeemQuan;
                        break;
                    
                    case TransactionType.RETURN:
                        totals.returns++;
                        totals.purchases += t.purchQuan;
                        calc = TransactionFunctions.calculateTransactionTotals(t.price, t.purchQuan, t.discount, t.tax);  //note quantity negative here         
                        totals.returnAmounts += (-calc.total);  //total is negative, so we need to negate it
                        totals.taxes += calc.tax; //subtract tax returned from tax collected

                        //For instant redeemables, this are equal and cancel. For redeemable purchase return, this is positive (0 - -quan).  
                        //For returning the redeem transaction, this is negative (-quan - 0). 
                        const quanRedeemedCredit = t.redeemQuan - t.purchQuan;  
                        if (quanRedeemedCredit < 0) { //For redeem counts, we only care when it is negative.
                            totals.redeems += quanRedeemedCredit;
                        }

                        totals.openRedeems -= quanRedeemedCredit; //for purchase return, reduces the open redeemable (it was returned).  For redeem return, adds it back (its now available again)
                        break;
                    
                    case TransactionType.REDEEM:
                        totals.redeems += t.redeemQuan;
                        totals.openRedeems -= t.redeemQuan; //was used
                        break;
                    
                    case TransactionType.DEPOSIT:
                    case TransactionType.REFUND:
                        totals.deposits++;
                        totals.despositAmounts += t.price;
                        break;
                        
                    case TransactionType.ADJUST_ADD:
                    case TransactionType.ADJUST_SUB:
                        totals.adjustments += t.price;
                        break;
                        
                    default:
                        break;   
                } 
            });
            
            this.setState({totals: totals, hasSearched: true});
            
        }
        this.decrementBusy();
    }
    
    _download = () => {

        if (!this._validateSearch())
            return;

        const search = this._getSearchString();
        
        this.incrementBusy();
        const filename = "Transaction Export from " + PP.selectedDatabase + " on " + DateUtils.downloadTimeString() + ".csv";
        
        this.secureFileDownload("/ppcs/databases/" + PP.selectedDatabase + "/marketplace/transactions/download" + search, filename, this._downloadResponse, this._fetchErrorCallback); 
    }
    
    
    _downloadResponse = () => {
        this.decrementBusy();
        console.log("Download complete");
    }
    
    _setRowProps = (row, dataIndex, rowIndex) => {
        return DataTable.getRowStyle(this.state.selectedRow, dataIndex, rowIndex, ThemeColors.selectedColor, ThemeColors.tableAlternatingRowsGray);
    }
   
  
    
    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
    }
    
    
    
    _startRefund = () => {

        if (!this.tableHasSelectedRows())
            return;
    
        const row = this.state.filteredTableData[this.state.selectedRow];
        const type = row.transactionType;
        
        if (type !== TransactionType.DEPOSIT)
             return;
           
        if (row.account == null)  //must have an account
            return;
                
        this.incrementBusy();
     
        //fetch account, need balance
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/marketplace/accounts/" + row.account, {}, this._fetchAccountDetailCallback, this._fetchErrorCallback); 
    }
     
    _fetchAccountDetailCallback = (response) => {
        this.setBusy(false);

        if (response) {
            const row = this.state.filteredTableData[this.state.selectedRow];
            const detailedAccount = new Account(response);
            
            let maxRefundable = row.price;
            if (detailedAccount.balance < maxRefundable)
                maxRefundable = detailedAccount.balance;
            
            if (maxRefundable <= 0) {
                this.showConfirmAlert("Error", "There are no funds on the ticket to refund. Try returning purchases from this ticket first.", 'red');
                return;
            }
                
            this.setState({refundPopupOpen: true, maxRefundable: maxRefundable});
        }
        else
            this.showConfirmAlert("Error", "Failed to retrieve ticket for this transaction", 'red');

    }
    
    
    _continueRefund = (amount, comment) => {
        
        let amountToRefund = Currency.round(amount);
        this.setState({refundPopupOpen: false});
        const row = this.state.filteredTableData[this.state.selectedRow];
        
        //Extract the order number part from the comment (it follows the "Order " string)
        const orderNumber = row.comment.split(" ")[1];

        if (!orderNumber) {
            this.showConfirmAlert("Error", "No Order associated with this transaction", 'red');
            return;
        }
        
        const refund = new Refund(row.account, row.membershipID, orderNumber, amountToRefund, comment);
        
        const msg = Currency.symbolForISO(PP.currency) + amountToRefund + " will be deducted from Ticket and refunded to patron's card/bank.";
        
        this.showConfirmAlert("Confirm Refund Request", msg, 'black', "Cancel", () => this._doRefund(refund), "Refund", 'red');   
    } 
    
    
    _doRefund = (refund) => {
        
        this.incrementBusy();
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/marketplace/transactions/refund",
                             {method: "POST", body: JSON.stringify(refund)}, 
                             this._refundSuccessful, this._fetchErrorCallback);   
    }
    
    _refundSuccessful = () => {
        this.showConfirmAlert("Success", "Refund request was submitted. It may take several days for the patron to receive their funds. Refresh Transactions to view Refund.", 'green');
        this.decrementBusy(false);
    }

    
    _startReturn = () => {

        if (!this.tableHasSelectedRows())
            return;
    
        const row = this.state.filteredTableData[this.state.selectedRow];
        const type = row.transactionType;
        
        if (type !== TransactionType.PURCHASE && type !== TransactionType.REDEEM)
             return;
           
        if (row.account == null)  //must have an account
            return;
        
        
        let maxQuantityToReturn;
        if (row.transactionType === TransactionType.PURCHASE)
            maxQuantityToReturn = row.purchQuan;
        else //redeem
            maxQuantityToReturn = row.redeemQuan;

        this.setState({returnPopupOpen: true, maxQuantityToReturn: maxQuantityToReturn});
       
    }
        
    _continueReturn = (quantity, comment) => {
        this.setState({returnPopupOpen: false});

        const row = this.state.filteredTableData[this.state.selectedRow];
        this._fetchProductForReturn(row, quantity, comment);
    }    
        
        
    _fetchProductForReturn = (selectedRow, quantity, comment) => {
        this.incrementBusy();
        
        const productID = selectedRow.productRef.reference;
  
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/marketplace/products/",
                             {}, (response) => this._promptContinueReturn(response, selectedRow, quantity, comment), this._fetchErrorCallback,
                             encodeURIComponent(productID));     
  
    }
    
      
        
    _promptContinueReturn = (response, row, quantity, comment) => {
        
        this.decrementBusy();

        const productToReturn = response ? new Product(response) : null;

        if (productToReturn) {
            if (productToReturn.unlimitedRedeem() && row.transactionType === TransactionType.PURCHASE) {
                this.showConfirmAlert("Warning", '"' + productToReturn.name + '" is an unlimited redeemable product. Executing a return for this purchase will stop all open redemptions for this product ' +
                                     'even if it was previously purchased in the past. Proceed?',
                                     'black', "Cancel", () => this._promptFinishReturn(row, quantity, comment), "Return", 'red');  
            }
            else
                this._promptFinishReturn(row, quantity, comment);
        }
        else {
             this.showConfirmAlert("Confirm", "The product to be returned no longer exists. Continue with Return?",
                                     'black', "Cancel", () => this._promptFinishReturn(row, quantity, comment), "Return", 'red');  
        }
    }
     
   
    _promptFinishReturn = (row, quantity, comment) => {

        let purchQuan = 0;
        let redeemQuan = 0;
        if (row.purchQuan > 0 && row.redeemQuan === 0)  //return purchase of redeemable product
            purchQuan = quantity;
        else if (row.purchQuan === 0 && row.redeemQuan > 0) //return a redemption
            redeemQuan = quantity;
        else if (row.purchQuan === row.redeemQuan && row.purchQuan > 0) {  //return purchase of instant redeemable
            purchQuan = quantity;
            redeemQuan = quantity;
        }
        else {
            console.log("Quantity inconsistent, cannot process return");
            return;
        }
            

        //Start a new Return with the selected row information
        let theReturn = new Return(row.account, row.membershipID, row.productRef.reference, row.productName, purchQuan, redeemQuan,
                                   row.price, row.discount, row.tax, comment);
                          
        let returnMessage;
        let returnLabel;
        if (row.transactionType === TransactionType.PURCHASE) {
                                    
            const calc = TransactionFunctions.calculateTransactionTotals(row.price, purchQuan, row.discount, row.tax);           
            const totalReturned = Currency.round(Math.abs(calc.total));
            
            returnLabel = "Return Purchase?";
            returnMessage = "Quantity " + purchQuan + " of product \"" + row.productName + "\" will be returned and Ticket will be credited with " + Currency.symbolForISO(PP.currency) + totalReturned + ".";
        }
        else { //redeem
            returnLabel = "Return Redemption?";
            returnMessage = "Quantity " + redeemQuan + " of product \"" + row.productName + "\" will be credited back as a redeemable to Ticket.";
        }
        
        this.showConfirmAlert(returnLabel, returnMessage, 'black', "Cancel", () => this._doReturn(theReturn), "Return", 'red');   

    }
    
    _doReturn = (theReturn) => {
        
        this.incrementBusy();
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/marketplace/transactions/return",
                             {method: "POST", body: JSON.stringify(theReturn)}, 
                             this._returnSuccessful, this._fetchErrorCallback);   
            
    }
     
     
     _returnSuccessful = () => {
        this.showConfirmAlert("Success", "Return was Successful. Refresh Transactions to view Return.", 'green');
        this.decrementBusy(false);
    }
    
    
    
    _startCancellation = () => {
        
        if (!this.tableHasSelectedRows())
            return;
    
        const row = this.state.filteredTableData[this.state.selectedRow];
           
        if (!TransactionType.isCancellable(row.transactionType) || row.account == null || row.comment == null)
             return;
           
        
        //Extract the order number part from the comment (it follows the "Order " string)
        const orderNumber = row.comment.split(" ")[1];

        if (!orderNumber)
            return;
                
        this.showConfirmAlert("Confirm", 'Attempt to Cancel Order "' + orderNumber + '" for Ticket "' + row.account + '"? All pending deposit ' +
                              ' and purchase transactions associated with this order number will be cancelled.',
                              'black', "Cancel", () => this._continueCancel(orderNumber), "Proceed with Cancel", 'red');  
    }
    
    _continueCancel = (orderNumber) => {
        this.incrementBusy();
  
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/marketplace/transactions/cancel/" + orderNumber,
                             {method: "POST"}, 
                             this._cancelSuccessful, this._fetchErrorCallback);   
            
    }
     
     
     _cancelSuccessful = () => {
        this.showConfirmAlert("Success", "Cancellation was Successful. Refresh Transactions to view changes.", 'green');
        this.decrementBusy(false);
    }
    
    
    _inquire = () => {
        
       if (!this.tableHasSelectedRows())
            return;
    
        const row = this.state.filteredTableData[this.state.selectedRow];
 
        //Extract the order number part from the comment (it follows the "Order " string)
        const orderNumber = row.comment.split(" ")[1];

        if (!orderNumber) {
            this.showConfirmAlert("Error", "No Order associated with this transaction", 'red');
            return;
        }
        
        
        this.incrementBusy();
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/marketplace/transactions/inquire/",
                             {method: "POST"}, 
                             this._inquirySuccessful, this._fetchErrorCallback, encodeURIComponent(orderNumber));   
            
    }
    
    _inquirySuccessful = (response) => {
        
        if (response) {
            const messageComponent = <div>
                                        <div>
                                            <span style={{display: 'inline', fontWeight: 'bold'}}>Order: </span>
                                            <span style={{display: 'inline', }}>{response.order}</span>
                                        </div>
                                        <div>
                                            <span style={{display: 'inline', fontWeight: 'bold'}}>Payment Status: </span>
                                            <span style={{display: 'inline', }}>{response.status}</span>
                                        </div>
                                        <div>
                                            <span style={{display: 'inline', fontWeight: 'bold'}}>Funds Received: </span>
                                            <span style={{display: 'inline', }}>{response.funds}</span>
                                        </div>
                                        {response.error ? 
                                            <div style={{color: 'red'}}>
                                                <span style={{display: 'inline', fontWeight: 'bold'}}>Last Payment Error: </span>
                                                <span style={{display: 'inline', }}>{response.error}</span>
                                            </div> : null
                                        }

                                   </div>;


            this.showConfirmAlert("Inquiry Results" , null, 'green', "OK", null, null, 'black', messageComponent);
        }
        this.decrementBusy(false);
    }
    
    

    //Called when fetch of log entries are done, creates the table data, and sets filteredTableData equal to the table data
    _updateTableData = () => {
        
        const tableData = this.state.transactions.map((entry) => {
 
            //For each entry, create a row with all the column data
            return {date: entry.date,
                    transactionType: entry.transactionType,
                    account: entry.accountRef,
                    attendant : entry.attendant,
                    location: entry.location,
                    membershipID: entry.membershipID,
                    productRef: {hasProduct: TransactionType.hasProductAssociated(entry.transactionType), reference: entry.productRef},
                    productName: entry.productName,
                    purchQuan: entry.purchQuan,
                    redeemQuan: entry.redeemQuan,
                    price: entry.price,
                    discount: entry.discount,
                    tax: entry.tax,
                    balChange: entry.balChange,
                    comment: entry.comment 
                   }; 
         });
         
         
        //Clear table toolbar settings
        if (this._toolbarRef.current)
            this._toolbarRef.current.reset();


        this.setState({tableData: tableData, filteredTableData: tableData});  //set the data, and filtered data = data
      
    }


    //Creates filteredTableData by filtering the tableData
    _filterTableData = () => {
        
        const text = this._searchText.toLowerCase();
        
        if (text === 0) {
            this.setState({filteredTableData: this.state.tableData});
            return;
        }
        
        const filteredTableData = this.state.tableData.filter(row => { 
            
            //Matches
            const accountMatch = row.account.toLowerCase().includes(text);
            const productMatch = row.productName ? row.productName.toLowerCase().includes(text) : false;
            const membershipMatch = row.membershipID ? row.membershipID.toLowerCase().includes(text) : false;
            const attendantMatch = row.attendant ? row.attendant.toLowerCase().includes(text) : false;
            const locationMatch = row.location ? row.location.toLowerCase().includes(text) : false;
            const commentMatch = row.comment ? row.comment.toLowerCase().includes(text) : false;
                   
            return accountMatch || productMatch || membershipMatch || attendantMatch || locationMatch || commentMatch;
            
        });

        this.setState({filteredTableData: filteredTableData});
    }
    
    _dateChangeCallback = (start, end) => {
        this.setState({startDateSearch: start, endDateSearch: end});
    }
    
    
    _dateParseError = (error) => {
        this.showConfirmAlert("Error in Date Field", error, 'red');
    }
    
    
    render() {
        
        

        //One of the Totals squares
        const Widget = (props) =>  <SummaryWidget backgroundColor='#FCFCFC' 
                                                tooltip={props.tooltip} 
                                                label={props.label} 
                                                value={props.value}
                                                valueStyle={{color: props.isNegative ? 'red' : 'black'}}/>;
    
        let returnable = false;
        let cancellable = false;
        let inquirable = false;
        let refundable = false;
        if (this.tableHasSelectedRows()) {
            const row = this.state.filteredTableData[this.state.selectedRow];
            if (row) {
                if ((row.transactionType === TransactionType.PURCHASE || row.transactionType === TransactionType.REDEEM) && row.account)
                    returnable = true;

                if (TransactionType.isCancellable(row.transactionType))
                    cancellable = true;
                
                if (row.comment && row.comment.includes("Order"))
                    inquirable = true;
                
                if (row.transactionType === TransactionType.DEPOSIT && row.comment && row.comment.includes("Order"))
                    refundable = true;
            }
        }
        
        if (!PP.user.hasPermissionTo(Permissions.MARKETPLACE_REFUNDS)) {
            returnable = false;
            refundable = false;
        }
        if (!PP.user.hasPermissionTo(Permissions.MARKETPLACE_REFUNDS))
            cancellable = false;
        
        const currencySymbol = Currency.symbolForISO(PP.currency);
                
        return (
            <div>
                {this.getConfirmAlertComponent()}
                
                <ReturnRefundPopover isOpen={this.state.returnPopupOpen} forReturn={true} maxVal={this.state.maxQuantityToReturn}
                                     okCallback={this._continueReturn} cancelCallback={() => this.setState({returnPopupOpen: false})}/>
                                     
                <ReturnRefundPopover isOpen={this.state.refundPopupOpen} maxVal={this.state.maxRefundable}
                                     okCallback={this._continueRefund} cancelCallback={() => this.setState({refundPopupOpen: false})}/>                 
                               
                <TextEntryPopover isOpen={this.state.approveCommentOpen} showSkip={true} multiline={true} title="Approval Comment/Reason" 
                                 okCallback={(text) => this._startApprovalOrCancellation(true, text)} skipCallback={() => this._startApprovalOrCancellation(true)}  cancelCallback={() => this.setState({approveCommentOpen: false})}/>


                <TextEntryPopover isOpen={this.state.cancelCommentOpen} showSkip={true} multiline={true} title="Cancellation Comment/Reason" 
                                 okCallback={(text) => this._startApprovalOrCancellation(false, text)} skipCallback={() => this._startApprovalOrCancellation(false)}  cancelCallback={() => this.setState({cancelCommentOpen: false})}/>


                <Paper style={this.styles.paper}>
                    <Typography variant="body2" style={this.styles.paperLabel}>Search Transactions</Typography>  
                    
                    <Grid container direction="row" spacing={2} style={{padding: 10}}>

                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            
                            <DateRangeSelector calendarColor={ThemeColors.calendarColor}
                                               dateFormat={PP.getDateFormat()}
                                               timeOptions={this._timeOptions}
                                               minYear={2020}
                                               initialTimeRange="Any"
                                               onDateChange={this._dateChangeCallback}
                                               onParseError={this._dateParseError}/>
                            
                        </Grid>
                    

                        <Grid item lg={3} md={4} sm={6} xs={12}>
                        
                            <ManageTextField autoAccept={true} fullWidth={true} justify='left' label="Ticket"                           
                                            ref={this._accountSearchFieldRef}
                                            json="ticketSearchField"
                                            onFieldChange={(json, val) => this.setState({accountSearch: val})} changedBackgroundColor='white'/>
                        
                            <ManageTextField autoAccept={true} fullWidth={true} justify='left' label="Product ID"                           
                                            json="productIDSearchField"
                                            style={{marginTop: 15}}
                                            onFieldChange={(json, val) => this.setState({productSearch: val})} changedBackgroundColor='white'/>
                        </Grid>
  
                         <Grid item lg={3} md={4} sm={12} xs={12}>
                         
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <Tooltip title="Export and Download Transactions to a .csv file">
                                   <Button fullWidth onClick={this._download} variant="outlined" style={{marginRight: 10, maxWidth: 200}} component="label" startIcon={<GetAppIcon />}>
                                       Download
                                   </Button>
                                </Tooltip>

                                <Tooltip title="Search and Display Transactions below">
                                   <Button fullWidth onClick={this._search} variant="outlined" color='primary' style={{marginLeft: 10, maxWidth: 200}} component="label" startIcon={<SearchIcon />}>
                                       Display
                                   </Button>
                                </Tooltip>
                            </div>
                            
                        </Grid>
                
                    </Grid>
                 </Paper>
                
                {this.state.isBusy ? this.getBusyComponent('center', {marginTop: 20}) : (<div style={{paddingBottom: 60}}/>)}

                <div style={{marginTop: 10}}/>
                
                {this.state.hasSearched ? 
                <div>
                    <Paper style={this.styles.paper}>
                        <div style={{display: 'flex'}}>
                            <Typography variant="body2" style={this.styles.paperLabel}>Transaction Search Totals</Typography>  
                            <Typography variant="body2" style={{...this.styles.paperLabel, textAlign: 'right'}}>{"Amounts in " + PP.currency}</Typography>  
                        </div>
                        <Typography variant="body2" style={this.styles.pendingLabel}>Pending amounts not included</Typography>  

                        <Grid container direction="row" spacing={2} style={{padding: 10}}>

                            <Grid item md={2} sm={4} xs={6}>
                                <Widget label={"Sales (" + this.state.totals.purchases + ")"} 
                                        value={currencySymbol + Currency.round(this.state.totals.sales - this.state.totals.returnAmounts)}
                                        tooltip="The total number of Products purchased and the proceeds from those purchases, including taxes, less any returns"
                                        isNegative={this.state.totals.sales - this.state.totals.returnAmounts < 0}/>
                            </Grid>
                            <Grid item md={2} sm={4} xs={6}>
                                <Widget label={"Deposits (" + this.state.totals.deposits + ")"} 
                                        value={currencySymbol + Currency.round(this.state.totals.despositAmounts)}
                                        tooltip="The number of Deposits and the total amount of all Deposits made through the Patron Portal, less Refunds"
                                        isNegative={this.state.totals.despositAmounts < 0}/>
                            </Grid>
                            <Grid item md={2} sm={4} xs={6}>
                                <Widget label="Redemptions" value={this.state.totals.redeems} 
                                        tooltip="The number of Redeemable Products redeemed, less any redemptions returned"
                                        isNegative={this.state.totals.redeems < 0}/>
                            </Grid>
                            <Grid item md={2} sm={4} xs={6}>
                                <Widget label="Adjustments" value={currencySymbol + Currency.round(this.state.totals.adjustments)} 
                                        tooltip="Total of any manual adjustments (positive and negative)"
                                        isNegative={this.state.totals.adjustments < 0}/>
                            </Grid>
                            <Grid item md={2} sm={4} xs={6}>
                                <Widget label="Tax Collected" value={currencySymbol + Currency.round(this.state.totals.taxes)} 
                                        tooltip="Total amount of tax collected from all purchases, less any tax from returns"
                                        isNegative={this.state.totals.taxes < 0}/>
                            </Grid>
                             <Grid  item md={2} sm={4} xs={6}>
                                <Widget label="Outstanding Funds" value={currencySymbol + Currency.round(this.state.totals.balanceChanges)}
                                        tooltip={"Total change in the outstanding funds based on these Transactions.  Deposits, positive Adjustments, and Returns increase " +
                                        "the outstanding funds. Purchases, Refunds, and negative Adjustments decrease the funds. Redemptions do not affect them."}
                                        isNegative={this.state.totals.balanceChanges < 0}/>
                            </Grid>

                        </Grid> 
                     </Paper>

                    <div style={{marginTop: 10}}/>

                    <DataTableComponent title="" data={this.state.filteredTableData} columns={this.columns} options={this.state.tableOptions} flipToRowWidth={991} hoverColor={ThemeColors.tableHover}/>   

                    <div style={{display: 'flex'}}>
                        <Tooltip title="Return the Selected Transaction">
                            <Button fullWidth disabled={!returnable} onClick={this._startReturn} variant="outlined" color='secondary' style={{marginLeft: 10, marginTop: 20, maxWidth: 300}} component="label" startIcon={<AssignmentReturnIcon />}>
                                Return
                            </Button>
                        </Tooltip>
                        <Tooltip title="Refund the Deposit back to the patron">
                            <Button fullWidth disabled={!refundable} onClick={this._startRefund} variant="outlined" color='secondary' style={{marginLeft: 10, marginTop: 20, maxWidth: 300}} component="label" startIcon={<CreditCardIcon />}>
                                Request Refund
                            </Button>
                        </Tooltip>
                        <Tooltip title="Inquire details on the Order associated with the pending Transaction">
                            <Button fullWidth disabled={!inquirable} onClick={this._inquire} variant="outlined" color='primary' style={{marginLeft: 10, marginTop: 20, maxWidth: 300}} component="label" startIcon={<LiveHelpIcon />}>
                                Inquire
                            </Button>
                        </Tooltip>
                        <Tooltip title="Cancel the Order associated with the pending Transaction (all transactions with this Order are cancelled)">
                            <Button fullWidth disabled={!cancellable} onClick={this._startCancellation} variant="outlined" color='secondary' style={{marginLeft: 10, marginTop: 20, maxWidth: 300}} component="label" startIcon={<ClearIcon />}>
                                Cancel
                            </Button>
                        </Tooltip>
                    </div>
                    <div style={{marginTop: 10}}/>
                </div>
                : null}
                
            </div>

        );

    }
}


export default withCookies(TransactionTab);

