
import { Product } from './Product'
import { DateUtils } from 'react-frontend-utils'

export class Redeemable {
    
    productID;
    quantity;   //set to -1 when unlimited
    unavailableReason;
    secondsUntilExpire; //for unlimited, number of seconds until expires (if still available, otherwise null)

    constructor(productJson) {
        if (productJson) {
            this.productID = productJson.productID;
            this.quantity = productJson.quantity;
            this.unavailableReason = productJson.unavailableReason;
            this.secondsUntilExpire = productJson.secondsUntilExpire;
        }
    }
    
    //Create a new Redeemable suitable for sending to the backend
    static create(productID, quantity) {
        const r =  new Redeemable();
        r.productID = productID;
        r.quantity = quantity;
        r.unavailableReason = null;
        return r;
    }
    
    remainingString = () => {
        if (this.quantity > 0) {
            return this.quantity;
        }
        if (this.secondsUntilExpire == null && !this.unavailableReason)
            return "Unlimited";
        else if (this.secondsUntilExpire > 0)  {
            return "Unlimited (" + DateUtils.relativeTimeFormat(this.secondsUntilExpire / 60) + " remaining)";
        }
        else
            return "Unlimited (Expired)";
    }
    
    expiresTimeString = () => {
        if (this.quantity > 0) {  //does not expire
            return "";
        }
        if (this.secondsUntilExpire == null && !this.unavailableReason)  //never
            return "";
        else if (this.secondsUntilExpire > 0)  {
            return DateUtils.relativeTimeBriefFormat(this.secondsUntilExpire / 60);
        }
        else
            return "Expired";
    }
    
    
}



export class RedeemableProduct {
    
    redeemable;   //object of class Redeemable
    product;      //object of class Product
    
    constructor(redeemableProductJson) {
        this.redeemable = new Redeemable(redeemableProductJson.redeemable);
        
        if (redeemableProductJson.jsonProduct)
            this.product = new Product(redeemableProductJson.jsonProduct);
    }
}
