import { DateUtils } from 'react-frontend-utils'

import { RedeemableProduct } from './RedeemableProduct'


/**
 * This class represents a Account in the Marketplace, received from a JsonAccount
 */
export class Account {
    
    static ACCOUNT_NUMBER_LEN = 36;

    id;                                         //may not be null
    name;                                       //string
    email;                                      //string
    active;                                     //boolean
    hasPin;                                     //boolean
    createDate;                        
    
    //These are only populated when account inquriry is performed
    balance;
    pendingTransactions;
    redeemables;
    
    referDate;    
  
    lastTransaction;    

    constructor(accountJson) {
        
        if (accountJson) {
            this.id = accountJson.id;
            this.name = accountJson.name;
            this.email = accountJson.email;
            this.active = accountJson.active;
            this.hasPin = accountJson.hasPin;
            
            if (accountJson.createDate)
                this.createDate = DateUtils.parseJsonDate(accountJson.createDate, true); 
                  
            if (accountJson.redeemables) {
                this.redeemables = accountJson.redeemables.map(r => new RedeemableProduct(r));
                this.balance = accountJson.balance;
                this.pendingTransactions = accountJson.pendingTransactions;
            } 
                
            if (accountJson.referDate)
                this.referDate = DateUtils.parseJsonDate(accountJson.referDate, true); 
   
            this.lastTransaction = accountJson.lastTransaction;
        }       
    }
    
    
}


export class AccountRequest {
    
    name;
    email;
    pin;
    
    constructor(name, email, pin) {
       this.name = name;
       this.email = email;
       this.pin = pin;
    }

}



export const PublicArrayStatus = ["AVAILABLE", "NOT_FOUND", "LINK_EXPIRED", "DISABLED"];


export class PublicAccount {
    
     id;
     name;
     email;
     status;
     
    constructor(accountJson) {
        
        if (accountJson) {
            this.id = accountJson.id;
            this.name = accountJson.name;
            this.email = accountJson.email;
            if (accountJson.status != null)
                this.status = PublicArrayStatus[accountJson.status];
        }
    }
}