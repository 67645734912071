import React, { Component } from 'react';

import { Container, Popover, Typography, Button, IconButton, Paper, Tooltip, Grid} from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel';

import { PP } from '../models/PP'

import MemberListEntry, { MemberListModes } from '../components/MemberListEntry'


export default class MultiMemberCheckInSelect extends Component {

    styles = {
        paperLabel: {
            color: 'blue',
            fontSize: '12pt',
            flexGrow: 1,
            marginLeft: 10
        }
    };

    _onClose;
    _onAction;
    _membersSelected = new Set();
    _defaultSelectedMember = undefined;
    
    constructor(props) {
        super(props);
        this._onAction = props.onAction;
        this._onClose = props.onClose;
    }
   
    _selectMember = (index, member, isSelected) => {
        if (isSelected) 
            this._membersSelected.add(member.id);
        else
            this._membersSelected.delete(member.id);
        
        this.forceUpdate();
    }
   
    _closeButtonPressed = () => {
        this._onClose();
        this._membersSelected = new Set();  //clear all for next time
        this._defaultSelectedMember = undefined;
        this.forceUpdate();
    }
    
    _doAction = () => {
        this._onAction(this._membersSelected);
        this._membersSelected = new Set();  //clear all for next time
        this._defaultSelectedMember = undefined;
        this.forceUpdate();
    }
    
    _selectAll = () => {
        this.props.members.forEach(member => {
          
            switch (this.props.mode) {
                case MemberListModes.CHECKIN:
                    if (!member.suspended && member.entryPasses !== 0)  //ok to add, can check in
                        this._membersSelected.add(member.id);
                    break;
                    
                case MemberListModes.CHECKOUT:
                    if (member.isCheckedIn)
                        this._membersSelected.add(member.id);
                    break;
                    
                default:
                    console.log("Invalid mode");
            }
            
            this.forceUpdate(); 
        });
    }    
        
    
    render() {
        
        if (!this.props.isOpen)
            return null;
        
       
        if (this._defaultSelectedMember === undefined && this.props.selectedMember) {
            this._defaultSelectedMember = this.props.selectedMember;
            console.log("Default selected: " + this._defaultSelectedMember.name());
            this._membersSelected = new Set();  //clear

            //For checkin, only default select this member if they have entry passes.  Always add them for check-out
            if (this.props.mode === MemberListModes.CHECKOUT || (this.props.mode === MemberListModes.CHECKIN && this._defaultSelectedMember.entryPasses !== 0)) {    
                this._membersSelected.add(this._defaultSelectedMember.id);                     
                console.log("Adding ID to set: " + this._defaultSelectedMember.id)
            }
        }
        
        const numMembersSelected = this._membersSelected.size;
        
        let title;
        let titleColor;
        let actionButtonPrefix;
        switch (this.props.mode) {
       
            case MemberListModes.CHECKIN:
                title = "Check In Members";
                titleColor = 'green';
                actionButtonPrefix = "Check In ";
                break;

            case MemberListModes.CHECKOUT:
                title = "Check Out Members";
                titleColor = 'blue';
                actionButtonPrefix = "Check Out ";
                break;

            default:
                
        }
        
        const actionButtonLabel= actionButtonPrefix + numMembersSelected + " Member" + (numMembersSelected === 1 ? "" : "s");


        let width = window.innerWidth * (this.props.isMobile ? 0.95 : 0.8);  //98% when small, otherwise 80% the window

        const passesLabel = this.props.mode === MemberListModes.CHECKIN ? (this.props.isMobile ? "Passes" : "Entry Passes") : "";

        return (

            <Popover open={this.props.isOpen} 
                anchorReference="anchorPosition"
                anchorPosition={{left: window.innerWidth/2, top: window.innerHeight/2}}
                transformOrigin={{vertical: 'center', horizontal: 'center'}} >
                

                <Container style={{marginBottom: 20, width: width}}>
                           
                    <div style={{display: 'flex', justifyContent: 'spaceBetween', marginBottom: 10}}>
                        <Typography variant="h6" style={{color: titleColor}}>{title}</Typography>   
                        <Tooltip title="Close">
                            <IconButton edge="end"onClick={this._closeButtonPressed} style={{marginLeft: 'auto', marginTop: -6}}>
                                <CancelIcon />
                            </IconButton>
                        </Tooltip>   
                    </div>
   
                    <Paper>                           
                        <div style={{minHeight: 100}}>

                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <Typography variant="body2" style={this.styles.paperLabel}>{"Membership: " + this.props.membership.id}</Typography> 
                                <Button onClick={this._selectAll} style={{marginTop: -6, color: 'blue', textDecoration: 'underline', textTransform: 'capitalize'}}>Select All</Button>
                            </div>
                            
                            <div style={{margin: 15}}/>
                            
                             <Grid container direction="row" justify='center' alignItems='center' spacing={this.props.isVerySmall ? 1 : 2}>

  
                                <Grid item xs={this.props.isMobile ? 3 : 2}>    
                                </Grid>
  
                                <Grid item xs={3}>    
                                    <Typography variant="body2" style={{marginLeft: 10}}>Member</Typography>                                          
                                </Grid>
                                
                                <Grid item xs={this.props.isMobile ? 2 : 3}>
                                    {PP.notesHidden() ? null :
                                        <Typography variant="body2">Notes</Typography>   
                                    }
                                </Grid>


                                <Grid item xs={2}>
                                    <Typography variant="body2">Status</Typography>                                           
                                </Grid>


                                <Grid item xs={1}>             
                                    <Typography variant="body2">{passesLabel}</Typography>                                           
                                </Grid>

                                <Grid item xs={1}>
                                </Grid>


                            </Grid>
   
              
                            {this.props.members.map((member, index) => 
                                <MemberListEntry key={index} member={member} index={index} isSelected={this._membersSelected.has(member.id)}
                                                  select={this._selectMember} mode={this.props.mode}
                                                  isMobile={this.props.isMobile} isVerySmall={this.props.isVerySmall}/>)}

                        </div>
                    </Paper>  
                    
                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: 10, marginTop: 20}}>
                        <Button disabled={numMembersSelected === 0} onClick={this._doAction} fullWidth variant="contained" 
                                 color="primary" style={{color: 'white', maxWidth: 300}}>{actionButtonLabel}</Button>
                    </div>

                </Container>

            </Popover>
        );
        
        
    }


}
