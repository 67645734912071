import { withCookies } from 'react-cookie';

import { AbstractStatisticsTab } from './AbstractStatisticsTab';
import { CheckInStat } from '../../models/CheckInStat'

import { ThemeColors } from '../../Theme'

import { PP } from '../../models/PP'
import { DateUtils } from 'react-frontend-utils'

export class CheckInStatsTab extends AbstractStatisticsTab {
  
    
  
    _options = {
      
        chart: {...this._standardChartOptions, animations: {enabled: false}},
        colors: [ThemeColors.memberRed, ThemeColors.guestBlue, ThemeColors.patronGreen], 
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "90%"
            }
        },
        dataLabels: {
            enabled: false         
        },
        xaxis: {
            type: 'datetime',
            labels: {
                datetimeUTC: false,
                datetimeFormatter: this._datetimeFormatter()
            }
        },
        
        tooltip: {
            x: {
                format: this._dateFormat()
            },
            y: {
                formatter: (value) => { return value.toFixed(0); }  //Format as integer
            }
        }
    }

    constructor(props) {
        super(props); 
        this._chartOptions = this._options;
        this._chartType = "bar";
        this._chartFetchPath = "/ppcs/databases/" + PP.selectedDatabase + "/stats/checkins";
        this._chartDataHandlerCallback = this._processChartData;
        this._hasLocation = true;
        this._hasAggregate = true;
        this._hasTime = true;
        this._hasTimeRange = true;
        this._hasDownload = true;
    }
    
    _processChartData = (response) => {
        
        const checkInData = response.map(stat => new CheckInStat(stat));
        
        if (checkInData.length === 0) {  //no data
            this.setState({chartData: null});
            return;
        }
        
        const memberSeriesData = checkInData.map(stat => [stat.time, stat.members]);
        const guestSeriesData = checkInData.map(stat => [stat.time, stat.guests]);
        const patronSeriesData = checkInData.map(stat => [stat.time, stat.patrons]);
        
        const series = [
            {
              name: "Members",
              data: memberSeriesData
            },
            {
              name: "Guests",
              data: guestSeriesData
            },
            {
              name: "Tickets",
              data: patronSeriesData
            }
        ];
      
        this.setState({chartData: series});
    }
    
    _download = () => {
                
        let query = this._locationParam(this.state.selectedLocation);

        if (query)
            query += "&" + this._intervalParam(this.state.selectedAggregate);  //append
        else
            query = "?" + this._intervalParam(this.state.selectedAggregate);  //set
          
        
        const filename = "Check In Statistics at " + this.state.selectedLocation + " from " + PP.selectedDatabase + " on " + DateUtils.downloadTimeString() + ".csv";
        
        this.secureFileDownload("/ppcs/databases/" + PP.selectedDatabase + "/stats/checkins/download", filename, null, null, query); 
    
    }
    
}
   
export default withCookies(CheckInStatsTab);