import React, { Component } from 'react';
import { Container, Typography, FormControl, RadioGroup, Radio, FormControlLabel } from '@material-ui/core'

    
/**
 *  The SwitchDatabasePage provides a radio-button list of available databases to select from. 
 *  
 *  The parent component MUST pass in a prop called selectDatabaseCallback which is a function that takes a parameter which is a String value
 *  of the database name selected.  The callback is invoked with the user selects one of databases
 * 
 *  The parent component MUST pass in a prop called availableDatabases which is an array of Strings with the database names. 
 *  
 *  The parent component MUST pass in a prop called selectedDatabase which is the String of the currently selected database
 * 
 */
    
    

export class SwitchDatabasePage extends Component {
  
  
    styles = {
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
        }
    }
  
  
    state = {
        selectedDatabase: undefined
    }

    constructor(props) {
        super(props);
        this.state.selectedDatabase = props.selectedDatabase;
    }


    /**
     * Callback when new radio button is selected. Set the current state of the radio button group selection, and callback to the parent
     * to tell them the database name has changed
     * @param {Object} event that fired the change 
     */
    _handleSelectDatabase = (event) => {
        const databaseName = event.target.value;
        this.setState({selectedDatabase: databaseName});
        this.props.selectDatabaseCallback(databaseName);
    };
    
    render() {
        
        return (
            
            <Container >

                    <Typography variant="h5" style={{textAlign: 'center', paddingBottom: 20}}>Select Working Database</Typography>
                    <Container style={this.styles.container}>
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="database" name="selectedDatabase" value={this.state.selectedDatabase} onChange={this._handleSelectDatabase}>

                              {this.props.availableDatabases.map((database) =>                      
                                 database !== "admin" ? (<FormControlLabel key={database} value={database} control={<Radio color="primary" />} label={database} />) : null
                              )}


                            </RadioGroup>
                        </FormControl>
                    </Container>

            </Container>
        );

    }
    
}