import React from 'react';
import { withCookies } from 'react-cookie';

import { Container, Popover, Button, TextField, Box, Typography, Tooltip } from '@material-ui/core'

import { RestComponent } from 'react-frontend-utils'

import { PP } from '../models/PP'
import { Membership } from '../models/Membership'


export class NewMembershipPopover extends RestComponent {

    styles = {
        button: {
            marginTop: 10,
            marginLeft: 10,
            marginRight: 10
        },
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
    };

    _showSkip;
    _okCallback;
    _skipCallback;
    _cancelCallback;
    _maxChars;
    
    state = {
        textEntry: ""
    }

    constructor(props) {
        super(props);
        this._showSkip = props.showSkip;
        this._okCallback = props.okCallback;
        this._skipCallback = props.skipCallback;
        this._cancelCallback = props.cancelCallback;
        this._maxChars = props.maxChars ? props.maxChars : 1024;
    }
   
    _textFieldChanged = (event) => {
        if (this.state.textEntry.length >= this._maxChars)
            return;
        
        this.setState({textEntry: event.target.value});
    }
    
    
    _okButtonPressed = () => {
        this._okCallback(this.state.textEntry);
        this.setState({textEntry: ""});  //clear text for next time
    }
    
    
    _cancelButtonPressed = () => {
        this._cancelCallback();
        this.setState({textEntry: ""});  //clear text for next time
    }
    
    _suggestButtonPressed = () => {
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/memberships", {}, this._fetchMembershipsCallback, this._fetchErrorCallback); 
    }
    
    _fetchMembershipsCallback = (response) => {
        if (response) {            
            let memberships = response.map((membership) => new Membership(membership));
            memberships = memberships.filter(membership => membership.isIdNumeric());     // remove non-numeric entries

            let next = 1;
            if (memberships.length !== 0) {
                memberships.sort((a, b) => parseInt(b.id) - parseInt(a.id));      //sort descending
                next = parseInt(memberships[0].id) + 1;     // pick the largest, and add 1
            }

            this.setState({textEntry: next.toString().padStart(8, "0")});
        }
    }
    
    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
    }
    
    render() {
        
        let maxWidth = window.innerWidth * 0.66;  //two thirds the window, up to 650
        if (maxWidth > 650)
            maxWidth = 650;
        
        let open = false;
        if (this.props.isOpen === true)
            open = true;
        
        
        return (
            <Popover open={open} 
                anchorReference="anchorPosition"
                anchorPosition={{left: window.innerWidth/2, top: window.innerHeight/2}}
                transformOrigin={{vertical: 'center', horizontal: 'center'}} >
                <Box style={{margin: 20, width: maxWidth}}>
                    <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>New Membership ID</Typography>   
                    <TextField autoFocus value={this.state.textEntry} onChange={this._textFieldChanged} variant="outlined" fullWidth={true} inputProps={{maxLength: this._maxChars}} InputLabelProps={{ shrink: true}} />
                    <Container style={this.styles.container}>    
                        <Button onClick={this._okButtonPressed} color="primary" variant="outlined" style={this.styles.button}>OK</Button>
                        <Button onClick={this._cancelButtonPressed} variant="outlined" style={this.styles.button}>Cancel</Button>
                        <Tooltip title="Suggest the next largest numeric entry among all Membership IDs. Membership IDs with non-numeric characters are excluded.">
                            <Button onClick={this._suggestButtonPressed} color="primary" variant="outlined" style={this.styles.button}>Suggest</Button>
                        </Tooltip>
                    </Container>
                </Box>
            </Popover>
        );
        
        
    }


}


export default withCookies(NewMembershipPopover);