import React from 'react';

import { AppBar, Toolbar, Typography, IconButton, Tooltip } from '@material-ui/core'


import { logo } from '../utils/Image'
import { OpenInNewTab } from '../App'
import HomeIcon from '@material-ui/icons/Home';

import { ThemeColors } from '../Theme'

export function PatronAppBar(props) {
           
    const gutterMargin = props.isMobile ? 8 : 20;
          
    const styles = {
        appTitle: {
           marginLeft: 10,
           transform: "skewX(-10deg)",
           textShadow: "2px 2px #333333",
           fontWeight: "bold",
           fontSize: "200%",
           flexGrow: 0   //fill all space to push other elements to the right edge
        },
        mainTitle: {
           textShadow: "2px 2px #333333",
           fontWeight: "bold",
           fontSize: "160%",
           textAlign: 'right',
           flexGrow: 1   //fill all space to push other elements to the right edge
        }
    };
           
    return (
        <AppBar position="static" style={{marginBottom: 12, backgroundColor: ThemeColors.appBarBackground}}>
            <div style={{paddingTop: 0, paddingBottom: 4, paddingLeft: gutterMargin, paddingRight: gutterMargin}}>
                <Toolbar disableGutters={true}>

                    {logo}
                    <Typography variant="h5" style={styles.appTitle}>Marketplace</Typography>
                    <Typography variant="h5" style={styles.mainTitle}>Patron Portal</Typography>
                </Toolbar>

            </div>
        </AppBar>
    );
}


export function DatabaseHeader(props) {
    
    const database = props.database;
    const name = database.extendedName ? database.extendedName : database.name;
    
    let marketURL = database.marketURL;
    if (marketURL && !marketURL.startsWith("http://") && !marketURL.startsWith("https://"))
        marketURL = "http://" + marketURL;
    
    return (
        <div>
            {props.showHomeLink && marketURL ?
                <Tooltip title={"Go to Homepage for " + name}>
                    <IconButton style={{float: 'right'}} onClick={() => OpenInNewTab(marketURL)}>
                        <HomeIcon/>
                    </IconButton>
                </Tooltip> : null
            }
            <Typography variant="h5">{name}</Typography>
            <Typography variant="body2">{database.address}</Typography>
        </div>
    );
}
