import React from 'react';
import ReactDOM from 'react-dom';
import "react-datepicker/dist/react-datepicker.css";  
import './index.css';   //After react-datepicker .css to override
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  document.getElementById('root')
);

