import React, { Fragment, Component } from 'react';
import { withCookies } from 'react-cookie';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import { Paper, Tab, Tabs } from '@material-ui/core'
import CheckInStatsTab from './statistics/CheckInStatsTab'
import MemberUseStatsTab from './statistics/MemberUseStatsTab'
import GuestUseStatsTab from './statistics/GuestUseStatsTab'
import MembershipStatsTab from './statistics/MembershipStatsTab'
import OccupancyStatsTab from './statistics/OccupancyStatsTab'
import MembershipTypesStatsTab from './statistics/MembershipTypesStatsTab'

const LARGE_WIDTH = 1400;
const MOBILE_WIDTH = 1200;
const VERY_SMALL = 768;
const EXTREMELY_SMALL = 580;

const TabPages = {
    CHECKINS:    {tabIndex: 0, text: "Check Ins",               collapseText: null,         collapseAt: 0},
    MEMBERSHIPS: {tabIndex: 1, text: "Membership History",      collapseText: "History",    collapseAt: MOBILE_WIDTH},
    TYPES:       {tabIndex: 2, text: "Membership Types",        collapseText: "Types",      collapseAt: MOBILE_WIDTH},
    MEMBER_USE:  {tabIndex: 3, text: "Member Use Frequency",    collapseText: "Member Use", collapseAt: LARGE_WIDTH},
    GUEST_USE:   {tabIndex: 4, text: "Guest Use Frequency",     collapseText: "Guest Use",  collapseAt: LARGE_WIDTH},
    OCCUPANCY:   {tabIndex: 5, text: "Occupancy",               collapseText: "Occup",      collapseAt: 436}
};


Object.freeze(TabPages);

export class StatisticsTab extends Component {
  
    state = {
        isWide: false,
        selectedTab: TabPages.CHECKINS.tabIndex
    }

    componentDidMount() {
        this._updateSize();
        window.addEventListener("resize", this._updateSize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this._updateSize);
    }


    _updateSize = () => {
        this.setState({ windowWidth: window.innerWidth, isMobile: window.innerWidth < MOBILE_WIDTH }); 
    }

    _switchTab = (event, newValue) => {
        this.setState({selectedTab: newValue});
    }
    
 
    
    render() {
        
        //Override breakpoints for tabs in order for the reponsiveness to improve (collapses space between at "sm" size)
        const tabThemeOverride = createMuiTheme({
            breakpoints: {
              values: {
                xs: 0,
                sm: MOBILE_WIDTH,
                md: 1280,
                lg: 1280,
                xl: 1920
              }
            }
        });         
        
        
        //Selects the current tab to view
        const viewingTab = (() => {
            
            switch (this.state.selectedTab) {

                case TabPages.CHECKINS.tabIndex:   
                    return <CheckInStatsTab />;
                           
                case TabPages.MEMBER_USE.tabIndex:
                    return <MemberUseStatsTab/>;
                    
                case TabPages.GUEST_USE.tabIndex:
                    return <GuestUseStatsTab/>;
                    
                case TabPages.MEMBERSHIPS.tabIndex:
                    return <MembershipStatsTab/>;
                    
                case TabPages.TYPES.tabIndex:
                    return <MembershipTypesStatsTab/>;
                    
                case TabPages.OCCUPANCY.tabIndex:
                    return <OccupancyStatsTab/>;
                
                default:
                    return <div>Tab Not Found</div>;

            }
        })();
        
        //Creates the text label for the tab, shrinking font and collapsing to abbreviated text as necessary
        //to fit the width of the screen
        const tabLabel = (tab) => {
            let fontSize = 14;
            if (this.state.windowWidth < VERY_SMALL)
                fontSize -= 1; 

            if (this.state.windowWidth < EXTREMELY_SMALL)
                fontSize -= 1;

            let text = this.state.windowWidth < tab.collapseAt ? tab.collapseText : tab.text;

            const split = text.split(" ");
            
            if (this.state.windowWidth < EXTREMELY_SMALL && split.length > 1)     
                return (<span style={{fontSize: fontSize}}>{split[0]}<div/>{split[1]}</span>);
            else
                return (<span style={{fontSize: fontSize}}>{text}</span>);

        };

        const style = this.state.windowWidth < EXTREMELY_SMALL ? {minWidth: 50} : {};
        
        
        const tabsArray = [];
        for (const index in TabPages) {
            const tab = TabPages[index];
            tabsArray.push(<Tab key={tab.tabIndex} style={style} value={tab.tabIndex} label={tabLabel(tab)}/>);
        }
        
        return (
            
            <Fragment >

                <Paper>
                    <MuiThemeProvider theme={tabThemeOverride}>
                    <Tabs value={this.state.selectedTab} onChange={this._switchTab} indicatorColor="primary" textColor="primary" variant='fullWidth' >
                        
                        {tabsArray}
                    
                    </Tabs>
                    </MuiThemeProvider>
                </Paper>

                <div style={{padding: 10}}/>
           
                <div>
                    {viewingTab} 
                </div>

            </Fragment>
        );

    }
    
};


export default withCookies(StatisticsTab);