import { DateUtils } from 'react-frontend-utils'


export class DailyStat {
    date;     
    
    deposits;
    purchases;               //less returns (taxes not included)
    adjustments;
    redemptions;             //less returns
    
    constructor(userJson) {
        this.date = DateUtils.parseJsonDate(userJson.date);         
        this.deposits = userJson.deposits;
        this.purchases = userJson.purchases;
        this.adjustments = userJson.adjustments;
        this.redemptions = userJson.redemptions;
    }
}



export class MarketAnalysis {
    
    productsSold;            //array of Products Sold: {name: String, id: String, info: String, category: String, quantity: Number}
    dailyStats;     

    constructor(userJson) {
        
        if (userJson) {
            this.productsSold = userJson.productsSold;
            this.dailyStats = userJson.dailyStats.map(stat => new DailyStat(stat));
        }
    }
    
    
}

   