import { DateUtils } from 'react-frontend-utils'
import { ThemeColors } from '../Theme'

export class InvoiceQueryResponse {
    
    totalInvoices;
    lastUpdate;
    
    invoices;       //array of Invoice
    
    firstIndex;
    
    constructor(json) {
        if (json) {
            this.totalInvoices = json.totalInvoices;
            
            if (json.lastUpdate != null) 
                this.lastUpdate = DateUtils.parseJsonDate(json.lastUpdate, true); 
            
            if (json.invoices)
                this.invoices = json.invoices.map(invoice => new Invoice(invoice));
            else
                this.invoices = [];
            
            this.firstIndex = json.firstIndex; 
        }  
    } 
    
    counts = () => {
        
        let counts = {paid: {count: 0, total: 0},
                      open: {count: 0, total: 0},
                      overdue: {count: 0, total: 0}
                      };
        
        for (let invoice of this.invoices) {
            switch (invoice.status) {
                case InvoiceStatus.OVERDUE:
                    counts.overdue.count++;
                    counts.overdue.total += invoice.total;
                    //fallthrough
                case InvoiceStatus.OPEN:
                case InvoiceStatus.UNCOLLECTABLE:
                    counts.open.count++;
                    counts.open.total += invoice.total;
                    break;
                    
                case InvoiceStatus.PAID:
                case InvoiceStatus.SETTLED:
                    counts.paid.count++;
                    counts.paid.total += invoice.total;
                    break;
                default:
                    break;
            }
        }
        
        return counts;
    }
    
    
}



export const InvoiceStatus = {
    DRAFT:           {ordinal: 0, label: "Draft", backgroundColor: ThemeColors.cancelGray},  //should never see this
    OPEN:            {ordinal: 1, label: "Open", backgroundColor: ThemeColors.transactionBlue},
    PAID:            {ordinal: 2, label: "Paid", backgroundColor: ThemeColors.depositGreen},
    UNCOLLECTABLE:   {ordinal: 3, label: "Uncollectable", backgroundColor: ThemeColors.cancelGray}, //we don't allow this
    VOID:            {ordinal: 4, label: "Void", backgroundColor: ThemeColors.cancelGray},
    OVERDUE:         {ordinal: 5, label: "Overdue", backgroundColor: ThemeColors.overdueOrange},  
    SETTLED:         {ordinal: 6, label: "Settled", backgroundColor: ThemeColors.settledPurple},
    
    canCancelOrPay: (status) => {
        return status === InvoiceStatus.OVERDUE || status === InvoiceStatus.OPEN;
    }
    
};

export class Invoice {
    
    id   
    number;             //Stripe Invoice number
    membershipID;       //Can be null for "OTHER"
    customerEmail;      
    
    requestID;
    cycle;
    
    subject;
    total;
    currency;
    createDate;
    dueDate;
    closedDate;
    pdfUrl;
    payUrl;
    actionComment;
    
    status;

    constructor(json) {
        if (json) {
            
            this.id = json.id;
            this.number = json.number;
            this.membershipID = json.membershipID;
            this.customerEmail = json.customerEmail;
            this.subject = json.subject;
            this.total = json.total;
            this.currency = json.currency;
            this.cycle = json.cycle;
            this.pdfUrl = json.pdfUrl;
            this.payUrl = json.payUrl;
            this.actionComment = json.actionComment;
            
            if (json.createDate != null) {
                this.createDate = DateUtils.parseJsonDate(json.createDate, true); 
            }
            if (json.dueDate != null) {
                this.dueDate = DateUtils.parseJsonDate(json.dueDate, true); 
            }
            if (json.closedDate != null) {
                this.closedDate = DateUtils.parseJsonDate(json.closedDate, true); 
            }
            
            if (json.status != null) {
                for (let index in InvoiceStatus) {
                    const status = InvoiceStatus[index];

                    if (status.ordinal === json.status)
                        this.status = status;
                }
                
            }
        }
    }    

}