import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';

import { Paper, Button, Tab, Tabs, TextField, Tooltip, Grid, Typography } from '@material-ui/core'

import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ClearIcon from '@material-ui/icons/Clear';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import SearchIcon from '@material-ui/icons/Search';

import { PP } from '../models/PP'
import { Membership } from '../models/Membership'
import { Member } from '../models/Member'
import { Product } from '../models/Product'
import { Account } from '../models/Account'
import { RestComponent } from 'react-frontend-utils' 
import ProductSearchPopover from '../components/ProductSearchPopover'
import ProductListEntry, { ProductListModes } from '../components/ProductListEntry'
import { TransactionFunctions } from '../utils/TransactionFunctions'
import { Order } from '../models/Order'
import { TransactionType } from '../models/Transaction'
import { Redeemable } from '../models/RedeemableProduct'
import { ThemeColors } from '../Theme'

import { Currency, Permissions } from 'react-frontend-utils'

const MOBILE_WIDTH = 1200;
const VERY_SMALL = 768;
const EXTREMELY_SMALL = 580;

const TabPages = {
    PURCHASE:   {tabIndex: 0, text: "Purchase",    collapseText: null,    priority: 1, collapseAt: 0,            visible: () => PP.user.hasPermissionTo(Permissions.MARKETPLACE_PURCHASE) && PP.attendedMarketplacePurchaseEnabled},
    REDEEM:     {tabIndex: 1, text: "Redeem",      collapseText: null,    priority: 2, collapseAt: MOBILE_WIDTH, visible: () => PP.user.hasPermissionTo(Permissions.MARKETPLACE_REDEEM)}
};


export class MarketTab extends RestComponent {
  
    _clearTimeout = 120000;      //ms to wait before "clearing soon" prompt
    _shortTimeoutClear = 30000;  //ms to wait after notifying that clear will happen soon
  
    _barcodeInput;             //reference to the barcode input widget
    _clearTimer = null;               //Timeout when the fields all clear
    _transactionInProgress = false;  //true when Purchase or Redeem is submitted
    
    _ticketToRedeem = null;
    
    styles = {
     
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 10,
            marginBottom: 10
        },
        paper: {
            width: '100%',
            height: '100%'
        },
        paperLabel: {
            color: 'blue',
            fontSize: '12pt',
            flexGrow: 1
        },
        currencyLabel: {
            marginLeft: 15,
            marginRight: 15,
            marginBottom: 5,
            color: 'gray',
            fontSize: '9pt',
            flexGrow: 1,
            textAlign: 'right'
        },
        textfield: {
            marginBottom: 15
        }
        
    };
  
  
    constructor(props) {
        super(props);     
        this.state.barcodeFieldValue = "";                              //text for the barcode field
        this.state.account = null;                                      //after account lookup, account is stored here  
        this.state.cart = [];                                           //shopping cart - array of {products, quantity, quantityStep}
        this.state.toRedeem = [];                                       //holds RedeemableProducts and quantities
        this.state.isMobile = false;
        this.state.isVerySmall = false;
        this.state.membershipID = "";
        this.state.foundMembershipID = null;
        this.state.foundMember = null;
        this.state.comment = "";
        this.state.selectedTab = -1;
        this.state.showProductSearchPopover = false;
        this.state.databaseInfo = null;
        
        this._ticketToRedeem = props.ticketToRedeem;                    //Passed in from another tab   

        let priority = Number.MAX_VALUE;
        
        //Find the tab with the lowest priority, yet visible
        Object.keys(TabPages).forEach(key => {
            if (TabPages[key].priority < priority && TabPages[key].visible()) {
                this.state.selectedTab = TabPages[key].tabIndex;
                priority = TabPages[key].priority;
            }
        }); 
    }
    
    
    //Stop and clear any existing timer
     _stopTimer = () => {
        if (this._clearTimer) {
            clearTimeout(this._clearTimer);  //stop any existing timer
            this._clearTimer = null;
        }
    }
    
    
    //Start or reset the existing timer, calling the "timeout" function on timeout
    _resetTimer = () => {        
        this._stopTimer();
        this._clearTimer = setTimeout(this._timeout, this._clearTimeout);
    }
    
    
    _timeout = () => {
        
        const afterShortTimeout = () => {this.closeConfirmAlert(); 
                                         this._clear();};
        
        this._clearTimer = setTimeout(afterShortTimeout, this._shortTimeoutClear);
        this.showConfirmAlert("Do you need more time?", "This screen will clear soon. Press Continue to keep working on this transaction.", 
                              'black', null, this._resetTimer, "Continue");   

 
    }


    
    /**
     * Add a resize listener to adapt to width changes
     */
    componentDidMount() {
        super.componentDidMount();

        this._updateSize();
        window.addEventListener("resize", this._updateSize);
        
        if (this._ticketToRedeem) {
            this.setState({selectedTab: TabPages.REDEEM.tabIndex});        
            this._search(this._ticketToRedeem);
        }
        //Clear, to avoid double searches
        this._ticketToRedeem = null;
        
        this._fetchDatabase();
    }
    
  
    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener("resize", this._updateSize);
        this._stopTimer();
    }
    

    //Called after mounting the page
    _fetchDatabase = () => {
        this.setBusy(true);
        this.secureJSONFetch("/patron/databases/" + PP.selectedDatabase, {}, this._fetchDatabaseCallback, this._fetchErrorCallback); 
    }


    //When database sucessfully fetched, get the details from the response, and then if the referrer or qr is set,
    //fetch the account details from it
    _fetchDatabaseCallback = (response) => {
        this.setBusy(false);
        if (response)
            this.setState(({databaseInfo: response, showStore: true}));
    }


    //callback when window changes size
    _updateSize = () => {
        this.setState({isMobile: window.innerWidth < 916, isVerySmall: window.innerWidth < 476 });  //custom, split between bootstrap and mui
    }
    
    
    _switchTab = (event, newValue) => {
        this.setState({selectedTab: newValue});
        this._focusBarcodeField();
    }
    
    
    _focusBarcodeField() {
        if (this._barcodeInput)
            this._barcodeInput.focus();
    }
       
   
    //Just reflect the barcode field value from what is entered
    _barcodeFieldChanged = (event) => {
        this.setState({barcodeFieldValue: event.target.value});
    }
    
    
    _barcodeKeyDown = (event) => {
    
        if (event.key === 'Enter') {  //enter key
            this._resetTimer();
            
            const searchString = event.target.value;
            console.log("Barcode/Scan entered: " + searchString);
            
            this._search(searchString);
           
            event.target.select(); //re-highlight all text
        }
    }
    
    
    _search = (searchString) => {
        
        if (searchString) {
            this.setBusy(true);
             
            searchString = encodeURIComponent(searchString);  //prevent malicious strings

            if (searchString.length > Account.ACCOUNT_NUMBER_LEN)
                this._errorCallback("Invalid entry");
            else if (searchString.length > Product.MAX_PRODUCT_ID_LEN) { //too long to be product, try account
                //fetch account inquiry
                this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/marketplace/accounts/", {}, 
                                     this._fetchAccountDetailCallback, this._errorCallback, searchString); 
            }
            else if (this.state.selectedTab === TabPages.PURCHASE.tabIndex) {  //only scan for products on the purchase tab
                this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/marketplace/products/",
                                    {}, this._fetchProductDetailCallback, this._errorCallback, searchString + "?availableOnly=true");     
            }
            else {
                this._errorCallback("Invalid entry");
            }
                
        }
        
    }
   
   
    _fetchAccountDetailCallback = (response) => {

        this.setBusy(false);
        this._resetTimer();
        
        if (response) {
            const account = new Account(response);  

            console.log(account);
            const toRedeem = [];
            
            if (account.redeemables) {
                //For each RedeemableProduct in the account, map to a structure that contains the product, initial quantity of 0, and the redeemable
                for (let r of account.redeemables) {

                    if (r.product) {//valid product  
                        
                        //Here we fudge the cost value to show the quantity and the discount/tax values to null so they don't show in the ProductListEntry 
                        r.product.cost = r.redeemable.quantity === -1 ? "Unlimited" : r.redeemable.quantity;
                        
                        //Here we fudge the discount value to show the number of seconds until expiring (Remain)
                        r.product.discount = r.redeemable.expiresTimeString();
                        r.product.tax = null;
                        toRedeem.push({product: r.product, quantity: 0, redeemable: r.redeemable});
                    }
                }
            }
                               
            this.setState({account: account, toRedeem: toRedeem, barcodeFieldValue: ""});             
        }
       
    }
    
    
    _productAddOrIncreaseQuantity = (product) => {
        const cart = this.state.cart;
        let existing = false;
        for (let item of cart) {

            if (item.product.id === product.id) { //already have this product, so increment quantity
                if (product.maxQuantity != null && item.quantity >= product.maxQuantity) {
                    this.setState({barcodeFieldValue: ""});    
                    this.showConfirmAlert("Error", "Maximum quantity for product reached", 'red');
                    return;
                }
                if (product.redeemable() && product.inventory != null && item.quantity >= product.inventory) {
                    this.setState({barcodeFieldValue: ""});    
                    this.showConfirmAlert("Error", "Maximum inventory for product reached", 'red');
                    return;
                }

                item.quantity += item.product.minQuantity;
                existing = true;
            }               
        }

        if (!existing)  //not found, add
            cart.push({product: product, quantity: product.minQuantity, quantityStep: product.minQuantity});

        this.setState({barcodeFieldValue: "", cart: cart});    
    }
    
    
    _fetchProductDetailCallback = (response) => {

        this.setBusy(false);
        this._resetTimer();
        
        if (response) {
            const product = new Product(response);
            this._productAddOrIncreaseQuantity(product);       
        } 
    }
    
    
    _productAddedFromSearch = (product) => {
     
        const cart = this.state.cart;

        for (let item of cart) {
            if (item.product.id === product.id) { //already have this product, so ignore
                return;
            }
        }
        this._productAddOrIncreaseQuantity(product);       
 
    }

    _itemQuantityChanged = (index, value) => {
        this._resetTimer();

        let newQuantity = parseInt(value);
        
        switch (this.state.selectedTab) {
            case TabPages.PURCHASE.tabIndex:
                const cart = this.state.cart;
                const product = cart[index].product;

                newQuantity = TransactionFunctions.validateQuantity(newQuantity, product);

                cart[index].quantity = newQuantity;
                this.setState({cart: cart}); 
                break;

             
            case TabPages.REDEEM.tabIndex:
                
                const toRedeem = this.state.toRedeem;
                const redeemable = toRedeem[index].redeemable;
                
                if (redeemable.quantity !== -1 && redeemable.quantity < newQuantity) //limit to the number of redeemables for this product (unless unlimited: -1)
                    newQuantity = redeemable.quantity;
                
                if (newQuantity < 0)
                    newQuantity = 0;

                toRedeem[index].quantity = newQuantity;
                this.setState({toRedeem: toRedeem}); 
  
                break;
                
            default:
                console.log("Invalid tab index");
        }
        
            
 
    }

    _productRemoved = (index) => {

        this._resetTimer();

        const cart = this.state.cart;
        
        cart.splice(index, 1);   //remove the i'th element
        this.setState({cart: cart});  
    }
    
    _redeemItemZeroed = (index) => {
        
        const toRedeem = this.state.toRedeem;
        toRedeem[index].quantity = 0;
       
        this.setState({toRedeem: toRedeem}); 
  
    }
    
   
    //Callback when the search fails
    _errorCallback = (error) => {
        this._resetTimer();
        this.showConfirmAlert("Error", error, 'red');
        this.setBusy(false);
        this._focusBarcodeField();
    }
    
   
    
    //clear all fields
    _clear = () => {
         this.setState({barcodeFieldValue: "", 
                        account: null, 
                        cart: [], 
                        toRedeem: [], 
                        membershipID: "", 
                        foundMembershipID: null,
                        foundMember: null,
                        comment: ""});
         this._focusBarcodeField();
         this._stopTimer();
    }
    
   

    _numRedeems = () => {
        
        let total = 0;
        
        for (let redeemable of this.state.toRedeem) {
            const q = redeemable.quantity;
            total += q;
        }
        return total;
    }

   
    _purchase = () => {
        
        if (this._transactionInProgress)
            return;  //avoid duplicate transactions

        
        this._resetTimer();
        this.setBusy(true);
        
        if (!this.state.account)
            return;
        
        this._transactionInProgress = true;

        //Get a list of redeemables and admittances purchased, to display to the attendant
        let redeemables = [];
        let admittances = 0;
        for (let item of this.state.cart) {
            if (item.product.redeemable()) 
                redeemables.push(item.product.name + (item.product.isAutoRedeemable() ? " (automatically redeemed)" : ""));
            else {
                if (item.product.admits())  //if instantly redeemable and admits, add the quan
                    admittances += item.quantity;
            }
        }
        
        
        const order = Order.createFromCheckout(false, this.state.cart, this.state.account.id,  this.state.foundMembershipID, this.state.comment);

        const endPath = "?location=" + encodeURIComponent(PP.getLocation());

        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/marketplace/transactions/order",
                             {method: "POST", body: JSON.stringify(order)}, 
                             () => this._purchaseSuccessful(redeemables, admittances), this._purchaseRedeemErrorCallback, endPath);      
    }
    
    
    _redeem = () => {
      
        if (this._transactionInProgress)
            return;  //avoid duplicate transactions
          
        
        this._resetTimer();
        this.setBusy(true);
        this._transactionInProgress = true;
   
        
        //Get a list of redeemables from the toRedeem array
        let redeemables = this.state.toRedeem.map(item => Redeemable.create(item.redeemable.productID, item.quantity));
        
        //Filter any items with zero quantity
        redeemables = redeemables.filter(redeemable => redeemable.quantity > 0); 
        
        //Get a list of admittances redeemed, to display to the attendant
        let admittances = 0;
        for (let item of this.state.toRedeem) {
            if (item.product.admits())  //if admits, add the quan
                admittances += item.quantity;
        }
                       
        const order = new Order(this.state.account.id, null, TransactionType.REDEEM, 
                                this.state.comment, null, redeemables, 0);
      
                                                
        const endPath = "?location=" + encodeURIComponent(PP.getLocation());

        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/marketplace/transactions/order",
                             {method: "POST", body: JSON.stringify(order)}, 
                             () => this._redeemSuccessful(admittances), this._purchaseRedeemErrorCallback, endPath);      
    }
    
    
    
    _purchaseSuccessful = (redeemables, admittances) => {
        this._resetTimer();
        this._transactionInProgress = false;

        const message = <span>
                            <span>Purchase Complete</span>
                            {admittances > 0 ? 
                                <div style={{marginTop: 10}}>{"You may admit " + admittances + " patron" + (admittances > 1 ? "s" : "") + " into the facility"}</div>
                            : null}
                            {redeemables.length > 0 ? 
                                <div style={{marginTop: 10}}>
                                    <div>These Products were added to the patron's Ticket and can be redeemed on the Redeem tab:</div>
                                    <ul>
                                        {redeemables.map((item, index) => <li key={index}>{item}</li>)}
                                    </ul>
                                </div>
                            : null}
                        </span>;

        this.showConfirmAlert("Success", "", 'green', "OK", null, null, null, message);
        this.setBusy(false);
        this._focusBarcodeField();
        this._clear();
    }
    
    _redeemSuccessful = (admittances) => {
        this._resetTimer();
        this._transactionInProgress = false;

        const message = <span>
                            <span>Redeem Complete</span>
                            {admittances > 0 ? 
                                <div style={{marginTop: 10}}>{"You may admit " + admittances + " patron" + (admittances > 1 ? "s" : "") + " into the facility"}</div>
                            : null}
                        </span>;

        this.showConfirmAlert("Success", "", 'green', "OK", null, null, null, message);
              
        this.setBusy(false);
        this._focusBarcodeField();
        this._clear();
    }
    
    _purchaseRedeemErrorCallback = (error) => {
        this._transactionInProgress = false;
        this._errorCallback(error);
    }
   
   
    _membershipFieldChanged = (event) => {
        const newText = event.target.value;
        this.setState({membershipID: newText});
    }
    
    _membershipFieldKeyDown = (event) => {
    
        if (event.key === 'Enter') {  //enter key
            this._resetTimer();
            this._membershipFieldBlur();
            event.target.select(); //re-highlight all text
        }   
    }
            
    _membershipFieldBlur = () => {
        const searchString = this.state.membershipID;
        console.log("Membership Search entered: " + searchString);
        if (searchString || this.state.foundMembershipID)  //if we have a string, or we have a previously searched value
            this._searchMembership(searchString);
    }
    
    _searchMembership = (searchString) => {
        this.setBusy(true);
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/membership/search?query=" + searchString + "&retrieveMembers=true&retrieveMemberImages=false", 
                             {}, this._searchMembershipCallback, this._searchMembershipError); 
    }
    
    
    _searchMembershipError = (error) => {
        this.setBusy(false);
        this._resetTimer();
        this._errorCallback(error);
        this.setState({membershipID: "", foundMembershipID: null, foundMember: null});

    }
      
      
    //Callback for searching for Member - JSON response is an object (tuple) containing a membership and an array of one member
    _searchMembershipCallback = (response) => {
        
        this.setBusy(false);
        this._resetTimer();
        if (response) {            
            const membership = new Membership(response.membership);        
            
            //Validate membership
            if (membership.suspended) {            
                this.showConfirmAlert("Error", "Membership is Suspended", 'red'); 
                this.setState({foundMembershipID: null, foundMember: null});
                return;        
            }
            if (membership.expiresIn() < 0) {
                this.showConfirmAlert("Error", "Membership is Expired", 'red');   
                this.setState({foundMembershipID: null, foundMember: null});
                return;        
            }
            
            const members = response.members;
            let foundMember = null;
            if (response.selectedIndex >= 0) {  //search included a barcode or Member ID, so that member index is here - otherwise -1
                foundMember = new Member(members[response.selectedIndex]);
            }
            
            this.setState({membershipID: membership.id, foundMembershipID: membership.id, foundMember: foundMember});
        }
   
    }
  
  
    _searchProducts = () => {
        this._resetTimer();
        this.setState({showProductSearchPopover: true});
    }


    //Checks to see if the product is an unlimited redeemable and if so, and the current account has the same product in its redeemable list, return true
    _checkDuplicateUnlimitedRedeemable = (product) => {
       
        if (!product.unlimitedRedeem())  //not unlimited, so return false
            return false;
       
        //If we have an account and it has redeemables
        if (this.state.account &&this.state.account.redeemables) {

            for (let redeemable of this.state.account.redeemables) {

                if (redeemable.product && redeemable.product.unlimitedRedeem()) { //valid unlimited reeeem product 
                    
                    if (redeemable.product.id === product.id)
                        return true;
                } 
            }
        }
        
        return false;
    }


    render() {
         
        const fontSize = this.state.isVerySmall ? 12 : (this.state.isMobile ? 14 : 16);


        //Creates the text label for the tab, shrinking font and collapsing to abbreviated text as necessary
        //to fit the width of the screen
        const tabLabel = (tab) => {
            let fontSize = 14;
            if (this.state.windowWidth < VERY_SMALL)
                fontSize -= 1; 

            if (this.state.windowWidth < EXTREMELY_SMALL)
                fontSize -= 1;

            let text = this.state.windowWidth < tab.collapseAt ? tab.collapseText : tab.text;

            const split = text.split(" ");
            
            if (this.state.windowWidth < EXTREMELY_SMALL && split.length > 1)     
                return (<span style={{fontSize: fontSize}}>{split[0]}<div/>{split[1]}</span>);
            else
                return (<span style={{fontSize: fontSize}}>{text}</span>);

        };

        const style = this.state.windowWidth < EXTREMELY_SMALL ? {minWidth: 50} : {};
        
        
        const tabsArray = [];
        for (const index in TabPages) {
            const tab = TabPages[index];
            if (tab.visible())
                tabsArray.push(<Tab key={tab.tabIndex} style={style} value={tab.tabIndex} label={tabLabel(tab)}/>);
        }
        
        const currencySymbol = Currency.symbolForISO(PP.currency);
        
        let listTitle;
        let scanTitle;
        let actionsTitle;
        let actionIcon;
        let action;
        let showBalance = true;
        let showSearch = true;
        let showTotals = true;
        let totals = 0;
        let totalExceedsBalance = false;
        let itemList;
        let itemAction;
        let canDoAction;
        let decimalPoints = 2;
        let productListMode;
        let requiresMembership = false;
        let showCurrency = false;
        let commentTooltip = "";
        
        switch (this.state.selectedTab) {
            case TabPages.PURCHASE.tabIndex:
                productListMode = ProductListModes.MARKET;
                showCurrency = true;
                listTitle = "Products";
                scanTitle = "Scan Product or Ticket";
                actionsTitle = "Purchase";
                commentTooltip = "This text will show up only in the Transactions list, not on the customer receipt.";
                actionIcon = <MonetizationOnIcon/>;
                itemList = this.state.cart;
                itemAction = this._productRemoved;
                totals = TransactionFunctions.totalShoppingCart(this.state.cart);  //calculate running totals      
                if (this.state.account && totals.total > this.state.account.balance)
                    totalExceedsBalance = true;

                requiresMembership = totals.requiresMembership > 0;

                canDoAction = !totalExceedsBalance && this.state.account && this.state.cart.length > 0 && (!requiresMembership || this.state.foundMembershipID);
                action = this._purchase;
                break;
             
            case TabPages.REDEEM.tabIndex:
                productListMode = ProductListModes.REDEEM;
                listTitle = "Redeemables";
                scanTitle = "Scan Ticket";
                actionsTitle = "Redeem";
                commentTooltip = "This text will show up only in the Transactions list, not on the customer receipt. When redeeming products that admit a patron, this comment will show up in the Log.";
                showBalance = false;
                showTotals = false;
                showSearch = false;
                decimalPoints = 0;
                itemAction = this._redeemItemZeroed;
                itemList = this.state.toRedeem;
                actionIcon = <ConfirmationNumberIcon/>;
                canDoAction = this.state.account && this._numRedeems() > 0;
                action = this._redeem;
                break; 
            
            default:
                console.log("Invalid tab index");
        }        
        
        if (this._transactionInProgress)
            canDoAction = false;  //never can do action while transaction in progress
        
        
        return (
            
            <Fragment >
                
                {this.getConfirmAlertComponent()}
                
                <ProductSearchPopover isOpen={this.state.showProductSearchPopover} onClose={() => {this.setState({showProductSearchPopover: false});}}
                                      onProductAdd={this._productAddedFromSearch} isMobile={this.state.isMobile} isVerySmall={this.state.isVerySmall} />


                <Paper>
                    <Tabs value={this.state.selectedTab} onChange={this._switchTab} indicatorColor="primary" textColor="primary" variant='fullWidth' >
                        
                        {tabsArray}
                    
                    </Tabs>
                </Paper>
           
                <div style={{margin: 20}}/>

                <div style={{display: 'flex'}}>
                    <TextField size="small" autoFocus type="search" inputRef={(field) => this._barcodeInput = field} value={this.state.barcodeFieldValue} onChange={this._barcodeFieldChanged}  
                                label={scanTitle} onKeyDown={this._barcodeKeyDown} variant="outlined" 
                                fullWidth={true} InputLabelProps={{ shrink: true}} />

                    {showSearch ? 
                        <div style={{display: 'flex'}}>
                            <div style={{marginLeft: 10}}/>

                            <Tooltip title="Search for Products">
                                <Button onClick={this._searchProducts} variant="outlined" 
                                color="primary" startIcon={this.state.isVerySmall ? null : <SearchIcon style={{transform: 'scaleX(-1)'}}/>}>Search</Button>
                            </Tooltip>
                        </div>
                        : null
                    }
                    {this.state.isBusy ? this.getBusyComponent('center', {marginLeft: 10, maxWidth: 72}) : <div style={{minWidth: 50, maxWidth: 50}}/> }
                </div>
                   
                   
                   
                <div style={{margin: 20}}/>

                <Grid container direction="row" spacing={3}>

                    { /*----------------------------  LIST LEFT  ----------------------------------------*/ null }


                    <Grid item md={9} xs={12} style={{justifyContent: 'center'}}>    
                        <Paper>                           
                            <div style={{minHeight: 100}}>

                                <div style={{display: 'flex'}}>
                                    <Typography variant="body2" style={{...this.styles.paperLabel, marginLeft: 10}}>{listTitle}</Typography>   
                                    {showCurrency ? <Typography variant="body2" style={this.styles.currencyLabel}>{"Amounts in " + PP.currency}</Typography>  
                                                    : null}
                                </div>

                                <div style={{margin: 25}}/>
                                            
                                {itemList.map((item, index) => 
                                    <ProductListEntry key={index} item={item} index={index} mode={productListMode}
                                                      quantityChanged={this._itemQuantityChanged} fixedPoint={decimalPoints}
                                                      currencySymbol={currencySymbol}
                                                      accountHasUnlimitedRedeemable={this._checkDuplicateUnlimitedRedeemable(item.product)}
                                                      action={itemAction} disableReason={(item.redeemable && item.redeemable.unavailableReason) ? item.redeemable.unavailableReason : null}
                                                      isMobile={this.state.isMobile} isVerySmall={this.state.isVerySmall}/>)}
                                    

                            </div>
                        </Paper>                          


                     </Grid>

                    { /*----------------------------  ACCOUNT RIGHT ----------------------------------------*/ null }


                    <Grid item md={3} xs={12} style={{justifyContent: 'center'}}>  
                    
                        <Grid container direction="row" spacing={3}>
                            <Grid item md={12} xs={6} >    

                                <Paper>                           
                                    <div style={{marginLeft: 10, marginRight: 10}}>

                                        <Typography variant="body2" style={this.styles.paperLabel}>Ticket</Typography>   
                                        <div style={{margin: 25}}/>

                                        <TextField size="small" label="Ticket Number" variant="outlined" value={this.state.account ? (this.state.account.id.substring(0, 8) + "...") : ""} style={this.styles.textfield} fullWidth={true} inputProps={{readOnly: true }} InputLabelProps={{ shrink: true}} />
                                        <TextField size="small" label="Name" variant="outlined" value={this.state.account ? this.state.account.name : ""} style={this.styles.textfield} fullWidth={true} inputProps={{readOnly: true }} InputLabelProps={{ shrink: true}} />
                                        {showBalance ? <TextField size="small" label="Balance" variant="outlined" value={this.state.account ? Currency.round(this.state.account.balance) : ""} style={this.styles.textfield} fullWidth={true} inputProps={{readOnly: true, style: {fontWeight: 'bold', textAlign: 'right', backgroundColor: totalExceedsBalance ? 'yellow' : 'white'} }} InputLabelProps={{ shrink: true}} />
                                        : null}

                                        {requiresMembership ? <div>
                                                                    <Tooltip title={"One or more products require a valid Membership. " + (this.state.foundMembershipID ? " This Membership is valid." : " Enter a valid Membership ID or one if its Member's barcodes.")}>
                                                                        <TextField size="small" onKeyDown={this._membershipFieldKeyDown} onBlur={this._membershipFieldBlur} label="Membership ID" variant="outlined" value={this.state.membershipID} onChange={this._membershipFieldChanged} style={this.styles.textfield} fullWidth={true} inputProps={{readOnly: false, style: {backgroundColor: this.state.foundMembershipID ? ThemeColors.validGreen : 'yellow' }} } InputLabelProps={{ shrink: true}} />
                                                                    </Tooltip>  
                                                                    {this.state.foundMember ? 
                                                                        <Typography variant="body2" style={{marginLeft: 10, paddingBottom: 15}}>{"Member: " + this.state.foundMember.name() + " (" + this.state.foundMember.id + ")"}</Typography> 
                                                                        : null}
                                                              </div>
                                        : null}
                                            
                                    </div>
                                </Paper>
                            </Grid>   
                            
                            <Grid item md={12} xs={6}>                            
                                <Paper>                           
                                    <div style={{marginLeft: 10, marginRight: 10}}>

                                        <Typography variant="body2" style={this.styles.paperLabel}>{actionsTitle}</Typography>   
                                        <div style={{margin: 25}}/>

                                        {showTotals ? 
                                        <div>                                               
                                            <TextField size="small" label="Subtotal" variant="outlined" value={currencySymbol + Currency.round(totals.subtotal)} style={this.styles.textfield} fullWidth={true} inputProps={{readOnly: true, style: {textAlign: 'right'}}} InputLabelProps={{ shrink: true}} />
                                            <TextField size="small" label="Tax" variant="outlined" value={currencySymbol + Currency.round(totals.tax)} style={this.styles.textfield} fullWidth={true} inputProps={{readOnly: true, style: {textAlign: 'right'}}} InputLabelProps={{ shrink: true}} />
                                            <TextField size="small" label="Total" variant="outlined" value={currencySymbol + Currency.round(totals.total)} style={this.styles.textfield} fullWidth={true} inputProps={{readOnly: true, style: {textAlign: 'right', fontWeight: 'bold', backgroundColor: totalExceedsBalance ? 'yellow' : 'white'}}} InputLabelProps={{ shrink: true}} />
                                        </div> : null}

                                        <Tooltip title={commentTooltip}>
                                            <TextField size="small" multiline rows={2} label={this.state.isVerySmall ? "Comment" : "Comment for this " + actionsTitle} variant="outlined" 
                                                        value={this.state.comment} onChange={(event) => this.setState({comment: event.target.value})} 
                                                        style={this.styles.textfield} fullWidth={true} inputProps={{readOnly: false}}
                                                        InputProps={{style: {fontSize: fontSize}}}
                                                        InputLabelProps={{ shrink: true}} />
                                        </Tooltip>

                                        <Grid container direction="row" spacing={2}>
                                            <Grid item md={12} sm={6} xs={12}>    
                                                <Button disabled={!canDoAction} onClick={action} fullWidth={true} variant="contained" 
                                                        color="primary" style={{color: 'white'}} startIcon={actionIcon}>{actionsTitle}</Button>
                                            </Grid>
                                            <Grid item md={12} sm={6} xs={12}>    
                                                <Button onClick={this._clear} style={{marginBottom: 5}} fullWidth={true} 
                                                        variant="outlined" startIcon={<ClearIcon style={{color: 'red'}}/>}>Cancel</Button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Paper>
                            </Grid>

                        </Grid>   

                    </Grid>   
                    
                </Grid>
            </Fragment>
        );

    }
};
    

    
  

export default withCookies(MarketTab);