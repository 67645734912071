import { PP } from '../models/PP'


export class Refund {

    account;
    membershipID
    attendant;
    
    orderNumber;
    amount;
    comment;
    
    constructor(account, membershipID, orderNumber, amount, comment) {
        this.account = account;
        this.membershipID = membershipID;
        this.attendant = PP.user.nameReversed();

        this.orderNumber = orderNumber;
        this.amount = amount;
        this.comment = comment;
    }
}