import { PP } from '../models/PP'

export class Return {

    account;
    attendant;
    membershipID;
    
    productID;
    productName;
    
    purchQuan;
    redeemQuan;
    price;
    discount;
    tax;
    
    comment;
    
    constructor(account, membershipID, productID, productName, purchQuan, redeemQuan, price, discount, tax, comment) {
        this.account = account;
        this.membershipID = membershipID;
        this.productID = productID;
        this.productName = productName;
        this.attendant = PP.user.nameReversed();
        this.purchQuan = purchQuan;
        this.redeemQuan = redeemQuan;
        this.price = price;
        this.discount = discount;
        this.tax = tax;
        this.comment = comment;
    }
    
}
