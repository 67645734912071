import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core'
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import { Email, ManageTextField } from 'react-frontend-utils'
import { ThemeColors } from '../Theme'

export default class ManageEmailField extends ManageTextField {
        
        
    _emailButtonClicked = () => {
        Email.sendEmail(this.state.text);
    }
        
    render() {
        const canEmail = !this.props.isReadOnly;
        
        
        //Render the calendar buttons surrounded along with the (super's) text field
        return (

            <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}} >   
                <Tooltip title={"Enter email addresses separated by commas or semicolons"} >
                    <span style={{width: '100%', display: 'flex'}}>
                        {super.render()}
                    </span>
                </Tooltip>

                <Tooltip title={"Send email using the configured mail client"} >
                    <IconButton edge="start" disabled={!canEmail} onClick={this._emailButtonClicked} style={{...this.props.style, marginLeft: 4}}>
                        <MailOutlineIcon style={{color: !canEmail ? 'lightGray' : ThemeColors.mailColor}} />
                    </IconButton>
                </Tooltip>
            </div>
        );
    } 
        
        
}
