import React from 'react';
import { Typography } from '@material-ui/core'
import 'rapidoc'
import 'rapipdf/dist/rapipdf-min.js';

import { PP } from '../models/PP';
import { PopupMenu, Permissions } from 'react-frontend-utils' 
import { ThemeColors } from '../Theme'

export function HelpPage(props) {

    const isAdmin = PP.user.isAdministrator();      // Admin or superadmin
    const canViewApi = PP.user.hasPermissionTo(Permissions.EDIT_DATABASE);
    const marketplaceView = PP.user.hasPermissionTo(Permissions.SIMPLE_MARKETPLACE_MANAGE) || PP.user.hasPermissionTo(Permissions.FULL_MARKETPLACE_CONTROL);
    const billingView = PP.user.hasPermissionTo(Permissions.VIEW_INVOICES) || PP.user.hasPermissionTo(Permissions.MANAGE_INVOICES) || PP.user.hasPermissionTo(Permissions.ISSUE_INVOICES);
            

    const HelpPages = [
        {label: "Introduction", source: "/help/help.html", visible: true},
        {label: "Check-In Tab", source: "/help/help.html#checkin", visible: true},
        {label: "Market Tab", source: "/help/help.html#market", visible: true},
        {label: "Member List Tab", source: "/help/help.html#memberlist", visible: true},
        {label: "Manage Tab", source: "/help/help.html#manage", visible: true},
        {label: "Statistics Tab", source: "/help/help.html#stats", visible: true},
        {label: "Log Tab", source: "/help/help.html#log", visible: true},
        {label: "Operations Tab", source: "/help/help.html#ops", visible: true},
        {label: "Preferences Page", source: "/help/help.html#prefs", visible: true},
        {label: null, visible: isAdmin},  //divider
        {label: "User Administration", source: "/help/admin.html", visible: isAdmin},
        {label: "App Management", source: "/help/apps.html", visible: isAdmin},
        {label: "Marketplace", source: "/help/marketplace.html", visible: marketplaceView},
        {label: "Billing", source: "/help/billing.html", visible: billingView}        
    ];
    
    const ApiManualPage = {label: "Manual", source: "/publicdoc/api.html", visible: canViewApi};
    const ApiReferencePage = {label: "Online Reference", source: "/v3/api-docs", visible: canViewApi};
    const ApiDownloadPage = {label: "PDF Reference", visible: canViewApi};

    const iframeStyle = {
        flexGrow: 1, 
        border: 'none', 
        width: '100%',
        height: props.helpHeight,
        marginTop: 10,
        padding: 0
    };
    
    const topNavStyle = {
        marginLeft: 10, 
        marginRight: 20,
        width: '100%', 
        display: 'flex',
        justifyContent: 'space-between'
    };
    
    
    const helpItems = (() => {
        
        let menuItems = [];
        
        //For each menu item, set the label, selected if the visible page label is our label, and callback is to select ourself, 
        for (let i=0; i<HelpPages.length; i++) {
            
            if (!HelpPages[i].visible)
                continue;
            
            if (HelpPages[i].label === null)  //break                
                menuItems.push(null);

            else {
                menuItems.push({label: HelpPages[i].label, 
                                isSelected: (props.visiblePage ? props.visiblePage.label === HelpPages[i].label : false), 
                                selectCallback: () => {props.selectPageCallback(HelpPages[i]);}
                               });
            }
        }
     
        return menuItems;
    })();
    
    
    const ref = React.createRef();
    
    const downloadPDF = () => {
        if (ref.current) {
            ref.current.generatePdf("/v3/api-docs");
        }
    };
    
    
    const apiItems = [ {label: ApiManualPage.label, isSelected: (props.visiblePage ? props.visiblePage.label === ApiManualPage.label : false), selectCallback: () => {props.selectPageCallback(ApiManualPage);}},
                       {label: ApiReferencePage.label, isSelected: (props.visiblePage ? props.visiblePage.label === ApiReferencePage.label : false), selectCallback: () => {props.selectPageCallback(ApiReferencePage);}},
                       {label: ApiDownloadPage.label, isSelected: (props.visiblePage ? props.visiblePage.label === ApiDownloadPage.label : false), selectCallback: downloadPDF}
                     ];
            
    
    
    //If a page was set, show it, otherwise show the first
    const pageSource = props.visiblePage ? props.visiblePage.source : HelpPages[0].source;
    
    const isAPIRef = props.visiblePage ? props.visiblePage.label === ApiReferencePage.label : false;

    return (
            
            
        <div>
            <span style={topNavStyle}>              
                <PopupMenu menuIcon={(<Typography variant="button" >Help Topic ▼</Typography>)} menuItems={helpItems}/>
                {canViewApi ?
                    <PopupMenu menuIcon={(<Typography variant="button" >API ▼</Typography>)} menuItems={apiItems}/>
                    : null
                }
            </span>
            <div style={{marginBottom: 5, borderBottom: '1px solid lightGray'}}/>

                    
            {isAPIRef ? 
                <div style={{marginTop: 20, height: window.innerHeight * .77}}>
                    <ApiInteractiveDoc/>
                </div> 
                : 
                <iframe title="Help Content" style={iframeStyle} src={pageSource}></iframe> 
            }
                  
                  
            <rapi-pdf style={{display: 'none'}} ref={ref} 
                      pdf-primary-color={ThemeColors.appBarBackground} pdf-alternate-color={ThemeColors.appBarBackground} 
                      pdf-title=" " pdf-footer-text="API Reference" />
        </div>    
            
    );
       
}


export function ApiInteractiveDoc(props) { 
        
    return <rapi-doc
            spec-url = "/v3/api-docs"
            render-style = "focused"
            show-header="false"
            allow-server-selection="false"
            schema-style = "table"
            theme="light"
            nav-bg-color="#F2F2F2"
            font-size="large"
            nav-text-color="#000000"
            primary-color={ThemeColors.appBarBackground}
            api-key-name="x-api-key"
            api-key-location="header"
            api-key-value="-"
            sort-tags="true"
            sort-endpoints-by="summary"
            fill-request-fields-with-example="false"
            schema-description-expanded="true"/>;     
}