import { PP } from '../models/PP'
import { DateUtils } from 'react-frontend-utils'



/**
 * This class represents a PoolPass Member, received from a JsonMember
 */
export class Member {
    
    
    static actions = [{name: "CONTACT"}, {name: "HOLD"}, {name: "PRINT"}, {name: "PROCESS"}, 
                      {name: "REDIRECT"}, {name: "REJECT"}, {name: "REVIEW"}, {name: "SCHEDULE"}];
    
    membershipRef;          //String
    id;                     //integer   
    lastName;               //String
    firstName;              //String
    barcode;                //String
    birthDate;              //Date
    suspended;              //boolean
    imageBase64;            //base64 encoded member image, or null
    signInDates = [];       //array of Date
    entryPasses;            //integer, -1 = unlimited
    isCheckedIn;            //boolean
    notes;                  //String
    relation;               //String
    limited;                //boolean
    actions;                //integer
    
    hasImage = null;        //null, not available, or true/false
    lastTransaction;        //integer
    
    
    
    constructor(userJson) {
        
        if (userJson) {
            this.membershipRef = userJson.membershipReference;
            this.id = userJson.id;
            this.lastName = userJson.lastName;
            this.firstName = userJson.firstName;
            this.barcode = userJson.barcode;
            
            if (userJson.birthDate) {
                this.birthDate = DateUtils.parseJsonDate(userJson.birthDate);
                this.birthDate.setHours(0, 0, 0, 0);  //zero time component, converts to local 
            }
            
            this.suspended = userJson.suspended;
            if (userJson.signInDates)
                this.signInDates = userJson.signInDates.map(dateString => DateUtils.parseJsonDate(dateString));
            this.entryPasses = userJson.entryPasses;
            
            this.entryPasses = userJson.entryPasses;          
            this.imageBase64 = userJson.imageBase64;
            
            this.isCheckedIn = userJson.isCheckedIn;
            this.notes = userJson.notes;
            this.relation = userJson.relation;
            this.limited = userJson.limited;
            this.actions = userJson.actions;
            
            if (userJson.hasImage)
                this.hasImage = userJson.hasImage;
                
            this.lastTransaction = userJson.lastTransaction;
   
        }
        
    }
    
    //Create a new Member json object for the Membership, with existing Members as a guide not to duplicate names
    static createNewJson(membership, existingMembers, barcode, limited) {
                
        //Get largest Member ID
        let largestID = 0;
        existingMembers.forEach(member => {
            if (member.id > largestID) {
                largestID = member.id;
            }
        });
        largestID += 1;
        
        const lastPrefix = limited ? "NewLimitedMember" : "NewMember";
        const firstName = limited ? "NewLimited" : "New";

        //Keep incrementing the same number to the last name until there exists no member with that last name
        let lastName = lastPrefix + largestID;
        while (true) {
            for (const member of existingMembers) {
                if (member.lastName === lastName) {  //member exists with same last name
                    lastName += largestID;
                    continue;
                }            
            }
            //reached end without a duplicate
            break;
        }
        
        const newMember = {membershipReference: membership.id,
                           lastName: lastName,
                           firstName: firstName,
                           barcode: barcode,
                           limited: limited ? true : false,
                           birthDate: DateUtils.jsonDateString(new Date(1900, 0, 1, 0, 0, 0)),
                           entryPasses: PP.initialEntryPasses()
                           };
        
        return newMember;
    }
    
    
    
    name = () => {
        return this.firstName + " " + this.lastName;
    }
    
    nameReversed = (short) => {
        const name = this.lastName + ", " + this.firstName;    
        if (short && name.length > 30) 
            return name.substring(0, 27) + "...";
        else
            return name;
    }
    
    
    shortName = () => {
        const name = this.name();
        
        if (name.length > 30) 
            return name.substring(0, 27) + "...";
        else
            return name;
        
    }
    
    shortRelation = () => {
        
        if (this.relation.length > 20) 
            return this.relation.substring(0, 17) + "...";
        else
            return this.relation;
        
    }
    
    age = () => {
        return DateUtils.calculateAge(this.birthDate);        
    }
    
    
    hasNoAge = () => {
        return this.birthDate.getFullYear() <= 1900;
    }
    
    
    ageString = () => {
     
        if (this.hasNoAge(this.birthDate))
            return "--";
        else {
            return String(this.age());
        }
        
    }
    
    lastCheckIn = () => {
        
        if (this.signInDates.length === 0) 
            return null;      
        else 
            return this.signInDates[this.signInDates.length - 1];
        
    }
    
    
    lastCheckInString = () => {
        const lastCheckIn = this.lastCheckIn();
        return lastCheckIn ? PP.checkInTimeFormat(lastCheckIn) : "Never";
    }
    
    
    //Return the number of seconds since last check in, or null for never
    lastCheckedInAgo = () => {
        const lastCheckIn = this.lastCheckIn();
        if (lastCheckIn === null)
            return null;
        
        const now = new Date(); 
        
        return Math.floor((now.valueOf() - lastCheckIn.valueOf())/1000);  //seconds ago        
    
    }
    
    
    entryPassesString = (short = false) => {
        if (this.entryPasses < 0)
            return short ? "∞" : "Unlimited";
        else
            return String(this.entryPasses);
    }
    
    
    //Return the number of check-ins after the supplied date, if the after date is null, return count of all check-ins
    numCheckIns = (after) => {
        
        if (this.signInDates.length === 0)
            return 0;
        
        if (after === null)
            return this.signInDates.length;
        
        let count = 0;
        for (const date of this.signInDates) {
            if (date.valueOf() >= after)
                count++;
        }
        return count;
        
    }
    
    static getActionsArray = () => {
        return Member.actions.map(a => a.name); 
    }
      
      
}
