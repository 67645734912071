import React, { Fragment, Component } from 'react';
import { withCookies } from 'react-cookie';

import { Typography, TextField, Paper, Container } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'

import PoolPassTheme, { ThemeColors } from '../Theme'
import { RestComponent } from 'react-frontend-utils'
import { PatronAppBar, DatabaseHeader } from '../components/PatronAppBar'


/**
 * This route displays a page containing all the public databases. After mounting, all public databases are fetched and displayed.  The user can filter
 * on database names/attributes to filter the list. Clicking on an item in the list re-routes to the PatronPortal route.
 * 
 * The "database" object used in this class is not defined as an object, but is represented by the JsonPublicGroup fields from the backend
 * 
 */


class PatronPortalSearch extends RestComponent {
  

    constructor(props) {
        super(props);
        this.state.isMobile = false;
        this.state.databases = [];          //Array of objects that contain public database info:  "name", "extendedName", "address", and "url"
        this.state.filteredDatabases = []; 
        this.state.searchFieldValue = "";
        this.setBusy(true);
    }

    
    //Just reflect the barcode field value from what is entered
    _searchFieldChanged = (event) => {
        const searchText = event.target.value;
        this.setState({searchFieldValue: searchText});
        this._filterDatabases(searchText);
    }
    
   
    componentDidMount() {
        super.componentDidMount();
        this._updateSize();
        this._fetchPublicDatabases();
        window.addEventListener("resize", this._updateSize);
    }
  
    componentWillUnmount() {
       super.componentWillUnmount();
       window.removeEventListener("resize", this._updateSize);
    }

    //callback when window changes size
    _updateSize = () => {
        this.setState({ isMobile: window.innerWidth < 600 });  //custom, split between bootstrap and mui
    }

    
    _fetchPublicDatabases = () => {
        this.setBusy(true);
        this.secureJSONFetch("/patron/databases", {}, this._fetchDatabasesCallback, this._fetchErrorCallback); 
    }

    _fetchDatabasesCallback = (response) => {
        this.setBusy(false);
        if (response) {
            this.setState(({databases: response, filteredDatabases: response}));  //databases and the filtered values are the same after fetch
        } 
        
    }
    
    _fetchErrorCallback = (error) => {
        this.setBusy(false);
        this.setState({serverError: error.toString()});
    }
    
    
    _filterDatabases = (searchText) => {
                
        if (!searchText) {
            this.setState({filteredDatabases: this.state.databases});
            return;
        }

        searchText = searchText.toLowerCase();
            
        
        const filtered = this.state.databases.filter((database) => {

            if (database.name && database.name.toLowerCase().includes(searchText))
                return true;
            
            if (database.extendedName && database.extendedName.toLowerCase().includes(searchText))
                return true;
            
            if (database.address && database.address.toLowerCase().includes(searchText))
                return true;
            
            if (database.marketURL && database.marketURL.toLowerCase().includes(searchText))
                return true;
            
            return false;
            
        });
            
        this.setState({filteredDatabases: filtered});
    }



   
    _databaseSelected = (database) => {
        let port = (window.location.port ? ':' + window.location.port : '');
 
        window.location.href = "//" + window.location.hostname + port + "/patron?facility=" + database; 
    }
    
    
    render() {
        
        const gutterMargin = this.state.isMobile ? 8 : 20;

        const serverErrorMessage = this.state.serverError ? <Typography variant="h5">Server Error: {this.state.serverError}</Typography> : null;        
               
        return (
    
            <ThemeProvider theme={PoolPassTheme}>
                <Fragment>

                    <PatronAppBar isMobile={this.state.isMobile}/>

                    {serverErrorMessage}
                    {this.state.isBusy ? this.getBusyComponent('center', {marginTop: 10}) : null}


                    <Container maxWidth={'lg'} disableGutters={true} style={{paddingLeft: gutterMargin, paddingRight: gutterMargin}}>
                        
                        {this.state.databases.length > 0 ? 
                            <div>
                                <TextField autoFocus value={this.state.searchFieldValue} onChange={this._searchFieldChanged}  
                                       label="Search for your Community" variant="outlined" 
                                       inputProps={{style: {fontSize: 20}}} fullWidth={true} InputLabelProps={{ shrink: true}} />

                                <Typography variant="body2" style={{color: 'gray', fontStyle: 'italic'}}>Search for any part of the community name, address, or website URL</Typography>
                            </div> : 
                            
                           <div>
                                <Typography variant="h6" style={{color: 'gray', fontStyle: 'italic', textAlign: 'center', marginTop: 20}}>Community list is updating, please check back in a few minutes</Typography>                                
                            </div>
                
                        }
        
                        <div style={{marginTop: 20}}/>
        
                        { this.state.filteredDatabases.map((database, index) => 
                            <div key={index} style={{marginBottom: 15}} >
                                <DatabaseRender database={database} onClick={this._databaseSelected}/>
                            </div>
                            
                        ) }
                    </Container>

                </Fragment>
            </ThemeProvider>    
        );
    }
};

export default withCookies(PatronPortalSearch);



class DatabaseRender extends Component {
    
    state = {mouseIn: false, mouseDown: false};
    
    _onClick;
    
    constructor(props) {
        super(props);
        this._onClick = props.onClick;
    }
    
    _clicked = () => {
        console.log("Selected: " + this.props.database.name);
        this._onClick(this.props.database.name);
    }
    
    _onMouseOver = () => {
        this.setState({mouseIn: true});
    }
    _onMouseOut = () => {
        this.setState({mouseIn: false, mouseDown: false});
    }
    _onMouseDown = () => {
        this.setState({mouseDown: true});
    }
    _onMouseUp = () => {
        if (this.state.mouseIn)  //released while in the component
            this._clicked();        
        this.setState({mouseDown: false});
    }
    
    
    render() {
        
        let background = this.state.mouseIn ? ThemeColors.tableAlternatingRowsGray : 'white'; 
        if (this.state.mouseDown)
            background = ThemeColors.tableHeaderBackgroundColor;
        
        return (
            
            <Paper style={{marginLeft: 20, marginRight: 20, padding: 10, minHeight: 100, background: background}} onMouseOver={this._onMouseOver} 
                    onMouseOut={this._onMouseOut} onMouseDown={this._onMouseDown}  onMouseUp={this._onMouseUp}>
                   
                <DatabaseHeader database={this.props.database}/>


            </Paper>
        );
    }
}
    
               