import React, { Component } from 'react';
import { Container, Popover, Button, TextField, Box, Typography, Grid } from '@material-ui/core'
import { AudioPlay } from '../utils/Audio'



export default class OccupancyChangePopover extends Component {

    styles = {
        button: {
            marginLeft: 10,
            marginRight: 10
        },
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
    };

 
    _okCallback;
    _cancelCallback;
    
    state = {
        textEntry: null
    }
    
    constructor(props) {
        super(props);
        this._okCallback = props.okCallback;
        this._cancelCallback = props.cancelCallback;
    }
   
    _textFieldChanged = (event) => {
        let val = event.target.value;
        if (val < 0)
            val = 0;
        this.setState({textEntry: val});
    }
    
    
    _okButtonPressed = () => {

        const setVal = this.state.textEntry ? this.state.textEntry : this.props.initialValue;  //use initial value if not yet set 

        this._okCallback(setVal);
        this.setState({textEntry: null});  //clear text for next time
    }
    
    _cancelButtonPressed = () => {
        this._cancelCallback();
        this.setState({textEntry: null});  //clear text for next time
    }
    
    _numberButtonPressed = (val) => {
        this._okCallback(val);
        this.setState({textEntry: null});  //clear text for next time
        AudioPlay.playCheckOutAudio();
    }
    
    render() {
        
        let maxWidth = window.innerWidth * 0.5;  //half the window, up to 350
        if (maxWidth > 350)
            maxWidth = 350;
        
        const buttons = [-1, -2, -3, -4, -5, -6];
        
        const buttonMinWidth = window.innerWidth > 400 ? 60 : 40;
                
        const setVal = this.state.textEntry ? this.state.textEntry : this.props.initialValue;  //use initial value if not yet set 
        
        return (
            <Popover open={this.props.isOpen} 
                anchorReference="anchorPosition"
                anchorPosition={{left: window.innerWidth/2, top: window.innerHeight/2}}
                transformOrigin={{vertical: 'center', horizontal: 'center'}}  >
                <Box style={{margin: 20, width: maxWidth}}>
                    <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>Change Occupancy</Typography>  
                    <div style={{marginTop: 20}}/>

                    <Grid container direction="row" spacing={0}>
                    
                        {buttons.map((button, index) =>
                            (<Grid item xs={4} key={index}>
                                <Container style={this.styles.container}>    
                                    <Button onClick={() => {this._numberButtonPressed(button)}} color="primary" variant="outlined" style={{minWidth: buttonMinWidth}}>{button}</Button>
                                </Container>
                            </Grid>))}
                    
                    </Grid>

                    <div style={{marginTop: 20}}/>
                    <Container style={this.styles.container}>    
                        <TextField label="Set" type="number" value={setVal} onChange={this._textFieldChanged} variant="outlined" fullWidth={false} InputLabelProps={{ shrink: true}} />
                    </Container>
        
                    <Container style={this.styles.container}>    
                        <Button onClick={this._okButtonPressed} color="primary" variant="outlined" style={this.styles.button}>OK</Button>
                        <Button onClick={this._cancelButtonPressed} variant="outlined" style={this.styles.button}>Cancel</Button>
                    </Container>
                </Box>
            </Popover>
        );
        
        
    }


}