import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';

import { Typography, Container, Button, Link } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import PrintIcon from '@material-ui/icons/Print';

import PoolPassTheme, { ThemeColors } from '../Theme'
import { RestComponent } from 'react-frontend-utils'

import { PatronAppBar, DatabaseHeader } from '../components/PatronAppBar'
import { PublicAccount } from '../models/Account'

import Store from '../patron/Store'
import Checkout from '../patron/Checkout'


/**
 * Route page for a single database's Patron Portal.  The caller must pass a prop called "facility" which is the database name to use. The
 * public details on this database are fetched on component mount.
 * 
 * The caller may pass a prop called referrer, which is a unique code emailed to the patron. If this is present the patron's account
 * number and name is fetched. If not, the user can proceed as guest or request to be emailed a referrer code
 * 
 * The caller may alternatively pass a prop called qr, which is the account id. If this is present the patron's account
 * number and name is fetched. If not, the user can proceed as guest or request to be emailed a referrer code
 * 
 * Once the database (and account, if referred) is retrieved, the component starts with the Store page visible. Selecting "Checkout" on the Store
 * page switches to the Checkout Page (the Store page still exists - it is hidden and can be returned to)
 * 
 * The "database" object used in this class is not defined as an object, but is represented by the JsonPublicGroup fields from the backend
 */
class PatronPortal extends RestComponent {
  
    styles = {       
        divider: {
            marginTop: 10, 
            marginBottom: 20, 
            border: '2px solid gray'
        },
        link : {
            display: 'flex', 
            justifyContent: 'center', 
            color: 'white',
            textDecoration: 'underline',
            fontSize: 14
        }
    };
    
    
    _facilityName;

    constructor(props) {
        super(props);
        this.state.isMobile = false;
        this.state.isVerySmall = false;
        this.state.database = null;                              //the database that was fetched after load
        this.state.account = null;                               //the PublicAccount, if null, proceed as guest.
        this.state.checkout = null;                              //the checkout object, from Store page, passed to Checkout Page
        
        this.state.showStore = false;
        this.state.showCheckout = false;
        this.state.checkoutComplete = false;
        this.state.checkoutWasFree = false;
        this.state.qrCodeImage = null;
        this.state.accountID = null;
        
        this._facilityName = encodeURIComponent(props.facility);  //encode for URL safe
    }


    componentDidMount() {
        super.componentDidMount();
        this._updateSize();
        this._fetchDatabase();
        window.addEventListener("resize", this._updateSize);
    }
  
    componentWillUnmount() {
       super.componentWillUnmount();
       window.removeEventListener("resize", this._updateSize);
    }

    //callback when window changes size
    _updateSize = () => {
        this.setState({isMobile: window.innerWidth < 916, isVerySmall: window.innerWidth < 476 });  //custom, split between bootstrap and mui
    }

    //Called after mounting the page
    _fetchDatabase = () => {
        this.setBusy(true);
        this.secureJSONFetch("/patron/databases/" + this._facilityName, {}, this._fetchDatabaseCallback, this._fetchErrorCallback); 
    }


    //When database sucessfully fetched, get the details from the response, and then if the referrer or qr is set,
    //fetch the account details from it
    _fetchDatabaseCallback = (response) => {
        if (response) {

            const database = response;
            if (this.props.referrer) {
                console.log("Fetch " + this.props.referrer);
                this._fetchAccount("referrer=" + this.props.referrer, database);  //the account query is a referrer code
                return;  //don't clear busy, still fetching
            }
            else if (this.props.qr) {
                this._fetchAccount("qr=" + this.props.qr, database);  //the account query is a qr code
                return;  //don't clear busy, still fetching
            }
            else { //no referrer, goto Store a Guest
                console.log("Arrived as Guest");
                this.setState(({database: database, showStore: true}));
            }
        } 
        this.setBusy(false);
    }
    
    //Call to fetch the account from the account link
    _fetchAccount = (accountQuery, database) => {
        
        this.secureJSONFetch("/patron/databases/" + this._facilityName + "/account?" + accountQuery, 
                             {}, (response) => this._fetchAccountCallback(response, database), this._fetchErrorCallback); 
    }
    
    
    //If account was fetched, set the PublicAccount.  Then proceed with Account.
    _fetchAccountCallback = (response, database) => {
        this.setBusy(false);
        if (response) {
            const account = new PublicAccount(response);
            this.setState({database: database, account: account, showStore: true});  //goto store, logged in with account
        }
           
    }
    
    
    _fetchErrorCallback = (error) => {
        this.setBusy(false);
        this.setState({serverError: error.toString()});
    }
    
    
    _onCheckoutFromStore = (checkout) => {
        this.setState({checkout: checkout, showStore: false, showCheckout: true}); //switch to the checkout page
    }

    _returnToStore = () => {
        this.setState({showStore: true, showCheckout: false}); //switch back to the store page
    }
    
    //show the purchase complete, end.
    _purchaseComplete = (accountID, wasCharged) => {
        this.setState({accountID: accountID, showStore: false, showCheckout: false, checkoutComplete: true, checkoutWasFree: !wasCharged}); 
        
        this.secureJSONFetch("/patron/qrImage?value=" + accountID, {},
                             this._fetchQRCodeCallback, this._fetchErrorCallback); 
        
    }
    
    _fetchQRCodeCallback = (response) => {
        if (response) {
            const qrCodeBase64Image = response;
            this.setState({qrCodeImage: qrCodeBase64Image});
        }
    }
    
    //If the Proceed as Guest button is pressed, clear the account information that may have come from referrer
    _clearAccount = () => {
        this.setState({account: null});
    }
    

    render() {
        
        const gutterMargin = this.state.isMobile ? 8 : 20;
        let limitMaxWidth = false;

        const serverErrorMessage = this.state.serverError ? <Typography variant="h5">Server Error: {this.state.serverError}</Typography> : null;        

        return (
    
            <ThemeProvider theme={PoolPassTheme}>
                <Fragment>

                    <PatronAppBar isMobile={this.state.isMobile}/>
                                   
                    {serverErrorMessage}
                                        
                    <Container maxWidth={limitMaxWidth ? 'lg' : false} disableGutters={true} style={{paddingLeft: gutterMargin, paddingRight: gutterMargin}}>
                        {this.state.database ?  <div>      
                                                    <DatabaseHeader showHomeLink={true} database={this.state.database}/> 
                                                    <div style={this.styles.divider}/>
                                                </div> : null}

                        {this.state.isBusy ? this.getBusyComponent('center', {marginTop: 20, marginBottom: 20}) : null}

                        {this.state.database && !this.state.checkoutComplete ?
                            <div style={{minHeight: 450}}>
                                <Store visible={this.state.showStore} database={this.state.database} account={this.state.account} 
                                        onProceedAsGuestRequested={this._clearAccount}
                                        onCheckoutRequested={this._onCheckoutFromStore}
                                        returnFromCheckout={this.state.checkout}
                                        isMobile={this.state.isMobile} isVerySmall={this.state.isVerySmall}/>

                                <Checkout visible={this.state.showCheckout} database={this.state.database} account={this.state.account} 
                                          checkout={this.state.checkout} returnToStore={this._returnToStore} purchaseComplete={this._purchaseComplete}/>        
                        
                            </div> : null
                        }
                        
                        {this.state.checkoutComplete ? 
                            <div>
                                <Typography style={{display: 'flex', justifyContent: 'center'}} variant="h6">
                                    {"Thank you" + (this.state.checkoutWasFree ? "!" : " for your purchase!")}
                                 </Typography> 
                                 
                                {this.state.database.ticketEnabled ?
                                    <Fragment>

                                        <Typography style={{display: 'flex', justifyContent: 'center'}} variant="h6">
                                            You can print the Ticket below and present it at your facility. If you provided an email address, you will receive this Ticket and a receipt by email.
                                        </Typography>  
                                        <img style={{display: 'block', marginLeft: 'auto', marginRight: 'auto', marginTop: 40, marginBottom: 30}} 
                                             src={this.state.qrCodeImage} alt={"qrCode"} width={200} height={200}/>

                                        <Typography style={{display: 'flex', justifyContent: 'center', marginBottom: 60}} variant="h6">
                                            {this.state.accountID}
                                        </Typography> 

                                        <div style={{marginLeft: 'auto', marginRight: 'auto', maxWidth: 200, marginBottom: 40}}>
                                            <Button onClick={() => window.print()} variant="contained" fullWidth
                                                color="primary" style={{color: 'white'}} startIcon={<PrintIcon/>}>Print Ticket</Button>
                                        </div>

                                        <Typography style={{display: 'flex', marginTop: 40, justifyContent: 'center', color: 'gray'}} variant="body2">
                                            Keep this ticket as you would cash.  Tampering with this ticket's unique QR code or using the ticket in any other way than its intended purpose violates the Patron Portal terms and conditions.
                                        </Typography> 
                                    </Fragment>
                                : 
                                    <Typography style={{display: 'flex', justifyContent: 'center'}} variant="h6">
                                        If your purchase included guest passes, they have been added to your Membership. For other purchases, see your community website for additional information.
                                    </Typography>
                                }
                                
                                                              
                            </div> : null
                        }
                            
                            
                    </Container>
                    
                    { /*-----------   FOOTER  -------------------*/ null }

                    <div style={{display: 'block', padding: 20, marginTop: 40, backgroundColor: ThemeColors.appBarBackground}}>
                       <Link style={this.styles.link} href={"//" + window.location.hostname + ":" + window.location.port + "/patron/terms"}>Terms and Conditions</Link>
                    </div>
                </Fragment>
            </ThemeProvider>    
        );
    }
};

export default withCookies(PatronPortal);


