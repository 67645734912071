import React from 'react';
import { withCookies } from 'react-cookie';

import { IconButton, Tooltip, Grid, Typography, Paper, TextField, Button } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import { ThemeColors } from '../Theme'
import { PP } from '../models/PP'
import { Membership, membershipTooltip } from '../models/Membership'
import { Restriction, RestrictionType } from '../models/Restriction'

import { RestComponent, PopupMenu, StyledTooltip } from 'react-frontend-utils' 
import { DataTable, DataTableComponent } from 'react-frontend-utils'




export class RestrictionsSubpage extends RestComponent {
  
    styles = {
        paperLabel: {
            marginLeft: 15,
            color: 'blue',
            fontSize: '12pt',
            flexGrow: 1
        },
        gridStyle: {
            marginLeft: 20, 
            marginRight: 20,
            display: 'flex',
            alignContent: 'center'
        },
        submitButton: {
            marginTop: 30,
            marginBottom: 20,
            display: 'flex', 
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        lineItem: {
            marginLeft: 5,
            marginBottom: 8,
            flexGrow: 1
        }
    };
  
       
    
  
    //An array describing each column, generate dynamically to refresh on state change
    columns = () => { return  [
        
        {name: "restriction", label: "Location",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.firstCellProps,
                      customBodyRender: val => val.location
                      }}, 
  
        {name: "type", label: "Type",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.firstCellProps, customBodyRender: DataTable.longStringCellRenderer}}, 
  
        {name: "subject", label: "Restriction",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.lastCellProps, customBodyRender: DataTable.longStringCellRenderer}}
    
    ];};


    
    _backToOperationsTab;
    _fetchCount = 0;            //when creating new Restriction, we fetch Memberships and Types.  This count determines when both fetches are done, and we can render
   
    
    tableHasSelectedRows = () => {
        return this.state.selectedRow >= 0;
    }
    
    
    //True if a row can be modified
    canModify = () => {
        if (!this.tableHasSelectedRows())
            return false;
        
        const row = this.state.tableData[this.state.selectedRow];
        
        //selected row not in filtered data
        if (row === undefined)
            return false;
        
        return true;
    }
    
    
    
    constructor(props) {
        super(props);
        this._backToOperationsTab = props.backToOperationsTab;
        
        this.state.restrictions = [];    //array of Restrictions, from last fetch
        
        this.state.selectedRow =  -1;                    //the currently selected row in the table
        this.state.newRestrictionOpen = false;          //display the new Restriction form, instead of the table
        
        this.state.tableData = [];         //all the table data
        
        
        //Fields below are used when creating a new Restriction
        this.state.membershipTypes = [];            //fetched types from database, array of type names
        this.state.selectedMembershipType = null;   //currently selected type
        
        this.state.memberships = [];                //fetched JsonMemberships array from database
        this.state.selectedMembership = null;       //currently selected Membership
        
        this.state.selectedLocation = "";
        this.state.selectedLocationValue = null;
        
        this.state.guestsField = 0;
        this.state.memberIDField = 1;
        this.state.type = RestrictionType.MEMBER.ordinal;
        this.state.locationField = "";        
               
        this.state.tableOptions =  {onRowSelectionChange: ((currentRowsSelected) => this._rowClick(currentRowsSelected)),
                                    customToolbar: () => {return null; },
                                    setRowProps: (row, dataIndex, rowIndex) => this._setRowProps(row, dataIndex, rowIndex)
                                    };
        
        
    }
    
    
    
    /**
     * When the page loads, refresh the table
     */
    componentDidMount() {
        super.componentDidMount();
        this._updateSize();
        window.addEventListener("resize", this._updateSize);
        
        this._refreshTable();

    }
    
    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener("resize", this._updateSize);
    }

    //callback when window changes size
    _updateSize = () => {
        this.setState({ isMobile: window.innerWidth < 960, isWide: window.innerWidth > 1280 });
    }
    
    
    _setRowProps = (row, dataIndex, rowIndex) => {
        return DataTable.getRowStyle(this.state.selectedRow, dataIndex, rowIndex, ThemeColors.selectedColor, ThemeColors.tableAlternatingRowsGray);
    }
   
   
    //Callback when a row is clicked
    _rowClick = (currentRowsSelected) => {
     
        const row = currentRowsSelected.length > 0 ? currentRowsSelected[0].dataIndex : -1;
        
        if (row >= 0) {
            const id = this.state.tableData[row].restriction.id;
            console.log("Restriction List: selected row: " + row + " Restriction id: " + id);
        }
       
        this.setState( {selectedRow: row} );
       
        
    }
    
    
    //Callback when all Membership types are fetched, response is array of type Strings, select the first in the list
    _fetchMembershipTypesCallback = (response) => {
        if (response) {    
            const selectedType = response.length > 0 ? response[0] : null;
            this.setState({membershipTypes: response, selectedMembershipType: selectedType});
        } 
        this.decrementBusy();
        this._fetchCount--;
        
        //If no more fetches ongoing, open the new restriction
        if (this._fetchCount === 0) {
            this.setState({newRestrictionOpen: true});
        }
        
    }

    //Callback when all Memberships are fetched, response is array of JsonMemberships, select the first in the list
    _fetchMembershipsCallback = (response) => {
        if (response) {    
            const memberships = response.map(m => new Membership(m));
            const selectedMembership = memberships.length > 0 ? memberships[0] : null;
            this.setState({memberships: memberships, selectedMembership: selectedMembership});
        } 
        this.decrementBusy();
        this._fetchCount--;
        
        //If no more fetches ongoing, open the new restriction
        if (this._fetchCount === 0) {
            this.setState({newRestrictionOpen: true});
        }
    }
    
    _refreshTable = () => {
        if (PP.selectedDatabase) {
        
            this.incrementBusy();
   
            //fetch all Restrictions
            this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/restrictions", {}, this._fetchRestrictionsCallback, this._fetchErrorCallback); 
        }
    }
    
    //Callback for fetching Restrictions - JSON response is an array of Restriction objects
    _fetchRestrictionsCallback = (response) => {
        if (response) {            
            this.state.restrictions = response.map((r) => new Restriction(r));
        }            
        this._updateTableData();
        this.decrementBusy();
    }

    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.setBusy(false);
    }
     
    
    //Clear all state for the new Restriction form
    _clearNewRestriction = () => {
        this.setState({locationField: "", memberIDField: 1, guestsField: 0, type: RestrictionType.MEMBER.ordinal});
    }
     
     
    //User selects to create a new Restriction 
    _openNewRestriction = () => {
            
        this._fetchCount = 2;    
            
        //fetch all membership types
        this.incrementBusy();
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/membership/types", {}, this._fetchMembershipTypesCallback); 

        //fetch all memberships
        this.incrementBusy();
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/memberships", {}, this._fetchMembershipsCallback); 

    } 
     
     
    
    //User requests to delete an Restriction
    _askDeleteRestriction = () => {
        if (!this.tableHasSelectedRows())
            return;
        
        const row = this.state.tableData[this.state.selectedRow];
        const r = row.restriction;

        this.showConfirmAlert("Confirm", 
                              "Delete Restriction?",
                              'black',
                              "Cancel", 
                              () => this._deleteRestriction(r.id),
                              "Delete",
                              'red');
    } 
    
    _deleteRestriction = (id) => {
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/restrictions/" + id, 
                             {method: "DELETE"}, 
                             this._refreshTable,
                             this._fetchErrorCallback); 
    }
    
    
     
    _submitNewRestriction = () => {
        
        let subject;
        switch (this.state.type) {
          
            case RestrictionType.MEMBER.ordinal:
                subject = this.state.selectedMembership.id + "-" + parseInt(this.state.memberIDField);
                break;
            
            case RestrictionType.MEMBERSHIP.ordinal:
                subject = this.state.selectedMembership.id;
                break;
            
            case RestrictionType.MEMBERSHIP_TYPE.ordinal:
                subject = this.state.selectedMembershipType;
                break;
          
            case RestrictionType.GUESTS.ordinal:
                subject = parseInt(this.state.guestsField);
                break;    
            
            default:
                subject = null;
                break;
        }
        
        const newRestriction = Restriction.create(this.state.locationField,
                                                  this.state.type,
                                                  subject
                                                  );

        this.incrementBusy();

        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/restrictions", 
                             {method: "POST", body: JSON.stringify(newRestriction)}, 
                             this._submitRestriction,
                             this._fetchErrorCallback); 
        
    }
    
    _submitRestriction = (response) => {
                
        this.decrementBusy();        
        this._clearNewRestriction();
        this.setState({newRestrictionOpen: false});
        this._refreshTable();  //pull in the change to the table
        
    }
    

    //Called when fetch is done, creates the table data
    _updateTableData = () => {
        
        
        const tableData = this.state.restrictions.map(r => {

            //For each ir, create a row with all the column data
            return {restriction: r,     //location column
                    type: r.type.label,
                    subject: r.subject
                    }; 
        });
        
        //Sort the table by location, ascending
        tableData.sort((a, b) => {
           return a.restriction.location > b.restriction.location ? 1 : -1;           
        });
         
        this.setState({tableData: tableData, selectedRow: -1});  

    }


    _closeNewRestriction = () => {
        this._clearNewRestriction();
        this.setState({newRestrictionOpen: false});
    }
    
    
    
    _locationMenuItems = () => {
                
        let menuItems = [];
        
        this.props.locations.forEach((location) => {
            menuItems.push({label: location, 
                            selectCallback: () => {this.setState({locationField: location}); }
                            });             
                
        });
         
        return menuItems;
        
    };
    
    //-------------------------------------------------------------------------------------------------------------------------

        
    render() {
        
        let membershipWidget = <div style={{display: 'flex', alignItems: 'center'}}>      
                                        <Autocomplete
                                            size='small'
                                            value={this.state.selectedMembership}
                                            onChange={(event, newValue) => {this.setState({selectedMembership: newValue});}}
                                            options={this.state.memberships}
                                            getOptionLabel={(option) => option.id}
                                            fullWidth
                                            blurOnSelect
                                            openText="Memberships"
                                            disableClearable
                                            renderInput={(params) => <TextField {...params} label="Membership ID" variant="outlined" InputLabelProps={{ shrink: true }} />}
                                        />
                                        <StyledTooltip title={membershipTooltip(this.state.selectedMembership)}>
                                            <Typography variant="body2" style={{fontSize: 16, fontWeight: 'bold', color: 'blue', marginLeft: 8}}>ⓘ</Typography>
                                        </StyledTooltip>    
                                    </div>
                      
                
        const typeOptions = [{title: RestrictionType.MEMBER.label, ordinal: 0}, 
                             {title: RestrictionType.MEMBERSHIP.label, ordinal: 1}, 
                             {title: RestrictionType.MEMBERSHIP_TYPE.label, ordinal: 2}, 
                             {title: RestrictionType.GUESTS.label, ordinal: 3}];
                             
            
        let subjectWidget;
        switch (this.state.type) {
             case RestrictionType.MEMBER.ordinal:
                subjectWidget =  <Grid container direction='row' spacing={2} >
                                    <Grid item sm={9} xs={6}>
                                        {membershipWidget}  
                                    </Grid>
                                    <Grid item sm={3} xs={6}>
                                        <TextField label="Member ID" value={this.state.memberIDField}
                                                   type="number"
                                                   InputProps={{ inputProps: { min: 1 } }}
                                                   style={{marginLeft: 5}}
                                                   onChange={(event) => this.setState({memberIDField: event.target.value})} 
                                                   variant="outlined" fullWidth size="small" InputLabelProps={{ shrink: true }} />
                                    </Grid>
                                </Grid>;
                break;
         
            case RestrictionType.MEMBERSHIP.ordinal:
               subjectWidget = membershipWidget;
                break;
                
                       
            case RestrictionType.MEMBERSHIP_TYPE.ordinal:
                subjectWidget = <Autocomplete
                                        size='small'
                                        value={this.state.selectedMembershipType}
                                        onChange={(event, newValue) => {this.setState({selectedMembershipType: newValue});}}
                                        options={this.state.membershipTypes}
                                        blurOnSelect
                                        openText="MembershipType"
                                        disableClearable
                                        renderInput={(params) => <TextField {...params} label="Membership Type" variant="outlined" InputLabelProps={{ shrink: true }} />}
                                    />
                break;
                
                
            case RestrictionType.GUESTS.ordinal:
                subjectWidget =  <Tooltip title="Enter the maximum number of guests">
                                        <TextField label="Max Guests" value={this.state.guestsField}
                                                   type="number"                                                    
                                                   InputProps={{ inputProps: { min: 0 } }}
                                                   onChange={(event) => this.setState({guestsField: event.target.value})} 
                                                   variant="outlined" fullWidth size="small" InputLabelProps={{ shrink: true }} />
                                 </Tooltip>
                break;
            
            default:
                subjectWidget = <div>Unknown Restriction Type</div>;
                break;
        }
        
               
        //Validate form fields entered before allowing submit
        const cannotSubmit = this.state.locationField.length === 0 ||
                          (this.state.type === RestrictionType.MEMBER.ordinal && parseInt(this.state.memberIDField) < 1) ||
                          (this.state.type === RestrictionType.GUESTS.ordinal && parseInt(this.state.guestsField) < 0);
                          
                               
                
        return (
            <div>

                {this.getConfirmAlertComponent()}
                
    
                { this.state.newRestrictionOpen ? 
                    //----------------- New Restriction Form -----------------------}
                    <Paper style={{padding: 20, marginLeft: this.state.isMobile ? 5 : 30, marginRight: this.state.isMobile ? 5 : 30}}>

                        <div>

                            <div style={{display: 'flex', height: 40}}>
                                <Typography variant="body2" style={this.styles.paperLabel}>Create New Restriction</Typography> 

                                <Tooltip title="Cancel">
                                    <IconButton edge="end" disabled={this.state.groupToEdit === null} onClick={this._closeNewRestriction} style={{marginTop: -4, marginRight: 4}}>
                                        <CancelIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            
                            <Grid container direction='row' style={{marginTop: 10}} spacing={2} >

                                <Grid item xs={12}>
                                    <div style={{width: '100%', display: 'flex', alignItems: 'center'}} >  
                                        <Tooltip title="Location where this restriction applies.">
                                            <TextField label="Location" value={this.state.locationField}
                                                       style={{marginRight: 10}}
                                                       onChange={(event) => this.setState({locationField: event.target.value})} 
                                                       variant="outlined" fullWidth size="small" InputLabelProps={{ shrink: true }} />
                                        </Tooltip>
        
                                        <PopupMenu menuIcon={(<ExpandMoreIcon fontSize='large' color='primary'/>)}  
                                                menuItems={this._locationMenuItems()} 
                                                menuTooltipText={"Suggest Location"}/>
                                    </div> 
                                </Grid>
                             
                                 
                                <Grid item md={4} xs={12}>
                                
                                    <Autocomplete
                                        size='small'
                                        value={typeOptions[this.state.type]}
                                        onChange={(event, newValue) => {this.setState({type: newValue.ordinal});}}
                                        options={typeOptions}
                                        getOptionLabel={(option) => option.title}
                                        fullWidth
                                        blurOnSelect
                                        openText="Type"
                                        disableClearable
                                        renderInput={(params) => <TextField {...params} label="Type" variant="outlined" InputLabelProps={{ shrink: true }} />}
                                    />
                                </Grid>
                                <Grid item md={8} xs={12} >
                                    {subjectWidget}
                                </Grid>
   
                            </Grid> 
                            
                           
                            <Button onClick={this._submitNewRestriction} variant="contained" fullWidth={false} color='primary' style={this.styles.submitButton} disabled={cannotSubmit}>Add Restriction</Button>

                            {this.getBusyComponent('center')}
                        </div>

                    </Paper>
                
                    :

                    //----------------- Restriction Table and Controls -----------------------

                    <div>  
                    
                        <Button color="primary" style={{marginBottom: 20}} onClick={this._backToOperationsTab}>
                            <ArrowBackIosIcon fontSize='small'/> 
                            <Typography variant="body1">Back to Operations</Typography>   
                        </Button> 
                        <div/>
                        <div style={{display: 'flex', float: 'right', marginTop: 15}}>
                            <Tooltip title={"Create New Restriction"}>
                                <IconButton disabled={false} edge="end" onClick={this._openNewRestriction} style={{marginTop: -4}} enterTouchDelay={0}>
                                    <AddCircleOutlineIcon style={{color: ThemeColors.addColor}} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={"Delete Restriction"}>
                                <IconButton disabled={!this.tableHasSelectedRows()} edge="end" onClick={this._askDeleteRestriction} style={{marginTop: -4, marginRight: 4}}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>     
                        </div>                          

                        <DataTableComponent title={this.getBusyComponent('left')} data={this.state.tableData} columns={this.columns()} options={this.state.tableOptions} hoverColor={ThemeColors.tableHover}/>       

                    </div>
                }
                
            </div>

        );

    }
}



export default withCookies(RestrictionsSubpage);

