import React, { Component } from 'react';
import { Container, Popover, Button, TextField, Box, Typography } from '@material-ui/core'

import { PP } from '../models/PP'



export default class AdjustBalancePopover extends Component {

    styles = {
        button: {
            marginLeft: 10,
            marginRight: 10
        },
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 20,
            marginBottom: 10
        }
    };

 
    _okCallback;
    _cancelCallback;
    
    state = {
        balanceEntry: null,
        commentEntry: null
    }
    
    constructor(props) {
        super(props);
        this._okCallback = props.okCallback;
        this._cancelCallback = props.cancelCallback;
        this._maxChars = props.maxChars ? props.maxChars : 1024;
    }
   
    _numberFieldChanged = (event) => {
        let val = event.target.value;
        if (val == 0)
            val = null;
        this.setState({balanceEntry: val});
    }
    
    _commentFieldChanged = (event) => {
        let val = event.target.value;
        this.setState({commentEntry: val});
    }
    
    
    _okButtonPressed = () => {

        if (!this.state.balanceEntry)
            return;

        const setVal = this.state.balanceEntry; 
        const comment = this.state.commentEntry;
        
        this._okCallback(setVal, comment);
        this.setState({balanceEntry: null, commentEntry: null});  //clear text for next time
    }
    
    _cancelButtonPressed = () => {
        this._cancelCallback();
        this.setState({balanceEntry: null, commentEntry: null});  //clear text for next time
    }
    
    
    render() {
        
        let maxWidth = window.innerWidth * 0.5;  //half the window, up to 350
        if (maxWidth > 700)
            maxWidth = 700;
        
         
        return (
            <Popover open={this.props.isOpen} 
                anchorReference="anchorPosition"
                anchorPosition={{left: window.innerWidth/2, top: window.innerHeight/2}}
                transformOrigin={{vertical: 'center', horizontal: 'center'}}  >
                
                <Box style={{margin: 20, width: maxWidth}}>
                    <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>Adjust Balance</Typography>  
                    <div style={{marginTop: 20}}/>

                    <Container style={this.styles.container}>    
                        <TextField label="Adjustment" type="number" onChange={this._numberFieldChanged} variant="outlined" fullWidth={false} InputLabelProps={{ shrink: true}} />
                        <Typography variant="subtitle1" style={{marginLeft: 10}}>{PP.currency}</Typography>  
                    </Container>

                    <TextField label="Comment" autoFocus multiline={true} rows={5} value={this.state.commentEntry} onChange={this._commentFieldChanged} variant="outlined" fullWidth={true} inputProps={{maxLength: this._maxChars}} InputLabelProps={{ shrink: true}} />
        
                    <Container style={this.styles.container}>    
                        <Button onClick={this._okButtonPressed} disabled={!this.state.balanceEntry} color="primary" variant="outlined" style={this.styles.button}>OK</Button>
                        <Button onClick={this._cancelButtonPressed} variant="outlined" style={this.styles.button}>Cancel</Button>
                    </Container>
                </Box>
            </Popover>
        );
        
        
    }


}