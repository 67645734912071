import React from 'react';

import { multilineJSX } from 'react-frontend-utils'
import { DateUtils, Currency } from 'react-frontend-utils'
import { PP } from './PP'
import { ThemeColors } from '../Theme'

export const InvoiceRequestStatus = {
    SCHEDULED:       {ordinal: 0, label: "Scheduled", tooltip: "This request is scheduled for the future."},
    PROCESSING:      {ordinal: 1, label: "Processing", tooltip: "The request is currently being processed, try refreshing the table momentarily."},
    SENT:            {ordinal: 2, label: "Sent", tooltip: "The request has been sent to all recipients. This request is closed."},
    STOPPED:         {ordinal: 3, label: "Stopped", tooltip: "Scheduling of this request has been stopped. The request is closed."},
    NOT_SENT:        {ordinal: 4, label: "Not Sent", tooltip: "No Memberships matched the request criteria. The request is closed."},
    ERRORED:         {ordinal: 5, label: "Errored", tooltip: "Invoices were not sent because of an error"},
    
    isComplete: (type) => {
        return type === InvoiceRequestStatus.SENT || type === InvoiceRequestStatus.STOPPED  || type === InvoiceRequestStatus.NOT_SENT || type === InvoiceRequestStatus.ERRORED;
    },
    
    style: (status) => {
        
        const baseStyle = {paddingLeft: 2, paddingRight: 2, borderRadius: 2, color: 'white', textAlign: 'center'};
        
        switch (status) {
            case InvoiceRequestStatus.PROCESSING:
                return {...baseStyle, color: 'black', backgroundColor: ThemeColors.processingYellow};
            
            case InvoiceRequestStatus.SENT:
                return {...baseStyle, backgroundColor: ThemeColors.depositGreen};

            case InvoiceRequestStatus.SCHEDULED:
                return {...baseStyle, backgroundColor: ThemeColors.transactionBlue};
            
            case InvoiceRequestStatus.ERRORED:
                return {...baseStyle, backgroundColor: ThemeColors.errorRed};

            case InvoiceRequestStatus.NOT_SENT:
            case InvoiceRequestStatus.STOPPED:
                return {...baseStyle, backgroundColor: ThemeColors.cancelGray};
            
            default:
                return baseStyle;
        }      
    }
    
    
};

export const InvoiceRequestMode = {
    ALL:             {ordinal: 0, label: "All Memberships"},
    TYPE:            {ordinal: 1, label: "Membership Type"},
    MEMBERSHIP:      {ordinal: 2, label: "Single Membership"},
    OTHER:           {ordinal: 3, label: "Other"}
};


export const InvoiceRequestOptions = [{name: "SUSPENDED", tooltip: "When checked only suspended Memberships will receive this invoice"}, 
                                      {name: "EXPIRED", tooltip: "When checked only expired Memberships will receive this invoice"},
                                      {name: "OVERDUE", tooltip: "When checked only Memberships with overdue invoices will receive this invoice (often used for late fees)"}];



export const RecurFrequency = {
        NEVER:          {ordinal: 0, label: "Never", description: "Invoice will be sent only once"},
        WEEKLY:         {ordinal: 1, label: "Weekly", description: "Invoice will be sent on the same day, each week"},
        BIWEEKLY:       {ordinal: 2, label: "Bi-Weekly", description: "Invoice will be sent on the same day, every 2 weeks"},
        MONTHLY:        {ordinal: 3, label: "Monthly", description: "Invoice will be sent on the same date of each month"},
        BIMONTHLY:      {ordinal: 4, label: "Bi-Monthly", description: "Invoice will be sent on the same date, every 2 months"}, 
        TRIMONTHLY:     {ordinal: 5, label: "Tri-Monthly", description: "Invoice will be sent on the same date, every 3 months"}, 
        SEMIANNUALY:    {ordinal: 6, label: "Semi-Annually", description: "Invoice will be sent on the same date, twice a year"},
        ANNUALLY:       {ordinal: 7, label: "Annually", description: "Invoice will be sent on the same date each year"},
        BIENNIALLY:     {ordinal: 8, label: "Biennially", description: "Invoice will be sent on the same date, every 2 years"},
        
        doesRecur: (val) => {
            return val.ordinal > 0;
        },
        
        fromOrdinal: (o) => {
            for (let index in RecurFrequency) {
                const recur = RecurFrequency[index];

                if (recur.ordinal === o)
                    return recur;
            } 
            return null;
        }
};



export const InvoiceRequestLineItemQuanInterpretation = {
    
    NORMAL:           {ordinal: 0, label: "Normal"},
    PER_MEMBER:       {ordinal: 1, label: "Per Member"},
    OVERDUE_BAL:      {ordinal: 2, label: "% of Overdue Balance"}
};


export class InvoiceRequestLineItem {
    
    name;
    quantity;   
    unitPrice;
    quanInterp;     //InvoiceRequestLineItemQuanInterpretation enum on incoming, ordinal on outgoing
    
    constructor(json) {
        if (json) {
            this.name = json.name;
            this.quantity = json.quantity;
            this.unitPrice = json.unitPrice;

            for (let index in InvoiceRequestLineItemQuanInterpretation) {
                const interp = InvoiceRequestLineItemQuanInterpretation[index];

                if (interp.ordinal === json.quanInterp)
                    this.quanInterp = interp;
            } 
        }
    }
    
    static create() {
        const li = new InvoiceRequestLineItem();
        li.name = "";
        li.quantity = 1;
        li.unitPrice = 0.0;
        li.quanInterp = InvoiceRequestLineItemQuanInterpretation.NORMAL.ordinal;
        return li;
    }
    
    
    //Returns a JSX format of the line item in table row 
    tooltip = (index, style) => {
        
        let amount;
        switch (this.quanInterp) {
            case InvoiceRequestLineItemQuanInterpretation.NORMAL:
                amount = "(" + this.quantity + ") x " + Currency.symbolForISO(PP.currency) + this.unitPrice.toFixed(2);
                break;
            case InvoiceRequestLineItemQuanInterpretation.PER_MEMBER:
                amount = "num members x " + Currency.symbolForISO(PP.currency) + this.unitPrice.toFixed(2);
                break;
            case InvoiceRequestLineItemQuanInterpretation.OVERDUE_BAL:
                amount = this.quantity + "% of outstanding balance";
                break;
            default:
                amount = "?";
                break;
        }
        
        return (<tr key={index} style={style}>
                    <td style={style}><b>{index+1}</b></td>
                    <td style={style}>{this.name}</td>
                    <td style={style}>{amount}</td>
                </tr>
               );
        
    };

    
    
}



/**
 * This class represents a InvoiceRequest for Billing, received from a JsonInvoiceRequest
 */
export class InvoiceRequest {
    
    id   
    status;             //InvoiceRequestStatus enum on incoming, ordinal on outgoing
    createDate;
    lastSentDate;
    nextSendDate;
    cycle;
    subject;
    mode;               //InvoiceRequestMode enum on incoming, ordinal on outgoing
    recipient;
    options;
    recur;
    lineItems = [];
    daysDue;
    note;
    comment;
    
    expDateOnPay;
    emailOnPay;
    unsuspendOnPay;
    incGuestOnPay;
    incExpOnPay;
    
    lastActivity;
 
    constructor(irJson) {
        if (irJson) {
            
            this.id = irJson.id;
            
            if (irJson.status != null) {
                for (let index in InvoiceRequestStatus) {
                    const status = InvoiceRequestStatus[index];

                    if (status.ordinal === irJson.status)
                        this.status = status;
                }
                
            }
            
            this.createDate = DateUtils.parseJsonDate(irJson.createDate, true); 
            
            if (irJson.lastSentDate != null) {
                this.lastSentDate = DateUtils.parseJsonDate(irJson.lastSentDate, true); 
            }
            if (irJson.nextSendDate != null) {
                this.nextSendDate = DateUtils.parseJsonDate(irJson.nextSendDate, true); 
            }
            this.cycle = irJson.cycle;

            this.subject = irJson.subject;
            
            if (irJson.mode != null) {
                for (let index in InvoiceRequestMode) {
                    const mode = InvoiceRequestMode[index];

                    if (mode.ordinal === irJson.mode)
                        this.mode = mode;
                }              
            }
            
            
            this.recipient = irJson.recipient;
            this.options = irJson.options;
            
             if (irJson.recur != null) {
                 this.recur = RecurFrequency.fromOrdinal(irJson.recur);             
            }
            
            if (irJson.lineItems) {
                irJson.lineItems.forEach(lineItem => {this.lineItems.push(new InvoiceRequestLineItem(lineItem));});
            }
            
            this.daysDue = irJson.daysDue;
            this.note = irJson.note;
            this.comment = irJson.comment;
            this.lastActivity = irJson.lastActivity;
            
            if (irJson.expDateOnPay != null)
                this.expDateOnPay = DateUtils.parseJsonDate(irJson.expDateOnPay);
            
            this.emailOnPay = irJson.emailOnPay;
            this.unsuspendOnPay = irJson.unsuspendOnPay;
            this.incGuestOnPay = irJson.incGuestOnPay;
            this.incExpOnPay = irJson.incExpOnPay;
        }       
    }
    
  
    static create(subject, mode, recipient, options, lineItems, scheduleDate, daysDue, recur, note, comment, expDateOnPay, emailOnPay, unsuspendOnPay, incGuestOnPay, incExpOnPay) {
        const ir = new InvoiceRequest();
        ir.subject = subject;
        ir.mode = mode;
        ir.recipient = recipient;        
        ir.options = options;
        ir.lineItems = lineItems;
        ir.nextSendDate = scheduleDate ? DateUtils.jsonUTCDateString(scheduleDate, true) : null;  //null for immediate
        ir.daysDue = daysDue;
        ir.recur = recur;
        ir.note = note;
        ir.comment = comment;
        ir.expDateOnPay = expDateOnPay;
        ir.emailOnPay = emailOnPay;
        ir.unsuspendOnPay = unsuspendOnPay;
        ir.incGuestOnPay = incGuestOnPay;
        ir.incExpOnPay = incExpOnPay;
          
        return ir;
    }
    
    
    static optionBitPosition(optionName) {
   
        for (let i=0; i<InvoiceRequestOptions.length; i++) {
            if (InvoiceRequestOptions[i].name === optionName)
                return i;
        }
    }

    
    forSuspended = () => {
        const shift = InvoiceRequest.optionBitPosition("SUSPENDED");
        return ((this.options >> shift) & 0x1) === 1;
    }
    
    forExpired = () => {
        const shift = InvoiceRequest.optionBitPosition("EXPIRED");
        return ((this.options >> shift) & 0x1) === 1;
    }
    
    forHasBalance = () => {
        const shift = InvoiceRequest.optionBitPosition("HAS BALANCE");
        return ((this.options >> shift) & 0x1) === 1;
    }
    
    recipientDescription = () => {
    
        switch (this.mode) {
            case InvoiceRequestMode.ALL:
                return "All Memberships";

            case InvoiceRequestMode.TYPE:
                return "Membership Type: " + this.recipient;

            case InvoiceRequestMode.MEMBERSHIP:
                return "Single Membership: " + this.recipient;

            case InvoiceRequestMode.OTHER:
                return "Other: " + this.recipient;  

            default:
                return "?";    
        }
    }
    
}
        

//Returns a JSX format of invoice request data suitable for use on a tooltip on hover over an Invoice Request item
export const invoiceRequestTooltip = (ir) => {
    if (!ir)
        return "";

    return (<div>
            <div style={{...InvoiceRequestStatus.style(ir.status), float: 'right'}}>{ir.status.label}</div>
                <div style={{paddingBottom: 8, borderBottom: '1px solid gray'}}><b>Subject: </b>{ir.subject}</div> 
                <div><b>Recipient: </b>{ir.recipientDescription()}</div>
                <div><b>Created: </b>{PP.checkInTimeFormat(ir.createDate)}</div> 
                <div><b>Last Sent: </b>{ir.lastSentDate ? PP.checkInTimeFormat(ir.lastSentDate) : ""}</div> 
                <div><b>Next Send: </b>{ir.nextSendDate ? PP.checkInTimeFormat(ir.nextSendDate) : ""}</div> 
                <div style={{marginTop: 5}}/>
                <div><b>Comment: </b>{multilineJSX(ir.comment, true)}</div>
            </div>

           );  
};
  