import React, { Fragment } from 'react';

import { ThemeProvider } from '@material-ui/styles'

import PoolPassTheme from '../Theme'

import { ApiInteractiveDoc } from '../pages/HelpPage'

//Public access routes to API documentation

export function ApiDoc(props) {


    return (                
        <ThemeProvider theme={PoolPassTheme}>
            <Fragment>
                <ApiInteractiveDoc/>
            </Fragment>
        </ThemeProvider>    

    );
       
}

export function ApiManual(props) {
       
    const iframeStyle = {
        flexGrow: 1, 
        border: 'none', 
        width: '100%',
        height: window.innerHeight,
        marginTop: 10,
        padding: 0
    };
    
    return <iframe title="Help Content" style={iframeStyle} src="/publicdoc/api.html"></iframe>; 

}