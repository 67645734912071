import React from 'react';
import ag_logo from '../assets/ag_logo.png'
import pp_logo from '../assets/pp_logo.png'
import winIcon from '../assets/winIcon.png'
import iosIcon from '../assets/iosIcon.png'
import rfidIcon from '../assets/rfidIcon.png'
import avatar from '../assets/avatar.png'
import avatar_grey from '../assets/avatar_grey.png'
import generic_product from '../assets/generic_product.png'
import generic_product_grey from '../assets/generic_product_grey.png'

export const MEMBER_IMAGE_NATIVE_WIDTH = 412;
export const MEMBER_IMAGE_NATIVE_HEIGHT = 550;

export const PRODUCT_IMAGE_NATIVE_WIDTH = 640;
export const PRODUCT_IMAGE_NATIVE_HEIGHT = 640;

const MEMBER_IMAGE_WIDTH = MEMBER_IMAGE_NATIVE_WIDTH/1.3;
const MEMBER_IMAGE_HEIGHT = MEMBER_IMAGE_NATIVE_HEIGHT/1.3;

export const MEMBER_IMAGE_ASPECT_RATIO = MEMBER_IMAGE_NATIVE_WIDTH/MEMBER_IMAGE_NATIVE_HEIGHT;
export const PRODUCT_IMAGE_ASPECT_RATIO = 1.0;

const MOBILE_SCALE = 1.5;

//LOGO for top menu bar
export const logo = (<img src={ag_logo} alt="AGLogo" height="48" style={{background: 'white', padding: 4, borderRadius: 8}}/>);

export const appIcons = {
    macIconImg: (<img src={pp_logo} alt="MacIcon" width="64" height="64" />),
    winIconImg: (<img src={winIcon} alt="WinIcon" width="64" height="64" />),
    iosIconImg: (<img src={iosIcon} alt="iOSIcon" width="64" height="64" />),
    rfidIconImg: (<img src={rfidIcon} alt="rfidIcon" width="64" height="64" />)
};




export const ImageFunctions = {

    memberImageSize: (isMobile = false) => {

        const scale = isMobile ? MOBILE_SCALE : 1.0;

        return {width: MEMBER_IMAGE_WIDTH/scale, 
                height: MEMBER_IMAGE_HEIGHT/scale};

    },

    productImageSize: () => {
        
        return {width: PRODUCT_IMAGE_NATIVE_WIDTH/4.2, 
                height: PRODUCT_IMAGE_NATIVE_HEIGHT/4.2};

    },

    /**
     * Returns JSX img tag with the image from the base64 encoded member image. If there is no image,
     * a generic avatar image is returned
     * @param member {Member} to render the image for
     * @param isMobile {Boolean} isMobile, true if size is xs
     * @param scale a value to scale down by (2 = 2 times smaller)  Default = 1.0
     * @param opacity, a value 0.0 to 1.0, where 1.0 is fully opaque.  Default = 1.0
     * @returns img tag with the local image
     */
    memberImageURL: (member, isMobile = false, scale = 1.0, opacity = 1.0) => {

        const size = ImageFunctions.memberImageSize(isMobile);

        if (member.imageBase64) {
            return (<img src={member.imageBase64} 
                        alt={"member"}
                        width={size.width / scale} 
                        height={size.height / scale} 
                        style={{opacity: opacity}}
                    />);
        }
        else {
           return (<img src={avatar} alt="member avatar" 
                        width={size.width / scale} 
                        height={size.height / scale}  
                        style={{opacity: opacity}}
                    />);
        }

    },


    /**
     * Returns JSX img tag with the image from the base64 encoded product image. If there is no image,
     * a generic avatar image is returned
     * @param product {Product} to render the image for
     * @param scale a value to scale down by (2 = 2 times smaller)  Default = 1.0
     * @param opacity, a value 0.0 to 1.0, where 1.0 is fully opaque.  Default = 1.0
     * @returns img tag with the local image
     */
    productImageURL: (product, scale = 1.0, opacity = 1.0) => {

        const size = ImageFunctions.productImageSize();

        if (!product) {
            return (<img src={generic_product_grey} alt="product placeholder" 
                        width={size.width / scale} 
                        height={size.height / scale}
                        style={{opacity: opacity}}/>);
        }

        if (product.imageBase64) {
            return (<img src={product.imageBase64} 
                        alt={"product"}
                        width={size.width / scale} 
                        height={size.height / scale} 
                        style={{opacity: opacity}}/>);
        }
        else {
           return (<img src={generic_product} alt="product placeholder" 
                        width={size.width / scale} 
                        height={size.height / scale}
                        style={{opacity: opacity}}/>);
        }

    },

    /**
     * Returns JSX img tag with a placeholder member image avatar (greyed out avatar)
     * @param isMobile {Boolean}, true if size is xs
     * @returns img tag with the placeholder image
     */
    memberImagePlaceholderURL: (isMobile = false) => {

        const size = ImageFunctions.memberImageSize(isMobile);


        return (<img src={avatar_grey} alt="member placeholder" 
                        width={size.width} 
                        height={size.height}  />);


    },


};

