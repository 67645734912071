import React from 'react';
import { withCookies } from 'react-cookie';

import { Paper, Tooltip, Typography, Button, Grid, Divider } from '@material-ui/core'

import SearchIcon from '@material-ui/icons/Search';
import PeopleIcon from '@material-ui/icons/People';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import BlockIcon from '@material-ui/icons/Block';

import { DepositSum } from '../models/DepositSum'

import { RestComponent, DateRangeSelector, DateUtils, Currency, SummaryWidget } from 'react-frontend-utils'

import { PP } from '../models/PP'
import { ThemeColors } from '../Theme'


const MOBILE_WIDTH = 914;

export class FinancialsPage extends RestComponent {
  
  
    static currentYear = (new Date()).getFullYear();
  
    //options for the time range pulldown
    _timeOptions = [
        {label: "Month to Date", startTime: DateUtils.startOfMonth().valueOf(), endTime: null}, 
        {label: "Year to Date", startTime: DateUtils.startOfYear().valueOf(), endTime: null},
        {label: (FinancialsPage.currentYear-1).toString(), startTime: DateUtils.startOfYear(FinancialsPage.currentYear-1).valueOf(), endTime: DateUtils.lastDayOfYear(FinancialsPage.currentYear-1).valueOf()},
        {label: (FinancialsPage.currentYear-2).toString(), startTime: DateUtils.startOfYear(FinancialsPage.currentYear-2).valueOf(), endTime: DateUtils.lastDayOfYear(FinancialsPage.currentYear-2).valueOf()},
        {label: (FinancialsPage.currentYear-3).toString(), startTime: DateUtils.startOfYear(FinancialsPage.currentYear-3).valueOf(), endTime: DateUtils.lastDayOfYear(FinancialsPage.currentYear-3).valueOf()},
        {label: "All", startTime: null, endTime: null}
    ];
    
    styles = {
        paper: {
            width: '100%',
            height: '100%',
            marginTop: 10,
            marginBottom: 10
        },
        paperLabel: {
            marginLeft: 15,
            marginRight: 15,
            marginBottom: 5,
            color: 'gray',
            fontSize: '9pt',
            flexGrow: 1
        },
        divider: {
            marginLeft: 10,
            marginRight: 10
        }
    }
  
 

    constructor(props) {
        super(props);

        this.state.sums = [];         //all the sums data
        this.state.totalsArray = [];   //totals for each currency
        this.state.totalMemberCount = 0;
        this.state.totalPurchaseCount = 0;
        this.state.totalRedeemCount = 0;
        this.state.totalCancellations = 0;
        
        this.state.selectedTimeRange = "All";
        this.state.selectedTimeRangeValue = "All";
        
        this.state.startDateSearch = null;
        this.state.endDateSearch = null;
       
        this.state.isMobile = false;
    }
    
    componentDidMount() {
       this._updateSize();
       window.addEventListener("resize", this._updateSize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this._updateSize);
    }


    _updateSize = () => {
        this.setState({isMobile: window.innerWidth < MOBILE_WIDTH }); 
    }
    
    
 
    _validateSearch = () => {
        
        const start = this.state.startDateSearch ? new Date(this.state.startDateSearch) : null;
        const end = this.state.endDateSearch ? new Date(this.state.endDateSearch) : null;
        
        if (start && end && start > end) {
            this.showConfirmAlert("Error", "From Date is after To Date", 'red');
            return false;
        }
        return true;       
    }
 
  
    _getSearchString = () => {
        let search = "";
        
        if (this.state.startDateSearch)
            search += "fromDate=" + this.state.startDateSearch + "&";
        if (this.state.endDateSearch)
            search += "toDate=" + this.state.endDateSearch + "T23:59:59&";  //add time to the end of the day
        
        if (search)
            search = "?" + search.substring(0, search.length-1);  //remove trailing &
        
        return search;
    }
  
    
    _search = () => {
        if (!this._validateSearch())
            return;

        const search = this._getSearchString();
        
        this.incrementBusy();       
        this.secureJSONFetch("/ppcs/aggregate/deposits" + search, {},
                             this._searchResponse, this._fetchErrorCallback); 
   
    }
    
    
    _searchResponse = (response) => {
        if (response) {        
            const sums = response.map((depositSum) => new DepositSum(depositSum));  
            
            const totalsMap = new Map();
            let totalMembers = 0;
            let totalPurchases = 0;
            let totalRedeems = 0;
            let totalCancellations = 0;
            
            sums.forEach(sum => {
               
               let currentVal = totalsMap.get(sum.currency);  //get running total for this currency
               if (currentVal) {
                   currentVal += sum.depositSum;
                   totalsMap.set(sum.currency, currentVal);  //add to existing
               }    
               else
                   totalsMap.set(sum.currency, sum.depositSum);  //create new entry for this currency
               
               totalMembers += sum.members;
               totalPurchases += sum.purchaseSum;
               totalRedeems += sum.redeemSum;
               totalCancellations += sum.cancellations;
            });
            
            const totalsArray = [];
            for (let [key, value] of totalsMap)
                totalsArray.push({currency: key, value: value});
 
            
            this.setState({sums: sums, totalsArray: totalsArray, totalMemberCount: totalMembers, 
                           totalPurchaseCount: totalPurchases, totalRedeemCount: totalRedeems, 
                           totalCancellations: totalCancellations});
        }
        this.decrementBusy();
    }
    
    
    
    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
    }
    
     _dateChangeCallback = (start, end) => {
        this.setState({startDateSearch: start, endDateSearch: end});
    }
    
    
    _dateParseError = (error) => {
        this.showConfirmAlert("Error in Date Field", error, 'red');
    }
        
    render() {        
                  
        return (
            <div>
                {this.getConfirmAlertComponent()}
                
                <Paper style={this.styles.paper}>
                    <Typography variant="body2" style={this.styles.paperLabel}>Totals over Time</Typography>  
                    
                    <Grid container direction="row" spacing={2} style={{padding: 10}}>

                        <Grid item md={4} sm={6} xs={12}>
                        
                            <DateRangeSelector calendarColor={ThemeColors.calendarColor}
                                               dateFormat={PP.getDateFormat()}
                                               timeOptions={this._timeOptions}
                                               minYear={2020}
                                               initialTimeRange="All"
                                               onDateChange={this._dateChangeCallback}
                                               onParseError={this._onParseError}/>
                        
                        </Grid>
                   
                         <Grid item md={4} sm={6} xs={12}>
                         
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                              
                                <Tooltip title="Search and Display Totals below">
                                   <Button fullWidth onClick={this._search} variant="outlined" color='primary' style={{marginLeft: 10, maxWidth: 200}} component="label" startIcon={<SearchIcon />}>
                                       Calculate
                                   </Button>
                                </Tooltip>
                            </div>
                            
                        </Grid>
                
                    </Grid>
                 </Paper>
                
                {this.state.isBusy ? this.getBusyComponent('center', {marginTop: 20}) : (<div style={{paddingBottom: 60}}/>)}

                <div style={{marginTop: 10}}/>
                
                <Grid container direction="row" spacing={2} style={{padding: 10}}>
                    {this.state.totalsArray.map(entry =>
                        <Grid item md={3} sm={4} xs={6}>
                            <SummaryWidget label={"Deposits in " + entry.currency} 
                                            value={Currency.symbolForISO(entry.currency) + Currency.round(entry.value)}
                                            backgroundColor='#FCFCFC'
                            />
                        </Grid>
                    )}
            
                    <Grid item md={3} sm={4} xs={6}>
                        <SummaryWidget label="Average Members" 
                                value={this.state.totalMemberCount}
                        />
                    </Grid>
                    <Grid item md={3} sm={4} xs={6}>
                        <SummaryWidget label="Purchases" 
                                value={this.state.totalPurchaseCount}
                                backgroundColor='#FCFCFC'
                        />
                    </Grid>
                    <Grid item md={3} sm={4} xs={6}>
                        <SummaryWidget label="Redemptions" 
                                value={this.state.totalRedeemCount}
                                backgroundColor='#FCFCFC'
                        />
                    </Grid>
                    <Grid item md={3} sm={4} xs={6}>
                        <SummaryWidget label="Cancellations" 
                                value={this.state.totalCancellations}
                                backgroundColor='#FCFCFC'
                        />
                    </Grid>
                </Grid>


                <Paper style={this.styles.paper}>

                    <Grid container direction="row" spacing={2} style={{padding: 10}}>
                        <Grid item xs={2}>
                            <Typography variant="body2" style={{fontWeight: 'bold'}}>{(this.state.isMobile ? "DB" : "Databases") + " (" + (this.state.sums.length) + ")"}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                        <Typography variant="body2" style={{textAlign: 'right', fontWeight: 'bold'}}>{this.state.isMobile ? <PeopleIcon/> : "Members (Ave)"}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant="body2" style={{textAlign: 'right',  fontWeight: 'bold'}}>{this.state.isMobile ? <MonetizationOnIcon/> : "Purchases"} </Typography>
                        </Grid>   
                        <Grid item xs={1}>
                            <Typography variant="body2" style={{textAlign: 'right',  fontWeight: 'bold'}}>{this.state.isMobile ? <ConfirmationNumberIcon/> : "Redemptions"}</Typography>
                        </Grid>  
                        <Grid item xs={2}>
                            <Typography variant="body2" style={{textAlign: 'right',  fontWeight: 'bold'}}>{this.state.isMobile ? <BlockIcon/> : "Cancellations"}</Typography>
                        </Grid>  
                        <Grid item xs={2}>
                            <Typography variant="body2" style={{textAlign: 'right',  fontWeight: 'bold'}}>{this.state.isMobile ? "BAL" : "Outstanding Funds"}</Typography>
                        </Grid> 
                        <Grid item xs={2}>
                            <Typography variant="body2" style={{textAlign: 'right',  fontWeight: 'bold'}}>{this.state.isMobile ? "DEP" : "Deposits"}</Typography>
                        </Grid>                               
                    </Grid>

                    {this.state.sums.map((sumEntry, index) =>
                        <div key={index}>
                            {index > 0 ? <Divider style={this.styles.divider}/> : <Divider/>  /*Smaller dividers except the first*/}
                            
                            <Grid container direction="row" spacing={2} style={{padding: 10}}>
                                <Grid item xs={2}>
                                    <Typography variant="body1">{sumEntry.database}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="body1" style={{textAlign: 'right'}}>{sumEntry.members}</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant="body1" style={{textAlign: 'right'}}>{sumEntry.purchaseSum}</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant="body1" style={{textAlign: 'right'}}>{sumEntry.redeemSum}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="body1" style={{textAlign: 'right'}}>{sumEntry.cancellations}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="body1" style={{textAlign: 'right'}}>{Currency.round(sumEntry.balance)}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="body1" style={{textAlign: 'right'}}>{Currency.round(sumEntry.depositSum) + " " + sumEntry.currency}</Typography>
                                </Grid>                               
                            </Grid>
       
                        </div>
                    )}

                </Paper>
 
            </div>

        );

    }
}


export default withCookies(FinancialsPage);

