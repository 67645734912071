
export class MembershipStat {
    
    time;               //Date
    memberships;        //integer   
    members;            //integer
    
    constructor(userJson) {
        
        if (userJson) {
            this.time = new Date(userJson.time);  //time is in UTC
            this.memberships = userJson.memberships;
            this.members = userJson.members;
        }
    }
    
    //Create a new stat from an existing stat, replacing the time with the current UTC time
    static fromLast(stat) {
        let newStat = new MembershipStat();
        newStat.time = new Date();
        newStat.memberships = stat.memberships;
        newStat.members = stat.members;
        return newStat;
    }
}

