import React from 'react';
import { Typography, Tooltip } from '@material-ui/core'

import ManageEmailField from './ManageEmailField'
import { ManageTextField, ManageTimeField, ManageNumericField, ManageDecimalField, ManageDateField, ManageBirthDateField, ManageSwitch, ManageBitwiseCheckboxes, DateUtils } from 'react-frontend-utils'

import { PP } from '../models/PP'
import { ThemeColors } from '../Theme'


export const ManagedField = {

    /**
     * Creates a React Component, one of the Managed Fields in Components, based on the paramaters supplied
     * @param {type} field - an object that must contain the following properties:
     *                          component: a string describing the component, one of the switch cases
     *                          json: the json object identifier to be passed to the managed component
     *                          label: name of the managed field
     *                          ref: a React reference for the field
     *                          lines: for TextField, the number of lines
     *                          hasInfinity: for Numeric fields, whether or not to show the infinite button
     *                          hasNever: true for date fields to show Never button
     *                          color: for switches, primary or secondary color
     *                          tooltip: tooltip for the 
     *                          
     * @param {type} index      and index to be used for a react key when mapping over an array of fields
     * @param {type} onFieldChange  callback when the field changes
     * @param {type} onFieldInputError callback when an input field has an error
     * @param {type} isReadOnly     true to flag as read only
     */
    getComponentForField: (field, index, onFieldChange, onFieldInputError, isReadOnly) => {

        let multiline = 0;
        let isRelativeTime = false;
        
        const editable = true;   // ignore - always enable (use isReadOnly)

        switch (field.component) {
            case "TextArea":
                multiline = field.lines;  //fallthrough
            case "TextField":
                return (<ManageTextField key={index} tooltip={field.tooltip} multiline={multiline} style={{marginTop: 15}} json={field.json} label={field.label} ref={field.ref} onFieldChange={onFieldChange} isReadOnly={isReadOnly} editable={editable} />);
            case "EmailField":
                return (<ManageEmailField key={index} tooltip={field.tooltip} multiline={multiline} style={{marginTop: 15}} json={field.json} label={field.label} ref={field.ref} onFieldChange={onFieldChange} isReadOnly={isReadOnly} editable={editable}/>);                   
            case "Numeric":
                return (<ManageNumericField key={index} tooltip={field.tooltip} infinityText={field.infinityText} hasNegative={field.hasNegative} hasInfinity={field.hasInfinity} hideButtons={field.hideButtons} minValue={field.minValue} maxValue={field.maxValue} json={field.json} style={{marginTop: 15}} label={field.label} ref={field.ref} onFieldChange={onFieldChange} isReadOnly={isReadOnly} editable={editable}/>);
            case "Decimal":
                return (<ManageDecimalField key={index} tooltip={field.tooltip} infinityText={field.infinityText} decimalPlaces={field.decimalPlaces} hideButtons={true} minValue={field.minValue} maxValue={field.maxValue} hasInfinity={field.hasInfinity} json={field.json} style={{marginTop: 15}} label={field.label} ref={field.ref} onFieldChange={onFieldChange} isReadOnly={isReadOnly} editable={editable}/>); 
            case "Birthdate":
                return (<ManageBirthDateField hasNever={field.hasNever} neverText={field.neverText} tooltip={field.tooltip} key={index} json={field.json} style={{marginTop: 15}} label={field.label} ref={field.ref} onFieldChange={onFieldChange} onParseError={onFieldInputError} isReadOnly={isReadOnly} dateFormat={PP.getDateFormat()} editable={editable} calendarColor={ThemeColors.calendarColor}/>);                          
            case "Calendar":
                return (<ManageDateField hasNever={field.hasNever} neverText={field.neverText} tooltip={field.tooltip} key={index} json={field.json} style={{marginTop: 15}} maxYear={DateUtils.calendarMaxYear()} label={field.label} ref={field.ref} onFieldChange={onFieldChange} onParseError={onFieldInputError} isReadOnly={isReadOnly} dateFormat={PP.getDateFormat()} editable={editable} calendarColor={ThemeColors.calendarColor}/>);          
            case "RelativeTime":
                isRelativeTime = true; //fallthrough
            case "TimeOfDay":
                return (<ManageTimeField hasNever={field.hasNever} twelveHour={true} relativeTime={isRelativeTime} tooltip={field.tooltip} neverText={field.neverText} key={index} json={field.json} style={{marginTop: 15}} label={field.label} ref={field.ref} onFieldChange={onFieldChange} onParseError={onFieldInputError} isReadOnly={isReadOnly} editable={editable} clockColor={ThemeColors.clockColor} />);          
            case "Switch":
                return (<ManageSwitch isSwitch key={index} color={field.color} json={field.json} tooltip={field.tooltip} style={{marginTop: 15}} label={field.label} onFieldChange={onFieldChange} ref={field.ref} isReadOnly={isReadOnly} editable={editable}/>)
            case "Checkboxes":
                return (<ManageBitwiseCheckboxes key={index} json={field.json} style={{marginTop: 15}} label={field.label} labels={field.checkboxLabels} onChange={onFieldChange} ref={field.ref} isReadOnly={isReadOnly} editable={editable} />)
            case "Div":
                return (<DividerWithInfo label={field.label} tooltip={field.tooltip}/>);
            default: 
                return null;
        }
    },


    /**
     * Set (or clear) the all the fields based on the item. If set, use the initialValue function from the field, if clear, set to undefined.
     * If the ref doesn't exist, ignore
     * @param {type} fields array of field, where field is the object described in the switch statement above
     * @param {type} item the object passed to the initialValue function of field
     */
    setFields: (fields, item) => {
             //Lookup the ReactComponent and set its value to product
            fields.forEach(field => {if (field.ref)
                                        field.ref.current.set(item ? field.initialValue(item) : undefined);
                                    }
                          );
    }
};


/**
 * Light gray label with divider that has a label, and optionally an ⓘ tooltip hover over
 * props.label is the text label
 * props.tooltip is the tooltip text, null for no ⓘ symbol
 */
function DividerWithInfo(props) {
    

    return <div style={{marginTop: 25, marginBottom: 20, borderBottom: '1px solid lightGray'}}>
                <span style={{display: 'flex'}}>
                    <Typography variant="body2" style={{fontSize: 12, color: 'gray', marginLeft: 5}}>{props.label}</Typography>
                    {props.tooltip ? 
                        <Tooltip title={props.tooltip}>
                            <Typography variant="body2" style={{fontSize: 12, fontWeight: 'bold', color: 'blue', marginLeft: 8}}>ⓘ</Typography>
                        </Tooltip>
                        : null}
                </span>
            </div>;
    
}