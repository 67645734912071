import React from 'react';

import { Tooltip, Typography, Divider, Grid, FormControlLabel, Checkbox } from '@material-ui/core'
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import CheckIcon from '@material-ui/icons/Check';

import { PP } from '../models/PP'
import { ImageFunctions } from '../utils/Image';

/**
 * A react function that generates an entry in a member list for the Member, with checkboxes to select or delesect the Member
 * The checkbox is disabled if the Member

 * The caller must pass the following props:
    
 * mode: one of the MemberListModes, determines how to render depending on the use case

 * index: the index of the member from an array
 * isMobile: for smaller size, removes buttons
 * isVerySmall: for really small sizes, shrinks content even more
 * member: the Member object
 * isSelected: if the Member is selected
 * select: callback that takes the index, member, and true/false whether the selection box was selected
 */

export const MemberListModes = {
    CHECKIN: 0,      
    CHECKOUT: 1
};

export default function MemberListEntry(props) {
    
    
    const index = props.index;
    const member = props.member;   //Member object
   
    let disabled = false; 
    let suspendReason = null;
    let checkBoxTooltip = "";
    let entryPasses = null;
    let checkBoxColor;
    
    switch (props.mode) {
       
        case MemberListModes.CHECKIN:
            disabled = member.suspended || member.entryPasses === 0;
            if (member.suspended)
                suspendReason = "Suspended";
            else if (member.entryPasses === 0)
                suspendReason = "No Entry Passes";
            
            checkBoxTooltip = "Select for Check In";
            entryPasses = member.entryPassesString(true);
            checkBoxColor = 'green';
            break;
            
        case MemberListModes.CHECKOUT:
            if (!member.isCheckedIn)
                return null;
            checkBoxTooltip = "Select for Check Out";
            checkBoxColor = 'blue';
            break;
            
        default:
            return <div>Unhandled Member List Mode</div>;
    }
      
    const fontSize = props.isVerySmall ? 12 : (props.isMobile ? 14 : 16);
  
    const checkboxScale = props.isVerySmall ? '1' : (props.isMobile ? 1.2 : 1.5);
      
    const styles = {
       
        divider: {
            marginLeft: 10,
            marginRight: 10
        },
        text: {
            color: disabled ? 'gray' : 'black',
            fontSize: fontSize-2,
            marginLeft: 5
        },
        nameText: {
            color: disabled ? 'gray' : 'black',
            fontSize: fontSize+2,
            fontWeight: 'bold',
            marginLeft: 5
        },
        checkedInText: {
            color: 'green',
            fontSize: fontSize-1,
            marginLeft: 5
        },
        disabledText: {
            color: 'red',
            fontSize: fontSize-1,
            fontStyle: 'italic',
            marginLeft: 5
        },
        checkbox: {
            transform: 'scale(' + checkboxScale + ')',
            color: checkBoxColor
        }
    };
    
    const select = (event) => {
        const isChecked = event.target.checked;
        props.select(index, member, isChecked);
    }
    
    const checkedInText = props.isMobile ? <CheckIcon fontSize='small' style={{color: 'green'}}/> : "Checked In";
    const checkedInVal = PP.checkOutEnabled() && member.isCheckedIn ? checkedInText : ""
    
    const checkedInTooltip = PP.checkOutEnabled() && member.isCheckedIn ? "Member is currently checked in" : "";
    
    const shortNotes = member.notes.length < 75 ? member.notes : (member.notes.substring(0, 72) + "...");
    
    let suspendReasonVal;
    if (suspendReason && props.isMobile)
        suspendReasonVal = <NotInterestedIcon fontSize='small' style={{color: 'red'}}/>;
    else
        suspendReasonVal = suspendReason;
    
    return (
            
        <div key={index}>

            <Divider style={styles.divider}/>
                      
             <div style={{margin: 10}}>
                <Grid container direction="row" justify='center' alignItems='center' spacing={props.isVerySmall ? 1 : 2}>

  
                    <Grid item xs={props.isMobile ? 3 : 2}>    
                        {ImageFunctions.memberImageURL(member, false, props.isVerySmall ? 6.0 : 4.0, disabled ? 0.4 : 1.0)}
                    </Grid>

                    <Grid item xs={3}>
                        <span style={{display: 'flex'}}>                         
                            <div style={{flexGrow: 1}}>
                                <Typography variant="body2" style={styles.nameText}>{member.shortName()}</Typography>
                                <Typography variant="body2" style={styles.text}>{member.shortRelation()}</Typography>
                                {PP.addressAndAgeHidden() ? null :
                                    <Typography variant="body2" style={styles.text}>{"Age: " + member.ageString()}</Typography>
                                }
                                {member.limited ?
                                    <Typography variant="body2" style={{...styles.text, fontStyle: 'italic'}}>Limited Member</Typography>
                                    : null
                                }
                            </div>                          
                        </span>  
                    </Grid>
                    
                    <Grid item xs={props.isMobile ? 2 : 3}>
                        {PP.notesHidden() ? null :
                            <span style={{display: 'flex'}}>                         
                                <div style={{flexGrow: 1}}>
                                    <Tooltip title={member.notes}>
                                        <Typography variant="body2" style={styles.text}>{shortNotes}</Typography> 
                                    </Tooltip>
                                </div>                          
                            </span>  
                        }
                    </Grid>

              
                    <Grid item xs={2}>
                        <span style={{display: 'flex'}}>                         
                            <div style={{flexGrow: 1}}>  
                                <Tooltip title={checkedInTooltip}>
                                    <Typography variant="body2" style={styles.checkedInText}>{checkedInVal}</Typography>
                                </Tooltip>
                                <Tooltip title={suspendReason}>
                                    <Typography variant="body2" style={styles.disabledText}>{suspendReasonVal}</Typography>
                                </Tooltip>
                            </div>                          
                        </span>  
                    </Grid>
              
              
                    <Grid item xs={1}>             
                        {entryPasses ? <Typography variant="body2" style={styles.text}>{entryPasses}</Typography> : null}
                    </Grid>
              
                    <Grid item xs={1}>
                        <div style={{display: 'flex', justifyContent: 'right'}}>

                            {!disabled ? 
                                <Tooltip title={checkBoxTooltip}>
                                    <FormControlLabel control={
                                        <Checkbox checked={props.isSelected} onChange={select} color="primary" style={styles.checkbox}/>
                                    }/>
                                </Tooltip>
                                : null}
                        </div>
                    </Grid>
                        
                        
                </Grid>

            </div>
        </div>
                
      
    );
        
}