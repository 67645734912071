import React, { Fragment} from 'react';
import Chart from "react-apexcharts";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import Hammer from 'react-hammerjs'
import { DIRECTION_HORIZONTAL } from 'hammerjs';

import { TextField, IconButton, Tooltip,  Typography } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';

import { PP } from '../../models/PP'
import { DateUtils, RestComponent } from 'react-frontend-utils'


/**
 * This tab is the (abstract) base class for all Statistics Tabs. For statistics tabs that have time on the x-axis, it provides zoom, pan, and
 * time selection components.  For tabs that support location-specific statis, it provides a location pulldown.
 * 
 * Subclasses must set the following props in their constructor, after calling super():
 * 
 * _chartOptions: ApexChart options structure, which may include the superclass property "standardChartOptions"
 * _chartType: an Apex chart type string (bar, line, etc.)
 * _chartFetchPath: the REST endpoint to post a GET request (for those with locations, the location query param is appended
 * _chartDataHandlerCallback: a callback after data fetch, which takes a "response" parameter and sets the state.chartData object used by ApexCharts
 * _hasLocation: true if this chart supports locations
 * _hasAggregate: true if this chart supports time aggregation
 * _hasTime: true if this chart has time on the x axis
 * _hasTimeRange: true if this chart has the time range control.  If _hasTime is false, then timeRangeCallback must be defined
 * 
 * Subclasses can set the optional property:
 * 
 * _timeRangeCallback: callback when the time-range dropdown changes, passed the selected startTime. Must be defined if does not _haveTime but _hasTimeRange.  If not defined,
 *                      the default zoom to time range is called.
 * 
 * _minZoomTime: minimum zoom time in hours (default 6)
 */


const MOBILE_WIDTH = 892;

export class AbstractStatisticsTab extends RestComponent {
    
    _chartOptions;
    _chartType;
    _chartFetchPath;
    _chartDataHandlerCallback;
    _hasLocation;
    _hasAggregate;
    _hasTime;
    _hasTimeRange;
    _hasDownload;
    _hasAllLocations;
    _minZoomTime = 6;
    
    _chartRef = React.createRef();
    _panRightRef = React.createRef();
    _panLeftRef = React.createRef();
    _zoomInRef = React.createRef();
    _zoomOutRef = React.createRef();
    _panTimer;
    _keyUpTimer;
        
    //options for the time range pulldown
    _timeOptions = [
        {label: "Today", startTime: DateUtils.startOfToday().valueOf()},
        {label: "Last 7 days", startTime: DateUtils.startOfToday().valueOf() - (7 * DateUtils.MS_PER_DAY)},
        {label: "Last 30 days", startTime: DateUtils.startOfToday().valueOf() - (30 * DateUtils.MS_PER_DAY)},
        {label: "Last 60 days", startTime: DateUtils.startOfToday().valueOf() - (60 * DateUtils.MS_PER_DAY)}, 
        {label: "Last 90 days", startTime: DateUtils.startOfToday().valueOf() - (90 * DateUtils.MS_PER_DAY)},
        {label: "Month to Date", startTime: DateUtils.startOfMonth().valueOf()}, 
        {label: "Year to Date", startTime: DateUtils.startOfYear().valueOf()},
        {label: "Year Prior to Date", startTime: DateUtils.startOfYear().valueOf() - (365 * DateUtils.MS_PER_DAY)},
        {label: "All", startTime: null}
    ];

    _aggregateOptions = [{label: "Hourly", interval: "HOUR"},
                         {label: "Daily", interval: "DAY"},
                         {label: "Weekly", interval: "WEEK"},
                         {label: "Monthly", interval: "MONTH"},
                         {label: "Yearly", interval: "YEAR"}];


    //Callback when the chart zooms - this can be called when the user drags a zoom rectangle. Limit the maximum zoom-in level
    _chartDidZoom(chart, axes) {
        const minX = axes.xaxis.min;
        const maxX = axes.xaxis.max;
        
        let range = maxX - minX;
        const center = minX + range/2;
        
        //Limit zoom-in to min hours
        if (range < DateUtils.MS_PER_HOUR * this._minZoomTime) {
            range = DateUtils.MS_PER_HOUR * this._minZoomTime;
            chart.zoomX(center - range/2, center + range/2);
        }
    }
     
     
     //Standard chart options that should be included within the _chartOptions structure as chart: this._standardChartOptions,
    _standardChartOptions = {
        stacked: true,
        zoom: {
            enabled: false
        },
        toolbar: {
            show: false
        },
        animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 600,
            animateGradually: {
                enabled: false
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
        },
        events: {
            zoomed: (chart, axes) => {this._chartDidZoom(chart, axes);}
        }

    };
    
   
    
    //The format to apply to time values
    _dateFormat = (includeMinutes = false) => {
        
        const minutes = includeMinutes ? ":mm" : "";
        switch (PP.getDateFormat()) {
            case DateUtils.DateFormatType.ISO8601:
                return "yyyy MMM-dd h" + minutes + "TT";

            case DateUtils.DateFormatType.US:
                return "MMM-dd yyyy h" + minutes + "TT"; 
            
            default:
                return "unhandled";
        }
    }
    
    
    _yearMonthFormat = () => {
        
        switch (PP.getDateFormat()) {
            case DateUtils.DateFormatType.ISO8601:
                return "yyyy MMM";

            case DateUtils.DateFormatType.US:
                return 'MMM yyyy'; 
                
            default:
                return "unhandled";
        }
    }
    
    _datetimeFormatter = (includeMinutes) => {
        const minString = includeMinutes ? ":mm" : "";
        return {
            year: 'yyyy',
            month: this._yearMonthFormat(),
            day: "MMM-dd",
            hour: "h" + minString + "TT"
        };
    }
 

    constructor(props, hasAllLocations = true) {
        super(props);
        this.state.chartData = null;
        this.state.selectedLocation = "All Locations";
        this.state.selectedLocationValue = "All Locations";
        this.state.locations = ["All Locations"];
        this.state.selectedTimeRange = "All";
        this.state.selectedTimeRangeValue = "All";
        
        if (!PP.viewingStatsAggregate) //if not yet set, set to the default
            PP.viewingStatsAggregate = "Daily"; 
        
        this.state.selectedAggregate = PP.viewingStatsAggregate;
        this.state.selectedAggregateValue = PP.viewingStatsAggregate;
        
        this.state.isWide = true;
        this.state.chartDataProcessing = false;       //true when we are processing data in a web worker

        this._hasAllLocations = hasAllLocations;
    }

    /**
     * When the page loads, fetch locations (if needed), then fetch stats
     */
    componentDidMount() {

        super.componentDidMount();
        this._updateSize();
        window.addEventListener("resize", this._updateSize);
        
        if (this._hasTime) {
            window.addEventListener("keydown", this._keyDown);
            window.addEventListener("keyup", this._keyUp);
        }
        
        if (PP.selectedDatabase) {   
            if (this._hasLocation)
                this._fetchLocations();
            else
                this._fetchStats(null, PP.viewingStatsAggregate);
        }
    }
    
    componentWillUnmount() {
        super.componentWillUnmount();

        window.removeEventListener("resize", this._updateSize);
        
        if (this._hasTime) {
            this._panCancel();
            window.removeEventListener("keydown", this._keyDown);
            window.removeEventListener("keyup", this._keyUp);
        }
    }
    

    _updateSize = () => {
        this.setState({ isWide: window.innerWidth >= MOBILE_WIDTH }); 
    }
    
    
    
    //Helper method for category chart types, allows updating different category labels fetched from server
    //Labels is an array of strings, data is the series array
    _updateCategoryChart = (labels, data) => {
           
        if (labels.length === 0) {
            this.setState({chartData: []});
            return;
        }
        
        if (!this._chartRef.current) {  //ref does not yet exist (chart hasn't been rendered) so ok to set the chart option directly
            this._chartOptions.xaxis.categories = labels;
        }
        else {  //use reference to update the labels
            const updateOptions = {xaxis: {categories: labels}};
            this._chartRef.current.chart.updateOptions(updateOptions); 
        }

        this.setState({chartData: data, chartHeight: 100 + labels.length * 30});        
    }
        
    
    _fetchLocations = () => {
        this.setBusy(true);
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/locations", {}, this._fetchLocationsDoneCallback, this._fetchErrorCallback);       
    }
    
    //Set the locations state for the locations pulldown, then fetch stats
    _fetchLocationsDoneCallback = (response) => {
        this.setBusy(false);
        
        let selectedLoc = "All Locations";  //initially, select all locations
        if (response.includes(PP.viewingStatsLocation))  //if our locations response includes the one we want to view, select that
            selectedLoc = PP.viewingStatsLocation;
        else {
            if (!this._hasAllLocations) {  //otherwise, if we can't use "All Locations", pick the first one of the response
                
                if (response.length > 0)
                    selectedLoc = response[0];
                else {
                    this.setState({chartData: null});  //no locations? no data
                    return;
                }
                
            }
        }
        
        if (this._hasAllLocations)
            this.setState({locations: ["All Locations", ...response], selectedLocation: selectedLoc});
        else
            this.setState({locations: [...response], selectedLocation: selectedLoc});
            
           
        this._fetchStats(selectedLoc, PP.viewingStatsAggregate);
    }
    
    _locationParam = (location) => {
        return location ? (location === "All Locations" ? "" : "?location=" + encodeURIComponent(location)) : "";
    }
   
    
    _intervalParam = (intervalLabel) => {
        let intervalVal = "DAY";
        for (const option of this._aggregateOptions) {
            
            if (intervalLabel === option.label) {
                intervalVal = option.interval;            
                break;
            }
        }
        
       return "aggregateBy=" + intervalVal;        
    }
   
    _fetchStats = (location, interval) => {
        
        let query = this._locationParam(location);

        if (this._hasAggregate) {
            if (query)
                query += "&" + this._intervalParam(interval);  //append
            else
                query = "?" + this._intervalParam(interval);  //set
        }
          
            
        this.setBusy(true);
        
        this.secureJSONFetch(this._chartFetchPath, 
                            {}, this._fetchDoneCallback, this._fetchErrorCallback, query); 
    }
    
    //When stats are fetched, call the handler in the subclass to parse the data
    _fetchDoneCallback = (response) => {
        this._chartDataHandlerCallback(response);
        this.setBusy(false);
        
        setTimeout(this._timeRangeChanged, 500);
    }
 
    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.setBusy(false);
    }
   
   
    //Called when user selects a new location from the dropdown
    _locationChanged = (event, newValue) => {
        const newLocation = newValue;
        
        this.setState({selectedLocation: newLocation});
        PP.viewingStatsLocation = newLocation;
        
        //refetch stats
        this._fetchStats(newLocation, PP.viewingStatsAggregate);
    }
    
    _aggregateChanged = (event, newValue) => {
       
        this.setState({selectedAggregate: newValue});
        PP.viewingStatsAggregate = newValue;
        
        //refetch stats
        this._fetchStats(this.state.selectedLocation, PP.viewingStatsAggregate);
    }

    //Called when user selects a new time range from the dropdown
    _timeRangeChanged = () => {
        
        if (!this._chartRef.current)
            return;
        
        const selected = this.state.selectedTimeRange;
        
        //Find the selected start time
        let startTime = null;
        for (const option of this._timeOptions) {
            
            if (selected === option.label) {
                startTime = option.startTime;            
                break;
            }
        }
        
        if (this._timeRangeCallback) {
            this._timeRangeCallback(startTime);
            return;
        }
        
        
        if (startTime === null) {  //just reset if not found or for "All"
            this._chartRef.current.chart.resetSeries();
            return;
        }
        
        const now = new Date();
        this._chartRef.current.chart.zoomX(startTime.valueOf(), now.valueOf());  //from start until now
    }
    
    
    //Zoom, where direction is either "in" or "out".  Limited in range to 10 years or min hours
    _zoom = (direction) => {
        
        if (!this._chartRef.current)
            return;
        
        const minX = this._chartRef.current.chart.w.globals.minX;
        const maxX = this._chartRef.current.chart.w.globals.maxX;
        
        let range = maxX - minX;
        const center = minX + range/2;
        
        if (direction === "in") 
            range /= 2; 
        else
            range *= 2;
        
        //Limit range between 10 years and min hours
        if (range > DateUtils.MS_PER_YEAR * 10  || range < DateUtils.MS_PER_HOUR * this._minZoomTime)
            return;
        
        this._chartRef.current.chart.zoomX(center - range/2, center + range/2);
    
    }
    
    //Continue to pan (while button is held down)
    _panLeft = () => {
        this._pan("left");
        this._panTimer = setInterval( () => {this._pan("left");}, 20 );
    }

    //Continue to pan (while button is held down)
    _panRight = () => {
        this._pan("right");
        this._panTimer = setInterval( () => {this._pan("right");}, 20 );        
    }
    
        
    //Pan, where direction is either "left" or "right".  Pans 5% of the range each time if not otherwise specified
    _pan = (direction, amount = 0.05) => {
        
        if (!this._chartRef.current)
            return;
        
        let minX = this._chartRef.current.chart.w.globals.minX;
        let maxX = this._chartRef.current.chart.w.globals.maxX;
        
        const shiftRange = (maxX - minX)*amount;  //% of range for each pan

        if (direction === "left") {
            minX -= shiftRange;
            maxX -= shiftRange;       
        }
        else {
            minX += shiftRange;
            maxX += shiftRange;     
        }
        
        this._chartRef.current.chart.zoomX(minX, maxX);

    }
    
    
    //When pan button is released, stop the panning timer
    _panCancel = () => {
        if (this._panTimer) {
            clearInterval(this._panTimer);    
            this._panTimer = null;
        }
    }
    
    
    //Handle key-presses for stat charts with time, and we have a reference, but ignore repeated keys (we use our own timer)
    _keyDown = (event) => {
        if (this._hasTime && this._chartRef.current && !event.repeat) {

            this._panCancel();  //cancel any held pan key

            switch (event.key) {
                case 'ArrowLeft':
                    clearTimeout(this._keyUpTimer); 
                    this._panLeftRef.current.focus();
                    this._panLeft();
                    break;
                case 'ArrowRight':
                    clearTimeout(this._keyUpTimer); 
                    this._panRightRef.current.focus();
                    this._panRight();
                    break;
                
                case '-':
                    clearTimeout(this._keyUpTimer); 
                    this._zoomOutRef.current.focus();
                    this._zoom("out");
                    break;
                    
                case '=':
                case '+':
                     clearTimeout(this._keyUpTimer); 
                    this._zoomInRef.current.focus();
                    this._zoom("in");
                    break;
               
                default:
                    break;
            }
        }
    }
    
    //Key released, stop any panning timer, remove focus from all buttons
    _keyUp = (event) => {
        this._panCancel();
        
        this._keyUpTimer = setTimeout(() => {
                            this._panLeftRef.current.blur();
                            this._panRightRef.current.blur();
                            this._zoomInRef.current.blur();
                            this._zoomOutRef.current.blur();
                        }, 300);
    }
    
 
    _handleSwipeGesture = (event) => {
        if (this._hasTime) {
            const dir = event.deltaX > 0 ? "left" : "right";
            setTimeout(() => {this._pan(dir, Math.abs(event.deltaX)/400);});
        }
    }
    
    
    render() {
        
        const locationComponent = this._hasLocation ? 
            <Autocomplete
               size='small'
               value={this.state.selectedLocation}
               onChange={this._locationChanged}
               inputValue={this.state.selectedLocationValue}
               onInputChange={(event, newValue) => { this.setState({selectedLocationValue: newValue}); }}                       
               options={this.state.locations}
               style={{ width: 300, marginRight: 20 }}
               blurOnSelect
               openText="Select Location"
               disableClearable
               renderInput={(params) => <TextField {...params} label="Location" variant="outlined" InputLabelProps={{ shrink: true }} />}
             /> : null;
                                     
        const timeRangeComponent = this._hasTimeRange ? 
            <Autocomplete
               size='small'
               value={this.state.selectedTimeRange}
               onChange={(event, newValue) => { this.setState({selectedTimeRange: newValue}); }}
               inputValue={this.state.selectedTimeRangeValue}
               onInputChange={(event, newValue) => { this.setState({selectedTimeRangeValue: newValue}); }}
               onClose={() => {setTimeout(this._timeRangeChanged, 1); /*call after a slight delay to propagate state*/}}
               options={this._timeOptions.map(option => option.label)}
               blurOnSelect
               disableClearable
               openText="Select Time Range"
               style={{ width: 180, marginRight: 20 }}
               renderInput={(params) => <TextField {...params} label="Time Range" variant="outlined" InputLabelProps={{ shrink: true }} />}
            /> : null;
            
        const aggregateComponent = this._hasAggregate ? 
            <Autocomplete
               size='small'
               value={this.state.selectedAggregate}
               onChange={this._aggregateChanged}
               inputValue={this.state.selectedAggregateValue}
               onInputChange={(event, newValue) => { this.setState({selectedAggregateValue: newValue}); }}
               options={this._aggregateOptions.map(option => option.label)}
               blurOnSelect
               disableClearable
               openText="Select Aggregate Interval"
               style={{ width: 180 }}
               renderInput={(params) => <TextField {...params} label="Aggregate Interval" variant="outlined" InputLabelProps={{ shrink: true }} />}
            /> : null;


        const downloadComponent = this._hasDownload ?
            <Tooltip title={"Export and Download as a .csv file"}>
                <IconButton style={{marginLeft: 10}} onClick={this._download}>
                   <CloudDownloadIcon/>
               </IconButton>                
            </Tooltip> : null;

        const iconSize = this.state.isWide ? '30' : '24';
        
        const chartTimeControls = this._hasTime ?
            <Fragment>

                <Tooltip title="Zoom In">
                   <IconButton onClick={() => this._zoom("in")} style={{marginTop: -5}} ref={this._zoomInRef}>
                       <ZoomInIcon style={{fontSize: iconSize}}/>
                   </IconButton> 
                </Tooltip>


                <Tooltip title="Zoom Out">
                   <IconButton onClick={() => this._zoom("out")} style={{marginTop: -5, marginRight: this.state.isWide ? 10 : 4}} ref={this._zoomOutRef}>
                       <ZoomOutIcon style={{fontSize: iconSize}}/>
                   </IconButton> 
                </Tooltip>

                <Tooltip title="Pan Left">
                   <IconButton onMouseDown={this._panLeft} onMouseUp={this._panCancel} onMouseLeave={this._panCancel} style={{marginTop: -5}} ref={this._panLeftRef} >
                      <ArrowBackIosIcon style={{fontSize: iconSize-4}} />
                   </IconButton> 
                </Tooltip>

                <Tooltip title="Pan Right">
                   <IconButton onMouseDown={this._panRight} onMouseUp={this._panCancel} onMouseLeave={this._panCancel} style={{marginTop: -5}} ref={this._panRightRef}>
                       <ArrowBackIosIcon style={{fontSize: iconSize-4, transform: 'scaleX(-1)'}}/>
                   </IconButton> 
                </Tooltip>
            </Fragment>
            : null;
        
        
        const hammerOptions = {
            recognizers: {
                swipe: {
                    direction: DIRECTION_HORIZONTAL,
                    threshold: 1,
                    velocity: 0.1
                }
            }
        };
        
        return ( 
            <div>
    
                {this.getConfirmAlertComponent()}   
                
                {this.state.isWide ? 
                    <div style={{display: 'flex', justifyContent: 'left', alignItems: 'center', marginBottom: 10}}>
                        {locationComponent}                       
                        {timeRangeComponent} 
                        {aggregateComponent} 
                        {downloadComponent}
                        <span style={{flexGrow: 1}  /*take up remaining space*/} />   
                        {chartTimeControls}
                    </div> 
                    :
                    <Fragment>
                        <div style={{display: 'flex', justifyContent: 'left', marginBottom: 20}}>
                            {locationComponent}                       
                            {aggregateComponent} 
                        </div>
                        <div style={{display: 'flex', justifyContent: 'right', marginBottom: 10}}>
                            {timeRangeComponent} 
                            <span style={{flexGrow: 1}  /*take up remaining space*/} />   
                            {chartTimeControls}
                        </div> 
                    </Fragment>
                }
                
                {this.state.isBusy ? this.getBusyComponent('center', {margin: 40}) : 
                      
                    <div>
                        {this.state.chartData ? 
                            <div style={{display: this.state.chartData.length > 0 ? 'block' : 'none', cursor: "crosshair"}}>
                                <Hammer onSwipe={this._handleSwipeGesture} options={hammerOptions}>
                                    <div>
                                        <Chart
                                            ref={this._chartRef}
                                            options={this._chartOptions}
                                            series={this.state.chartData}
                                            type={this._chartType}
                                            height={this.state.chartHeight ? this.state.chartHeight : window.innerHeight*0.60}
                                        />  
                                    </div>
                                </Hammer>
                            </div>
                            : null
                        }
                        {(!this.state.chartData || this.state.chartData.length === 0) && !this.state.chartDataProcessing ?
                            <div style={{display: 'flex', justifyContent: 'center', margin: 40}}>
                                <Typography variant="h6">No Chart Data</Typography>   
                            </div>
                            : null
                        }
                    </div>
                }
            </div>
                    
        );
        
    }
  
};

