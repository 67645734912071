import accept from '../assets/accept.wav'
import deny from '../assets/deny.wav'
import clickOut from '../assets/clickOut.wav'
import guestpass_used from '../assets/guestpass_used.wav'
import camera_shutter from '../assets/camera_shutter.wav'

import { PP } from '../models/PP'

const acceptAudio = new Audio(accept);
const denyAudio = new Audio(deny);
const checkOutAudio = new Audio(clickOut);
const guestUsedAudio = new Audio(guestpass_used);
const cameraShutterAudio = new Audio(camera_shutter);


export const AudioPlay = {

    playAcceptAudio: () => {
        if (PP.soundEffectsEnabled())
            acceptAudio.play();
    },
    playDenyAudio: () => {
        if (PP.soundEffectsEnabled())
            denyAudio.play();
    },
    playCheckOutAudio: () => {
        if (PP.soundEffectsEnabled())
            checkOutAudio.play();
    },
    playGuestPassUseAudio: () => {
        if (PP.soundEffectsEnabled())
            guestUsedAudio.play();
    },
    playCameraShutter: () => {
        if (PP.soundEffectsEnabled())
            cameraShutterAudio.play();
    }
};