import React, { Fragment, Component } from 'react';
import { withCookies } from 'react-cookie';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import { Paper, Tab, Tabs } from '@material-ui/core'

import { PP } from '../models/PP'
import { Permissions } from 'react-frontend-utils'

import ProductTab from '../mk_tabs/ProductTab'
import AccountTab from '../mk_tabs/AccountTab'
import TransactionTab from '../mk_tabs/TransactionTab'
import SettingsTab from '../mk_tabs/SettingsTab'
import AnalysisTab from '../mk_tabs/AnalysisTab'

import boxIcon from '../assets/boxIcon.png'
import qrIcon from '../assets/qrIcon.png'
import coinIcon from '../assets/coinIcon.png'
import gearIcon from '../assets/gearIcon.png'
import pieIcon from '../assets/pieIcon.png'

const MOBILE_WIDTH = 992;
const VERY_SMALL = 724;
const EXTREMELY_SMALL = 580;
const INSANELY_SMALL = 370;
  
const ICON_SIZE = 18; //native icon size is 20


export class ManageMarketplacePage extends Component {
  
    tabPages = {
        PRODUCTS:     {tabIndex: 0, icon: boxIcon,    text: "Products",      collapseText: "Products", collapseAt: EXTREMELY_SMALL, visible: () => PP.user.hasPermissionTo(Permissions.SIMPLE_MARKETPLACE_MANAGE)},
        ACCOUNTS:     {tabIndex: 1, icon: qrIcon,     text: "Tickets",       collapseText: "Tickets",  collapseAt: EXTREMELY_SMALL, visible: () => PP.user.hasPermissionTo(Permissions.SIMPLE_MARKETPLACE_MANAGE)},
        TRANSACTIONS: {tabIndex: 2, icon: coinIcon,   text: "Transactions",  collapseText: "Transact", collapseAt: EXTREMELY_SMALL, visible: () => PP.user.hasPermissionTo(Permissions.SIMPLE_MARKETPLACE_MANAGE)},
        ANALYSIS:     {tabIndex: 3, icon: pieIcon,    text: "Analysis",      collapseText: "Analysis", collapseAt: EXTREMELY_SMALL, visible: () => PP.user.hasPermissionTo(Permissions.SIMPLE_MARKETPLACE_MANAGE)},
        SETTINGS:     {tabIndex: 4, icon: gearIcon,   text: "Settings",      collapseText: "Settings", collapseAt: EXTREMELY_SMALL, visible: () => PP.user.hasPermissionTo(Permissions.FULL_MARKETPLACE_CONTROL)}
    }
  
  
    state = {
        windowWidth : VERY_SMALL,
        selectedTab: this.tabPages.PRODUCTS.tabIndex,
        selectedAccountFromTransactions: null,
        selectedProductFromTransactions: null
    }

    componentDidMount() {
       this._updateSize();
       window.addEventListener("resize", this._updateSize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this._updateSize);
    }


    _updateSize = () => {
        this.setState({ windowWidth: window.innerWidth, isMobile: window.innerWidth < MOBILE_WIDTH }); 
    }

    //User switches tab - set the new tab and clear the selections from other tabs
    _switchTab = (event, newValue) => {
        this.setState({selectedTab: newValue, 
                       selectedAccountFromTransactions: null, 
                       selectedProductFromTransactions: null,
                       selectedAccountFromAccounts: null});
    }

    //Callback when account value is selected from the Transactions table, switch tab and set the prop on the AccountTab
    _onAccountSelectFromTransactionsTab = (account) => {
        this.setState({selectedAccountFromTransactions: account, selectedTab: this.tabPages.ACCOUNTS.tabIndex});        
    }
    
    //Callback when product id is selected from the Transactions table, switch tab and set the prop on the ProductTab
    _onProductSelectFromTransactionTab = (product) => {
        this.setState({selectedProductFromTransactions: product, selectedTab: this.tabPages.PRODUCTS.tabIndex});                
    }

    _onShowAccountInTransactions = (accountID) => {
        this.setState({selectedAccountFromAccounts: accountID, selectedTab: this.tabPages.TRANSACTIONS.tabIndex});        
    }

    render() {
        
        //Override breakpoints for tabs in order for the reponsiveness to improve (collapses space between at "sm" size)
        const tabThemeOverride = createMuiTheme({
            breakpoints: {
              values: {
                xs: 0,
                sm: MOBILE_WIDTH,
                md: 1280,
                lg: 1280,
                xl: 1920
              }
            }
        });         
        
        
        //Selects the current tab to view
        const viewingTab = (() => {
            
            switch (this.state.selectedTab) {

                case this.tabPages.PRODUCTS.tabIndex:   
                    return <ProductTab selectedProduct={this.state.selectedProductFromTransactions}/>;
                    
                case this.tabPages.ACCOUNTS.tabIndex:
                    return <AccountTab selectedAccount={this.state.selectedAccountFromTransactions}
                                       onShowAccountInTransactions={this._onShowAccountInTransactions}/>;
                    
                case this.tabPages.TRANSACTIONS.tabIndex:
                    return <TransactionTab onAccountSelect={this._onAccountSelectFromTransactionsTab}
                                           onProductSelect={this._onProductSelectFromTransactionTab}
                                           selectedAccountFromAccounts={this.state.selectedAccountFromAccounts}/>;
                    
                case this.tabPages.ANALYSIS.tabIndex:
                    return <AnalysisTab/>;    
                
                case this.tabPages.SETTINGS.tabIndex:
                    return <SettingsTab/>;
                
                default:
                    return <div>Tab Not Found</div>;

            }
        })();
        
        
        //Creates the text label for the tab, shrinking font, adding a <div> between icon and text, and collapsing to abbreviated text as necessary
        //to fit the width of the screen
        const tabLabel = (tab) => {
            let fontSize = 15;
            let iconSize = ICON_SIZE;

            if (this.state.windowWidth < MOBILE_WIDTH) {
                fontSize -= 1; 
                iconSize -= 2;
            }
            if (this.state.windowWidth < EXTREMELY_SMALL) {
                fontSize -= 1;
                iconSize -= 1;
            }
            if (this.state.windowWidth < INSANELY_SMALL) {
                fontSize -= 2;
                iconSize -= 1;
            }

            let text = this.state.windowWidth >= tab.collapseAt ? tab.text : tab.collapseText;

            if (this.state.windowWidth < VERY_SMALL)     
                return (<span style={{fontSize: fontSize}}>
                            <img src={tab.icon} alt="icon" width={iconSize} height={iconSize}/> 
                            <div/>
                            {text}
                       </span>);
            else
                return (<span>
                            <img style={{verticalAlign: 'middle'}} src={tab.icon} alt="icon" width={iconSize} height={iconSize}/>
                            <span style={{verticalAlign: 'middle', paddingLeft: 8, fontSize: fontSize}}>{text}</span>
                        </span>);

        };

        const style = this.state.windowWidth < EXTREMELY_SMALL ? {minWidth: 50} : {};
        
        const tabsArray = [];
        for (const index in this.tabPages) {
            const tab = this.tabPages[index];
            if (tab.visible())
                tabsArray.push(<Tab key={tab.tabIndex} style={style} value={tab.tabIndex} label={tabLabel(tab)}/>);
        }
        
        
        return (
            
            <Fragment >

                <Paper>
                    <MuiThemeProvider theme={tabThemeOverride}>
                        <Tabs value={this.state.selectedTab} onChange={this._switchTab} indicatorColor="primary" textColor="primary" centered={!this.state.isMobile} variant={this.state.isMobile ? 'fullWidth' : 'standard'} >

                            {tabsArray} 

                        </Tabs>
                    </MuiThemeProvider>
                </Paper>
                

                <div style={{padding: 10}}/>
           
                <div>
                    {viewingTab} 
                </div>

            </Fragment>
        );

    }
    
};


export default withCookies(ManageMarketplacePage);
