import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';

import { Grid, Paper, Typography, TextField, Divider, Tooltip} from '@material-ui/core'
import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core'

import { ExternalLink } from '../App'
import { appIcons } from '../utils/Image'
import { RestComponent } from 'react-frontend-utils' 
    
import ScreenLockPortraitIcon from '@material-ui/icons/ScreenLockPortrait';
    
/**
 *  The ManageAppsPage provides a list of available databases and controls to set the app authorization key
 *  
 *  The parent component MUST pass in a prop called availableDatabases which is an array of Strings with the database names. 
 * 
 */
    
    

export class ManageAppsPage extends RestComponent {
  
  
    styles = {
        paper: {
            width: '100%',
            height: '100%'
        },
        paperLabel: {
            marginLeft: 15,
            color: 'blue',
            fontSize: '12pt'
        },
        iconLabel: {
            fontSize: '12pt',
            textAlign: 'center',
            flexGrow: 1
        },
        form: {
        },
        divider: {
            marginLeft: 10,
            marginRight: 10
        },
        textfield: {
            marginRight: 5,
            marginBottom: 10,
            float: 'right'
        }
     
    }

    _changeDatabase;
  
    constructor(props) {
        super(props);
        this.state.filteredList = props.availableDatabases;
    }


    _filterFieldChanged = (event) => {
        const searchString = event.target.value;
        
        if (searchString) //has a search string   
            this.state.filteredList = this.props.availableDatabases.filter((group) => group.includes(searchString));
        else
            this.state.filteredList = this.props.availableDatabases;
        
        this.forceUpdate(); //re-render            
    }
    
    _changeAuthKey = (database) => {
        //Check required information
        this.showConfirmAlert("Confirm", 
                              "Really change app authorization key for database \"" + database + "\"?",
                              'red',
                              "Cancel", 
                              () => {this._confirmChangeAuthKey(database);},
                              "Change",
                              'black');
    }



    _confirmChangeAuthKey = (database) => {
        this._changeDatabase = database;
         //Post the delete - refetch on success, or show error on error
        this.secureJSONFetch("/ppcs/groups/" + database + "/authKey", 
                             {method: "POST"},
                             this._getAuthKeyCompleteCallback,
                             this._fetchErrorCallback);       
    }
    
    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.setBusy(false);
    }
    
     _getAuthKeyCompleteCallback = (response) => {
        this.setBusy(false);
                
        const messageComponent = (
                <span>
                    <p>{"The Authorization Key for " + this._changeDatabase + " is: "}</p>
                    <p style={{fontSize: 22, textAlign: 'center', fontFamily: 'monospace'}}>{response}</p> 
                    <p>{"This key should be updated in all Apps."}</p>
                </span>
             );
        
        
        this.showConfirmAlert("App Authorization Key", "", null, "OK", null, null, null, messageComponent);
        this.forceUpdate(); //re-render         
    }
    
    render() {
        
        const filterColor = this.state.filteredList.length > 0 ? 'primary' : 'secondary';

        return (
            <Fragment>

                {this.getConfirmAlertComponent()}


                <TextField label="Filter" onChange={this._filterFieldChanged} color={filterColor} margin='dense' style={this.styles.textfield} InputLabelProps={{ shrink: true }} />
               
                <Grid container direction="row" spacing={2} >
                    
                   <Grid item sm={6} xs={12}>

                     <Paper style={{...this.styles.paper, marginBottom: 0}}>

                         <div style={{display: 'flex', height: 40}}>
                            <Typography variant="body2" style={this.styles.paperLabel}>Download Apps</Typography>  
                         </div> 

                        <Typography variant="body2" style={{margin: 10}}>
                            <span>Click one of the PoolPass App icons to visit the download page. To access your cloud account within an App, you need to set an Authorization Code. If you change the
                                  authorization code, it will need to be re-entered on each App. Visit </span>
                            <ExternalLink href="http://bit.ly/pp_setupapps" target={<Typography style={{display: 'inline'}} variant="body2">this page</Typography>} />
                            <span> for instructions on setting up cloud connections in an App.</span>
                        </Typography>  
        
                        <div style={{paddingTop: 10}}/>

                        <Grid container alignContent='center' direction="row" spacing={1} >
                            <Grid item xs={4}>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <ExternalLink href="https://www.pool-pass.com/download" target={appIcons.winIconImg}/>
                                </div>
                                <Typography variant="body2" style={this.styles.iconLabel}>Windows</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <ExternalLink href="https://www.pool-pass.com/download" target={appIcons.macIconImg}/>
                                </div>
                                <Typography variant="body2" style={this.styles.iconLabel}>MacOS</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <ExternalLink href="https://apps.apple.com/us/app/poolpass/id1478568915?ls=1" target={appIcons.iosIconImg}/>
                                </div>
                                <Typography variant="body2" style={this.styles.iconLabel}>iOS</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" style={{margin: 10}}>Use the RFID Companion App alongside the browser to scan RFID tags into this cloud portal. Click the icon to visit the download page.</Typography> 
                            </Grid>
                            <Grid item xs={4}>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <ExternalLink href="https://www.pool-pass.com/rfid" target={appIcons.rfidIconImg}/>
                                </div>
                                <Typography variant="body2" style={this.styles.iconLabel}>Windows</Typography>
                            </Grid>
                        </Grid>
                         
                        <div style={{paddingTop: 20}}/>

                     </Paper>
                   </Grid>
                    
                    { /*---------------------------- DATABASES -------------------------------------------*/ null }

                   <Grid item sm={6} xs={12}>

                     <Paper style={{...this.styles.paper, marginBottom: 0}}>

                        <div style={{display: 'flex', height: 40}}>
                            <Typography variant="body2" style={this.styles.paperLabel}>Set Authorization Codes</Typography>  
                        </div>

                        {this.getBusyComponent()}

                        <List dense>
                            {this.state.filteredList.map((database, index) =>
                                (<div key={index}>
                                    {index > 0 ? <Divider style={this.styles.divider}/> : <Divider/>  /*Smaller dividers except the first*/}
                                    <ListItem>       
                                        <ListItemText primary={database}/>
                                            <ListItemSecondaryAction>
                                                {database !== 'admin' ? (
                                                        <Tooltip title={"Change App Authorization Key for " + database}>
                                                                <IconButton edge="end" onClick={() => {this._changeAuthKey(database);}}>
                                                                    <ScreenLockPortraitIcon/>
                                                                 </IconButton>
                                                        </Tooltip>
                                                ) : null}
                                            </ListItemSecondaryAction>                                                   
                                    </ListItem>
                                 </div>)                       
                            )}
                        </List>
                    </Paper>
                  </Grid>

                </Grid>
            </Fragment>
        );
    }
    
}

export default withCookies(ManageAppsPage);