import { DateUtils } from 'react-frontend-utils'


export const TransactionType = {
    DEPOSIT:        {ordinal: 0, label: "Deposit"},
    PURCHASE:       {ordinal: 1, label: "Purchase"},
    RETURN:         {ordinal: 2, label: "Return"},
    REDEEM:         {ordinal: 3, label: "Redeem"},
    ADJUST_ADD:     {ordinal: 4, label: "Adjust Add"},
    ADJUST_SUB:     {ordinal: 5, label: "Adjust Sub"},
    PEND_DEPOS:     {ordinal: 6, label: "Pending Deposit"},
    PEND_PURCH:     {ordinal: 7, label: "Pending Purchase"},
    CANCELLED:      {ordinal: 8, label: "Cancelled"},
    PEND_REFUND:    {ordinal: 9, label: "Pending Refund"},
    REFUND:         {ordinal: 10, label: "Refund"},
    
    isCancellable: (transactionType) => {
        return transactionType === TransactionType.PEND_PURCH || transactionType === TransactionType.PEND_DEPOS;
    },

    hasProductAssociated: (transactionType) => {
        return transactionType === TransactionType.PURCHASE || 
               transactionType === TransactionType.RETURN || 
               transactionType === TransactionType.REDEEM ||
               transactionType === TransactionType.PEND_PURCH;

    },

    contributesToBalanceChange: (transactionType) => {
        return transactionType !== TransactionType.PEND_PURCH && 
               transactionType !== TransactionType.PEND_DEPOS && 
               transactionType !== TransactionType.CANCELLED;
    }
};




/**
 * This class represents a Transaction in the Marketplace, received from a JsonTransaction
 */
export class Transaction {
    
   
    date;
    transactionType;
    accountRef;
    attendant;
    membershipID;
    productRef;
    productName;
    purchQuan;
    redeemQuan;
    price;
    discount;
    tax;
    balChange;
    comment;
    location;
 
    constructor(transactionJson) {
        if (transactionJson) {
            
            if (transactionJson.date)
                this.date = DateUtils.parseJsonDate(transactionJson.date, true); 
            
            if (transactionJson.transactionType != null) {
                for (let index in TransactionType) {
                    const type = TransactionType[index];

                    if (type.ordinal === transactionJson.transactionType)
                        this.transactionType = type;
                }
                
            }
            
            //These always have quantity
            const hasQuantity = this.transactionType === TransactionType.PURCHASE || 
                                this.transactionType === TransactionType.REDEEM || 
                                this.transactionType === TransactionType.RETURN ||
                                this.transactionType === TransactionType.PEND_PURCH;
                        
            
            const hasTaxOrDiscount = this.transactionType === TransactionType.PURCHASE || 
                                    this.transactionType === TransactionType.RETURN ||
                                    this.transactionType === TransactionType.PEND_PURCH;
            
            this.accountRef = transactionJson.accountRef;
            this.attendant = transactionJson.attendant;
            this.membershipID = transactionJson.membershipID;
            this.productRef = transactionJson.productRef;
            this.productName = transactionJson.productName;
           
            //Leave certain transaction types blank because they don't convey anything 
            if (hasQuantity) {
                this.purchQuan = transactionJson.purchQuan;
                this.redeemQuan = transactionJson.redeemQuan;       
            }
            
            //Some transactions don't involve discount or tax, so leave blank - they are always zero
            if (hasTaxOrDiscount) {
                this.tax = transactionJson.tax;
                this.discount = transactionJson.discount;
            }
    
            if (this.transactionType !== TransactionType.REDEEM)  //Redeem transactions do not have a price
                this.price = transactionJson.price;
            
            this.balChange = transactionJson.balChange;
            this.comment = transactionJson.comment;
            this.location = transactionJson.location;
   
        }       
    }
    
}
        