import React from 'react';
import { withCookies } from 'react-cookie';

import { RestComponent } from 'react-frontend-utils' 

import { Container, Popover, TextField, Typography, Paper, Tooltip, IconButton, FormControlLabel, FormControl, Checkbox} from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel';

import ProductListEntry, { ProductListModes } from '../components/ProductListEntry'
import { PP } from '../models/PP'
import { Product } from '../models/Product'


export class ProductSearchPopover extends RestComponent {

    styles = {
        paperLabel: {
            color: 'blue',
            fontSize: '12pt',
            flexGrow: 1,
            marginLeft: 10
        },
        currencyLabel: {
            marginLeft: 15,
            marginRight: 15,
            marginBottom: 5,
            color: 'gray',
            fontSize: '9pt',
            flexGrow: 1,
            textAlign: 'right'
        },
        notFoundText: {
            textAlign: 'center',
            fontStyle: 'italic',
            color: 'gray'
        }
    };

    _onClose;
    _onProductAdd;
    _itemAction;
    

    constructor(props) {
        super(props);
        this._onProductAdd = props.onProductAdd;
        this._onClose = props.onClose;
        
        this.state.searchText = "";
        this.state.itemList = [];            //found products
        this.state.notFoundString = null;
        this.state.showRedeemables = false;
    }
   
    _productAddRequested = (index) => {
        const item = this.state.itemList[index];
        this._onProductAdd(item.product);
    }
   
    //Just reflect the search field value from what is entered
    _searchFieldChanged = (event) => {
        this.setState({searchText: event.target.value});
    }
    
    
    _searchKeyDown = (event) => {
    
        if (event.key === 'Enter') {  //enter key
            
            const searchString = event.target.value;
            console.log("Search entered: " + searchString);
            
            this._search(searchString);
            event.target.select(); //re-highlight all text
        }
    }
    
    _search = (searchString) => {
        this.setBusy(true);
    
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/marketplace/products?availableOnly=true&searchString=",
                            {}, this._fetchProductsCallback, this._errorCallback, encodeURIComponent(searchString));     
        
    }
    
    _fetchProductsCallback = (response) => {
        this.setBusy(false);
        if (response) {
            
            const items = response.map(product => {return {product: new Product(product), quantity: 0, quantityStep: 0};});
            const notFoundString = items.length === 0 ? "Nothing matched your search" : null;
            this.setState({itemList: items, notFoundString: notFoundString});
        }
    }
    
    //Callback when the search fails
    _errorCallback = (error) => {
        this.setBusy(false);
    }
    
    _closeButtonPressed = () => {

        this._onClose();
        this.setState({textEntry: null, itemList: [], notFoundString: null, showRedeemables: false});  //clear all for next time
    }

    _showRedeemablesChanged = (event) => {
        const showRedeemables = event.target.checked;
        
        if (showRedeemables) {
            this.showConfirmAlert("Alert", "Some Products shown are Redeemable. If the patron purchases them, they are added to their Ticket to be redeemed later on the Redeem tab.", 'red');
        }
     
        this.setState({showRedeemables: showRedeemables});
    }
    
    
    render() {
        
        let width = window.innerWidth * (this.props.isMobile ? 0.95 : 0.8);  //98% when small, otherwise 80% the window

        return (

            <Popover open={this.props.isOpen} 
                anchorReference="anchorPosition"
                anchorPosition={{left: window.innerWidth/2, top: window.innerHeight/2}}
                transformOrigin={{vertical: 'center', horizontal: 'center'}} >
                
                {this.getConfirmAlertComponent()}

                <Container style={{marginBottom: 20, width: width}}>
                           
                    <div style={{display: 'flex', justifyContent: 'flexEnd', marginBottom: 10}}>       
                        <Tooltip title="Close">
                            <IconButton edge="end"onClick={this._closeButtonPressed} style={{marginLeft: 'auto'}}>
                                <CancelIcon />
                            </IconButton>
                        </Tooltip>   
                    </div>
                          
                    <Tooltip title="Search on any part of a product's ID, name, category, or description">
                         <TextField size="small" autoFocus type="search" value={this.state.searchText} onChange={this._searchFieldChanged}  
                                    label="Search for Product" onKeyDown={this._searchKeyDown} variant="outlined" 
                                    fullWidth={true} InputLabelProps={{ shrink: true}} />
                    </Tooltip>
                                    
                    <div style={{margin: 15}}/>

                    <Paper>                           
                        <div style={{minHeight: 100}}>

                            <div style={{display: 'flex'}}>
                                <Typography variant="body2" style={this.styles.paperLabel}>Matching Products</Typography>   
                                <Typography variant="body2" style={this.styles.currencyLabel}>{"Amounts in " + PP.currency}</Typography>
                            </div>

                            <div style={{margin: 25}}/>

                            {this.state.isBusy ? this.getBusyComponent('center', {}) :
                                
                                <div>
                                    <Typography variant="body1" style={this.styles.notFoundText}>{this.state.notFoundString}</Typography>

                                    {this.state.itemList.map((item, index) => 
                                    <ProductListEntry key={index} item={item} index={index} mode={ProductListModes.SEARCH}
                                                        fixedPoint={2}
                                                        action={this._productAddRequested}
                                                        hideRedeemables={!this.state.showRedeemables}
                                                        isMobile={this.props.isMobile} isVerySmall={this.props.isVerySmall}/>)}
                                </div>
                            }
                               
            

                        </div>
                    </Paper>  
                    
                    <Tooltip title="Show Products that are Redeemable at a later time">         
                        <FormControl component="fieldset" >  
                           <FormControlLabel control={<Checkbox checked={this.state.showRedeemables} 
                                                      onChange={this._showRedeemablesChanged}
                                                      color="primary"/>} 
                                             label="Show Redeemables"/>
                        </FormControl>
                    </Tooltip>

                </Container>

            </Popover>
        );
        
        
    }


}



export default withCookies(ProductSearchPopover);