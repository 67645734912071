import React, { Fragment, Component } from 'react';
import { withCookies } from 'react-cookie';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import { Paper, Tab, Tabs } from '@material-ui/core'

import checkMarkIcon from '../assets/checkMarkIcon.png'
import listIcon from '../assets/listIcon.png'
import writingHandIcon from '../assets/writingHandIcon.png'
import chartIcon from '../assets/chartIcon.png'
import logIcon from '../assets/logIcon.png'
import gearIcon from '../assets/gearIcon.png'
import shoppingIcon from '../assets/shoppingIcon.png'

import { PP } from '../models/PP'
import { Permissions } from 'react-frontend-utils';

import CheckInTab from '../pp_tabs/CheckInTab'
import MarketTab from '../pp_tabs/MarketTab'
import MemberListTab from '../pp_tabs/MemberListTab'
import ManageTab from '../pp_tabs/ManageTab'
import StatisticsTab from '../pp_tabs/StatisticsTab'
import LogTab from '../pp_tabs/LogTab'
import OpsTab from '../pp_tabs/OpsTab'


const MOBILE_WIDTH = 1146;
const SMALL = 996;
const EXTREMELY_SMALL = 667;
const INSANELY_SMALL = 447;
  
const ICON_SIZE = 18; //native icon size is 20

export class PoolPassPage extends Component {
  
    tabPages = {
        CHECKIN: {tabIndex: 0, icon: checkMarkIcon,        text: "Check In",      collapseText: "Check", collapseAt: EXTREMELY_SMALL,    visible: PP.user.hasPermissionTo(Permissions.CHECK_IN) && !PP.checkInDisabled},
        MARKET:  {tabIndex: 1, icon: shoppingIcon,         text: "Market",        collapseText: null,    collapseAt: 0,                  visible: PP.user.hasPermissionTo(Permissions.MARKETPLACE_PURCHASE) || PP.user.hasPermissionTo(Permissions.MARKETPLACE_REDEEM)},
        LIST:    {tabIndex: 2, icon: listIcon,             text: "Member List",   collapseText: "List",  collapseAt: MOBILE_WIDTH,       visible: PP.user.hasPermissionTo(Permissions.VIEW_DATABASE)},
        MANAGE:  {tabIndex: 3, icon: writingHandIcon,      text: "Manage",        collapseText: null,    collapseAt: 0,                  visible: PP.user.hasPermissionTo(Permissions.VIEW_DATABASE)},
        STATS:   {tabIndex: 4, icon: chartIcon,            text: "Statistics",    collapseText: "Stats", collapseAt: MOBILE_WIDTH,       visible: PP.user.hasPermissionTo(Permissions.VIEW_DATABASE)},
        LOG:     {tabIndex: 5, icon: logIcon,              text: "Log",           collapseText: null,    collapseAt: 0,                  visible: PP.user.hasPermissionTo(Permissions.VIEW_DATABASE)},
        OPS:     {tabIndex: 6, icon: gearIcon,             text: "Operations",    collapseText: "Ops",   collapseAt: MOBILE_WIDTH,       visible: PP.user.isObserver() || PP.user.hasPermissionTo(Permissions.BULK_OPERATION)}   // observers can do some get bulk operations
    }
  
  
    state = {
        windowWidth : SMALL,
        selectedTab: null,
        memberToManage: {membership: null, member: null}, 
        memberToCheckIn: {membership: null, member: null},
        ticketToRedeem: null,
        membershipIDToManage: null
    }
    
    constructor(props) {
        super(props);
        
        if (props.membershipID !== undefined) { //if null, we also want to visit the page without a specific id
            this.state.selectedTab = this.tabPages.MANAGE.tabIndex;
            this.state.membershipIDToManage = props.membershipID;
            if (this.tabPages.MANAGE.visible)   // great, its visible, we can stay here
                return;
        }
        
        this.state.selectedTab = this.tabPages.CHECKIN.tabIndex;    // this is nominally the tab to initially select
        
        if (!this.tabPages.CHECKIN.visible) {
            this.state.selectedTab = this.tabPages.LIST.tabIndex;   // can't check in? then show the Member List
    
            if (!this.tabPages.LIST.visible)    // that's not visible?  a real problem, we can't show any tab
                this.state.selectedTab = -1;
        }

    
    }

    componentDidMount() {
       this._updateSize();
       window.addEventListener("resize", this._updateSize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this._updateSize);
    }


    _updateSize = () => {
        this.setState({ windowWidth: window.innerWidth, isMobile: window.innerWidth < MOBILE_WIDTH }); 
    }

    _switchTab = (event, newValue) => {  //switch to new tab, and clear any membershipIDToManage or ticketToRedeem that came from above
        this.setState({selectedTab: newValue, membershipIDToManage: null, ticketToRedeem: null});
    }
    
    checkInMember = (membershipID, memberID) => {
        console.log("Switching to Check In tab for: " + membershipID + "-" + memberID);
        this.setState({selectedTab: this.tabPages.CHECKIN.tabIndex, memberToCheckIn: {membership: membershipID, member: memberID}});
    }
    
    manageMember = (membershipID, memberID) => {
        console.log("Switching to Manage Member tab for: " + membershipID + "-" + memberID);
        this.setState({selectedTab: this.tabPages.MANAGE.tabIndex, memberToManage: {membership: membershipID, member: memberID}});
    }
    
    ticketFound = (ticketId) => {
        console.log("Switching to Marketplace tab for: " + ticketId);
        this.setState({selectedTab: this.tabPages.MARKET.tabIndex, ticketToRedeem: ticketId});
    }
    
    render() {
        
        //Override breakpoints for tabs in order for the reponsiveness to improve (collapses space between at "sm" size)
        const tabThemeOverride = createMuiTheme({
            breakpoints: {
              values: {
                xs: 0,
                sm: MOBILE_WIDTH,
                md: 1280,
                lg: 1280,
                xl: 1920
              }
            }
        });         
        
        
        //Selects the current tab to view
        const viewingTab = (() => {
            
            switch (this.state.selectedTab) {

                case this.tabPages.CHECKIN.tabIndex:   
                    return <CheckInTab onManageMember={this.manageMember} memberToCheckIn={this.state.memberToCheckIn}
                                       onTicketFound={this.ticketFound}/>;
                    
                case this.tabPages.MARKET.tabIndex:   
                    return <MarketTab ticketToRedeem={this.state.ticketToRedeem}/>;
                
                case this.tabPages.LIST.tabIndex:
                    return <MemberListTab onManageMember={this.manageMember} onCheckInMember={this.checkInMember}/>;
                
                case this.tabPages.MANAGE.tabIndex:
                    return <ManageTab memberToManage={this.state.memberToManage} onCheckInMember={this.checkInMember} membershipIDToManage={this.state.membershipIDToManage}/>;
        
                case this.tabPages.STATS.tabIndex:
                    return <StatisticsTab/>;
        
                case this.tabPages.LOG.tabIndex:
                    return <LogTab/>;
                       
                case this.tabPages.OPS.tabIndex:
                    return <OpsTab/>;               
                
                default:
                    return <div>Tab Not Found</div>;

            }
        })();
        
        
        //Creates the text label for the tab, shrinking font, adding a <div> between icon and text, and collapsing to abbreviated text as necessary
        //to fit the width of the screen
        const tabLabel = (tab) => {
            let fontSize = 15;
            let iconSize = ICON_SIZE;
            if (this.state.windowWidth < MOBILE_WIDTH) {
                iconSize -= 2;
            }
            if (this.state.windowWidth < MOBILE_WIDTH) {
                fontSize -= 1;
            }
            if (this.state.windowWidth < EXTREMELY_SMALL) {
                fontSize -= 1;
                iconSize -= 1;
            }
            if (this.state.windowWidth < INSANELY_SMALL) {
                fontSize -= 2;
                iconSize -= 1;
            }

            let text = this.state.windowWidth >= tab.collapseAt ? tab.text : tab.collapseText;

            if (this.state.windowWidth < SMALL)     
                return (<span style={{fontSize: fontSize}}>
                            <img src={tab.icon} alt="icon" width={iconSize} height={iconSize}/> 
                            <div/>
                            {text}
                       </span>);
            else
                return (<span>
                            <img style={{verticalAlign: 'middle'}} src={tab.icon} alt="icon" width={iconSize} height={iconSize}/>
                            <span style={{verticalAlign: 'middle', paddingLeft: 8, fontSize: fontSize}}>{text}</span>
                        </span>);

        };

        const style = this.state.windowWidth < EXTREMELY_SMALL ? {minWidth: 50} : {};
        
        const tabsArray = [];
        for (const index in this.tabPages) {
            const tab = this.tabPages[index];
            if (tab.visible)
                tabsArray.push(<Tab key={tab.tabIndex} style={style} value={tab.tabIndex} label={tabLabel(tab)}/>);
        }
        
        
        if (tabsArray.length === 0)
            return <div style={{textAlign: 'center', fontSize: 20}}>No Permisson for any Operation</div>

        
        return (
            
            <Fragment >

                {PP.user.isKiosk() ? null :
                    <Paper>
                        <MuiThemeProvider theme={tabThemeOverride}>
                            <Tabs value={this.state.selectedTab} onChange={this._switchTab} indicatorColor="primary" textColor="primary" centered={!this.state.isMobile} variant={this.state.isMobile ? 'fullWidth' : 'standard'} >

                                {tabsArray} 

                            </Tabs>
                        </MuiThemeProvider>
                    </Paper>
                }

                <div style={{padding: 10}}/>
           
                <div>
                    {viewingTab} 
                </div>

            </Fragment>
        );

    }
    
};


export default withCookies(PoolPassPage);