import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';

import { Typography, TextField, Paper, Grid, Button } from '@material-ui/core'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { Product } from '../models/Product'
import { RestComponent } from 'react-frontend-utils'
import ProductListEntry, { ProductListModes } from '../components/ProductListEntry'
import { TransactionFunctions } from '../utils/TransactionFunctions'
import { Currency, Email } from 'react-frontend-utils'


const MAX_TRANSACTION_AMOUNT = 1000.0;

/**
 * Store page for a single database's Patron Portal.  
 * 
 * The caller must pass the props:
 * 
 * visible:     true to show
 * database:    database (PublicJsonGroup) retrieved from GET /patron/databases/{databaseName}
 * account:     a PublicAccount object if the portal was visited using a referrer link, null otherwise (for guest)
 * onCheckoutRequested: a function called when the Checkout button is pressed, passing the funds to add, the cart, and membershipInfo in an object
 * onProceedAsGuestRequested: a function called when the "Proceed as Guest" button is pressed, which clears the account information
 * isMobile:    true for mobile sizes
 * isVerySmall: true for extremely small sizes
 * 
 * 
 * The "database" object used in this class is not defined as an object, but is represented by the JsonPublicGroup fields from the backend
 */
export class Store extends RestComponent {
  
    styles = {
        greeting: {
            textAlign: 'center',
            marginLeft: 40,
            marginRight: 40,
            fontSize: 18
        },
        paper: {
            width: '100%',
            height: '100%'
        },
        paperLabel: {
            color: 'blue',
            fontSize: '12pt',
            flexGrow: 1
        },
        currencyLabel: {
            marginLeft: 15,
            marginRight: 15,
            marginBottom: 5,
            color: 'gray',
            fontSize: '9pt',
            flexGrow: 1,
            textAlign: 'right'
        },
        membershipInstructionsLabel: {
            color: 'gray',
            fontSize: '9pt',
            flexGrow: 1,
            textAlign: 'left'
        },
        textfield: {
            marginBottom: 15
        },
        membershipField: {
            marginTop: 15,
            marginBottom: 5
        },
        thinDivider: {
            marginTop: 20, 
            marginBottom: 30, 
            border: '1px solid gray'
        },
        notFoundText: {
            textAlign: 'center',
            fontStyle: 'italic',
            color: 'gray'
        },
        helpButtons: {
            display: 'block', 
            marginTop: 10,
            color: 'gray', 
            textDecoration: 'underline'
        },
        helpText: {
            color: 'black',
            marginLeft: 20
        },
        searchingText: {
            marginTop: 10,
            textAlign: 'center',
            fontStyle: 'italic',
            color: 'gray'
        }
    };
  

    _databaseMktSettings;
    _onCheckoutRequested;
    _onProceedAsGuestRequested;

    constructor(props) {
        super(props);
        this._databaseMktSettings = props.database;

        this.state.membershipInfo = "";              //The membership ID or barcode typed in to identify the Membership
        this.state.cart = [];                   //shopping cart - array of {products, quantity, quantityStep}
        this.state.fundsValue = "0.00";         //add funds text field
        this.state.addFunds = 0;                //amount to add to account balance
        this.state.showAccountRequest = false;  //true to show the account request section
        this.state.searchingMembership = false;
        
        if (this._databaseMktSettings.ticketEnabled)
            this.state.showMarket = false;          //don't yet show the market section until they proceed as guest or request account
        else  //Tickets are disabled, so just go straight to the store
            this.state.showMarket = true;
        

        this.state.showWhyEmail = false;
        this.state.showNoEmail = false;

        this._onCheckoutRequested = props.onCheckoutRequested;
        this._onProceedAsGuestRequested = props.onProceedAsGuestRequested;
        
        //If Tickets are disabled, go straight to the store as a guest
        if (!this._databaseMktSettings.ticketEnabled)
            this._proceedAsGuest();
        
    }

    componentDidMount() {
        if (this.props.account)
            this._proceedWithAccount(this.props.account);
        else
            this._proceedAsGuest();
    }
    
    
    //Called when Checkout button is pressed, show terms, then tell Parent component to switch to Checkout page
    _startCheckout = () => {
        
        // Show terms and conditions, if not null or blank
        if (this._databaseMktSettings.termsAndConditions && this._databaseMktSettings.termsAndConditions.trim().length > 0) {

            this.showConfirmAlert("Terms and Conditions", this._databaseMktSettings.termsAndConditions,
                                  'black', "Cancel", 
                                  () => {this._doCheckout();},
                                  "I Agree",
                                  'green');
        }
        else
            this._doCheckout();
    }

    _doCheckout = () => {

        const filteredCart = this.state.cart.filter(item => item.quantity > 0); //filter out any items that have zero quantity
        
        const totals = TransactionFunctions.totalShoppingCart(filteredCart);  //calculate totals  
        const totalBeforeServiceFees = this.state.addFunds + totals.total;
        const totalServiceFees = TransactionFunctions.serviceFees(totalBeforeServiceFees, this._databaseMktSettings);
        const grandTotal = totalBeforeServiceFees + totalServiceFees;
        
        if (this.state.addFunds > 0 && this.state.addFunds < this._databaseMktSettings.minDeposit) {
            const minVal = Currency.symbolForISO(this._databaseMktSettings.isoCurrency) + this._databaseMktSettings.minDeposit;
            this.showConfirmAlert("Error", "If you are adding funds, the minimum amount you must add is " + minVal, 'red');
            return;
        }
            
        const isFree = grandTotal === 0;
        
        if (grandTotal > MAX_TRANSACTION_AMOUNT) {
            const maxVal = Currency.symbolForISO(this._databaseMktSettings.isoCurrency) + MAX_TRANSACTION_AMOUNT;
            this.showConfirmAlert("Error", "The limit for a single order is " + maxVal, 'red');
            return;
        }
        
        if (totals.requiresMembership) {
            this.setBusy(true);
            this.setState({searchingMembership: true});
            this.secureJSONFetch("/patron/databases/" + this._databaseMktSettings.name + "/membershipCheck?query=", {}, 
                                  () => this._continueCheckout(filteredCart, this.state.addFunds, totalServiceFees, isFree), this._membershipCheckFailed, encodeURIComponent(this.state.membershipInfo)); 
        }
        else
            this._continueCheckout(filteredCart, this.state.addFunds, totalServiceFees, isFree);
        
    }
    
    
    _continueCheckout = (filteredCart, addFunds, serviceFee, isFree) => {
        this.setBusy(false);
        this.setState({searchingMembership: false});
        const checkout = {funds: this.state.addFunds, cart: filteredCart, serviceFee: serviceFee, membershipInfo: this.state.membershipInfo, isFree: isFree};
        this._onCheckoutRequested(checkout);
    }
    
    
    _membershipCheckFailed = () => {
        this.setBusy(false);
        this.setState({searchingMembership: false});
        this.showConfirmAlert("Membership not Found", "We cannot find your Membership. Please check your entry and try again. Enter your pass number, barcode number, or Membership ID.", 'red');
    }
    
    //After proceeding, fetch all the product's available for sale
    _fetchMarket = () => {
        this.setBusy(true);
        this.secureJSONFetch("/patron/databases/" + this._databaseMktSettings.name +  "/products", 
                            {}, this._fetchMarketCallback, this._fetchErrorCallback);     
    }
    
    
    //Grab all products from the response and place them into the cart, with quantity zero
    _fetchMarketCallback = (response) => {
         this.setBusy(false);
         if (response) {            
            const products = response.map((product) => new Product(product));
            const cart = products.map((product) => { return {product: product, quantity: 0, quantityStep: product.minQuantity}; });
            this.setState({cart: cart});
        }
    }
    
       
    _fetchErrorCallback = (error) => {
        this.setBusy(false);
        this.setState({serverError: error.toString()});
    }
    
    
    //Account is null, and show the market
    _proceedAsGuest = () => {
        this._onProceedAsGuestRequested();
        this.setState({showMarket: true, showAccountRequest: false});  
        this._fetchMarket();
    }
    
    //Set the account, and if the account is in an available status, show the market
    _proceedWithAccount = (account) => {
        this.setState({showMarket: account.status === "AVAILABLE", showAccountRequest: false});
        this._fetchMarket();
    }
    
    
    //Called when the user presses the Submit button to send an email with account details and referrer link
    //Validate the email first
    _submitEmail = () => {
        console.log("Submitting: " + this.state.emailFieldValue);
        
        if (!Email.validateEmail(this.state.emailFieldValue)) {
            this.showConfirmAlert("Error", "Email address is not valid", 'red');
            return;
        }
        this.setBusy(true);
        this.secureJSONFetch("/patron/databases/" + this._databaseMktSettings.name + "/account?email=", 
                             {method: "POST"}, this._submitEmailCallback, this._submitEmailFailed, encodeURIComponent(this.state.emailFieldValue)); 
                             
    }
    
    _submitEmailCallback = () => {
        this.setBusy(false);
        this.showConfirmAlert("Ticket(s) Found!", "We found one or more Tickets associated with that email address. We resent those tickets to you. Please check your email.", 'green');
    }
    
    _submitEmailFailed = () => {
        this.setBusy(false);
        this.showConfirmAlert("Email not Found", "We were unable to find any Tickets associated with that email address. Please try again with a different address.", 'red');
   
    }
    
    
    _fundsValueChanged = () => {
        
        let newFunds = parseFloat(this.state.fundsValue);  //invalid string returns NaN
        if (!newFunds || newFunds < 0)
            newFunds = 0;  
        
        this.setState({addFunds: newFunds, fundsValue: Currency.round(newFunds)});
        
    }
    
    _itemQuantityChanged = (index, value) => {

        let newQuantity = parseInt(value);
        
        const cart = this.state.cart;
        const product = cart[index].product;

        newQuantity = TransactionFunctions.validateQuantity(newQuantity, product, true);

        cart[index].quantity = newQuantity;
        this.setState({cart: cart}); 
    }      
    
    _itemQuantityCleared = (index) => {
        this._itemQuantityChanged(index, 0);
    }
    
    
    //Zero all quantities
    _clearOrder = () => { 
        const cart = this.state.cart;
        cart.forEach(item => {item.quantity = 0;});
        this.setState({cart: cart, addFunds: 0, fundsValue: "0.00"}); 
    }
    
    
    //If the account is unavailable, the market isn't shown.  Show this reason instead.
    _unavailableReason = () => {

        let reason = "we can't access your ticket.";
        if (this.props.account.status) {
            switch (this.props.account.status) {
                case "AVAILABLE":
                    return null;    //its available!
                case "NOT_FOUND":
                    return reason + " This link is no longer valid. Please click Locate an Existing Ticket to request a new link.";
                case "LINK_EXPIRED":
                    return reason + " This link has expired. Please click Locate an Existing Ticket to request a new link.";
                case "DISABLED":
                    return reason + " This ticket has been deactivated. Please check with your facility manager.";                   
                default:
                    return reason + " There was an error getting your ticket status.";                   
            }
        }
        
        return reason;
    }
   
    _toggleWhyEmail = () => {
        this.setState(prevState => ({showWhyEmail: !prevState.showWhyEmail}));
    }
    
    _toggleNoEmail = () => {
        this.setState(prevState => ({showNoEmail: !prevState.showNoEmail}));
    }
   

    render() {
        
        if (!this.props.visible)
            return null;
                    
        let greeting = null;
        let proceedButtons = null;

        //Once fetching is done, set a greeting component
        if (!this.state.isBusy) {
            
            if (this.props.account) {  //retrieved an account (even if not useable)
                const name = this.props.account.name ? (" " + this.props.account.name) : "";  //account has a name, use it, otherwise none
                
                const unavailableReason = this._unavailableReason();  //if null, account is available, otherwise a reason why not
                
                if (unavailableReason) {  //can't use the account                  
                    greeting = <div style={this.styles.greeting}>{"We're sorry" + name + ", " + unavailableReason}</div>;
                }
                else
                    greeting = <div style={this.styles.greeting}>{"Welcome back" + name + "!"}</div>;
            }
            else { //no account - arrived without a referrer code
                greeting = null;
            }
            
        }
        
        
        //If not busy and the market is not showing, then show the proceed buttons.
        //If proceeding as guest, set the account to null and show the market section.
        //If requesting account, show the account request section
        if (!this.state.isBusy && !this.state.showMarket) {
            proceedButtons = <div>
                                       
                                <Button color="primary" style={{marginBottom: 20}} onClick={this._proceedAsGuest}>
                                    <ArrowBackIosIcon fontSize='small'/> 
                                    <Typography variant="body1">Return to Store as Guest</Typography>   
                                </Button> 
                                       
                                {this.state.showAccountRequest ? null :
                                    <div style={{display: 'flex', justifyContent: 'center'}}> 
                                       <Button onClick={() => {this.setState({showAccountRequest: true});}} variant="outlined" component="label">
                                           Locate an Existing Ticket
                                       </Button>
                                    </div>
                                }

                            </div>;              
        }
        
        let depositInfo = "";
        let infoText = "";
        let accountInfoText = "";
        let lookupExistingTicket = null;
        
        if (this.state.showMarket) {
            
            if (this._databaseMktSettings.purchaseOn) {
            
                if (this._databaseMktSettings.ticketEnabled) {
            
                    if (this._databaseMktSettings.canDeposit)
                        depositInfo = "You can also add money to the Ticket, which like a gift card, can be used to purchase items, such as concessions, at your facility. ";

                    infoText = "In this marketplace you can purchase items redeemable at your facility. After checkout, you will receive an email with a Ticket " +
                               "to present at your facility. The attendant will scan the Ticket to redeem your products. " + depositInfo;
                       
                    lookupExistingTicket = <div style={{display: 'flex', gap: 5, marginTop: 10, alignItems: 'center'}}>
                                                <div>Need to locate an existing Ticket?</div>
                                                <Button style={{textTransform: 'none', fontSize: 16, display: 'flex', textDecoration: 'underline', color: 'blue', fontWeight: 'normal', padding: 0}} 
                                                        onClick={() => {this.setState({showMarket: false, showAccountRequest: true});}}>Click here to receive your Tickets by email
                                                </Button>
                                            </div>

                    accountInfoText = this.props.account ? ("Because you visited this page using a link from your email, your purchases will be added to the same Ticket from that email. " +
                                                    "Please check your email to view your existing balance and redeemables.") : ("You can visit this marketplace again using the link in " +
                                                    "the email to purchase additional items " + (this._databaseMktSettings.canDeposit ? "and add funds to " : "on ") + "the same Ticket.");
                }

            }
            else {
                infoText = "The marketplace is currently unavailable. Please check back later.";
            }
                      
            
        }
        
        const totals = TransactionFunctions.totalShoppingCart(this.state.cart);  //calculate running totals    

        const totalBeforeServiceFees = this.state.addFunds + totals.total;
        
        const totalServiceFees = TransactionFunctions.serviceFees(totalBeforeServiceFees, this._databaseMktSettings);

        const grandTotal = totalBeforeServiceFees + totalServiceFees;

        const canDoPurchase = !this.state.isBusy && (grandTotal > 0 || totals.items > 0) && (totals.requiresMembership ? this.state.membershipInfo : true);
        const currency = this._databaseMktSettings.isoCurrency;
        const currencySymbol = Currency.symbolForISO(currency);
        const notFoundText = this.state.cart.length > 0 ? null : "There are no products currently available. Please check back later.";        
                
        return (
           
            <Fragment>
                
                {this.getConfirmAlertComponent()}

                {//If there is a valid database and no server error, show the greeting, 
                 //the proceed buttons, if any, and (if set) the account request section
                 !this.state.serverError ?
                    <div>

                        {greeting}

                        <div style={{marginTop: 20}}/>

                        {proceedButtons}

                        {this.state.showAccountRequest ? 

                            <div style={{marginTop: 20}}>

                                <Typography variant="body2" style={{fontSize: 18}}>If you have made purchases before and cannot locate your Ticket, enter your email address below. If your Ticket is found it will be emailed to you. Click the link in the email to make additional purchases.</Typography>
                                <div style={{display: 'flex', marginTop: 30}}>

                                    <TextField autoFocus value={this.state.emailFieldValue} onChange={(event) => {this.setState({emailFieldValue: event.target.value})}}  
                                       label="Email Address" variant="outlined" 
                                       inputProps={{style: {fontSize: 16}}} fullWidth={true} InputLabelProps={{ shrink: true}} />

                                    <Button onClick={this._submitEmail} style={{marginLeft: 10}} color='primary' variant="outlined" component="label">
                                           Submit
                                    </Button>
                                    
                                </div>
                           
                                <div style={{marginTop: 30}}>
            
                                    <Button onClick={this._toggleWhyEmail} style={this.styles.helpButtons}>Why do this?</Button>
                                    {this.state.showWhyEmail ? <div style={this.styles.helpText}>Some facilities offer products that never expire or can be used multiple times.  Rather than creating a new Ticket for each purchase, add new items to your existing Ticket. This allows you to manage all your items in one place. Use the link in the email to make additional purchases.</div> : null}

                                    <Button onClick={this._toggleNoEmail} style={this.styles.helpButtons}>I never received an email</Button>
                                    {this.state.showNoEmail ? <div style={this.styles.helpText}>Check your junk or spam folder. If your Ticket has nothing remaining on it, your facility manager may have deleted it. In this case please proceed as a guest.</div> : null}
            
                                </div>
            
                            </div> : null
                        }

                        {this.state.showMarket ? 
                            <div style={{marginTop: 20, marginLeft: 25, marginRight: 25}}>
                                <div>{infoText}</div>
                                <div style={{marginTop: 10}}>{accountInfoText}</div>
                                {lookupExistingTicket}
                            </div>
                            : null
                        }


                    </div> : null
                }

                {this.state.isBusy ? this.getBusyComponent('center', {marginTop: 10}) : null}
                {this.state.searchingMembership ? <div style={this.styles.searchingText}>We are validating your membership. This may take a few moments.</div> : null}
            
                {this.state.showMarket && this._databaseMktSettings.purchaseOn ?
                  <div>

                    <div style={this.styles.thinDivider}/>

                    <Grid container direction="row" spacing={3}>

                        { /*-----------   PRODUCTS TO PURCHASE LEFT  -------------------*/ null }
                        <Grid item md={9} xs={12} style={{justifyContent: 'center'}}> 

                            {this._databaseMktSettings.canDeposit ? 
                                <Paper style={{marginBottom: 25, maxWidth: 300, marginRight: 10}}>                           
                                    <div style={{marginLeft: 10, marginRight: 10}}>

                                        <div style={{display: 'flex'}}>
                                            <Typography variant="body2" style={this.styles.paperLabel}>Add Funds</Typography>   
                                            <Typography variant="body2" style={{...this.styles.currencyLabel, marginRight: 0}}>{"Amounts in " + currency}</Typography>  
                                        </div>  
                                        <div style={{margin: 25}}/>

                                        <TextField size="small" label="Amount" variant="outlined" type="number"
                                                   onChange={(event) => {this.setState({fundsValue: event.target.value});}}
                                                   onKeyDown={(event) => { if (event.key === 'Enter')   //enter key releases focus
                                                                               event.target.blur();  
                                                                          }}
                                                   onBlur={this._fundsValueChanged}
                                                   value={this.state.fundsValue} 
                                                   style={this.styles.textfield} fullWidth={true} 
                                                   inputProps={{readOnly: false, style: {textAlign: 'left'}, min: 0}} 
                                                   InputLabelProps={{ shrink: true}} />
                                    </div>
                                </Paper> : null
                            } 
                                

                            <Paper>                           
                                <div style={{minHeight: 100}}>

                                    <div style={{display: 'flex'}}>
                                        <Typography variant="body2" style={{...this.styles.paperLabel, marginLeft: 10}}>Products for Sale</Typography>   
                                        <Typography variant="body2" style={this.styles.currencyLabel}>{"Amounts in " + currency}</Typography>  
                                    </div>
                                    <Typography variant="body2" style={{...this.styles.currencyLabel, marginTop: -6, fontStyle: 'italic'}}>Prices shown include tax</Typography>  

                                    <div style={{margin: 25}}/>

                                    <Typography variant="body1" style={this.styles.notFoundText}>{notFoundText}</Typography>

                                    {this.state.cart.map((item, index) => 
                                        <ProductListEntry key={index} item={item} index={index} mode={ProductListModes.PUBLIC}
                                                          quantityChanged={this._itemQuantityChanged} fixedPoint={2}
                                                          action={this._itemQuantityCleared} currencySymbol={currencySymbol}
                                                          isMobile={this.props.isMobile} isVerySmall={this.props.isVerySmall}/>)}


                                </div>
                            </Paper>                          
                         </Grid>

                        { /*-----------  CHECKOUT RIGHT ----------------------*/ null }

                        <Grid item md={3} xs={12} style={{justifyContent: 'center'}}>  

                            {totals.requiresMembership ? 
                                <Paper style={{marginBottom: 25}}> 
                                    <div style={{marginLeft: 10, marginRight: 10, paddingBottom: 10}}>
                                        <Typography variant="body2" style={this.styles.paperLabel}>Membership</Typography>   
                                        <TextField size="small" variant="outlined" value={this.state.membershipInfo} 
                                                   onChange={(event) => {this.setState({membershipInfo: event.target.value})}} 
                                                   style={{...this.styles.membershipField, backgroundColor: (this.state.membershipInfo ? 'white' : 'yellow')}} 
                                                   fullWidth={true} InputLabelProps={{ shrink: true}} />
                                        <Typography variant="body2" style={this.styles.membershipInstructionsLabel}>{"A valid Membership is required by " + totals.requiresMembership + " product" + (totals.requiresMembership > 1 ? "s" : "") + ". Enter your pass number, barcode number, or Membership ID above."}</Typography>  
                                    </div>                                        
                                </Paper> : null
                             }

                            <Paper>                           
                                <div style={{marginLeft: 10, marginRight: 10}}>

                                    <Typography variant="body2" style={this.styles.paperLabel}>Purchase</Typography>   
                                    <div style={{margin: 25}}/>

                                    {this._databaseMktSettings.canDeposit ?
                                        <TextField size="small" label="Funds to Add" variant="outlined" value={currencySymbol + Currency.round(this.state.addFunds)} style={this.styles.textfield} fullWidth={true} inputProps={{readOnly: true, style: {textAlign: 'right'}}} InputLabelProps={{ shrink: true}} />
                                        : null
                                    }
                                    <TextField size="small" label="Products Subtotal" variant="outlined" value={currencySymbol + Currency.round(totals.subtotal)} style={this.styles.textfield} fullWidth={true} inputProps={{readOnly: true, style: {textAlign: 'right'}}} InputLabelProps={{ shrink: true}} />
                                    <TextField size="small" label="Tax" variant="outlined" value={currencySymbol + Currency.round(totals.tax)} style={this.styles.textfield} fullWidth={true} inputProps={{readOnly: true, style: {textAlign: 'right'}}} InputLabelProps={{ shrink: true}} />
                                    <TextField size="small" label="Service Fee" variant="outlined" value={currencySymbol + Currency.round(totalServiceFees)} style={this.styles.textfield} fullWidth={true} inputProps={{readOnly: true, style: {textAlign: 'right'}}} InputLabelProps={{ shrink: true}} />
                                    <TextField size="small" label="Total" variant="outlined" value={currencySymbol + Currency.round(grandTotal)} style={this.styles.textfield} fullWidth={true} inputProps={{readOnly: true, style: {textAlign: 'right', fontWeight: 'bold'}}} InputLabelProps={{ shrink: true}} />

                                    <Grid container direction="row" spacing={2}>
                                        <Grid item md={12} sm={6} xs={12}>    
                                            <Button disabled={!canDoPurchase} onClick={this._startCheckout} fullWidth={true} variant="contained" 
                                                    color="primary" style={{color: 'white'}} startIcon={<MonetizationOnIcon/>}>Checkout</Button>
                                        </Grid>
                                        <Grid item md={12} sm={6} xs={12}>    
                                            <Button onClick={this._clearOrder} style={{marginBottom: 5}} fullWidth={true} 
                                                    variant="outlined" startIcon={<ClearIcon style={{color: 'red'}}/>}>Clear</Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Paper>
                        </Grid>   

                    </Grid> 
                   </div> : null 
                }

            </Fragment>
        );
    }
}


export default withCookies(Store);
