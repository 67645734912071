import React from 'react';
import { withCookies } from 'react-cookie';

import { FixedSizeList } from 'react-window';

import { TextField, IconButton, Tooltip, Grid, Typography, Button, Paper } from '@material-ui/core'
import { Divider, List, ListItem, ListItemIcon, ListItemText, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'

import RefreshIcon from '@material-ui/icons/Refresh';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import DescriptionIcon from '@material-ui/icons/Description';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ReplyIcon from '@material-ui/icons/Reply';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import PrintIcon from '@material-ui/icons/Print';

import { PP } from '../models/PP'
import { ThemeColors } from '../Theme'
import { Membership } from '../models/Membership'
import { Member } from '../models/Member'
import { ImageFunctions, MEMBER_IMAGE_ASPECT_RATIO, MEMBER_IMAGE_NATIVE_WIDTH, MEMBER_IMAGE_NATIVE_HEIGHT } from '../utils/Image';

import { RestComponent, ImageUtils, PopupMenu, TextEntryPopover, WebcamCapture, ImageCropper, DragAndDrop } from 'react-frontend-utils'

import { ManagedField } from '../components/ManagedField'
import NewMembershipPopover from '../components/NewMembershipPopover'
import  MultiMemberSelector from '../components/MultiMemberSelector'
import { ManageTextField, ManageTextFieldWithButton, Permissions, multilineJSX } from 'react-frontend-utils'
import { AudioPlay } from '../utils/Audio'

/**
 * 
 * The ManageTab shows a single Membership, a list of its Members, and details for a selected Member (or blank if not selected).
 * 
 * The ManageTextField is used to programmatically create the TextField components from the ManageMembershipFields and ManageMemberFields 
 * const arrays below. The json tags are used to identify the field and provide the json key when calling the PATCH method on the 
 * Membership or Member to moidfy.  They key is the field to be modified. Null keys indicate the field is read-only. The "ref" is a 
 * React reference used to programmatically set the text when a new Membership/Member is selected. The initialValue provides the starting
 * text for that component.
 * 
 * When the text is changed a callback occurs from the ManageTextField to trigger a PATCH request. When the request succeeds, the field
 * changes color back to white. 
 * 
 * 
 * The caller MUST pass in a memberToManage object with two fields (membership, member).  
 *      Both fields can be populated: membership and member to manage
 *      Both fields can be null: blank tab
 *      Just member can be null: manage a membership, no member selected
 *      
 * 
 */


const ManageMembershipFields = [
    
    {component: "TextField", json: null, label: "Membership ID",  ref: React.createRef(), initialValue: (membership) => { return membership.id; } },
    {component: "TextArea", json: "address", lines: 3, label: "Address",  ref: React.createRef(), initialValue: (membership) => { return membership.address; } },
    {component: "TextField", json: "primaryPhone", label: "Primary Phone",  ref: React.createRef(), initialValue: (membership) => { return membership.primaryPhone; } },
    {component: "TextField", json: "secondaryPhone", label: "Alternate Phone",  ref: React.createRef(), initialValue: (membership) => { return membership.secondaryPhone; } },
    {component: "EmailField", json: "email", label: "Email",  ref: React.createRef(), initialValue: (membership) => { return membership.email; } },
    {component: "TextField", json: "emergencyContact", label: "Emergency Contact",  ref: React.createRef(), initialValue: (membership) => { return membership.emergencyContact; } },
    {component: "TextField", json: null, label: "Date Created",  ref: React.createRef(), initialValue: (membership) => { return PP.dateFormat(membership.joinDate); } },
    {component: "MembershipTypeField", json: "type", label: "Type",  ref: React.createRef(), initialValue: (membership) => { return membership.type; } },
    {component: "Calendar", json: "expirationDate", hasNever: true, label: "Expiration Date",  ref: React.createRef(), initialValue: (membership) => { return membership.expirationDate ? PP.dateFormat(membership.expirationDate) : "Never"; } },
    {component: "Numeric", json: "guestPasses", label: "Virtual Guest Passes",  hasInfinity: false, ref: React.createRef(), initialValue: (membership) => { return membership.guestPasses; } },
    {component: "Switch", color: 'red', json: "suspended", label: "Suspended",  ref: React.createRef(), initialValue: (membership) => { return membership.suspended; } },
    {component: "TextArea", json: "notes", lines: 6, label: "Notes", ref: React.createRef(), initialValue: (membership) => { return membership.notes; } }
       
];
   
const ManageMemberFields = [
    
    {component: "TextField", json: null, label: "Member ID",  ref: React.createRef(), initialValue: (member) => { return member.id; } },
    {component: "TextField", json: "lastName", label: "Last Name",  ref: React.createRef(), initialValue: (member) => { return member.lastName; } },
    {component: "TextField", json: "firstName", label: "First Name",  ref: React.createRef(), initialValue: (member) => { return member.firstName; } },
    {component: "RelationField", json: "relation", label: "Relation",  ref: React.createRef(), initialValue: (member) => { return member.relation; } },
    {component: "BarcodeField", json: "barcode", label: "Barcode",  ref: React.createRef(), initialValue: (member) => { return member.barcode; } },
    {component: "Birthdate", json: "birthDate", hasNever: false, label: "Birthday",  ref: React.createRef(), initialValue: (member) => { return PP.dateFormat(member.birthDate); } },
    {component: "TextArea", json: "notes", lines: 6, label: "Notes", tooltip: "These notes are displayed at check-in", ref: React.createRef(), initialValue: (member) => { return member.notes; } },
    {component: "Numeric", json: "entryPasses", label: "Entry Passes",  hasInfinity: true, ref: React.createRef(), initialValue: (member) => { return member.entryPasses; } },
    {component: "Switch", color: ThemeColors.limitedBlue, json: "limited", label: "Limited",  ref: React.createRef(), initialValue: (member) => { return member.limited; } },
    {component: "Switch", color: 'red', json: "suspended", label: "Suspended",  ref: React.createRef(), initialValue: (member) => { return member.suspended; } },
    {component: "Checkboxes", json: "actions", label: "Actions", checkboxLabels: Member.actions, ref: React.createRef(), initialValue: (member) => { return member.actions; } }
];


const Relations = ["Assistant", "Aunt", "Child", "Cousin", "Daughter", "Father", "Grandchild", "Grandfather",
                    "Grandmother", "Grandparent", "Guest", "Head of Household", "Mother", "Nanny", "Nephew",
                    "Niece", "Owner", "Parent", "Partner", "Renter", "Resident", "Roomate", "Son", "Spouse", "Temporary Resident", "Uncle"];
    

export class ManageTab extends RestComponent {
  
  
     
    styles = {
        paper: {
            width: '100%',
            height: '100%'
        },
        paperLabel: {
            marginLeft: 15,
            color: 'blue',
            fontSize: '12pt',
            flexGrow: 1
        },
        checkInTimeLabel: {
            marginTop: 15,
            marginLeft: 15,
            color: 'gray',
            fontSize: '9pt',
            flexGrow: 1
        },
        statusLabel: {
            fontSize: '10pt',
            marginLeft: 15,
            marginTop: 5,
            textAlign: 'center',
            color: 'white',
            width: 85,
            textTransform: 'uppercase',
            borderRadius: 2
        },
        signInList: {
            margin: 10,
            marginTop: 0,
            border: '1px solid #CCCCCC',
            borderRadius: '4px'
        },
        barcodeField: {
            fontSize: '20px'
        },  
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 15
        }
    }
    
    _refFieldsNeedUpdate = false;
    
    _barcodeInput;             //reference to the barcode input widget
    _memberToManage;
    _membershipIDToManage;
    _memberIdsToPrint = [];
    
    _onCheckInMember;
    _memberPageRef = React.createRef();
            
    constructor(props) {
        super(props);

        this._memberToManage = props.memberToManage;                    //Passed in from another tab   
        this._membershipIDToManage = props.membershipIDToManage;        //Passed in from route
        this._onCheckInMember = props.onCheckInMember;                 //Passed in from another tab 

        this.state.isMobile = false;
        this.state.isSmall = false;
        this.state.isWideBrowser = false;                               //adapts to wide width
        this.state.barcodeFieldValue = "";                              //text for the barcode field
        
        this.state.membership = null;                                   //currently displayed membership
        this.state.members = null;                                      //membership's members
        this.state.selectedMember = null;                               //currently displayed member
        
        this.state.newMembershipPromptOpen = false;                     //prompt for new MembershipID        
        this.state.reassignMembershipPromptOpen = false;                //prompt for reassign Membership ID
        this.state.reassignMembershipForAll = false;
        
        this.state.cropperOpen = false;                                 //true to show the cropper page instead
        this.state.imageToCrop = null;
    
        this.state.webcamOpen = false;
        this.state.showMultiMemberSelector = false;                     //true to show multi-member select
        this.state.showPrintFeeQuestion = false;                        //show question about incurring print fees
        this.state.membershipTypes = [];
    }
    
    
    /**
     * Add a resize listener to adapt to width changes
     */
    componentDidMount() {

        super.componentDidMount();
    
        this._updateSize();
        window.addEventListener("resize", this._updateSize);
        window.addEventListener("paste", this._paste);
        
        //Do a lookup for the passed in Membership ID to manage
        if (this._membershipIDToManage) {
            this.setState({barcodeFieldValue: this._membershipIDToManage});
            this._search(this._membershipIDToManage);
            this._membershipIDToManage = null; //clear to remove double search
            return;
        }
        
        if (!this._memberToManage.membership || !this._memberToManage.member)
            return;

        //Do a lookup for the passed in Member to manage
        const searchString = this._memberToManage.membership + "-" + this._memberToManage.member;
        this.setState({barcodeFieldValue: searchString});        
        this._search(searchString);
        
        //Clear, to avoid double searches
        this._memberToManage.membership = null;
        this._memberToManage.member = null;
        this._fetchTypes();

    }
    
  
    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener("resize", this._updateSize);
        window.removeEventListener("paste", this._paste);
    }
    
    
    componentDidUpdate() {
        
        //If we need to refresh the reference fields, after a new membership lookup or switch out of another screen
        if (this._refFieldsNeedUpdate) {
            //Lookup the ReactComponent and set its text value to the initial text value for each Membership field         
            ManagedField.setFields(ManageMembershipFields, this.state.membership);

            //Lookup the ReactComponent and set its text value to the initial text value for each Member field            
            ManagedField.setFields(ManageMemberFields, this.state.selectedMember);

            this._refFieldsNeedUpdate = false;
            this.forceUpdate();
            this._fetchTypes();
        }
    }
    
    
    _fetchTypes = () => {                
        //fetch all membership types
        this.incrementBusy();
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/membership/types", {}, this._fetchMembershipTypesCallback); 
    }

    //callback when window changes size
    _updateSize = () => {
        this.setState({ isWideBrowser: window.innerWidth < 1168, isSmall: window.innerWidth < 919,  isMobile: window.innerWidth < 716 });  
    }
    
    
    _paste = (event) => {
        
        const canAddMember = this.state.membership && PP.user.hasPermissionTo(Permissions.EDIT_DATABASE);
        const canEditMember = canAddMember && this.state.selectedMember;
        
        if (!canEditMember)
            return;
        
        ImageUtils.handlePasteImage(event, this._imageCaptured);
    }
    
    
    //Search for a membership (and possibly member) to potentially manage
    _search = (searchString) => {
         //Search for the barcode/ID
        this.setBusy(true);
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/membership/search?query=" + searchString,
                             {}, this._searchMemberCallback, this._errorCallbackWithClear); 
    }
    
    
    //Re-lookup the Membership/Member
    _refresh = () => {
        
        const searchString = this.state.membership.id + (this.state.selectedMember ? "-" + this.state.selectedMember.id : "");        
        this._search(searchString);
    }
    
    
    _nextMembership = () => {
        
        const query = this.state.membership ? ("?after=" + this.state.membership.id) : "";
        
        this.setBusy(true);
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/membership/next" + query,
                             {}, this._searchMemberCallback, this._errorCallbackWithClear); 
                             
        this._clear();
    }
    
    _prevMembership = () => {
        
        const query = this.state.membership ? ("?before=" + this.state.membership.id) : "";
        
        this.setBusy(true);
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/membership/prev" + query,
                             {}, this._searchMemberCallback, this._errorCallbackWithClear); 

        this._clear();
    }   
    
    
    _focusBarcodeField() {
        if (this._barcodeInput)
            this._barcodeInput.focus();
    }
    
    
    _barcodeKeyDown = (event) => {
        if (event.key === 'Enter') {  //enter key
            
            const searchString = event.target.value;
            console.log("Barcode/ID entered: " + searchString);
            
            this._search(searchString);
           
            event.target.select(); //re-highlight all text
        }
    }
    
    
   _fetchMembershipTypesCallback = (response) => {
        if (response) {    
            this.setState({membershipTypes: response.filter(item => item.length > 0)}); //ignore empty types
        } 
        this.decrementBusy();
    }
   
    //Callback for searching for Member - JSON response is an object (tuple) containing a membership, a member array, and the ID of the selected one
    _searchMemberCallback = (response) => {
        if (response) {            
            const membership = new Membership(response.membership);        
            const members = response.members.map(member => new Member(member));
            const selectedIndex = response.selectedIndex;
            
            const selectedMember = selectedIndex >= 0 ? members[selectedIndex] : null;
                
            console.log("Found Membership: " + membership.id + " with " + members.length + " members, selected: " + (selectedMember ? selectedMember.id : "none"));
            
            this.setState({membership: membership, members: members, selectedMember: selectedMember});  
            this._barcodeInput.select();
            
            this._refFieldsNeedUpdate = true;

        }
        this.setBusy(false);
    }
   
    //Callback when the search fails
    _errorCallbackWithClear = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this._clear();
    }
    
   
    
    //clear all fields
    _clear = () => {
        this.setState({barcodeFieldValue: "", membership: null, members: null, selectedMember: null});
        this.setBusy(false);
        this._focusBarcodeField();   
               
        //Lookup the ReactComponent and set its value to null
        ManagedField.setFields(ManageMembershipFields, null);
        ManagedField.setFields(ManageMemberFields, null);

    }
    
    
    _selectMember = (member) => { 
        console.log("Selected member: " + (member ? member.name() : "None"));
        this.setState({selectedMember: member});
             
        //Lookup the ReactComponent and set its text value to the initial value for each Member field  
        ManagedField.setFields(ManageMemberFields, member);

    }
     
   
    //Just reflect the barcode field value from what is entered
    _barcodeFieldChanged = (event) => {
        this.setState({barcodeFieldValue: event.target.value});
    }
    
    //When a field is changed, find the field in the array, and clear its background to indicate change succeeded
    _membershipFieldChangedCallback = (response, json) => {
        this.decrementBusy();

        const changedField = ManageMembershipFields.find(field => field.json === json);
        if (changedField) {
            changedField.ref.current.accept();  //accepted change            
        }
        
        //If we have a valid response, check the id - if we haven't switched Memberships, then update (the response contains the Membership ID and the changed field, and the last transaction)
        if (response) {
   
            const updatedMembership = new Membership(response);
             if (updatedMembership.id !== this.state.membership.id)  //different membership
                return;
            
            //Update the membership, replacing the changed field    
            this.state.membership[json] = updatedMembership[json];  
            this.state.membership.lastTransaction = updatedMembership.lastTransaction;  //update the last transaction value
            
            this.forceUpdate();  //because something in our state changed    
        }
    }
    
    //When a field is changed, find the field in the array, and clear its background to indicate change succeeded
    //The Member is returned as json in successful change
    _memberFieldChangedCallback = (response, json) => {
        this.decrementBusy();

        const changedField = ManageMemberFields.find(field => field.json === json);
        if (changedField) {
            changedField.ref.current.accept();  //accepted change            
        }
        
        //If we have a valid response, check the id - if we haven't switched Memberships, then update (the response contains the Membership ID and the changed field, and the last transaction)
        if (response) {        
            
            const updatedMember = new Member(response);
            if (updatedMember.membershipRef !== this.state.membership.id)  //different membership
                return;
            
            //Update the member array, replacing the changed field in the member
            this.state.members.forEach( (member) =>  {
                if (member.id === updatedMember.id) {
                    member[json] = updatedMember[json];  //set the changed field in the member array
                    member.lastTransaction = updatedMember.lastTransaction;  //update the last transaction value
                    return;
                }
            });
            
            this.forceUpdate();  //because something in our state changed
            
        }
        
    }
    
    
    _fieldChangedErrorCallback = (error) => {
        this.decrementBusy();
        this.showConfirmAlert("Error", error, 'red');
    }
    
    _fieldInputError = (label, error) => {
        this.showConfirmAlert("Error in " + label, error, 'red');
    }
 
    //When a Membership field is changed, create a JSON object with the key of the field and the new value. Then PATCH it to the server.
    _membershipFieldChanged = (json, value) => {                
        console.log("Update field: " + json + " to " + value);

        const body = {[json]: value, lastTransaction: this.state.membership.lastTransaction};
        
        this.incrementBusy();
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/memberships/" + this.state.membership.id,
                            {method: "PATCH", body: JSON.stringify(body)}, 
                             (response) => {this._membershipFieldChangedCallback(response, json);}, this._fieldChangedErrorCallback); 
    }
    
    //When a Member field is changed, create a JSON object with the key of the field and the new value. Then PATCH it to the server.
     _memberFieldChanged = (json, value) => {
        console.log("Update field: " + json + " to " + value);

        const body = {[json]: value, lastTransaction: this.state.selectedMember.lastTransaction};
        
        this.incrementBusy();
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/memberships/" + this.state.membership.id + "/members/" + this.state.selectedMember.id,
                            {method: "PATCH", body: JSON.stringify(body)}, 
                             (response) => {this._memberFieldChangedCallback(response, json);}, this._fieldChangedErrorCallback); 
    }
 
 
    //Post only a new photo (does not yet exist), for attendant use. Same as memberFieldChanged with the imageBase64 json field and different POST path
    _postMemberPhoto = (imageBase64) => {
         
        const json = "imageBase64";       
        const body = {[json]: imageBase64, lastTransaction: this.state.selectedMember.lastTransaction};
        
        this.incrementBusy();
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/memberships/" + this.state.membership.id + "/members/" + this.state.selectedMember.id + "/image",
                            {method: "POST", body: JSON.stringify(body)}, 
                             (response) => {this._memberFieldChangedCallback(response, json);}, this._fieldChangedErrorCallback); 

    }
 
 
 
    _suggestRelationMenuItems = (isReadOnly) => {
                
        const disabled = isReadOnly || !PP.user.hasPermissionTo(Permissions.EDIT_DATABASE);

        if (disabled)
            return [];

        let menuItems = [];
        
        Relations.forEach((relation) => {
            menuItems.push({label: relation, 
                            selectCallback: () => {
                                  const relationField = ManageMemberFields.find(field => field.component === "RelationField");
                                  relationField.ref.current.change(relation, true);  //change and immediately commit
                            }}
                           );             
                
        });
         
        return menuItems;
        
    };
    
    
    _suggestMembershipTypesMenuItems = (isReadOnly) => {
                
        const disabled = isReadOnly || !PP.user.hasPermissionTo(Permissions.EDIT_DATABASE);

        if (disabled)
            return [];

        let menuItems = [];
        
        this.state.membershipTypes.forEach((type) => {
            menuItems.push({label: type, 
                            selectCallback: () => {
                                  const membershipTypeField = ManageMembershipFields.find(field => field.component === "MembershipTypeField");
                                  membershipTypeField.ref.current.change(type, true);  //change and immediately commit
                            }}
                           );             
                
        });
         
        return menuItems;
        
    };
    
    
 
    //Create the React Component for the field in either the ManageMembershipFields or ManageMemberFields array
    _getComponentForField = (field, index, onFieldChange, isReadOnly) => {
               
        const editable = PP.user.hasPermissionTo(Permissions.EDIT_DATABASE);      
               
        //See if it's a generic one we can handle
        const reactComponent = ManagedField.getComponentForField(field, index, onFieldChange, this._fieldInputError, !editable || isReadOnly);
        
        if (!reactComponent) {  //if not, specific to this class
            switch (field.component) {
                case "BarcodeField":
                    return (<ManageTextFieldWithButton key={index} buttonTitle={"Suggest"} buttonPressed={this._suggestBarcode} style={{marginTop: 15}} textFieldRef={field.ref} json={field.json} 
                                                       editable={editable} label={field.label} onFieldChange={onFieldChange} isReadOnly={isReadOnly} />);
                case "RelationField":
                    return (<div key={index} style={{width: '100%', display: 'flex'}} >  
                                <ManageTextField style={{marginRight: 5, marginTop: 15}} json={field.json} label={field.label} ref={field.ref} onFieldChange={onFieldChange} 
                                                 isReadOnly={isReadOnly} editable={editable} />
                                <PopupMenu menuIcon={(<ExpandMoreIcon/>)}  
                                            menuItems={this._suggestRelationMenuItems(isReadOnly)} 
                                            menuIconStyle={{marginTop: 10}} 
                                            menuTooltipText={"Suggest Relation"}/>
                            </div>);
                    
                case "MembershipTypeField":
                    return (<div key={index} style={{width: '100%', display: 'flex'}} >  
                                <ManageTextField style={{marginRight: 5, marginTop: 15}} json={field.json} label={field.label} ref={field.ref} onFieldChange={onFieldChange} 
                                                 isReadOnly={isReadOnly} editable={editable} />
                                <PopupMenu menuIcon={(<ExpandMoreIcon/>)}  
                                            menuItems={this._suggestMembershipTypesMenuItems(isReadOnly)} 
                                            menuIconStyle={{marginTop: 10}} 
                                            menuTooltipText={"Existing Membership Types"}/>
                            </div>);

                default: 
                    return (<div>Unsupported Component</div>);
            }
        }
        
        return reactComponent;
    }
 
 
    _suggestBarcode = () => {
 
        const query = "?type=" + PP.barcodeSuggestionType() + "&digits=" + PP.barcodeSuggestionDigits() + "&uppercaseOnly=true";
        
        this.incrementBusy();
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/barcodes/suggest" + query,
                             {}, this._suggestBarcodeCallback, this._fieldChangedErrorCallback); 
    }
    
    //Response to the suggest barcode - returning a String with the suggested barcode
    _suggestBarcodeCallback = (response) => {
        this.decrementBusy();
        if (response && this.state.selectedMember) {     
                
            const barcodeField = ManageMemberFields.find(field => field.component === "BarcodeField");
            barcodeField.ref.current.change(response); 
            barcodeField.ref.current.setFocus();
        }  
    }
 
    _memberClicked = (member) => {
        this._selectMember(member); 
        window.scrollTo(0, this._memberPageRef.current.offsetTop);  //jump to the member page
    }
    
    _nextMember = () => {
        if (!this.state.selectedMember) { //no member currently selected
            this._selectMember(this.state.members[0]);  //select first
        }
        else {
            
            let currentIndex = 0;
            for (let i=0; i<this.state.members.length; i++) {
                if (this.state.members[i].id === this.state.selectedMember.id) {  //found selected member
                    currentIndex = i;
                    break;
                }
            }
            let nextIndex = currentIndex+1;  //next
            if (nextIndex >= this.state.members.length)
                nextIndex = 0;  //loop
            
            this._selectMember(this.state.members[nextIndex]);
            
        }
        
    }
    
    
    _newMembershipPromptOkCallback = (text) => {
        
        if (text) {  //some text
        
            console.log("Add new Membership: " + text);
            const newMembership = Membership.createNewJson(text);
                    
            this.incrementBusy();
            this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/memberships",
                                {method: "POST", body: JSON.stringify(newMembership)}, 
                                (response) => {this._newMembershipCallback(response);}, this._errorCallbackWithClear); 
            
            
        }
        this.setState({newMembershipPromptOpen: false}); //close prompt
    }
    
    
    //When a new Membership has been created, the response is the new Membership Json
    _newMembershipCallback = (response) => {
        this.decrementBusy();

        //If we have a valid response
        if (response) {        
            
            const newMembership = new Membership(response);

            //New memberships have no members, no selected members
            this.setState({membership: newMembership, members: [], selectedMember: null});  
            
            //Lookup the ReactComponent and set its text value to the initial text value for each Membership field
            ManagedField.setFields(ManageMembershipFields, newMembership);
            ManagedField.setFields(ManageMemberFields, null);
        }     
    }
    
    
    _reassignMembershipPromptOkCallback = (text) => {
        this.setState({reassignMembershipPromptOpen: false}); //close prompt

        if (!text)  //no text
            return;

        if (this.state.reassignMembershipForAll) {  // create new and reassign all members

            this.incrementBusy();
            this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/memberships/" + this.state.membership.id + "/reassign?newMembership=" + text,
                                   {method: "POST"}, 
                                   (response) => {this._reassignMembershipCallback(response);}, this._fieldChangedErrorCallback); 

        }
        else {  // reassign member

            const memberToReassign = this.state.selectedMember;

            if (memberToReassign && this.state.membership) {
                console.log("Reassign Member: " + memberToReassign.id);
                    
                this.incrementBusy();
                this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/memberships/" + this.state.membership.id + "/members/" + 
                                        memberToReassign.id + "?newMembership=" + text,
                                       {method: "PUT"}, 
                                       (response) => {this._reassignMemberCallback(response);}, this._fieldChangedErrorCallback); 
            }
            
            
        }
    }
    
    
    //When reassigned, the response is the new Member Json
    _reassignMemberCallback = (response) => {
        this.decrementBusy();

        //If we have a valid response
        if (response) {        
            
            const newMember = new Member(response);

            //Refresh and manage the new Membership with the new Member
            const searchString = newMember.membershipRef +  "-" + newMember.id;
            this._search(searchString);         
        }     
    }

    //When reassigned, the response is the new Membership Json
    _reassignMembershipCallback = (response) => {
        this.decrementBusy();

        // See if we want to delete this one
        if (response) {
            const newMembership = new Membership(response);
            const oldMembership = this.state.membership;

            this.showConfirmAlert("Success", "Membership was reassigned. Do you want to delete the old empty Membership \"" + oldMembership.id + "\"?", 'green', "No, Leave Empty", 
                                  () => this._doDeleteMembership(oldMembership, false), "Delete", 'red');

            //Refresh and manage the new Membership with the new Member
            const searchString = newMembership.id;
            this._search(searchString);
        }
    }

    
    
    //Callback when user presses the delete Membership button
    _deleteMembership = () => {
        
        if (this.state.membership) {   
            const andMembersText = this.state.members.length > 0 ? " and its Members" : "";
            this.showConfirmAlert("Confirm", "Do you really want to delete Membership \"" + this.state.membership.id + "\"" + andMembersText + "?", 'black', "Cancel", 
                                  () => this._doDeleteMembership(this.state.membership, true), "Delete", 'red');   
        }
    }
    
    //Send the DELETE to the server and clear all.  Do nothing on success, unless the successCallback is provided
    _doDeleteMembership = (membershipToDelete, clearOnComplete) => {
        
        if (membershipToDelete) {
            console.log("Delete Membership: " + membershipToDelete.id);
                    
            this.incrementBusy();

            const successFunc = clearOnComplete ? this._clear : () => this.setBusy(false);

            this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/memberships/" + membershipToDelete.id,
                                {method: "DELETE"}, 
                                successFunc, this._errorCallbackWithClear);  
        }
    }
    
    
    _sendEPasses = () => {
        if (this.state.membership) {   
            const amount = this.state.members.length;
            this.showConfirmAlert("Confirm", "Send " + amount + " ePass" + (amount > 1 ? "es" : "") + " to " + this.state.membership.email + "?", 'black', "Cancel", this._doSendEPasses, "Send", 'green');   
        }
    }
    
    _doSendEPasses = () => {
        
        if (this.state.membership) {
                    
            this.incrementBusy();
            this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/memberships/" + this.state.membership.id + "/sendEPasses",
                                {method: "POST"}, 
                                this._sendEpassSuccess, this._fieldChangedErrorCallback);  
        }
    }
    
    _sendEpassSuccess = (response) => {
        this.decrementBusy();     
        this.showConfirmAlert("Success", "ePasses were emailed. The Membership email should receive them within a few minutes.", 'green');
    }
    
    
    
    _sendPPasses = () => {
        this.setState({showMultiMemberSelector: true}); //show popup to select Members to print
    }
    
    _multiMembersSelected = (memberIdSet) => {  //callback after selecting Members to print
        const ids = Array.from(memberIdSet);
        this.setState({showMultiMemberSelector: false}); 

        this._memberIdsToPrint = ids;

        const canPrintWithoutFee = PP.user.hasPermissionTo(Permissions.TRIGGER_FREE_PASS_PRINTING);
        if (canPrintWithoutFee) {
            this.setState({showPrintFeeQuestion: true});  //request fee type
            return;    
        }
        
        //Must incur fee - submit print job with "true"
        this.showConfirmAlert("Confirm", "Submit print job for " + ids.length + " Member" + (ids.length > 1 ? "s" : "") + "? Your account will be charged for printing and mailing.",
                               'black', "Cancel", () => this._continueSubmitPrintJob(true), "Submit", 'green'); 
                               
    }
    
            
    _continueSubmitPrintJob = (incurFee) => {
         
        this.setState({showPrintFeeQuestion: false});  
        
        const messageComponent =
            <div>
                <Typography variant="body2">Is this the correct mailing address?</Typography>  

                <div style={{fontSize: 24, margin: 30}}>
                    {multilineJSX(this.state.membership.address)}
                </div>
            </div>;

        this.showConfirmAlert("Confirm", null, 'green', "Cancel", () => this._doPrintJob(incurFee), "Confirm Address", 'green', messageComponent);
        
    }
    
    _doPrintJob = (incurFee) => {
         
        const endPath = "?incurFee=" + incurFee + "&ids=" + this._memberIdsToPrint;

        this.incrementBusy();
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/memberships/" + this.state.membership.id + "/printPasses",
                            {method: "POST"}, 
                            this._submitPrintSuccess, this._fieldChangedErrorCallback, endPath);  

    }
    
    
    _submitPrintSuccess = (response) => {
        this.decrementBusy();     
        this.showConfirmAlert("Success", "Print job was submitted. You can check the status in the Pass Portal.", 'green');
    }
    
    
    _newMember = () => {
        this._newMemberCreate(false);
    }

    //Callback when the user presses the New Limited Member button.
    _newLimitedMember = () => {
        this._newMemberCreate(true);
    }
    
    //Callback when the user presses the New Member or New Limited Member button. First we try and get a suggested barcode. If that fails, we create the
    //Member anyway but without a barcode
    _newMemberCreate = (limited) => {
        
        if (!this.state.membership)
            return;
  
        this.incrementBusy();
        const query = "?type=" + PP.barcodeSuggestionType() + "&digits=" + PP.barcodeSuggestionDigits() + "&uppercaseOnly=true";
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/barcodes/suggest" + query,
                             {}, (response) => this._newMemberProceedWithBarcode(response, limited), 
                             () => this._newMemberProceedWithBarcode(null, limited)); 
  
    }

    _newMemberProceedWithBarcode = (suggestedBarcode, limited) => {
    
        this.decrementBusy();     
        const newMember = Member.createNewJson(this.state.membership, this.state.members, suggestedBarcode, limited);
        console.log("Add new Member: ", newMember);
                    
        this.incrementBusy();
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/memberships/" + this.state.membership.id + "/members",
                                {method: "POST", body: JSON.stringify(newMember)}, 
                                (response) => {this._newMemberCallback(response);}, this._fieldChangedErrorCallback); 
    }
    
    //When a new Member has been created, the response is the new Member Json
    _newMemberCallback = (response) => {
        this.decrementBusy();

        //If we have a valid response
        if (response) {                 
            const newMember = new Member(response);

            //add to members array
            this.setState((prevState) => ({members: [...prevState.members, newMember]}));  
            
            //select the new one
            this._selectMember(newMember);
        }     
    }
    
    //Callback when user presses the delete Member button
    _deleteMember = () => {
        
        if (this.state.selectedMember) {   
            this.showConfirmAlert("Confirm", "Do you really want to delete Member \"" + this.state.selectedMember.name() + "\"?", 'black', "Cancel", this._doDeleteMember, "Delete", 'red');   
        }
    }
    
    //Send the DELETE to the server
    _doDeleteMember = () => {
        const memberToDelete = this.state.selectedMember;
        
        if (memberToDelete && this.state.membership) {
            console.log("Delete Member: " + memberToDelete.id);
                    
            this.incrementBusy();
            this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/memberships/" + this.state.membership.id + "/members/" + memberToDelete.id,
                                {method: "DELETE"}, 
                                () => {this._deleteMemberComplete(memberToDelete);}, this._fieldChangedErrorCallback);  
        }
    }
    
    //Callback when delete member succeeds, clear selected Member and remove from list
    _deleteMemberComplete = (deletedMember) => {
        this.decrementBusy();

        if (deletedMember.membershipRef !== this.state.membership.id)  //different membership
            return;
            
        //Update the member array, removing the removed member
        const filteredMembers = this.state.members.filter( member =>  member.id !== deletedMember.id );        
        this.setState({members: filteredMembers});
        
        //If same member is still selected, deselect it
        if (this.state.selectedMember.id === deletedMember.id) 
            this._selectMember(null);
        
        if (filteredMembers.length === 0) {
            this.showConfirmAlert("Note", "All Members have been deleted. Do you also want to delete the Membership?", 'black', "Cancel", this._doDeleteMembership, "Delete", 'red');   
        }
        
    }
    
    _checkInOut = () => {
        const membershipID = this.state.membership.id;
        const memberID = this.state.selectedMember.id;
            
        console.log("Member Check In: " + membershipID + "-" + memberID);

        this._onCheckInMember(membershipID, memberID);
    }
    
    
    //Read data from the file as base64, and set it as the image to crop
    _showCropper = (file) => {
        
        const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
 
        if (!acceptedImageTypes.includes(file['type'])) {
            this.showConfirmAlert("Error", "File is not a valid image type", 'red');
            return;
        }
  
        ImageUtils.readImageFile(file, (dataURL) => {
            this.setState({showCropper: true, imageToCrop: dataURL});            
        });
       
    }

      
    //Callback when a file is dropped on the member image
    _memberImageDropped = (fileDropped) => {
        this._showCropper(fileDropped);
    }
    
    //Callback when the file is selected
    _memberImageFileSelected = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            console.log("Selected image file " + file.name);
            this._showCropper(file);
        }
        event.target.value = null;  //allow picking of the same value again

    }
    
    //Callback when the Cropper "Done" button is pressed, PATCH the new image to the server, and close the Cropper
    _imageCropped = (image) => {
                   
        if (image) {
            let imageB64 = ImageUtils.scaleImage(image, MEMBER_IMAGE_NATIVE_WIDTH, MEMBER_IMAGE_NATIVE_HEIGHT);
            
            if (PP.user.hasPermissionTo(Permissions.EDIT_DATABASE)) //normal edit, patch it to the server the old way (can overwrite)          
                this._memberFieldChanged("imageBase64", imageB64);
            else
                this._postMemberPhoto(imageB64);  //attendants can only post photos if they don't already exist
        }
        
        this._refFieldsNeedUpdate = true;  //update ref fields, because they were unmounted when we switched to cropper
        this.setState({showCropper: false, imageToCrop: null});  //hide cropper
    }
    
    //Callback when the Webcam "Done" button is pressed, send the image to the Cropper
    _imageCaptured = (image) => {
        if (image) {
            this.setState({showCropper: true, imageToCrop: image, webcamOpen: false});
        }
        else { //cancel pressed
            this._refFieldsNeedUpdate = true;  //update ref fields, because they were unmounted when we switched to webcam
            this.setState({webcamOpen: false});  
        }
    }
    
    
    //Callback when the user preses the Remove Image button, PATCH a null image to the server after confirming
    _removeImage = () => {

        const doDelete = () => { this._memberFieldChanged("imageBase64", null); };          
        this.showConfirmAlert("Confirm", "Really delete Member Image?", 'black', "Cancel", doDelete, "Delete", 'red');   

    }
    
    
    //Render the sign in row for each Member sign-in time
    _renderSignInDateRow = (props) => {
     
        const date = this.state.selectedMember.signInDates[props.index];
   
        return (
            <div key={props.index} style={props.style}>
                {props.index > 0 ? <Divider style={{...this.styles.divider, marginLeft: 8, marginRight: 8}}/> : <Divider/>  /*Smaller dividers except the first*/}
                                                    
                <ListItem>                                
                    <ListItemText primary={PP.checkInTimeFormat(date)}/>                                               
                </ListItem>
            </div>
        );
    }
    
        
        
    render() {
        
        //If currently in crop mode, just render the Cropper component
        if (this.state.showCropper) {
            
            return (<ImageCropper image={this.state.imageToCrop} 
                                  aspectRatio={MEMBER_IMAGE_ASPECT_RATIO} 
                                  onDone={this._imageCropped} 
                                  cancelIconColor={ThemeColors.darkRed}/>);
        }
        
        //If currently in webcam mode, just render the webcam component
        if (this.state.webcamOpen) {
            return (<WebcamCapture onDone={this._imageCaptured} onPreCapture={() => AudioPlay.playCameraShutter()} 
                                   width={MEMBER_IMAGE_NATIVE_WIDTH} height={MEMBER_IMAGE_NATIVE_HEIGHT}
                                   cancelIconColor={ThemeColors.darkRed}/>);
        }
        
        
                      
        let membershipStatus = "";
        let membershipStatusColor;
        if (this.state.membership) {
            if (this.state.membership.suspended) {
                membershipStatus = "Suspended";
                membershipStatusColor = 'red';
            }
            else if (this.state.membership.expiresIn() < 0) {
                membershipStatus = "Expired";
                membershipStatusColor = 'red';
            }
            else if (this.state.members.length === 0) {
                membershipStatus = "Inactive";
                membershipStatusColor = 'grey';
            }
            else {
                membershipStatus = "Active";
                membershipStatusColor = 'green';
            }
        }
        
        let memberStatus = "";
        let memberStatusColor;
        if (this.state.selectedMember) {
            if (this.state.selectedMember.suspended) {
                memberStatus = "Suspended";
                memberStatusColor = 'red';
            }
            else if (this.state.selectedMember.entryPasses === 0) {
                memberStatus = "No Passes";
                memberStatusColor = 'red';                
            }
            else {
                memberStatus = "Active";
                memberStatusColor = 'green';
            }
        }
        
        
        const canAddMembership = PP.user.hasPermissionTo(Permissions.EDIT_DATABASE);
        const canDeleteMembership = canAddMembership && this.state.membership;
        const canReassignMembership = canAddMembership && this.state.membership;
        const canAddMember = this.state.membership && PP.user.hasPermissionTo(Permissions.EDIT_DATABASE);
        const canEditMember = canAddMember && this.state.selectedMember;
        const canCheckInMember = this.state.selectedMember && PP.user.hasPermissionTo(Permissions.CHECK_IN);
        const canRefreshMembership = this.state.membership;
        const canDeletePhoto = canEditMember && this.state.selectedMember.imageBase64;
        const canSendEPasses = PP.printCapabilities.includes("EPASS") && this.state.membership && this.state.members.length > 0;
        const canSendPPasses = PP.printCapabilities.includes("CARD") && (PP.user.hasPermissionTo(Permissions.TRIGGER_PASS_PRINTING)) && this.state.membership && this.state.members.length > 0;
        const limited = this.state.selectedMember && this.state.selectedMember.limited;
        
        //Initially, canSetPhoto if there's a member and the user can view database
        let canSetPhoto = this.state.membership && this.state.selectedMember && Permissions.VIEW_DATABASE;
       
        //If not able to edit database, and a photo exists, can no longer set photo
        if (canSetPhoto && !PP.user.hasPermissionTo(Permissions.EDIT_DATABASE) && this.state.selectedMember.imageBase64)
            canSetPhoto = false;
        
        
        const signInRowSize = 40;    
        const signInRowCount = this.state.selectedMember ? this.state.selectedMember.signInDates.length : 0;
            
        return (
                   
            <div>
                {this.getConfirmAlertComponent()}        
                
                <NewMembershipPopover isOpen={this.state.newMembershipPromptOpen} 
                                 okCallback={this._newMembershipPromptOkCallback} cancelCallback={() => this.setState({newMembershipPromptOpen: false})}/>
    
                <TextEntryPopover isOpen={this.state.reassignMembershipPromptOpen} showSkip={false} multiline={false} title={this.state.reassignMembershipForAll ? "Reassign: Create New Membership ID" : "Reassign to Membership ID"} 
                                 okCallback={this._reassignMembershipPromptOkCallback} cancelCallback={() => this.setState({reassignMembershipPromptOpen: false})}/>
    
    
                <MultiMemberSelector isOpen={this.state.showMultiMemberSelector} onClose={() => this.setState({showMultiMemberSelector: false})}
                                    membership={this.state.membership} members={this.state.members}
                                    onContinue={this._multiMembersSelected} isMobile={this.state.isMobile}/>
                                    
                                    
                <Dialog open={this.state.showPrintFeeQuestion} onClose={()=> this.setState({showPrintFeeQuestion: false})}>

                    <DialogTitle>Charge Fee?</DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{color: 'black'}}>{"How should \"" + PP.selectedDatabase + "\" be charged for this print job? (Print count: " + this._memberIdsToPrint.length + ")"}</DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button variant="outlined" onClick={() => this._continueSubmitPrintJob(true)}>Standard Charge</Button>
                        <Button variant="outlined" onClick={() => this._continueSubmitPrintJob(false)}>Free</Button>
                        <Button variant="outlined" onClick={() => this.setState({showPrintFeeQuestion: false})}>Cancel</Button>
                    </DialogActions>

                </Dialog>

                      
                { /*----------------------------  TOP: BARCODE, CONTROL BUTTONS  ----------------------------------------*/ null }

                <div style={{padding: 8}}/>

                <div style={{display: 'flex'}}>
                        
                    {this.state.isBusy ? this.getBusyComponent('left', {marginRight: 20, marginTop: 10}) : 
                        <Tooltip title="Refresh">
                            <div style={{display: 'flex'}}>
                                <IconButton disabled={!canRefreshMembership} edge="end" onClick={this._refresh} color='primary' style={{marginLeft: 0, marginRight: 1}}>
                                    <RefreshIcon fontSize="large"/>
                                </IconButton>
                            </div>
                        </Tooltip>}
                    
                    <TextField autoFocus type="search" inputRef={(field) => this._barcodeInput = field} id="barcode" value={this.state.barcodeFieldValue} onChange={this._barcodeFieldChanged}  label="Query Membership/ID/Barcode" onKeyDown={this._barcodeKeyDown} variant="outlined" inputProps={{style: this.styles.barcodeField}} fullWidth={true} InputLabelProps={{ shrink: true}} />
                
                    <div style={{marginRight: 10}}/>
                    
                    <div style={{display: 'flex'}}>
                        <Tooltip title="Previous Membership">
                            <IconButton edge="end" onClick={this._prevMembership} color='primary'>
                                <ArrowBackIosIcon fontSize="small"/>
                            </IconButton>
                        
                        </Tooltip>
                    </div>            
                    <div style={{display: 'flex'}}>
                        <Tooltip title="Next Membership">
                            <IconButton edge="end" onClick={this._nextMembership} color='primary'>
                                <ArrowBackIosIcon fontSize="small" style={{transform: 'scaleX(-1)'}}/>
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div style={{marginRight: 10}}/>

                    
                    
                </div>
                
                <div style={{paddingBottom: 20}}/>
                
                <Grid container direction="row" spacing={3}>
                    
                    { /*----------------------------  MEMBERSHIP LEFT  ----------------------------------------*/ null }

                    
                    <Grid item md={4} sm={6} xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                        <div style={{flexGrow: 1, maxWidth: 400}}>
                            <Paper>
                            
                                <div style={{display: 'flex', height: 40}}>

                                    <div>
                                        <Typography variant="body2" style={this.styles.paperLabel}>Membership</Typography>   
                                        <Typography variant="body2" style={{...this.styles.statusLabel, backgroundColor: membershipStatusColor}}>{membershipStatus}</Typography>
                                    </div>

                                    <div style={{flexGrow: 1}}/>
                                     
                                     <Tooltip title={"Add New Membership"}>
                                          <IconButton disabled={!canAddMembership} edge="end" onClick={() => {this.setState({newMembershipPromptOpen: true})}} style={{marginTop: -4}}>
                                              <AddCircleOutlineIcon style={{color: canAddMembership ? ThemeColors.addColor : 'lightGray'}} />
                                          </IconButton>
                                     </Tooltip>
                                     <Tooltip title={"Delete Membership"}>
                                          <IconButton disabled={!canDeleteMembership} edge="end" onClick={this._deleteMembership} style={{marginTop: -4}}>
                                              <DeleteIcon />
                                          </IconButton>
                                     </Tooltip>
                                     <Tooltip title={"Send ePasses for all Members to Membership email"}>
                                          <IconButton disabled={!canSendEPasses} edge="end" onClick={this._sendEPasses} style={{marginTop: -4}}>
                                              <PhoneIphoneIcon style={{color: canSendEPasses ? ThemeColors.epassColor : 'lightGray'}} />
                                          </IconButton>
                                     </Tooltip>
                                     <Tooltip title={"Submit Print job for Physical Passes for selected Members"}>
                                          <IconButton disabled={!canSendPPasses} edge="end" onClick={this._sendPPasses} style={{marginTop: -4}}>
                                              <PrintIcon style={{color: canSendPPasses ? ThemeColors.ppassColor : 'lightGray'}} />
                                          </IconButton>
                                     </Tooltip>
                                     <Tooltip title={"Create a new Membership from this Membership, reassign all Members to the new one, and optionally delete this one"}>
                                        <IconButton disabled={!canReassignMembership} edge="end" onClick={() => {this.setState({reassignMembershipPromptOpen: true, reassignMembershipForAll: true})}} style={{marginTop: -4,  marginRight: 4}}>
                                            <ReplyIcon style={{transform: 'scaleX(-1)'}}/>
                                        </IconButton>
                                    </Tooltip>
                                    
                               </div>
                            
                                <div style={{padding: 10}}>
                                    { ManageMembershipFields.map((field, index) => 
                                         this._getComponentForField(field, index, this._membershipFieldChanged, !this.state.membership))
                                    }
                                </div>     
                                
                            </Paper>
                                       
                            <div style={{paddingBottom: 20}}/>

                            <Paper>
                                <Typography variant="body2" style={this.styles.paperLabel}>{"Member List (" + (this.state.members ? this.state.members.length : 0) + ")"}</Typography>  

                                <List dense style={{margin: 10}}>
                                    {this.state.members ? this.state.members.map((member, index) =>
                                        (<div key={index}>
                                            {index > 0 ? <Divider style={this.styles.divider}/> : <Divider/>  /*Smaller dividers except the first*/}
                                            <ListItem onClick={() => {this._memberClicked(member)}} selected={this.state.selectedMember && member.id === this.state.selectedMember.id}>                                
                                                <ListItemText primary={member.shortName()} 
                                                              secondary={<span>
                                                                            <span>{"ID: " + member.id}</span>
                                                                            <span style={{paddingLeft: 20}}>{member.shortRelation()}</span>
                                                                          </span>
                                                                         }
                                                />                                              

                                                { member.limited ?
                                                    <Tooltip title={member.name() + " is a limited Member"}>
                                                        <ListItemIcon>
                                                            <div fontSize='small' style={{backgroundColor: ThemeColors.limitedBlue, textAlign: 'center', width: 15, height: 15, padding: 1, paddingTop: 2, fontSize: 10, color: 'white', borderRadius: '50%', marginRight: 0}}>L</div>
                                                         </ListItemIcon>
                                                    </Tooltip> : <div style={{width: 55}}></div>}       

                                                { member.isCheckedIn && PP.checkOutEnabled() ?
                                                    <Tooltip title={member.name() + " is currently checked in"}>
                                                         <ListItemIcon>
                                                            <CheckIcon fontSize='small' style={{color: 'green', marginRight: 0}}/>
                                                         </ListItemIcon>
                                                         </Tooltip> : <div style={{width: 55}}></div>}
                                                    
                                                    
                                                { member.suspended ?
                                                    <Tooltip title={member.name() + " is suspended"}>
                                                        <ListItemIcon>
                                                            <NotInterestedIcon fontSize='small' style={{color: 'red', marginRight: 0}}/>
                                                         </ListItemIcon>
                                                    </Tooltip> : <div style={{width: 55}}></div>}

                                                                                                    
                                            </ListItem>
                                         </div>)                       
                                    ) : null}
                                </List>

                            </Paper>
                        </div>
                    </Grid>

                    { /*----------------------------  MEMBER INFO ----------------------------------------*/ null }


                    <Grid item md={8} sm={6} xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                        <Paper ref={this._memberPageRef}>
                        
                            <div style={{display: 'flex', height: 40}}>


                                <div>
                                    <Typography variant="body2" style={this.styles.paperLabel}>{this.state.isSmall ? "Member" : "Selected Member"}</Typography>
                                    <div style={{display: 'flex'}}>
                                        <Typography variant="body2" style={{...this.styles.statusLabel, backgroundColor: memberStatusColor}}>{memberStatus}</Typography>
                                        {limited ? <Typography variant="body2" style={{...this.styles.statusLabel, backgroundColor: ThemeColors.limitedBlue}}>Limited</Typography> : null}                                        
                                    </div>
                                </div>

                                <div style={{flexGrow: 1}}/>
                                
                                <Tooltip title="Go to Next member">
                                    <IconButton disabled={!this.state.membership || this.state.members.length === 0} edge="start" onClick={this._nextMember}>
                                        <ClearAllIcon fontSize="small" style={{transform: 'scale(-1.5, 1.5)'}}/>
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title={"Check-In/Out Member"}>
                                      <IconButton disabled={!canCheckInMember} edge="end" onClick={this._checkInOut} style={{marginTop: -4}}>
                                          <CheckCircleOutlineIcon style={{color: canCheckInMember ? 'green' : 'lightGray'}} />
                                      </IconButton>
                                 </Tooltip>

                                 <Tooltip title={"Add New Member to this Membership"}>
                                      <IconButton disabled={!canAddMember} edge="end" onClick={this._newMember} style={{marginTop: -4}}>
                                          <AddCircleOutlineIcon style={{color: canAddMember ? ThemeColors.addColor : 'lightGray'}}/>
                                      </IconButton>
                                 </Tooltip>
                                 
                                 <Tooltip title={"Reassign Member to another Membership"}>
                                      <IconButton disabled={!canEditMember} edge="end" onClick={() => {this.setState({reassignMembershipPromptOpen: true, reassignMembershipForAll: false})}} style={{marginTop: -4}}>
                                          <ReplyIcon style={{transform: 'scaleX(-1)'}}/>
                                      </IconButton>
                                 </Tooltip>
                                 
                                 <Tooltip title={"Delete Member"}>
                                      <IconButton disabled={!canEditMember} edge="end" onClick={this._deleteMember} style={{marginTop: -4}}>
                                            <DeleteIcon />
                                      </IconButton>
                                 </Tooltip>

                                 <Tooltip title={"Add New Limited Member to this Membership"}>
                                      <IconButton disabled={!canAddMember} edge="end" onClick={this._newLimitedMember} style={{marginTop: -4, marginRight: 4}}>
                                        <span style={{fontSize: 18, fontWeight: 'bold', color: canAddMember ? ThemeColors.limitedBlue : 'lightGray'}}>Ⓛ</span>
                                      </IconButton>
                                 </Tooltip>
                                    
                            </div>
                    
                            <Grid container direction="row" spacing={4}>


                                <Grid item md={6} xs={12} style={{display: 'flex', justifyContent: 'center'}}> 
                                
                                    <div>
                                        <div style={{padding: 10}}>
                                            { ManageMemberFields.map((field, index) => 
                                                 this._getComponentForField(field, 1000+index, this._memberFieldChanged, !this.state.selectedMember))
                                            }
                                        </div>

                                        <Typography variant="body2" style={this.styles.checkInTimeLabel}>{"Check-In Times (" + signInRowCount + ")"}</Typography>  

                                        <FixedSizeList style={this.styles.signInList} itemSize={signInRowSize} height={300} itemCount={this.state.selectedMember ? this.state.selectedMember.signInDates.length : 0}>
                                            {this._renderSignInDateRow}
                                        </FixedSizeList>
                                    </div>   
                                
                                </Grid>


                                 <Grid item md={6} xs={12} >
  
                                        <div style={{paddingTop: 24, paddingBottom: 20, display: 'flex', justifyContent: 'center'}}>
                                            {canEditMember ? (
                                                        <DragAndDrop handleDrop={this._memberImageDropped}>
                                                            <Tooltip title={"Drag and Drop New Image Below or Paste from Clipboard"} placement="top-start">
                                                                {ImageFunctions.memberImageURL(this.state.selectedMember, this.state.isWideBrowser)}
                                                            </Tooltip>
                                                    </DragAndDrop>) :
                                             (this.state.selectedMember ? ImageFunctions.memberImageURL(this.state.selectedMember, this.state.isWideBrowser) :
                                                                          ImageFunctions.memberImagePlaceholderURL(this.state.isWideBrowser)
                                                    )
                                            }
                                        </div>                                            
                                         
                                        <Grid container direction='column' alignContent='center' spacing={2}>
                                            
                                            <Grid item style={{marginLeft: 20, marginRight: 20}}>

                                                <Button fullWidth disabled={!canSetPhoto} variant="outlined" component="label" onClick={() => this.setState({webcamOpen: true})} 
                                                        startIcon={<CameraAltIcon style={{color: canSetPhoto ? '#7570ff' : 'lightGray'}}/>}>
                                                    Take from Webcam
                                                </Button> 
                                            </Grid>
                                            
                                            <Grid item style={{marginLeft: 20, marginRight: 20}}>
                                                <Button fullWidth disabled={!canEditMember} variant="outlined" component="label" 
                                                        startIcon={<DescriptionIcon style={{color: canEditMember ? '#87854a' : 'lightGray'}}/>}>
                                                    Load from Image File
                                                    <input accept="image/*" style={{display: 'none'}} type="file" onChange={this._memberImageFileSelected}/>
                                                 </Button>                                             
                                            </Grid>
                                            
                                            <Grid item style={{marginLeft: 20, marginRight: 20}}>
                                                <Button style={{marginBottom: 20}} fullWidth disabled={!canDeletePhoto} variant="outlined" onClick={this._removeImage} component="label" 
                                                        startIcon={<ClearOutlinedIcon style={{color: canDeletePhoto ? ThemeColors.darkRed : 'lightGray'}}/>}>
                                                    Remove Image
                                                 </Button>                                             
                                            </Grid>
                                            
                                        </Grid>
                                </Grid>                


                            </Grid>
                        </Paper>
                    </Grid>
                            
      
                </Grid>

            </div>
        );

    }
}


export default withCookies(ManageTab);