export class DepositSum {
    
    database;
    depositSum;
    balance;
    purchaseSum;
    redeemSum;
    cancellations;
    members;
    currency;
    
    constructor(userJson) {
        
        if (userJson) {

            this.database = userJson.databaseName;
            this.depositSum = userJson.depositSum;
            this.balance = userJson.balance;
            this.purchaseSum = userJson.purchaseSum;
            this.redeemSum = userJson.redeemSum;
            this.cancellations = userJson.cancellations;
            this.members = userJson.aveMembers;
            this.currency = userJson.isoCurrency;
            
        } 
        
    }
    
}
