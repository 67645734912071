import { withCookies } from 'react-cookie';

import { AbstractStatisticsTab } from './AbstractStatisticsTab';

import { PP } from '../../models/PP'

import { ThemeColors } from '../../Theme'
import { DateUtils } from 'react-frontend-utils'

export class GuestUseStatsTab extends AbstractStatisticsTab {
  
    
  
    _options = {
      
        chart: this._standardChartOptions,
        colors: [ThemeColors.guestBlue], //red
        plotOptions: {
            bar: {
                horizontal: true,
                columnWidth: "90%"
            }
        },
        dataLabels: {
            enabled: true         
        },
        xaxis: {
            position: 'top',
            labels: {
                formatter: (value) => { return value.toFixed(0); }  //Format as integer
            }
        },
        tooltip: {
            y: {
                formatter: (value) => { return value.toFixed(0); }  //Format as integer
            }
        }
        
    }
    
    _logs;
    _worker;
    _afterTime = null;

    constructor(props) {
        super(props); 
        this._chartOptions = this._options;
        this._chartType = "bar";
        this._chartFetchPath = "/ppcs/databases/" + PP.selectedDatabase + "/log?type=guest";
        this._chartDataHandlerCallback = this._processChartData;
        this._hasLocation = false;
        this._hasAggregate = false;
        this._hasTime = false;
        this._hasTimeRange = true;
        this._chartHeight = 1000;
    }
    
    componentDidMount() {
        super.componentDidMount();
        this._worker = new Worker("./worker/GuestUseWorker.js");
        this._worker.addEventListener("message", event => {  //response when worker is done processing
            const response = JSON.parse(event.data);
            
            this._updateCategoryChart(response.labels, response.series);
            this.setBusy(false);
            this.setState({chartDataProcessing: false});

        });
       
    }
    
    componentWillUnmount() {
        super.componentWillUnmount();
        if (this._worker) {
            this._worker.terminate();
        }
    }
    
    _processChartData = (response) => {
           
        //decode a partial Log Entry Object
        this._logs = response.map(entry => { return {            
                                                    time: DateUtils.parseJsonDate(entry.time).valueOf(),
                                                    membershipID: entry.membershipID,
                                                    guestsAdmitted: entry.guestsAdmitted
                                                   };    
        }); 
         
        this.setState({chartDataProcessing: true});
        
        setTimeout(this._reprocessChartData, 1);  //post to process right after 
    }
    
    
    _reprocessChartData = (afterTime = null) => {
     
        this.setBusy(true);
        this._afterTime = afterTime;
     
        const args = {logEntries: this._logs, afterTime: afterTime};
        this._worker.postMessage(JSON.stringify(args));  //send the arguments as JSON to the webworker
     
     

    }
    
    _timeRangeCallback = (startTime) => {
        if (this._afterTime === startTime) //don't reprocess unless time changes
            return;
        
        this.setState({chartDataProcessing: true});
        this._reprocessChartData(startTime);
    }
    
}
  
  
export default withCookies(GuestUseStatsTab);