import React from 'react';
import { Typography, Grid, Button, Container } from '@material-ui/core'

import { PP } from '../models/PP'


/**
 *  Shows a static screen after login
 *  
 *  The parent component MUST pass in a prop called goToPoolPass which is called when the user presses the Access PoolPass button
 * 
 */
    
export function LoggedInPage(props) {

    const boldStyle = {
           marginLeft: 4,
           marginRight: 4,
           fontWeight: 'bold',
           color: '#0000AA'
    };
    
                
       
    let toStartMessage = "To start using the portal for \"" + PP.selectedDatabase + "\", click below";

    //if user has multiple databases, add text
    toStartMessage += PP.user.databases.length > 1 ? ", or choose a new database from the Select Database Page." : ".";
                      

    return (
              
              
        <Container style={{paddingTop: 30}}>
            <Typography variant="h5" style={{textAlign: 'left', paddingBottom: 10}}>
                {"Welcome, " + PP.user.name()}
            </Typography>
            
            {!PP.user.isObserver() ?
                <Typography variant="subtitle1" style={{textAlign: 'left', paddingBottom: 40}}>
                    {PP.user.preferredLocation ? <span>Your location has been set automatically to your preferred location. </span> : null }
                    <span>If you are not located at </span>
                    <span style={boldStyle}>{PP.getLocation()}</span>
                    <span> please set your location on the Preferences Page.</span>
                </Typography>
            : null}
            
            
            <Typography variant="subtitle1" style={{textAlign: 'left', paddingBottom: 40}}>{toStartMessage}</Typography>
                                  
            <Grid container justify = "center">
                <Button onClick={props.goToPoolPass} variant="contained" color="primary" style={{color: 'white'}}>Access Database</Button>
            </Grid>
                    
           
        </Container>
    
    );
    
    
}

