import React, {Fragment} from 'react';
import { withCookies } from 'react-cookie';

import { TextField, IconButton, Tooltip, Grid, Box, Typography, Container, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import AddBoxIcon from '@material-ui/icons/AddBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';

import { ThemeColors } from '../Theme'
import { PP, RecentCheckInWarnMode } from '../models/PP'
import { Membership } from '../models/Membership'
import { Member } from '../models/Member'
import { Account } from '../models/Account'
import { Occupancy } from '../models/Occupancy'
import { LogEntry } from '../models/LogEntry'
import { GuestInfo } from '../models/GuestInfo'
import { Restriction } from '../models/Restriction'

import { multilineJSX, StyledTooltip } from 'react-frontend-utils'
import { ImageFunctions } from '../utils/Image';
import { AudioPlay } from '../utils/Audio'

import OccupancyChangePopover from '../components/OccupancyChangePopover'
import MultiMemberCheckInSelect from '../components/MultiMemberCheckInSelect'
import { MemberListModes } from '../components/MemberListEntry'
import { RestComponent, DateUtils, TextEntryPopover, Permissions } from 'react-frontend-utils' 


/**
 * 
 * The caller MUST pass in a onManageMember function to be called with two arguments (membership, member) when the manage button pressed
 * The caller MUST pass in a memberToCheckIn object, which may have null fields memberToCheckIn: {membership: null, member: null} 
 * 
 */


const RESTRICTION_CHECK_PERIOD = 600;  //seconds

export class CheckInTab extends RestComponent {
  
    _clearTimeout = PP.user.isKiosk() ? 8000 : 30000;  //ms to wait for clearing fields
  
    _barcodeInput;             //reference to the barcode input widget
    _onManageMember;           //callback function when pressing the manage button
    _memberToCheckIn;          //holds an object {membershipID, memberID} of the member to check in, whose fields are null if none
    _onTicketFound;            //callback function when a ticket is scanned
    _clearTimer = null;        //Timeout when the fields all clear
    _checkInOutMode;
    
    _lastRestrictionCheck;     //Time, in seconds, of the last restriction check
    
    styles = {
        barcodeField: {
            fontSize: 20
        },
        textfield: {
            marginBottom: 15
        },     
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 10,
            marginBottom: 10
        },
        guestPassContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 0,
            paddingBottom: 10
        },
        guestPassButton: {
            marginTop: 10,
            fontSize: 18,
            color: 'white'
        },
        occupancyButton: {
            fontSize: 18,
            marginRight: 20
        },
        memberLimited: {
            margin: 5,
            marginTop: 15,
            backgroundColor: ThemeColors.limitedBlue,
            padding: 5,
            fontSize: 15,
            color: 'white',
            borderRadius: 5
        },
        memberNotLimited: {
            backgroundColor: 'white',
            padding: 5,
            fontSize: 14,
            color: 'white'
        }
    }
  
    
    constructor(props) {
        super(props);
        this._onManageMember = props.onManageMember;
        this._memberToCheckIn = props.memberToCheckIn;
        this._onTicketFound = props.onTicketFound;

        this.state.barcodeFieldValue = "";                              //text for the barcode field
        this.state.foundMembership = null;                              //after member lookup, the membership details stored here
        this.state.foundMembers = null;                                 //after membership lookup (multi-checkin) the members for the membership are stored here
        this.state.foundMember = null;                                  //after member lookup, the member details stored here
        this.state.isMobile = false;                                    //adapts to mobile width
        this.state.isVerySmall = false;                                 //adapts to really narrow mobile width
        this.state.isDesktop = false;                                   //adapts to wide screen
        this.state.statusField = {text: "STATUS", color: 'white'};      //Should not be empty, because it will adjust layout, instead, use color white to blank
        this.state.guestPassValue = 0;                                  //current value shown on the number of guest passes to use
        this.state.promptCheckInCheckOut = false;                       //whether or not to show the check-in/check-out prompt
        this.state.justUsedGuestPasses = false;                         //Set to true whenever guest passes were just used
        this.state.guestRegisterPromptOpen = false;                     //true to present the Guest Register popover
        this.state.currentOccupancy = null;                             //The current occupancy at this location
        this.state.occupancyPromptOpen = false;                         //true to present the Occupancy change popover
        this.state.showMultiMemberCheckInSelect = false;                //true to show the Multi-Member Check In select popover
        this.state.multiMemberMode = null;
        this.state.restrictions = [];                                   //array of restrictions for this location
        this.state.maxGuests = null;                                    //restriction on the max guests
    }
    
    
    //Stop and clear any existing timer
     _stopTimer = () => {
        if (this._clearTimer) {
            clearTimeout(this._clearTimer);  //stop any existing timer
            this._clearTimer = null;
        }
    }
    
    
    //Start or reset the existing timer, calling the "clear" function on timeout
    _resetTimer = () => {        
        this._stopTimer();
        this._clearTimer = setTimeout(this._clear, this._clearTimeout);
    }


    //Get current occupancy of this location
    _getOccupancy = () => {
        const endPath = "?location=" + encodeURIComponent(PP.getLocation());
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/stats/occupancy/current", {}, 
                            this._getOccupancyCallback, this._occupancyErrorCallback, endPath); 
    }


    //Search for a member to potentially check in
    _searchForMember = (searchString) => {
         //Search for the barcode/ID
        this.setBusy(true);
        
        const currentTime = performance.now()/1000.0;
        
        if (currentTime - this._lastRestrictionCheck > RESTRICTION_CHECK_PERIOD) {
            this._getRestrictions();
        }
        
        //If multi-checkin enabled, search for Memberships - this will get the Membership and all Members, otherwise 
        //just get the Membership and the single member (more efficient)
        if (PP.multiMemberCheckinEnabled())
            this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/membership/search?query=" + searchString, {}, this._searchMembershipCallback, (error) => this._searchErrorCallback(error, searchString)); 
        else
            this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/members/search?query=" + searchString, {}, this._searchMemberCallback, (error) => this._searchErrorCallback(error, searchString)); 
    }
    
    /**
     * When the page loads, try checking in the memberToCheckIn, if they exist.
     * Add a resize listener to adapt to width changes
     */
    componentDidMount() {
        super.componentDidMount();

        this._updateSize();
        window.addEventListener("resize", this._updateSize);
        
        this._getOccupancy();
        this._getRestrictions();
        
        if (!this._memberToCheckIn.membership || !this._memberToCheckIn.member)
            return;

        const searchString = this._memberToCheckIn.membership + "-" + this._memberToCheckIn.member;
        this.setState({barcodeFieldValue: searchString});
        this._checkInOutMode = "LOOKUP";
        
        this._searchForMember(searchString);
        
        //Clear, to avoid double check ins
        this._memberToCheckIn.membership = null;
        this._memberToCheckIn.member = null;
    }
    
  
    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener("resize", this._updateSize);
        this._stopTimer();
    }

    //callback when window changes size
    _updateSize = () => {
        this.setState({ isDesktop: window.innerWidth >= 960, isMobile: window.innerWidth < 716, isVerySmall: window.innerWidth < 476 });  //custom, split between bootstrap and mui
    }
    
    
    _getRestrictions = () => {
        const endPath = "?location=" + encodeURIComponent(PP.getLocation());
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/restrictions", {}, this._getRestrictionsCallback, this._fetchErrorCallback, endPath); 
    }
    
    _getRestrictionsCallback = (response) => {
        if (response) {            
            let restrictions = response.map((r) => new Restriction(r));
            this.setState({restrictions: restrictions});
            
            let maxGuests = null;  //infinite
            for (let restriction of restrictions) {
                if (maxGuests === null || restriction.maxGuests < maxGuests)
                    maxGuests = restriction.maxGuests;
            }
            this.setState({maxGuests: maxGuests});
            this._lastRestrictionCheck = performance.now()/1000;
        } 
    }
    
    
    _focusBarcodeField() {
        if (this._barcodeInput)
            this._barcodeInput.focus();
    }
    
    
    _barcodeKeyDown = (event) => {
        if (event.key === 'Enter') {  //enter key
            
            const searchString = event.target.value;
            console.log("Barcode/ID entered: " + searchString);
            
            this._checkInOutMode = "BARCODE";
            this._searchForMember(searchString);
           
            event.target.select(); //re-highlight all text
        }
    }
    
    
    _getOccupancyCallback = (response) => {
        if (response) {
            const occ = new Occupancy(response);
            this.setState({currentOccupancy: occ.occupancy});
        }
        this._focusBarcodeField();
    }
   
    //Callback for searching for Member - JSON response is an object (tuple) containing a membership and an array of one member
    _searchMemberCallback = (response) => {
        if (response) {            
            const membership = new Membership(response.membership);        
            const member = new Member(response.members[0]); //always the first
                    
            this.setState({statusField: {text: "STATUS", color: 'white'}});;    //blank
            this.setState({foundMembership: membership, foundMember: member, guestPassValue: 0, justUsedGuestPasses: false});  
            this._barcodeInput.select();
            
            //If the member is already checked in, prompt for check out.  Otherwise, do check in
            if (member.isCheckedIn && PP.checkOutEnabled()) {
                this.setState({promptCheckInCheckOut: true});
            }
            else {
                this._checkRecentCheckIn(membership, member);          
            }
            
        }
        this.setBusy(false);
        this._resetTimer();
    }
    
    //Callback for searching for Membership - JSON response is an object (tuple) containing a membership and an array of members
    _searchMembershipCallback = (response) => {
        this.setBusy(false);
        this._resetTimer();
        
        if (response) {            
            
            const membership = new Membership(response.membership);        
            const members = response.members.map(member => new Member(member));
            const selectedIndex = response.selectedIndex;
            
            const member = selectedIndex >= 0 ? members[selectedIndex] : null;
                
            console.log("Found Membership: " + membership.id + " with " + members.length + " members, selected: " + (member ? member.id : "none"));

            this.setState({statusField: {text: "STATUS", color: 'white'}});;    //blank
            this.setState({foundMembership: membership, foundMembers: members, foundMember: member, guestPassValue: 0, justUsedGuestPasses: false});  
            this._barcodeInput.select();
            
            if (!member) {  //no member - just Membership was searched
                
                let signedInCount = 0;
                for (let member of members) {
                    if (member.isCheckedIn)
                        signedInCount++;
                }
                
                if (signedInCount > 0)  //at least one member is checked in, so prompt for check out
                    this.setState({promptCheckInCheckOut: true});
                else             
                    this._doCheckIn();  //no one checked in, proceed with check in
            }          
            //If the member is already checked in, prompt for check out.  Otherwise, do check in
            else if (member.isCheckedIn && PP.checkOutEnabled()) {
                this.setState({promptCheckInCheckOut: true});
            }
            else {
                this._checkRecentCheckIn(membership, member);     
            }
            
        }
    }
    
    
   
    //Callback when the search fails
    _searchErrorCallback = (searchError, searchString) => {
                        
        //Maybe this was a ticket, try it if the search string matches in length
        //If that search fails, show the original error
        if (searchString.length === Account.ACCOUNT_NUMBER_LEN) {
            
            if (!PP.user.hasPermissionTo(Permissions.MARKETPLACE_REDEEM)) {
                this._showSearchError("This looks like a Marketplace Ticket, but you do not have permission to redeem items on a Ticket");
                return;
            }
            
            this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/marketplace/accounts/", {}, 
                                 this._fetchAccountCallback, (error) => this._showSearchError(searchError, true), searchString); 
        }
        else 
            this._showSearchError(searchError);
            
    }
        
        
    _fetchAccountCallback = (response) => {
         const account = new Account(response);  

        console.log(account);
        
        this._clearMember();
        this.setBusy(false);
        this._onTicketFound(account.id);
    }    
        
        
    _showSearchError = (error, triedTicket = false) => {
        const append = triedTicket ? ". This also looks like a Marketplace Ticket, but no matching Ticket was found." : "";
        this.showConfirmAlert("Error", error + append, 'red');
        this._clearMember();
        this.setBusy(false);
        AudioPlay.playDenyAudio();
        this._barcodeInput.select();
    }
    
    //Callback when the occupancy check fails
    _occupancyErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
    }
    
    
    //clear all fields
    _clearMember = () => {
         this.setState({barcodeFieldValue: "", 
                        foundMembership: null, 
                        foundMembers: null,
                        foundMember: null,
                        guestPassValue: 0,
                        justUsedGuestPasses: false,
                        statusField: {text: "STATUS", color: 'white'}});
    }
     
   
   //Callback for the clear button, clear all fields and prompts
    _clear = () => {
       console.log("Clearing");
       this._clearMember();
       this.setState({promptCheckInCheckOut: false});    //blank
       this.closeConfirmAlert();
       this.setBusy(false);
       this._focusBarcodeField();
       this._stopTimer();
    }
   
   
    //Callback for the manage member button, call the onManageMember callback
    _manage = () => {
       if (this.state.foundMembership) {
            const id = this.state.foundMember ? this.state.foundMember.id : this.state.foundMembers[0].id;     
            this._onManageMember(this.state.foundMembership.id, id);
        }
    }
   
   
    //Just reflect the barcode field value from what is entered
    _barcodeFieldChanged = (event) => {
        this.setState({barcodeFieldValue: event.target.value});
    }
    
    
    //Add 1 to the guest pass value, if we have a foundMembership and the current value less than the number of passes owned
    _plusGuestPasses = () => {
        
        if (this.state.maxGuests != null && this.state.maxGuests <= this.state.guestPassValue)
            return;
        
        
        if (this.state.foundMembership && this.state.foundMembership.guestPasses > this.state.guestPassValue) {
            this.setState((prevState) => ({guestPassValue: prevState.guestPassValue+1}));
            this._resetTimer();
        }
    }
    
    //Subtract 1 from the guest pass value, if we have a foundMembership and the current value greater than zero
    _minusGuestPasses = () => {
        if (this.state.guestPassValue > 0) {
            this.setState((prevState) => ({guestPassValue: prevState.guestPassValue-1}));
            this._resetTimer();
        }
    }
    
    
    /**
     * Returns a React Component to prompt for CheckIn/CheckOut.  To show, set this.state.promptCheckInCheckOut to true
     */
    _getCheckInCheckOutPrompt = () => {
       
        const title = (PP.multiMemberCheckinEnabled() ? "At least one " : "") + "Member is currently checked in. Check Out?";
       
        return (           
            <Dialog open={this.state.promptCheckInCheckOut} onClose={() => {this._checkInPromptAction(null);}}>

                <DialogTitle>
                    <span>Check Out</span>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <span style={{color: 'black'}}>{title}</span>
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button variant="outlined" onClick={() => {this._checkInPromptAction(null);}} style={{color: 'black'}}>Cancel</Button>
                    <Button variant="outlined" onClick={() => {this._checkInPromptAction(false);}} style={{color: 'black'}}>Check In Again</Button>
                    <Button variant="outlined" onClick={() => {this._checkInPromptAction(true);}} style={{color: 'blue'}}>Check Out</Button>
                </DialogActions>

            </Dialog>
        );
    }
    
    //Call when a checkInCheckOut prompt button is pressed.  Null = cancel, false = check in again, true = check out
    _checkInPromptAction = (doCheckOut = null) => {
        if (doCheckOut !== null) {  
            if (doCheckOut)
                this._doCheckOut();
            else
                this._doCheckIn();
        }
           
        this.setState({promptCheckInCheckOut: false});  //close dialog
    }
    
    //If guest passes were just used, prompt to use more, otherwise, go use guest passes
    _useGuestPassesPromptAction = () => {

        window.scrollTo(0, this._barcodeInput.offsetTop);  //jump to barcode field (scroll up)

        if (this.state.justUsedGuestPasses) {
            this.showConfirmAlert("Confirm", "Guest Passes were just used. Use more?", 'black', "No", this._useGuestPassesPromptRegister, "Yes", 'black');
        }
        else {
            this._useGuestPassesPromptRegister();
        }
    }
    
    
    //Called when searching the log for the recent check ins for this location and the Member trying to check in. If there are any results, the member checked in
    //recently, if empty, proceed with check in
    _logSearchRecentCheckInCallback = (response) => {
        this.setBusy(false);

        if (response) {            
            if (response.length > 0) {
                const lastLogEntry = new LogEntry(response[0]); 
                
                const diffTime = Math.floor(((new Date()) - lastLogEntry.time) / 60000);  //to minutes
                const relativeTimeString = diffTime === 0 ? "just now" : DateUtils.relativeTimeFriendlyFormat(diffTime) + " ago";
                
                this.showConfirmAlert("Confirm", "Member last checked in at this location " + relativeTimeString + ". Check in again?", 'black', "No", this._doCheckIn, "Yes", 'black');   //no will cancel, yes check in 
            }
            else {
                this._doCheckIn();  //proceed with check in
            }
        }
        
    }
    
    _checkRecentCheckIn = (membership, member) => {
        
        switch (PP.recentCheckInWarnMode()) {
            
            case RecentCheckInWarnMode.NONE:
                this._doCheckIn();  //proceed directly to check in
                break;
                
            case RecentCheckInWarnMode.THIS_LOCATION:  //search the log for recent check ins this location
                
                const sinceDate = new Date();
                sinceDate.setHours(sinceDate.getHours() - PP.recentCheckInWarnTime());   //subtract hours
                
                const endPath = "?since=" + DateUtils.jsonDateString(sinceDate, true) + 
                                "&type=IN" +
                                "&location=" + encodeURIComponent(PP.getLocation()) +
                                "&membershipID=" + encodeURIComponent(membership.id) +
                                "&memberID=" + member.id;
                        
                this.setBusy(true);
                this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/log", {}, this._logSearchRecentCheckInCallback, this._fetchErrorCallback, endPath);               
                break;
                
            case RecentCheckInWarnMode.ANY_LOCATION:
                const lastCheckIn = member.lastCheckedInAgo();
                const warnTime = PP.recentCheckInWarnTime() * 3600;  //hours to seconds
                if (lastCheckIn && lastCheckIn < warnTime) {
                    
                     const diffTime = Math.floor(((new Date()) - member.lastCheckIn()) / 60000);  //to minutes
                     const relativeTimeString = diffTime === 0 ? "just now" : DateUtils.relativeTimeFriendlyFormat(diffTime) + " ago";
              
                    this.showConfirmAlert("Confirm", "Member checked in " + relativeTimeString + ". Check in again?", 'black', "No", this._doCheckIn, "Yes", 'black');   //no will cancel, yes check in 
                    
                } 
                else 
                    this._doCheckIn();
                break;
                
            default:
                console.log("Unknown Check in Mode");
                break;
        }
  
    }
    
    
    
    _denyCheckIn = (reason) => {
        this.setState({statusField: {text: reason, color: 'red'}});   
        AudioPlay.playDenyAudio();
        
                    
        const memberID = this.state.foundMember ? this.state.foundMember.id : this.state.foundMembers[0].id;

        const endPath = "?location=" + encodeURIComponent(PP.getLocation()) + "&mode=" + this._checkInOutMode + "&reason=" + reason;

        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/memberships/" + this.state.foundMembership.id + "/members/" +
                             memberID + "/deny",
                             {method: "POST"}, 
                             null, this._postActionsErrorCallback, endPath);   
    }
    
    //Validate its okay to check in and post check in
    _doCheckIn = () => {
                
        //Validate check in ok
        if (this.state.foundMembership.suspended) {            
            this._denyCheckIn("Membership is Suspended");   
            return;        
        }
        if (this.state.foundMember && this.state.foundMember.suspended) {
            this._denyCheckIn("Member is Suspended");   
            return;        
        }
        if (this.state.foundMembership.expiresIn() < 0) {
            this._denyCheckIn("Membership is Expired");   
            return;        
        }
        
        if (PP.multiMemberCheckinEnabled()) {
            this._stopTimer();  //unlimited time to enter members
            this.setState({showMultiMemberCheckInSelect: true, multiMemberMode: MemberListModes.CHECKIN});
            return;
        }
        
        
        if (this.state.foundMember.entryPasses === 0) {
            this._denyCheckIn("No more Entry Passes");   
            return;        
        }
        
           
        for (let restriction of this.state.restrictions) {
            if (restriction.isRestricted(this.state.foundMembership, this.state.foundMember.id)) {
                this._denyCheckIn("Restricted at this Location");   
                return;
            }
        }
        
        //Ok, try check in
        
        this.setBusy(true);
        this._resetTimer();
        
        
        const endPath = "?location=" + encodeURIComponent(PP.getLocation()) + "&mode=" + this._checkInOutMode;

        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/memberships/" + this.state.foundMember.membershipRef + "/members/" +
                             this.state.foundMember.id + "/checkin", {method: "POST"}, 
                             this._checkInCallback, this._postActionsErrorCallback, endPath);       
    }
    
    //Post for check out
    _doCheckOut = () => {  
        
        if (PP.multiMemberCheckinEnabled()) {
            this._stopTimer();  //unlimited time to enter members
            this.setState({showMultiMemberCheckInSelect: true, multiMemberMode: MemberListModes.CHECKOUT});
            return;
        }
        
        this.setBusy(true);
        this._resetTimer();
        
        const endPath = "?location=" + encodeURIComponent(PP.getLocation()) + "&mode=" + this._checkInOutMode;

        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/memberships/" + this.state.foundMember.membershipRef + "/members/" +
                             this.state.foundMember.id + "/checkout", {method: "POST"}, 
                             this._checkOutCallback, this._postActionsErrorCallback, endPath); 
    }
    
    
    _cancelMultiMemberCheckInSelect = () => {
        this.setState({showMultiMemberCheckInSelect: false});
    }
    
    _multiMembersSelected = (memberIdSet) => {        
        this.setState({showMultiMemberCheckInSelect: false});

      
        const foundMemberSelected = this.state.foundMember ? memberIdSet.has(this.state.foundMember.id) : false;        
        const ids = Array.from(memberIdSet);
            
        for (let restriction of this.state.restrictions) {
            for (let id of ids) {
                if (restriction.isRestricted(this.state.foundMembership, id)) {
                    this._denyCheckIn("Restricted at this Location");   
                    return;
                }
            }
        }
        
        this.setBusy(true);
        this._resetTimer();
        
        const endPath = "?memberIDs=" + ids + "&location=" + encodeURIComponent(PP.getLocation()) + "&mode=" + this._checkInOutMode;

        switch (this.state.multiMemberMode) {
            case MemberListModes.CHECKIN:
                        
                this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/memberships/" + this.state.foundMembership.id + "/members/checkin",
                                     {method: "POST"}, 
                                    () => this._checkInCallback(ids.length, foundMemberSelected), this._postActionsErrorCallback, endPath); 
                             
                break;              
                
            case MemberListModes.CHECKOUT:
            
                this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/memberships/" + this.state.foundMembership.id + "/members/checkout",
                                     {method: "POST"}, 
                                     () => this._checkOutCallback(ids.length, foundMemberSelected), this._postActionsErrorCallback, endPath); 
            
                break;
            default:
                return;
        } 
    }
    
    _useGuestPassesPromptRegister = () => {
        if (PP.guestRegisterEnabled()) {
            this._stopTimer();  //unlimited time to enter guest pass data
            this.setState({guestRegisterPromptOpen: true});
        }
        else
            this._useGuestPasses("");
    }
    
    //Post for use guest passes
    _useGuestPasses = (registerText) => {
        
        this.setBusy(true);
        this.setState({statusField: {text: "STATUS", color: 'white'}});;    //blank

        this._resetTimer();

        const guestInfo = new GuestInfo(this.state.guestPassValue, registerText);

        const memberID = this.state.foundMember ? this.state.foundMember.id : 0;

        const endPath = "?location=" + encodeURIComponent(PP.getLocation());

        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/memberships/" + this.state.foundMembership.id + "/members/" + memberID + 
                             "/admitGuests", 
                             {method: "POST", body: JSON.stringify(guestInfo)}, 
                             this._admitGuestsCallback, this._postActionsErrorCallback, endPath); 
    }
    
    //Success on check in post, default 0 for single check in
    _checkInCallback = (numCheckedIn = 0, foundMemberSelected = true) => {
        this.setBusy(false);
        this._resetTimer();

        const count = numCheckedIn > 0 ? (" " + numCheckedIn + " Member" + (numCheckedIn > 1 ? "s" : "")) : "";

        if (this.state.foundMember && foundMemberSelected)
            this.state.foundMember.entryPasses--;  //even unlimited, -1, just subtract again
        
        if (!foundMemberSelected) {
            this.setState({foundMember: null});  //don't show the member info who didn't check in (was deselected)
        }
        
        this.setState({statusField: {text: "✓  Checked In" + count, color: 'green'}});   
        AudioPlay.playAcceptAudio();
        console.log("Checked In");
        this._getOccupancy();

    }

    //Success on check out post, default 0 for single check out
    _checkOutCallback = (numCheckedOut = 0, foundMemberSelected = true) => {  //success
        this.setBusy(false);
        this._resetTimer();
        
        if (this.state.foundMember && foundMemberSelected)
            this.state.foundMember.isCheckedIn = false; 
         
        if (!foundMemberSelected) {
            this.setState({foundMember: null});  //don't show the member info who didn't check in (was deselected)
        }
        
        const count = numCheckedOut > 0 ? (" " + numCheckedOut + " Member" + (numCheckedOut > 1 ? "s" : "")) : "";

        this.setState({statusField: {text: "\u2b3f Checked Out" + count, color: 'blue'}});  
        AudioPlay.playCheckOutAudio();
        console.log("Checked Out");
        this._getOccupancy();

    }
    
    //Success on use guest pass post
    _admitGuestsCallback = () => {  //success
        this.setBusy(false);
        this._resetTimer();

        const used = this.state.guestPassValue;
        
        this.state.foundMembership.guestPasses -= used; 
        this.setState({statusField: {text: "✓  " + used + " guest pass" + (used > 1 ? "es" : "") + " used", color: 'green'},
                       guestPassValue: 0,
                       justUsedGuestPasses: true});
        AudioPlay.playGuestPassUseAudio();
        
        console.log("Used Guest Passes: " + used);
        this._getOccupancy();
    }
    
    
    //Callback when one of the post actions fails
    _postActionsErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.setBusy(false);
        this._resetTimer();
        this._barcodeInput.select();
        this.setState({statusField: {text: "STATUS", color: 'white'}});;    //blank
    }
    
    
    _guestRegisterOkCallback = (text) => {
        console.log("Guest Register Text: " + text);
        this.setState({guestRegisterPromptOpen: false});
        this._useGuestPasses(text);  
    }
    _guestRegisterSkipCallback = (text) => {
        this.setState({guestRegisterPromptOpen: false}); 
        this._useGuestPasses("");  //no register text
    }
    
    _changeOccupancy = () => {
        this._focusBarcodeField();
        this.setState({occupancyPromptOpen: true}); 
    }

    
    _occupancyOkCallback = (val) => {
        
        console.log("Occupancy Change to: " + val);
        this.setState({occupancyPromptOpen: false});
        
        const isDelta = val < 0;

        const endPath = "?location=" + encodeURIComponent(PP.getLocation()) + "&value=" + val + "&isDelta=" + isDelta;

        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/stats/occupancy", {method: "POST"}, 
                            this._getOccupancy, this._occupancyErrorCallback, endPath); 

    }

    
    render() {
                
        const membershipRefText = this.state.foundMembership ? this.state.foundMembership.id : "";
        const membershipTypeText = this.state.foundMembership ? this.state.foundMembership.type : "";
        const nameText = this.state.foundMember ? this.state.foundMember.name() : "";
        //Replace all line breaks with spaces
        const addressTextWithNewlines = this.state.foundMembership ? this.state.foundMembership.address : "";
        const addressText = this.state.foundMembership ? this.state.foundMembership.address.replace(/(\r\n|\n|\r)/gm, " ") : "";
        const ageText = this.state.foundMember ? this.state.foundMember.ageString() : "";
        const entryPassesText = this.state.foundMember ? this.state.foundMember.entryPassesString() : "";
        const expiresText = this.state.foundMembership ? this.state.foundMembership.expirationString() : "";
        const lastEntryText = this.state.foundMember ? this.state.foundMember.lastCheckInString() : "";
        const notesText = this.state.foundMember ? this.state.foundMember.notes : "";
        
        const expiresBackgroundColor = this.state.foundMembership && this.state.foundMembership.expiresIn() < 30 ? 'yellow' : 'white';
        const entryPassesBackgroundColor = this.state.foundMember && this.state.foundMember.entryPasses === 0 ? 'yellow' : 'white';
        
        const guestPassesText = this.state.foundMembership ? this.state.foundMembership.guestPasses : "";
        
        const memberSuspended = this.state.foundMember && this.state.foundMember.suspended; //if there's a found member and suspended
        
        const memberLimited = this.state.foundMember && this.state.foundMember.limited;  
        
        const canUseGuestPasses = this.state.foundMembership &&
                                  !this.state.foundMembership.suspended &&
                                  !memberSuspended &&
                                  this.state.foundMembership.expiresIn() >= 0 &&
                                  this.state.foundMembership.guestPasses > 0 && 
                                  this.state.guestPassValue > 0;
        
        
        const statusString = this.state.statusField.text;
        const statusColor = this.state.statusField.color;
        
        const currentOccupancyValue = this.state.currentOccupancy === null ? 0 : this.state.currentOccupancy;
        
        const currOccupancyString = this.state.currentOccupancy === null ? "----" : String(currentOccupancyValue).padStart(4, "0");
        
        let occupancyButtonStyle = {...this.styles.occupancyButton}; 
        let barcodeFieldStyle = {...this.styles.barcodeField};
        let iconSize = "large";
        let statusTextVariant = "h4";
        
        let searchLabel = PP.multiMemberCheckinEnabled() ? "Membership/ID/Barcode" : "Barcode/ID";
        if (PP.user.hasPermissionTo(Permissions.MARKETPLACE_REDEEM))
            searchLabel += "/Ticket";
        
        //Reduce sizes to fit small screens
        if (this.state.isVerySmall) {
            occupancyButtonStyle.fontSize -= 2;
            occupancyButtonStyle.marginRight -= 5;
            barcodeFieldStyle.fontSize -=2;
            iconSize = "medium";
            statusTextVariant = "h5";
        }
        
        return (
                   
            <div>
                {this._getCheckInCheckOutPrompt()}
                {this.getConfirmAlertComponent()}
                
                <TextEntryPopover isOpen={this.state.guestRegisterPromptOpen} showSkip={true} multiline={true} title="Guest Register" 
                                 okCallback={this._guestRegisterOkCallback} skipCallback={this._guestRegisterSkipCallback} cancelCallback={() => {this.setState({guestRegisterPromptOpen: false})}} />
                
                <OccupancyChangePopover isOpen={this.state.occupancyPromptOpen} initialValue={currentOccupancyValue}
                                        okCallback={this._occupancyOkCallback} cancelCallback={() => {this.setState({occupancyPromptOpen: false})}}/>
                                        
                <MultiMemberCheckInSelect isOpen={this.state.showMultiMemberCheckInSelect} onClose={this._cancelMultiMemberCheckInSelect} mode={this.state.multiMemberMode}
                                   membership={this.state.foundMembership} members={this.state.foundMembers} selectedMember={this.state.foundMember}
                                   onAction={this._multiMembersSelected} isMobile={this.state.isMobile} isVerySmall={this.state.isVerySmall} />

                
                { /*----------------------------  TOP: OCCUPANCY, BARCODE, CONTROL BUTTONS  ----------------------------------------*/ null }

                <div style={{padding: 8}}/>

                <div style={{display: 'flex'}}>
                        
                    {PP.user.isKiosk() ? null :
                        <Tooltip title="Current Occupancy at this Location">
                           <Button onClick={this._changeOccupancy} color="primary" variant="outlined" style={occupancyButtonStyle}>{currOccupancyString}</Button>
                       </Tooltip>
                    }
                        
                    <TextField autoFocus inputRef={(field) => this._barcodeInput = field} value={this.state.barcodeFieldValue} onChange={this._barcodeFieldChanged}  
                               label={searchLabel} onKeyDown={this._barcodeKeyDown} variant="outlined" 
                               inputProps={{style: barcodeFieldStyle}} fullWidth={true} InputLabelProps={{ shrink: true}} />

                    <Tooltip title="Clear">
                        <IconButton edge="end" onClick={this._clear} style={{marginLeft: this.state.isVerySmall ? 0 : 4}}>
                            <CancelIcon fontSize={iconSize} />
                        </IconButton>
                    </Tooltip>

                    {PP.user.hasPermissionTo(Permissions.VIEW_DATABASE) ? 
                        <Tooltip title="Manage Member">
                            <div style={{display: 'flex'}}>
                                <IconButton edge="end" onClick={this._manage} color='primary' disabled={this.state.foundMembership === null} style={{marginLeft: this.state.isVerySmall ? 0 : 4}}>
                                    <ArrowForwardIcon fontSize={iconSize}/>
                                </IconButton>
                            </div>
                        </Tooltip> 
                        : null
                    }
                    
                </div>
                
                { /*----------------------------  STATUS and BUSY  ----------------------------------------*/ null }

                
                <Container style={{...this.styles.container, height:60, marginBottom: 20}}>
                    {this.state.isBusy ? this.getBusyComponent('center', {marginRight: 10}) :
                                          (<Typography variant={statusTextVariant} align="center" style={{fontWeight: 'bold', color: statusColor}}>{statusString}</Typography> )
                    }
                </Container>

                <Grid container direction="row" spacing={4}>
                    
                    { /*----------------------------  LEFT GRID PANE: MEMBER PHOTO  ----------------------------------------*/ null }

                    
                    <Grid item md={4} sm={6} xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                                
                         <div>
                            {this.state.foundMember ? ImageFunctions.memberImageURL(this.state.foundMember, this.state.isMobile) : ImageFunctions.memberImagePlaceholderURL(this.state.isMobile)}

                            <Typography align="center" style={memberLimited ? this.styles.memberLimited : this.styles.memberNotLimited}>{memberLimited ? "Limited Member" : ""}</Typography> 
                        </div>
                    </Grid>

                    { /*----------------------------  MID GRID PANE: MEMBER INFO  ----------------------------------------*/ null }


                    <Grid item md={4} sm={6} xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                         <Box>
                            <TextField label="Membership ID" variant="outlined" value={membershipRefText} style={this.styles.textfield} fullWidth={true} inputProps={{readOnly: true }} InputLabelProps={{ shrink: true}} />
                            <TextField label="Name" variant="outlined" value={nameText} fullWidth={true} style={this.styles.textfield} inputProps={{readOnly: true }} InputLabelProps={{ shrink: true}} />
                            
                            {PP.addressAndAgeHidden() ? null :
                                <StyledTooltip title={multilineJSX(addressTextWithNewlines)}>
                                    <TextField label="Address" variant="outlined" value={addressText} fullWidth={true} style={this.styles.textfield} inputProps={{readOnly: true }} InputLabelProps={{ shrink: true}} />
                                </StyledTooltip>      
                            }
                                            
                            <div style={{display: 'flex'}}>
                                {PP.addressAndAgeHidden() ? null :
                                    <TextField label="Age" variant="outlined" value={ageText} fullWidth={true} style={{...this.styles.textfield, marginRight: 10}} inputProps={{readOnly: true, style: {textAlign: 'center'} }} InputLabelProps={{ shrink: true }} />
                                }
                                <TextField label="Remaining Entry Passes" variant="outlined" value={entryPassesText} fullWidth={true} style={this.styles.textfield} inputProps={{readOnly: true, style: {textAlign: 'center', backgroundColor: entryPassesBackgroundColor}  }} InputLabelProps={{ shrink: true }} />
                            </div>
                            
                            <TextField label="Expires" variant="outlined" value={expiresText}  fullWidth={true} style={this.styles.textfield} inputProps={{readOnly: true, style: {backgroundColor: expiresBackgroundColor} }} InputLabelProps={{ shrink: true}} />
                            <TextField label="Last Entry" variant="outlined" value={lastEntryText} fullWidth={true} style={this.styles.textfield} inputProps={{readOnly: true }} InputLabelProps={{ shrink: true}} />
                            <TextField label="Membership Type" variant="outlined" value={membershipTypeText} fullWidth={true} style={this.styles.textfield} inputProps={{readOnly: true }} InputLabelProps={{ shrink: true}} />                            
                            
                            {PP.notesHidden() ? null :
                                <TextField label="Member Notes" multiline rows={this.state.isDesktop ? 8 : 4} variant="outlined" value={notesText} fullWidth={true} style={this.styles.textfield} inputProps={{readOnly: true }} InputLabelProps={{ shrink: true}} />
                            }
                         </Box>
                    </Grid>
                    
                    { /*----------------------------  RIGHT GRID PANE: GUEST PASS USE  ----------------------------------------*/ null }

                    <Grid item md={4} sm={12} xs={12} style={{justifyContent: 'center'}}>
                         
                        {PP.virtualGuestPassHidden() ? null :
                            <Fragment>
                                <Container style={this.styles.guestPassContainer}>
                                    <TextField label="Available Guest Passes" variant="outlined" value={guestPassesText} fullWidth={false} style={this.styles.textfield} inputProps={{readOnly: true, style: {textAlign: 'center'}  }} InputLabelProps={{ shrink: true}} />
                                </Container>
                                <Container style={this.styles.guestPassContainer}>
                                    <IconButton onClick={this._minusGuestPasses} style={{marginLeft: 10}}>
                                        <IndeterminateCheckBoxIcon style={{ fontSize: 40 }} />
                                    </IconButton>                    
                                    <TextField label="" variant="outlined" value={this.state.guestPassValue} fullWidth={false} inputProps={{readOnly: true, style: {textAlign: 'center', fontSize: '20px'}  }} InputLabelProps={{ shrink: true}} />
                                    <IconButton onClick={this._plusGuestPasses} style={{marginRight: 10}}>
                                        <AddBoxIcon style={{ fontSize: 40 }}/>
                                    </IconButton>                    

                                </Container>
                                <Container style={this.styles.guestPassContainer}>
                                    <Button onClick={this._useGuestPassesPromptAction} color="primary" variant="contained" disabled={!canUseGuestPasses} style={this.styles.guestPassButton}>Use Virtual Guest Passes</Button>
                                </Container>
                            </Fragment>
                        }
                    </Grid>
                
      
                </Grid>

            </div>
        );

    }
}


export default withCookies(CheckInTab);