import { withCookies } from 'react-cookie';

import { AbstractStatisticsTab } from './AbstractStatisticsTab';

import { PP } from '../../models/PP'
import { ThemeColors } from '../../Theme'

export class MembershipTypesStatsTab extends AbstractStatisticsTab {
  
    
  
    _options = {
      
        chart: {...this._standardChartOptions, type: 'donut'},
        labels: [],
        colors: ThemeColors.pieChartColors,
        
        plotOptions: {
            pie: {
                donut: {
                    size: '50%',
                    labels: {
                        show: true,
                        total: {
                            show: true,
                            fontSize: '14px',
                            showAlways: true,
                            label: "Total Memberships"
                        }
                    }
                }
            }
        },

        dataLabels: {
            formatter: function (val, opts) {
                return opts.w.config.series[opts.seriesIndex];  //use the count value itself, not the percent
            }
        }
                
    }
    
    _typeStats;  //this is a simple array of JsonMembershipTypeStat: type (String) and count (int) fields 

    constructor(props) {
        super(props); 
        this._chartOptions = this._options;
        this._chartType = "donut";
        this._chartFetchPath = "/ppcs/databases/" + PP.selectedDatabase + "/stats/memberships/types";
        this._chartDataHandlerCallback = this._processChartData;
        this._hasLocation = false;
        this._hasAggregate = false;
        this._hasTime = false;
        this._hasTimeRange = false;
        this._chartHeight = 1000;
    }
    
    _processChartData = (response) => {
        
        this._typeStats = response;
        
        if (this._typeStats.length === 0)
            return;
        
        //Create the labels, using the type name or Unspecified for an empty string
        this._options.labels = this._typeStats.map(stat => stat.type ? stat.type : "Unspecified");  
        
        //Specify the data series
        const counts = this._typeStats.map(stat => stat.count);
        
        this.setState({chartData: counts});
        
    }
    
    
}
  
  
export default withCookies(MembershipTypesStatsTab);