
export class GuestInfo {
    
    numGuests;
    guestRegisterInfo;
    
    constructor(num, text) {
        this.numGuests = num;
        this.guestRegisterInfo = text;
    }
    
}
