import React from 'react';
import { withCookies } from 'react-cookie';

import { Paper, Typography, Grid, TextField, FormControl, RadioGroup, Radio, FormControlLabel, Checkbox, Tooltip} from '@material-ui/core'
import { PP, BarcodeSuggestionType, RecentCheckInWarnMode } from '../models/PP'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { RestComponent, DateUtils, PopupMenu, Permissions } from 'react-frontend-utils'
import { ManageNumericField, ManageDateField } from 'react-frontend-utils'

import { ThemeColors } from '../Theme'

/**
 *  The PreferencesPage updates preferences that are saved and retrieved in local storage
 *  
 *  The parent component MUST pass in a prop called locationDidUpdate which indicates the location name has changed.
 * 
 */
    
    
const MAX_LOCATION_LEN = 255;
const MAX_WARN_HOURS = 8760;

export class PreferencesPage extends RestComponent {
  
  
    styles = {
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
        },
        form: {
            marginLeft: 15
        },
        paper: {
            width: '100%',
            height: '100%'
        },
        paperLabel: {
            marginLeft: 15,
            color: 'gray',
            fontSize: '9pt',
            flexGrow: 1
        },
        helpLabel: {
            color: 'gray',
            fontSize: '11pt',
            fontStyle: 'italic',
            marginBottom: 20
        }
    };
    
    _calendarTextFieldRef = React.createRef();
    
    _locationUpdateCallback;
    _initialEntryPasses;
    _initialWarnHours;
    _initialExpirationDate;
    
    _locationField = React.createRef();

    constructor(props) {
        super(props);
        this._initialEntryPasses = PP.initialEntryPasses();  //managed field, just set initial text
                
        const expDate = PP.initialExpirationDate();
        this._initialExpirationDate = expDate ? PP.dateFormat(DateUtils.parseJsonDate(expDate)) : "Never"; //managed field, just set initial text
        this._locationUpdateCallback = props.locationDidUpdate;
        
        this.state.selectedDateFormat = PP.getDateFormat();
        this.state.checkOutEnabled = PP.checkOutEnabled();
        this.state.soundEffectsEnabled = PP.soundEffectsEnabled();
        this.state.guestRegisterEnabled = PP.guestRegisterEnabled();
        this.state.multiMemberCheckinEnabled = PP.multiMemberCheckinEnabled();
        this.state.hideGuestPasses = PP.virtualGuestPassHidden();
        this.state.hideAddressAndAge = PP.addressAndAgeHidden();
        this.state.notesHidden = PP.notesHidden();
        this.state.selectedBarcodeSuggestion = PP.barcodeSuggestionType();
        this.state.minBarcodeSuggestDigits = PP.barcodeSuggestionDigits();
        this.state.initialGuestPasses = PP.initialGuestPasses();
        this.state.recentCheckInWarnMode = PP.recentCheckInWarnMode();
        this.state.warnHours = PP.recentCheckInWarnTime();
        
        this.state.setLocation = PP.getLocation();
        this.state.locations = [];  //available locations, fetched after mounted
    }

    componentDidMount() {
        super.componentDidMount();
        this._fetchLocations();
    }

    _fetchLocations = () => {
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/locations", {}, this._fetchLocationsDoneCallback);       
    }
    
    //Set the locations state for the locations pulldown
    _fetchLocationsDoneCallback = (response) => {
        this.setState({locations: [...response]});
    }
  
   
    _locationFieldChanged = (event) => {
        this._changeLocation(event.target.value);
    };
    
    
    _changeLocation = (newLocation) => {
        this.setState({setLocation: newLocation});
        PP.setLocation(newLocation);
        this._locationUpdateCallback();
    }
    
    
    _selectDateFormat = (event) => {
        const format = event.target.value;
        PP.setDateFormat(format);
        this.setState({selectedDateFormat: format});
    };
 
 
    _selectBarcodeSuggestion = (event) => {
        const type = event.target.value;
        PP.setBarcodeSuggestionType(type);
        this.setState({selectedBarcodeSuggestion: type});
    };
    
    _barcodeSuggestionDigitsChanged = (event) => {
        const digits = event.target.value;
        if (digits < 1)
            return;
        PP.setBarcodeSuggestionDigits(digits);
        this.setState({minBarcodeSuggestDigits: digits});
    }
 
    _selectRecentCheckInWarnMode = (event) => {
        const mode = event.target.value;
        PP.setRecentCheckInWarnMode(mode);
        this.setState({recentCheckInWarnMode: mode});
    };
 
    _enableCheckOut = (event) => {
        const isChecked = event.target.checked;
        PP.enableCheckOut(isChecked);
        this.setState({checkOutEnabled: isChecked});
    }
    
    _enableGuestRegister = (event) => {
        const isChecked = event.target.checked;
        PP.enableGuestRegister(isChecked);
        this.setState({guestRegisterEnabled: isChecked});
    }
    
    _enableMultiMemberCheckin = (event) => {
        const isChecked = event.target.checked;
        PP.enableMultiMemberCheckin(isChecked);
        this.setState({multiMemberCheckinEnabled: isChecked});
    }
    
    _hideGuestPasses = (event) => {
        const isChecked = event.target.checked;
        PP.setVirtualGuestPassHidden(isChecked);
        this.setState({hideGuestPasses: isChecked});
    }
    
    _hideAddressAndAge = (event) => {
        const isChecked = event.target.checked;
        PP.setAddressAndAgeHidden(isChecked);
        this.setState({hideAddressAndAge: isChecked});
    }
    
    _hideNotes = (event) => {
        const isChecked = event.target.checked;
        PP.setNotesHidden(isChecked);
        this.setState({notesHidden: isChecked});
    }
    
    _enableSoundEffects = (event) => {
        const isChecked = event.target.checked;
        PP.enableSoundEffects(isChecked);
        this.setState({soundEffectsEnabled: isChecked});
    }
    
    _expirationDateChanged = (json, date) => {
        PP.setInitialExpirationDate(date);
        this._calendarTextFieldRef.current.accept();  //accepted change            
    }
    
    _dateParseError = (label, error) => {
        this.showConfirmAlert("Error", error, 'red');
    }
    
    _initialGuestPassesChanged = (event) => {
        const val = event.target.value;
        PP.setInitialGuestPasses(val);
        this.setState({initialGuestPasses: val});
    }
    
    _entryPassesChanged = (json, val) => {
        PP.setInitialEntryPasses(val);
    }
    
    _warnHoursChanged = (event) => {
        const digits = event.target.value;
        if (digits < 1 || digits > MAX_WARN_HOURS)
            return;
        this.setState({warnHours: digits});
        PP.setRecentCheckInWarnTime(digits);
    }
    
    _locationMenuItems = () => {
                
        let menuItems = [];
        
        this.state.locations.forEach((location) => {
            menuItems.push({label: location, 
                            selectCallback: () => {  this._changeLocation(location) }  //set the location field to the selected menu item
                            });             
                
        });
         
        return menuItems;
    };
        
    render () {
        
        const isNotObserver = !PP.user.isObserver();
        const canSetPref = PP.user.hasPermissionTo(Permissions.EDIT_DATABASE);
        
        return (

            <div >
                {this.getConfirmAlertComponent()}        

                <Typography variant="body2" style={this.styles.helpLabel}>Preferences apply only to this browser/computer, for all users. Each device must be configured separately.</Typography> 

                <Grid container direction="row" spacing={2}>

                    {isNotObserver ? 
                    <Grid item xs={12}>
                    
                         <div style={{width: '100%', display: 'flex', alignItems: 'center', marginBottom: 10}} >  
                            <Tooltip title="This device's current location">
                                <TextField ref={this._locationField} label="Location" value={this.state.setLocation} onChange={this._locationFieldChanged} 
                                           variant="outlined" fullWidth={true} style={{marginRight: 10}} 
                                           inputProps={{maxLength: MAX_LOCATION_LEN}} InputLabelProps={{ shrink: true}} />
                            </Tooltip>

                            <PopupMenu menuIcon={(<ExpandMoreIcon fontSize='large' color='primary'/>)}  
                                    menuItems={this._locationMenuItems()} 
                                    menuTooltipText={"Suggest Location"}/>
                        </div> 
                    </Grid> : null}
                    
                    {isNotObserver ? 
                    <Grid item xs={12} md={6} lg={4}>
                    
                         <Paper style={this.styles.paper}>
                            <Typography variant="body2" style={this.styles.paperLabel}>Check In Options</Typography> 
                                                     
                            <FormControl component="fieldset" style={this.styles.form} >  
                                <FormControlLabel control={<Checkbox checked={this.state.checkOutEnabled} onChange={this._enableCheckOut} color="primary"/>} label="Enable Check Out"/>
                                <FormControlLabel control={<Checkbox checked={this.state.guestRegisterEnabled} onChange={this._enableGuestRegister} color="primary"/>} label="Enable Guest Register"/>
                                <FormControlLabel control={<Checkbox checked={this.state.multiMemberCheckinEnabled} onChange={this._enableMultiMemberCheckin} color="primary"/>} label="Enable Multi-Member Check In"/>
                                <FormControlLabel control={<Checkbox checked={this.state.hideGuestPasses} onChange={this._hideGuestPasses} color="primary"/>} label="Hide Virtual Guest Passes"/>
                                <FormControlLabel control={<Checkbox checked={this.state.hideAddressAndAge} onChange={this._hideAddressAndAge} color="primary"/>} label="Hide Address and Age"/>
                                <FormControlLabel control={<Checkbox checked={this.state.notesHidden} onChange={this._hideNotes} color="primary"/>} label="Hide Member Notes"/>
                            </FormControl>
                            
                        </Paper>
                    </Grid> : null}
                    
                    {isNotObserver ? 
                    <Grid item xs={12} md={6} lg={4}>
                    
                         <Paper style={this.styles.paper}>
                            <Typography variant="body2" style={this.styles.paperLabel}>Warn on Recent Check In</Typography> 
                            
                            <FormControl component="fieldset" style={this.styles.form} >
                                <RadioGroup aria-label="warn check in mode" name="warn check in mode" value={this.state.recentCheckInWarnMode} onChange={this._selectRecentCheckInWarnMode}>

                                    <FormControlLabel key={1} value={RecentCheckInWarnMode.NONE} control={<Radio color="primary" />} label={RecentCheckInWarnMode.NONE} />
                                    <FormControlLabel key={2} value={RecentCheckInWarnMode.THIS_LOCATION} control={<Radio color="primary" />} label={RecentCheckInWarnMode.THIS_LOCATION} />
                                    <FormControlLabel key={3} value={RecentCheckInWarnMode.ANY_LOCATION} control={<Radio color="primary" />} label={RecentCheckInWarnMode.ANY_LOCATION} />

                                </RadioGroup>
                            </FormControl> 
                            
                            <div style={{maxWidth: 300, marginBottom: 15}}>
                                <Tooltip title="If the Member has checked in within this number of hours ago, warn before checking in">
                                    <TextField
                                        label="Recent Time (hrs)"
                                        type="number"
                                        style={{margin: 20}}
                                        value={this.state.warnHours}
                                        onChange={this._warnHoursChanged}
                                        InputLabelProps={{ shrink: true }} 
                                      />
                                </Tooltip>
                            </div>
                            
                        </Paper>
                    </Grid> : null}
                    
            
                    <Grid item xs={12} md={6} lg={4}>
                    
                         <Paper style={this.styles.paper}>
                            <Typography variant="body2" style={this.styles.paperLabel}>Date Format</Typography> 
                            <FormControl component="fieldset" style={this.styles.form} >
                                <RadioGroup aria-label="dateformat" name="dateFormat" value={this.state.selectedDateFormat} onChange={this._selectDateFormat}>

                                    <FormControlLabel key={1} value={DateUtils.DateFormatType.ISO8601} control={<Radio color="primary" />} label={DateUtils.DateFormatType.ISO8601} />
                                    <FormControlLabel key={2} value={DateUtils.DateFormatType.US} control={<Radio color="primary" />} label={DateUtils.DateFormatType.US} />

                                </RadioGroup>
                            </FormControl>  
                        </Paper>
                    </Grid>
                    
                   
                    {canSetPref ? 
                    <Grid item xs={12} md={6} lg={4}>
                    
                         <Paper style={this.styles.paper}>
                            <Typography variant="body2" style={this.styles.paperLabel}>Barcode Suggestion</Typography> 
                            <FormControl component="fieldset" style={this.styles.form} >
                                <RadioGroup aria-label="barcode suggestion" name="barcode suggestion" value={this.state.selectedBarcodeSuggestion} onChange={this._selectBarcodeSuggestion}>

                                    <FormControlLabel key={1} value={BarcodeSuggestionType.NEXT_NUMERIC} control={<Radio color="primary" />} label={BarcodeSuggestionType.NEXT_NUMERIC} />
                                    <FormControlLabel key={2} value={BarcodeSuggestionType.RANDOM_NUMERIC} control={<Radio color="primary" />} label={BarcodeSuggestionType.RANDOM_NUMERIC} />
                                    <FormControlLabel key={3} value={BarcodeSuggestionType.RANDOM_ALPHANUMERIC} control={<Radio color="primary" />} label={BarcodeSuggestionType.RANDOM_ALPHANUMERIC} />

                                </RadioGroup>
                            </FormControl>  
                            <TextField
                                label="Minimum Digits"
                                type="number"
                                style={{margin: 20}}
                                value={this.state.minBarcodeSuggestDigits}
                                onChange={this._barcodeSuggestionDigitsChanged}
                                InputLabelProps={{ shrink: true }} 
                              />
                        </Paper>
                    </Grid> : null}
                    
                    {canSetPref ? 
                    <Grid item xs={12} md={6} lg={4}>
                    
                         <Paper style={this.styles.paper}>
                            <Typography variant="body2" style={this.styles.paperLabel}>New Memberships and Members</Typography> 
                                
                                 <ManageDateField hasNever={true} json={"initialExpiration"} style={{marginLeft: 15, marginTop: 20, maxWidth: 310}} 
                                                 initialValue={this._initialExpirationDate}
                                                 maxYear={DateUtils.calendarMaxYear()}
                                                 ref={this._calendarTextFieldRef}
                                                 label="Initial Expiration Date" onFieldChange={this._expirationDateChanged} 
                                                 onParseError={this._dateParseError} 
                                                 dateFormat={PP.getDateFormat()}
                                                 calendarColor={ThemeColors.calendarColor}/>          
       
                                <TextField
                                    label="Initial Guest Passes"
                                    type="number"
                                    style={{marginLeft: 15, marginTop: 10, marginBottom: 20}}
                                    value={this.state.initialGuestPasses}
                                    onChange={this._initialGuestPassesChanged}
                                    InputLabelProps={{ shrink: true }} 
                                  />
                               
                                <div style={{maxWidth: 400, marginBottom: 15}}>
                                    <ManageNumericField autoCommit={true} fullWidth={true} hasInfinity={true} justify='left' 
                                                        commitTimeout={1} json="entryPasses" style={{marginTop: 15}} 
                                                        initialValue={this._initialEntryPasses} label="Initial Entry Passes" 
                                                        onFieldChange={this._entryPassesChanged} changedBackgroundColor='white'/>
                                </div>

                                          
                        </Paper>
                    </Grid> : null}
                    
                     <Grid item xs={12} md={6} lg={4}>
                    
                         <Paper style={this.styles.paper}>
                            <Typography variant="body2" style={this.styles.paperLabel}>Other Preferences</Typography> 
                            <FormControl component="fieldset" style={this.styles.form} >  
                                <FormControlLabel control={<Checkbox checked={this.state.soundEffectsEnabled} onChange={this._enableSoundEffects} color="primary"/>} label="Enable Sound Effects"/>
                            </FormControl>
                        </Paper>
                    </Grid>
                    
                    
                </Grid>

            </div>
        );
    }

   
}


export default withCookies(PreferencesPage);
