import { DateUtils } from 'react-frontend-utils'


const TypeOrderedEnums = [
        'Check In',
        'Check Out',
        'Admit Guests',
        'Deny',
        'All Out',
        'Manual',
        'Ticket'
    ];
    

const ModeOrderedEnums = ['Lookup', 
                          'Barcode/Manual', 
                          'RFID',
                          'App',
                          'Bluetooth'];


export class LogEntry {
    
    time;                    //Date
    membershipID = "";       //String   
    memberID = 0;            //Int
    name = "";               //String
    location = "";           //String
    
    type;                    //Type   IN ,OUT, GUEST, DENY, BULK_OUT, MANUAL
    mode = "";               //Mode    LOOKUP, BARCODE, RFID
    
    guestsAdmitted = 0;      //Int
    info = "";               //String
    
    
    
    constructor(userJson) {
        
        if (userJson) {

            this.time = DateUtils.parseJsonDate(userJson.time);
            
            if (userJson.membershipID)
                this.membershipID = userJson.membershipID;
            
            if (userJson.memberID)
                this.memberID = parseInt(userJson.memberID);
            
            if (userJson.memberName)
                this.name = userJson.memberName; 
            
            if (userJson.location)
                this.location = userJson.location;
            
            this.type = TypeOrderedEnums[userJson.type];
            
            if (userJson.mode !== null) 
                this.mode = ModeOrderedEnums[userJson.mode];
            
            if (userJson.guestsAdmitted)
                this.guestsAdmitted = parseInt(userJson.guestsAdmitted);
            
            if (userJson.info)
                this.info = userJson.info;
            
        }
    }
    
    isEntryType = () => {
        return this.type === 'Check In' ||
               this.type === 'Admit Guests' ||
               this.type === 'Ticket';
    }
    
    entryCount = () => {
        if (this.type === 'Check In')
            return 1;
        else if (this.type === 'Admit Guests' || this.type === 'Ticket')
            return this.guestsAdmitted;
        else
            return 0;
    }
    
}

