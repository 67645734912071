import React from 'react';
import { withCookies } from 'react-cookie';
import Chart from "react-apexcharts";

import Autocomplete from '@material-ui/lab/Autocomplete';
import BarChartIcon from '@material-ui/icons/BarChart';
import { TextField, Paper, Tooltip, Typography, Button, Grid } from '@material-ui/core'

import { ThemeColors } from '../Theme'
import { PP } from '../models/PP'
import { InvoiceRequest, invoiceRequestTooltip } from '../models/InvoiceRequest'

import { RestComponent } from 'react-frontend-utils' 
import { DateUtils, Currency, ManageDateField, SummaryWidget, StyledTooltip } from 'react-frontend-utils'




export class BillAnalysisTab extends RestComponent {
  
    
    styles = {
        paper: {
            width: '100%',
            height: '100%'
        },
        paperLabel: {
            marginLeft: 15,
            marginRight: 15,
            marginBottom: 5,
            color: 'gray',
            fontSize: '9pt',
            flexGrow: 1
        }
    }
    

    _startDateFieldRef = React.createRef();
    
    _currencySymbol = Currency.symbolForISO(PP.currency);
    
    //The format to apply to time values
    _dateFormat = () => {
        
        switch (PP.getDateFormat()) {
            case DateUtils.DateFormatType.ISO8601:
                return "yyyy MMM";

            case DateUtils.DateFormatType.US:
                return "MMM yyyy"; 
            
            default:
                return "unhandled";
        }
    }
    
    
    _yearMonthFormat = () => {
        
        switch (PP.getDateFormat()) {
            case DateUtils.DateFormatType.ISO8601:
                return "yyyy MMM";

            case DateUtils.DateFormatType.US:
                return 'MMM yyyy'; 
                
            default:
                return "unhandled";
        }
    }
    
    _datetimeFormatter = () => {
        return {
            year: 'yyyy',
            month: this._yearMonthFormat(),
            day: "MMM"
        };
    }
 
    
    _standardChartOptions = {
        stacked: false,
        zoom: {
            enabled: false
        },
        toolbar: {
            show: false
        },
        animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 600,
            animateGradually: {
                enabled: false
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
        }
    };
    
    _revenueChartOptions = {
      
        chart: this._standardChartOptions,
        colors: [ThemeColors.membershipsGreen], 
        stroke: {
            curve: 'stepline',
            width: 2
        },
        markers: {
            size: 2,
            strokeWidth: 0
        },
        dataLabels: {
            enabled: false         
        },
        xaxis: {
            type: 'datetime',
            labels: {
                datetimeUTC: true,
                datetimeFormatter: this._datetimeFormatter(true)
            }
        },
        yaxis: {
            labels: {
                formatter: (value) => { return this._currencySymbol + Currency.round(value, 0); }
            }
        },
        tooltip: {
            x: {
                format: this._dateFormat()
            },
            y: {
                formatter: (value) => { return this._currencySymbol + Currency.round(value); } 
            }
        }
    };
   
   
    _paidChartOptions = {
       
        chart: this._standardChartOptions,
        colors: [ThemeColors.redeemIndigo],
        xaxis: {
            max: 0,     //computed dynamically
            min: 0,     //computed dynamically
            title: {
                text: "Days"
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "90%"
            }
        },
        dataLabels: {
            enabled: false         
        },
        tooltip: {
            x: {
                formatter: function(value, opts) {
                    if (value === 0)
                        return "Paid on Time";
                    if (value === 1)
                        return "1 day late";
                    else if (value > 1)
                        return value + " days late";
                    else if (value === -1)
                        return "1 day early";
                    else if (value < -1)
                        return Math.abs(value) + " days early";
                }
            },
            y: {
                title: {
                     formatter: function(seriesName) { 
                         return "Invoice Count";
                     }
                }
            }
        },
        annotations: {
            xaxis: [
              {         //Paid Early
                x: 0,
                strokeDashArray: 1,
                borderColor: 'black',
                fillColor: 'black',
                opacity: 0,
                label: {
                  offsetY: -15,
                  offsetX: -52,
                  borderWidth: 0,
                  style: {
                    color: 'black',
                    fontSize: '14px'
                  },
                  orientation: 'horizontal',
                  text: '⭠ Paid Early'
                }
              },
              {      //Paid Late
                x: 0,
                strokeDashArray: 1,
                borderColor: 'black',
                fillColor: 'black',
                opacity: 0,
                label: {
                  offsetY: -15,
                  offsetX: 50,
                  borderWidth: 0,
                  style: {
                    color: 'black',
                    fontSize: '14px'
                  },
                  orientation: 'horizontal',
                  text: 'Paid Late ⭢'
                }
              }
            ]
          }
    };
  
    _aggregatePieChartOptions = {
      
        chart: this._standardChartOptions,
        labels: [],
        colors: ThemeColors.pieChartColors,

        dataLabels: {
            formatter: function (val, opts) {
                return Currency.symbolForISO(PP.currency) + Currency.round(opts.w.config.series[opts.seriesIndex]);  //use the count value itself, not the percent
            }
        },  
        tooltip: {
            y: {
                formatter: function(value, opts) {
                    return Currency.symbolForISO(PP.currency) + Currency.round(value);
                }
            }
        }
    }
    
    

    constructor(props) {
        super(props);
                      
        
        this.state.startDateSearch = null;
        this.state.invoiceRequests = [];    //array of InvoiceRequests, from last fetch
        this.state.selectedInvoiceRequest = null;
        
        this.state.hasSearched = false;  //has executed search (display button) at least once
                        
        this.state.aggregatePieData = null;    
        this.state.paidChartData = null;
        this.state.revenueChartData = null;
        this.state.collectedAmount = 0.0;
        this.state.outstandingAmount = 0.0;
        
    }
    
    /**
     * When the page loads, immediately fetch the first page of invoices
     */
    componentDidMount() {
        super.componentDidMount();

        //fetch all Invoice Requests
        this.incrementBusy();
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/billing/requests", {}, this._fetchInvoiceRequestsCallback, this._fetchErrorCallback); 
    }
    
   
    //Callback for fetching Invoice Requests - JSON response is an array of InvoiceRequest objects
    _fetchInvoiceRequestsCallback = (response) => {
        if (response) {            
            this.state.invoiceRequests = response.map((ir) => new InvoiceRequest(ir));
        }            
        this.decrementBusy();
    }

    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.setBusy(false);
    }
  
 
  
    _getSearchString = () => {
        let search = "";
        
        if (this.state.startDateSearch)
            search += "createdAfter=" + this.state.startDateSearch + "&";
        if (this.state.selectedInvoiceRequest)
            search += "requestID=" + this.state.selectedInvoiceRequest.id + "&";
      
        if (search)
            search = "?" + search.substring(0, search.length-1);  //remove trailing &
        
        return search;
    }
  
    
    _search = () => {
 
        const search = this._getSearchString();
        
        this.incrementBusy();       
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/billing/analysis" + search, {},
                             this._searchResponse, this._fetchErrorCallback); 
   
    }
    
    
    _searchResponse = (response) => {
        if (response) {        
            const analysis = response;  //no object here - just use json directly
    
            
            //Create the aggregate pie labels
            const labels = analysis.aggregate.map(stat => stat.name + " (" + stat.count + ")");  
            
            //Note in order to re-render properly the outermost items must be spread, apex charts needs to see the object changed
            this._aggregatePieChartOptions = {...this._aggregatePieChartOptions, labels: labels};
            
        
            //Specify the aggregate pie data series
            const aggregatePieData = analysis.aggregate.map(stat => stat.amount);
            

            const revenueSeries = [];
            let runningTotal = 0;
            for (let entry of analysis.revenue) {
                runningTotal += entry.amount;
                revenueSeries.push([entry.date, runningTotal]);
            }
            

            const revenueChartData = [
                {
                  name: "Accumulated Revenue",
                  data: revenueSeries
                }
            ];


            const paidLateSeries = []; 
            let xmax = 10;  //absolute value of the max day late (min to display - 10 days)
            let ymax = 0;  //value of the max count
            
            for (let entry of analysis.paid) {
                paidLateSeries.push([entry.dayLate, entry.count]);
                if (Math.abs(entry.dayLate) > xmax)
                    xmax = Math.abs(entry.dayLate);
                
                if (entry.count > ymax)
                    ymax = entry.count;
            }    

            const paidChartData = [
                {
                    name: "Payment",
                    data: paidLateSeries
                }
            ];
            
            xmax = 10 * Math.ceil(xmax / 10.0);  //to nearest 10
            
            const tickAmount = (xmax * 2)/10;  //ticks every 10 
            
            //Note in order to re-render properly the outermost items must be spread, apex charts needs to see the object changed
            this._paidChartOptions = {...this._paidChartOptions, xaxis: {...this._paidChartOptions.xaxis, max: xmax, min: -xmax, tickAmount: tickAmount}};
          
            this.setState({aggregatePieData: aggregatePieData, 
                          paidChartData: paidChartData,
                          revenueChartData: revenueChartData,
                          collectedAmount: analysis.collectedAmount, 
                          outstandingAmount: analysis.outstandingAmount, 
                          hasSearched: true});        
        }
        this.decrementBusy();
    }
   
  
    _startDateChanged = (json, val) => {
        const today = DateUtils.startOfToday();

        if (val !== null && (new Date(val) > today)) {  
            this._startDateFieldRef.current.change(PP.dateFormat(today), true); 
            return;
        }
        
 
        this._startDateFieldRef.current.accept();
        this.setState({startDateSearch: val});  //ok, set our state value

    }
    
    
    _dateParseError = (label, error) => {
        this.showConfirmAlert("Error in Date Field", error, 'red');
    }
    
    
    _selectNewInvoiceRequest = (event, newIR) => {
        this.setState({selectedInvoiceRequest: newIR});
    }
    
 
                                
        
    render() {
        
        const invoiceRequestWidget = <div style={{display: 'flex', alignItems: 'center', marginLeft: 20}}>      
                                        <Autocomplete
                                            size='small'
                                            value={this.state.selectedInvoiceRequest}
                                            onChange={this._selectNewInvoiceRequest}
                                            options={this.state.invoiceRequests}
                                            getOptionLabel={(option) => option.subject}
                                            fullWidth
                                            blurOnSelect
                                            openText="InvoiceRequests"
                                            renderInput={(params) => <TextField {...params} label="Invoice Request" variant="outlined" InputLabelProps={{ shrink: true }} />}
                                        />
                                        <StyledTooltip title={invoiceRequestTooltip(this.state.selectedInvoiceRequest)}>
                                            <Typography variant="body2" style={{fontSize: 16, fontWeight: 'bold', color: this.state.selectedInvoiceRequest ? 'blue' : 'lightGray', marginLeft: 8, marginRight: 8}}>ⓘ</Typography>
                                        </StyledTooltip>  
                                    </div>;
                                
                                
        const infoWidget =  <Typography variant="body2" style={{fontSize: 12, fontWeight: 'bold', color: 'blue', marginLeft: 8}}>ⓘ</Typography>;

        let pieMargin = 'auto';
        if (window.innerWidth > 1000)
            pieMargin = (window.innerWidth/100).toFixed(0) + "%";  //scale the margin as the window grows larger
        
        
        return (
            <div>
                {this.getConfirmAlertComponent()}
               

                <Paper style={this.styles.paper}>
                    <Typography variant="body2" style={this.styles.paperLabel}>Analyze Invoices</Typography>  
                    
                    <Grid container direction="row" spacing={2} style={{padding: 10}}>

                        <Grid item lg={3} md={4} sm={6} xs={12}>
                      
                            <ManageDateField hasNever={true} neverText="Any" json="startDateField" maxYear={this._maxYear}
                                            onFieldChange={this._startDateChanged} 
                                            onParseError={this._dateParseError}
                                            label="Created On/After" initialValue="Any"
                                            ref={this._startDateFieldRef}
                                            style={{marginTop: -6}}
                                            changedBackgroundColor='white'
                                            dateFormat={PP.getDateFormat()}
                                            calendarColor={ThemeColors.calendarColor}/>

                        </Grid>
                        
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            {invoiceRequestWidget}
                        </Grid>
                    

                        <Grid item lg={3} md={4} sm={12} xs={12}>
                                             
                            <Tooltip title="Analyze invoice data for the specified parameters">
                               <Button fullWidth onClick={this._search} variant="outlined" color='primary' style={{marginLeft: 20, maxWidth: 200}} component="label" startIcon={<BarChartIcon />}>
                                   Analyze
                               </Button>
                            </Tooltip>
                            
                        </Grid>
                
                    </Grid>
                 </Paper>
                
                {this.state.isBusy ? this.getBusyComponent('center', {marginTop: 20}) : (<div style={{paddingBottom: 60}}/>)}

                <div style={{marginTop: 10}}/>
                
                {this.state.hasSearched ? 
                    <div>
                        
                        <Grid container direction="row" spacing={2} style={{padding: 10}}>
                            <Grid item xs={6}>
                                <SummaryWidget label={"Collected"} 
                                        value={this._currencySymbol + Currency.round(this.state.collectedAmount)}
                                        tooltip="The totals of collected invoices (paid and settled)"
                                        borderColor={ThemeColors.depositGreen}/>
                            </Grid>                  
                            <Grid item xs={6}>
                                <SummaryWidget label={"Outstanding"} 
                                        value={this._currencySymbol + Currency.round(this.state.outstandingAmount)}
                                        tooltip="The totals of unpaid invoices (open and overdue)"
                                        borderColor={'red'}/>
                            </Grid>         
 
                        </Grid>
                        
                        {this.state.aggregatePieData ?
                             <div style={{marginTop: 40, marginBottom: 40, marginLeft: pieMargin, marginRight: pieMargin}}>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <Typography variant="button" align='center' style={{fontSize: 16}}>Collection Aging</Typography>  
                                </div>
                                <Chart
                                    options={this._aggregatePieChartOptions}
                                    series={this.state.aggregatePieData}
                                    type="pie"
                                    height={window.innerHeight*0.50}
                                />  
                            </div> 
                            : null
                        }
                        
                        {this.state.revenueChartData ? 
                            <div style={{display: this.state.revenueChartData.length > 0 ? 'block' : 'none', cursor: "crosshair"}}>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <Typography variant="button" align='center' style={{fontSize: 16}}>Accumulated Monthly Revenue</Typography>  
                                    <Tooltip title="Monthly accumulated revenue over time from paid and settled invoices">
                                        {infoWidget}
                                    </Tooltip>
                                </div>
                                <Chart
                                    options={this._revenueChartOptions}
                                    series={this.state.revenueChartData}
                                    type="area"
                                    height={window.innerHeight*0.40}
                                />  
                            </div>
                            : null
                        }
                        
                        {this.state.paidChartData ? 
                            <div style={{marginTop: 40, display: this.state.paidChartData.length > 0 ? 'block' : 'none', cursor: "crosshair"}}>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <Typography variant="button" align='center' style={{fontSize: 16}}>Payment Promptness</Typography>  
                                    <Tooltip title="Distribution of how early or late invoices are paid, in days. Points left of zero are paid early. Points right of zero are paid late.">
                                        {infoWidget}
                                    </Tooltip>
                                </div>
                                <Chart
                                    options={this._paidChartOptions}
                                    series={this.state.paidChartData}
                                    type="bar"
                                    height={window.innerHeight*0.40}
                                />  
                            </div>
                            : null
                        }
                        
                        
                    </div>
                : null}
                       
                <div style={{marginTop: 40}}/>

            </div>

        );

    }
}


export default withCookies(BillAnalysisTab);

