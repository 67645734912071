
export const RestrictionType = {
    MEMBER:             {ordinal: 0, label: "Single Member"},
    MEMBERSHIP:         {ordinal: 1, label: "Membership"},
    MEMBERSHIP_TYPE:    {ordinal: 2, label: "Membership Type"},
    GUESTS:             {ordinal: 3, label: "Maximum Guests"}
};



/**
 * This class represents a Restriction, received from a JsonRestriction
 */
export class Restriction {
    
    id   
    location;
    type;           //RestrictionType enum on incoming, ordinal on outgoing
    subject;
    
    membership;
    memberID;
    maxGuests = null;
    
    
   
    constructor(rJson) {
        if (rJson) {
            
            this.id = rJson.id;
            this.location = rJson.location;
            this.subject = rJson.subject; 

            if (rJson.type != null) {
                for (let index in RestrictionType) {
                    const type = RestrictionType[index];

                    if (type.ordinal === rJson.type)
                        this.type = type;
                }    
                
                if (this.type) {
                    switch (this.type) {
                        case RestrictionType.MEMBER:
                            let split = this.subject.split("-");
                            this.membership = split[0];
                            this.memberID = parseInt(split[1]);
                            break;

                        case RestrictionType.MEMBERSHIP:
                        case RestrictionType.MEMBERSHIP_TYPE:
                            this.membership = this.subject;
                            break;

                        case RestrictionType.GUESTS:
                            this.maxGuests = parseInt(this.subject);
                            break;
                        default:
                            console.log("Unhandled Restriction Type");     
                    }     
                }
            }
            
                     
            
            
        }       
    }
    
    //If a restriction matches, return who was restricted, otherwise return null
    isRestricted = (membership, memberID) => {
        
        switch (this.type) {
            case RestrictionType.MEMBER:
                return membership.id === this.membership && memberID === this.memberID;

            case RestrictionType.MEMBERSHIP:
                return membership.id === this.membership;
            
            case RestrictionType.MEMBERSHIP_TYPE:
                return membership.type === this.membership;  //type stored here

            case RestrictionType.GUESTS:
                return false;  //we don't check guests here

            default:
                console.log("Unhandled Restriction Type");
                return true;
        } 
    }

    
  
    static create(location, type, subject) {
        const r = new Restriction();
        r.location = location;
        r.type = type;
        r.subject = subject;
        
        return r;
    }
    
 
}
     
