import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import queryString from 'query-string';

import Home from './routes/Home';
import PatronPortal from './routes/PatronPortal'
import PatronPortalSearch from './routes/PatronPortalSearch'
import Terms from './routes/Terms'
import { ApiDoc, ApiManual } from './routes/ApiDoc'

//This is the latest notification. If a user profile has a notify index less than this, they are shown all the notifications up to
//this current one.  The notification messages are in the public /notify directory, numbered by index. For example: 1.html, 2.html
export const NOTIFY_INDEX = 13;


function App() {
  return (
    <div className="App">
      <CookiesProvider>
        <Router>
            <Switch>
            
                <Route path='/' exact={true} component={Home}/>

                <Route path='/membership' exact={true} render={(props) => {
                    let query = queryString.parse(props.location.search);

                    if (!query.id) {  //no specific database
                        return <Home membershipID={""} database={query.database}/>;    //we want to visit the page without a specific id
                    }
                    else {
                        return <Home membershipID={query.id} database={query.database}/>;
                    }
                }}/>
              
                     
                <Route path='/patron' exact={true} render={(props) => {
                    let query = queryString.parse(props.location.search);

                    if (!query.facility) {  //no specific database - just send them Home
                        return <Home/>;
                    }
                    else {
                        return <PatronPortal facility={query.facility} referrer={query.referrer} qr={query.qr}/>;
                    }
                }}/>

                <Route path='/patron/terms' exact={true} render={() => {return <Terms source="/publicdoc/marketterms.html"/>;}} />
                <Route path='/patron/billing/terms' exact={true} render={() => {return <Terms source="/publicdoc/billingterms.html"/>;}} /> 
                        
                <Route path='/developer/api/doc' exact={true} component={ApiDoc}/>
                <Route path='/developer/api/manual' exact={true} component={ApiManual}/>

            </Switch>
        </Router>
      </CookiesProvider>
    </div>
  );
}

export default App;


//Use this to send to an external website (safely!) in a new tab
export function ExternalLink(props) {
    
    return <a href={props.href} target="_blank" rel="noopener noreferrer">{props.target}</a>;
    
}


//Be careful here! XSS attack possible with url
export function OpenInNewTab(url) {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) 
        newWindow.opener = null;
    
}