import React from 'react'
import NewWindow from 'react-new-window'

import { Typography } from '@material-ui/core'

import { PP } from '../models/PP'
import { DateUtils } from 'react-frontend-utils'

const PRINT_DELAY = 3000; //ms

export const DailyLogWindow = (props) => {
 
    const styles = {
        table: {
            borderCollapse: 'collapse',
            width: '100%',
            marginBottom: 20,
            marginTop: 10
        },
        tableHeader: {
            borderBottom: '2px solid gray',
            textAlign: 'left',
            padding: 5,
            paddingRight: 10,
            marginBottom: 8,
            fontSize: 11,
            fontWeight: 'normal',
            textTransform: 'uppercase'
        },
        tableData: {
            borderBottom: '1px solid lightGray',
            textAlign: 'left',
            fontSize: 11,
            padding: 5,
            paddingRight: 10,
            paddingTop: 6,
            paddingBottom: 6
        }
    };


    const startTime = props.date ? props.date : DateUtils.startOfToday();  //use the date supplied or today if null
 
    const endTime = new Date(startTime.getTime());  //copy start date
    endTime.setHours(23, 59, 59, 0);  //set to the end of the day


    //Filter out only entries from the dateForLog
    const dailyLogEntries = props.logEntries.filter(entry => entry.time >= startTime && entry.time <= endTime && entry.isEntryType());
    
    //Sort by earliest to latest
    dailyLogEntries.sort((a, b) => { return a.time - b.time; });
    
    const header = "Daily Check-In Log for " + PP.selectedDatabase + " on " + PP.dateFormat(startTime);
      
    let total = 0;
    for (let entry of dailyLogEntries) {
        total += entry.entryCount();
    }
      
      
    return (
        <NewWindow title="Daily Log" name="Daily Log" 
                   onUnload={props.windowClosed} onBlock={props.windowBlocked}
                   onOpen={(window) => {setTimeout(() => {window.print();}, PRINT_DELAY);}}
                   features={{width: 1000, height: 768}}>
                   
            <Typography variant="h6" style={{textAlign: 'center', marginBottom: 20}}>{header}</Typography>
            
             <table style={styles.table}>
                <thead>
                    <tr>
                        <th style={styles.tableHeader}>Time</th>
                        <th style={styles.tableHeader}>Membership</th>
                        <th style={styles.tableHeader}>Name</th>
                        <th style={styles.tableHeader}>Location</th>
                        <th style={styles.tableHeader}>Count</th>
                        <th style={styles.tableHeader}>Activity</th>
                    </tr>
                </thead>
                <tbody>
                    {dailyLogEntries.map((entry, index) => 
                        <tr key={index}> 
                            <td style={styles.tableData}>{PP.checkInTimeFormat(entry.time)}</td>
                            <td style={styles.tableData}>{entry.membershipID ? (entry.membershipID + "-" + entry.memberID) : ""}</td>
                            <td style={styles.tableData}>{entry.name}</td>
                            <td style={styles.tableData}>{entry.location}</td>
                            <td style={styles.tableData}>{entry.entryCount()}</td>
                            <td style={styles.tableData}>{entry.type}</td>
                        </tr>
                    )}
                </tbody>
            </table>
            
        <Typography variant="body1" style={{marginTop: 20, marginLeft: 10}}>{total + " total check-ins on " + PP.dateFormat(startTime)}</Typography>

        </NewWindow>
    );
};
    