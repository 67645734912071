import React, { Component, Fragment } from 'react';
import { TextField, FormControlLabel, Switch, Typography, Tooltip, IconButton } from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import ActionSelectPopover from '../components/ActionSelectPopover'
import { PP } from '../models/PP'
import { ThemeColors } from '../Theme'

/**
 * The MemberListToolbar is part of the MemberList table. The caller must pass in props:
 *  a function called "tableHasSelectedRows" which is true when a row is selected
 *  a function called "onSearchChange" when the search field text, which accepts two search strings (name and membership)
 *  a function called "onCheckedInSwitchChanged" when the checked in switch changes state
 *  a function called "onActionFilterSwitchCallback" when the action filter switch changes state, passes the new action filter value (0 = no filters)
 *  a function called "foundRecords" which is called whenever the search field text changes to see if the search text field should go red (nothing found)
 *  a function called "onCheckInButtonPress" which is called whenever the check in button is pressed
 *  a function called "onEmailButtonPress" which is called whenever the email button is pressed
 *  a function called "canCheckIn" which returns true if the check in button should be enabled
 */

export default class MemberListToolbar extends Component {

    styles = {
        textfield: {
            marginBottom: 10,
            marginLeft: 10
        },
        switchLabel: {
            fontSize: 12
        },
        formLabel: {
        },
        iconButton: {
            marginTop: 8,
            marginRight: 5,
            marginLeft: 5
        }
     
    }

    state = {
        searchNameString: "",
        searchOtherString: "",
        showCheckedIn: false,
        showOnlyFirst: false,
        showLimited: false,
        actionFilterOn: false,
        showActionFilterPopup: false
    }
 
     _searchChangedCallback;
     _isCheckedInFilterCallback;
     _onlyFirstFilterCallback;
     _limitedFilterCallback;
     _actionFilterValueCallback;
     _foundRecords;
     _rowsAreSelected;
     _checkInButtonPressed;
     _emailButtonPressed;
     _canCheckIn;
     _filterActionsValue = 0;
    
    constructor(props) {
        super(props);
        this._searchChangedCallback = props.onSearchChange;
        this._isCheckedInFilterCallback = props.onCheckedInSwitchChanged;
        this._onlyFirstFilterCallback = props.onOnlyFirstSwitchChanged;
        this._limitedFilterCallback = props.onLimitedSwitchChanged;
        this._actionFilterValueCallback = props.onActionFilterSwitchCallback;
        this._foundRecords = props.foundRecords;
        this._rowsAreSelected = props.tableHasSelectedRows;
        this._checkInButtonPressed = props.onCheckInButtonPress;
        this._emailButtonPressed = props.onEmailButtonPress;
        this._canCheckIn = props.canCheckIn;
    }
 
    _searchNameFieldChanged = (event) => {
        
        const searchString = event.target.value;
        this.setState({searchNameString: searchString})
        this._searchChangedCallback(searchString, this.state.searchOtherString); 
    }
    
    _searchOtherFieldChanged = (event) => {
        
        const searchString = event.target.value;
        this.setState({searchOtherString: searchString})
        this._searchChangedCallback(this.state.searchNameString, searchString); 
    }
    
    _toggleCheckedIn = (event) => {
        this._isCheckedInFilterCallback(!this.state.showCheckedIn)
        this.setState((prevState) => ({showCheckedIn: !prevState.showCheckedIn}));
    }
    
    _toggleOnlyFirst = (event) => {
        this._onlyFirstFilterCallback(!this.state.showOnlyFirst)
        this.setState((prevState) => ({showOnlyFirst: !prevState.showOnlyFirst}));
    }
    
    _toggleLimited = (event) => {
        this._limitedFilterCallback(!this.state.showLimited)
        this.setState((prevState) => ({showLimited: !prevState.showLimited}));
    }
    
    //Called when the Action filter switch is changed
    _toggleActionFilter = (event) => {
        const checked = event.target.checked;
        
        if (checked)
            this.setState({actionFilterOn: true, showActionFilterPopup: true});  //show the popup, to prompt for which filters to select
        else {
            this.setState({actionFilterOn: false, showActionFilterPopup: false});  //filter off, hide popup, value = 0
            this._actionFilterValueCallback(0);  //tell the callback no filters
        }
    }
    
    //Callback when the filters popup has selected "OK"
    _filtersSelectedCallback = (filtersVal) => {
        this._filterActionsValue = filtersVal;
        this.setState({actionFilterOn: filtersVal > 0, showActionFilterPopup: false}); //switch off if value is 0, otherwise on, hide popup
        this._actionFilterValueCallback(filtersVal);  //tell the callback what the filter value is
    }
    
    _filtersCancelledCallback = () => {
        this.setState({actionFilterOn: false, showActionFilterPopup: false});  //filter off, value = 0
        this._actionFilterValueCallback(0); //tell the callback no filters
    }
    
    

    render() {
        const filterColor = this._foundRecords() ? 'primary' : 'secondary';

        const canCheckIn = this._rowsAreSelected() && this._canCheckIn();
        const checkInButtonColor = canCheckIn ? 'green' : '#DDDDDD';
        
        const canEmail = PP.user.isAdministrator();    //only administrators can send email

        return (
            <Fragment>
                <ActionSelectPopover isOpen={this.state.showActionFilterPopup} initialValue={this._filterActionsValue} label="Filter on Selected Actions"
                                     okCallback={this._filtersSelectedCallback} 
                                     cancelCallback={this._filtersCancelledCallback} />
    
    
                <Tooltip title={"Check in/out the selected member"}>
                    <span>
                        <IconButton aria-label="check in" disabled={!canCheckIn} style={{...this.styles.iconButton, color: checkInButtonColor}} onClick={this._checkInButtonPressed}>
                            <CheckCircleOutlineIcon/>
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip title={"Send email to filtered Memberships"}>
                    <IconButton edge="start" disabled={!canEmail} onClick={this._emailButtonPressed} style={{...this.styles.iconButton}}>
                        <MailOutlineIcon style={{color: !canEmail ? 'lightGray' : ThemeColors.mailColor}} />
                    </IconButton>
                </Tooltip>
                <Tooltip title={"Filter the table by any matching part of a Member's name"}>
                    <TextField label="Search Names" type="search" value={this.state.searchNameString} onChange={this._searchNameFieldChanged} color={filterColor} margin='dense' style={this.styles.textfield} InputLabelProps={{ shrink: true }} />            
                </Tooltip>
                <Tooltip title={"Filter the table by any matching part of any Membership text field"}>
                    <TextField label="Search Memberships" type="search" value={this.state.searchOtherString} onChange={this._searchOtherFieldChanged} color={filterColor} margin='dense' style={this.styles.textfield} InputLabelProps={{ shrink: true }} />
                </Tooltip>
                <Tooltip title={"Filter the table by only Members currently checked in"}>
    
                    <FormControlLabel style={this.styles.formLabel} control={<Switch size="small" color='primary' checked={this.state.showCheckedIn} onChange={this._toggleCheckedIn} />} labelPlacement="top"
                                      label={<Typography variant="body2" style={this.styles.switchLabel} color="textSecondary">Checked In</Typography>}/>
                </Tooltip>
                <Tooltip title={"Show only the first Member of each Membership"}>
    
                    <FormControlLabel style={this.styles.formLabel} control={<Switch size="small" color='primary' checked={this.state.showOnlyFirst} onChange={this._toggleOnlyFirst} />} labelPlacement="top"
                                      label={<Typography variant="body2" style={this.styles.switchLabel} color="textSecondary">Only First</Typography>}/>
                </Tooltip>
                <Tooltip title={"Show only limited Members"}>
    
                    <FormControlLabel style={this.styles.formLabel} control={<Switch size="small" color='primary' checked={this.state.showLimited} onChange={this._toggleLimited} />} labelPlacement="top"
                                      label={<Typography variant="body2" style={this.styles.switchLabel} color="textSecondary">Limited</Typography>}/>
                </Tooltip>
                <Tooltip title={"Filter the table by one or more checked Member actions"}>
    
                    <FormControlLabel style={this.styles.formLabel} control={<Switch size="small" color='primary' checked={this.state.actionFilterOn} onChange={this._toggleActionFilter} />} labelPlacement="top"
                                      label={<Typography variant="body2" style={this.styles.switchLabel} color="textSecondary">Pending Actions</Typography>}/>
                </Tooltip>
        
            </Fragment>
        );
    }
}
