import React from 'react';
import { withCookies } from 'react-cookie';

import { PP } from '../models/PP'

import { ThemeColors } from '../Theme'

import { LogEntry } from '../models/LogEntry'
import { RestComponent } from 'react-frontend-utils' 
import { DataTable, DataTableComponent } from 'react-frontend-utils'
import LogTableToolbar from '../components/LogTableToolbar'
import { DailyLogWindow } from '../components/DailyLogWindow'

import { DateUtils, TextEntryPopover } from 'react-frontend-utils'


/**
 * The Log tab shows a list of all LogEntries
 * On mounting of the component, the list of all entries is fetched from the server.
 */



function indexHeaderCellProps() {
    return {style: {...DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor).style, width: 20}};
}
function indexCellProps() {
    return {style: {...DataTable.firstCellProps().style, width: 20}};
}



export class LogTab extends RestComponent {
  
    //An array describing each column

    columns = [
        
        //Index column - data is a Int
        {name: "index", label: "#",
            options: {filter: false, sort: true, setCellHeaderProps: indexHeaderCellProps, setCellProps: indexCellProps}
        },
        
        //Date column - data is an object containing a Date
        //Sorting is done comparing the date
        //Rendering is the checkInTimeFormat applied to the date
        {name: "time", label: "Time",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps,
                        customBodyRender: (val) => {const text = PP.checkInTimeFormat(val);
                                                    return (
                                                         <div>{text}</div>                                              
                                                    ); 
                         }
                     }
        },
        //Membership ID column - data is a String
        {name: "membershipID", label: "Membership ID",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps, customBodyRender: DataTable.longStringCellRenderer}
        },
        //Member ID column - data is an Int
        {name: "memberID", label: "Member ID",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps}
        },
        //Items below are all Strings
        {name: "name", label: "Name",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps, customBodyRender: DataTable.longStringCellRenderer}
        },
        {name: "location", label: "Location",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps, customBodyRender: DataTable.longStringCellRenderer}
        },
        {name: "type", label: "Type",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps,
                        customBodyRender: (val) => {let text = String(val);
                                                    let style = null;

                                                    if (val === 'Deny') 
                                                        style= {color: 'white', backgroundColor: 'red'};   
                                                    else if (val === 'Manual')
                                                        style= {backgroundColor: ThemeColors.lightBlue};   

                                                    return (<div style={{...style, paddingLeft: 2, borderRadius: 2}}>{text}</div>); 
                                                   }
                     }

        },
        {name: "mode", label: "Mode",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps}
        },
        {name: "guests", label: "Guest Count",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.cellProps}
        },
        {name: "info", label: "Other Info",
            options: {filter: false, sort: true, setCellHeaderProps: () => DataTable.headerProps(ThemeColors.tableHeaderBackgroundColor), setCellProps: DataTable.lastCellProps, customBodyRender: DataTable.longStringCellRenderer}                   
        }
    ];



         
    _searchText = "";           //the currently displayed search  text
 

    //Called by the custom Toolbar, to determine what color it should be (if no found records, go red
    searchFoundRecords = () => {
       if (this.state.tableData.length === 0) //no records loaded, don't color the text to indicate search failed
           return true; 
       
       return this.state.filteredTableData.length !== 0;
    }
    
    
    

    constructor(props) {
        super(props);
        
        this.state.manualLogEntryPromptOpen = false;
        
        this.state.tableData = [];         //all the table data
        this.state.filteredTableData = []; //filtered data from search, filters
        
        this.state.dailyLogPopupOpen = false;
        this.state.datePicked = null;
        
        this.state.tableOptions =  {filter: false,
                                    search: false,
                                    print: false,
                                    download: false,
                                    selectableRowsOnClick: false,
                                    selectableRows: 'none',
                                    resizableColumns: false,
                                    rowHover: false,
                                    elevation: 0,
                                    viewColumns: false,
                                    selectToolbarPlacement: 'none',
                                    setRowProps: (row, dataIndex, rowIndex) => this._setRowProps(row, dataIndex, rowIndex)
                                    };
        
        
    }
    
    
    
    /**
     * When the page loads, fetch all members and memberships for the current database
     */
    componentDidMount() {
        super.componentDidMount();
        
        if (PP.selectedDatabase) {
            this._fetchAllLogEntries();
        }
    }
    
    _fetchAllLogEntries = () => {
        this.incrementBusy();
     
        //fetch all log entries
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/log", {}, this._fetchDoneCallback, this._fetchErrorCallback); 
    }
    
    //Called by the search field from the Custom toolbar, when the search field text changes
    searchFieldChanged = (searchText) => {
        this._searchText = searchText;
        this._filterTableData();
    }
    
    printDailyLog = (date) => {
        
        if (this.state.dailyLogPopupOpen) {
            this.showConfirmAlert("Already Open", "The daily log is already open in another browser window", 'red');
            return; 
        }
        
        this.setState({dailyLogPopupOpen: true, datePicked: date});
    }
    
    manualLogEntry = () => {
        this.setState({manualLogEntryPromptOpen: true});   
    }
    
    _manualLogEntryOkCallback = (text) => {
          
        if (!text) {
            this.showConfirmAlert("Error", "Log entry is empty", 'red');
            return;
        }
          
        console.log("Manual Log Entry Text: " + text);
        this.setState({manualLogEntryPromptOpen: false});
        
        this.incrementBusy(true);
        
        const endPath = "?location=" + encodeURIComponent(PP.getLocation());
     
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/log", 
                            {method: "POST", body: text}, 
                            () => {this.decrementBusy(); this._fetchAllLogEntries();}, this._fetchErrorCallback, endPath); 

    }
    
    deleteAll = (date) => {
        const dateStr = PP.dateFormat(date);
        this.showConfirmAlert("Confirm Delete", "Delete All Log Entries older than " + dateStr + "? This will also delete all guest use frequency statistics.", 'black', "Cancel", () => this._doDeleteAll(date), "Delete", 'red');   
    }
    
    //Delete all and re-fetch the table
    _doDeleteAll = (date) => {
        this.incrementBusy();
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/log?olderThan=" + DateUtils.jsonDateString(date, false), 
                            {method: "DELETE"},
                            () => {this.decrementBusy(); this._fetchAllLogEntries();}, this._fetchErrorCallback); 
    }
    
    
  
    
    download = () => {
        this.incrementBusy();
        const filename = "Log Export from " + PP.selectedDatabase + " on " + DateUtils.downloadTimeString() + ".csv";
        
        this.secureFileDownload("/ppcs/databases/" + PP.selectedDatabase + "/log/download", filename, this._downloadResponse, this._fetchErrorCallback); 
    }
    
    
    _downloadResponse = () => {
        this.decrementBusy();
        console.log("Download complete");
    }
    
    _setRowProps = (row, dataIndex, rowIndex) => {
        return DataTable.getRowStyle(null, dataIndex, rowIndex, ThemeColors.selectedColor, ThemeColors.tableAlternatingRowsGray);
    }
   
   
   
    
    //Callback for fetching Memberships - JSON response is an array of Membership objects
    _fetchDoneCallback = (response) => {
        this.decrementBusy();
        if (response) {            
            this.state.logEntries = response.map((entry) => new LogEntry(entry));
        }
        this._updateTableData();
    }

   

    
    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
    }
     

    //Called when fetch of log entries are done, creates the table data, and sets filteredTableData equal to the table data
    _updateTableData = () => {
        
        const tableData = this.state.logEntries.map((entry, index) => {
 
            //For each entry, create a row with all the column data
            return {index: index,
                    time: entry.time,
                    membershipID: entry.membershipID,
                    memberID: entry.memberID,
                    name: entry.name,
                    location: entry.location,
                    type: entry.type,
                    mode: entry.mode,
                    guests: entry.guestsAdmitted,
                    info: entry.info
                   }; 
         });

        this.setState({tableData: tableData, filteredTableData: tableData});  //set the data, and filtered data = data
                  
    }


    //Creates filteredTableData by filtering the tableData
    _filterTableData = () => {
        
        const text = this._searchText.toLowerCase();
        
        if (text === 0) {
            this.setState({filteredTableData: this.state.tableData});
            return;
        }
        
        const filteredTableData = this.state.tableData.filter(row => { 
            
            //Match on Membership
            const membershipMatch = row.membershipID.toLowerCase().includes(text);
            
            //Match on Member Name
            const memberNameMatch = row.name.toLowerCase().includes(text);
            
            //Match on Location
            const locationMatch = row.location.toLowerCase().includes(text);
          
            //Match on Info
            const infoMatch = row.info.toLowerCase().includes(text);
            
            //Match on Type
            const typeMatch = row.type.toLowerCase().includes(text);
            
            //Match on Mode
            const modeMatch = row.mode.toLowerCase().includes(text);
            
            return membershipMatch || memberNameMatch || locationMatch || infoMatch || typeMatch || modeMatch;
            
        });

        this.setState({filteredTableData: filteredTableData});
    }
        
    render() {
        
                
        return (
            <div>
                
                {this.state.dailyLogPopupOpen ? 
                    <DailyLogWindow copyStyles={true} windowClosed={() => { this.setState({dailyLogPopupOpen: false}); }} 
                                    windowBlocked={ () => { this.showConfirmAlert("Error", "Disable popup blockers", 'red'); }} 
                                    logEntries={this.state.logEntries} date={this.state.datePicked}
                                    />
                    : null 
                }
    
                {this.getConfirmAlertComponent()}
                
                <TextEntryPopover isOpen={this.state.manualLogEntryPromptOpen} showSkip={false} multiline={true} title="Log Entry" 
                                okCallback={this._manualLogEntryOkCallback} skipCallback={this._guestRegisterSkipCallback} cancelCallback={() => {this.setState({manualLogEntryPromptOpen: false});}} />
            
                <LogTableToolbar busyComponent={() => this.getBusyComponent('left', {}, 30)}
                                onSearchChange={this.searchFieldChanged} 
                                foundRecords={this.searchFoundRecords}
                                onManualLogRequested={this.manualLogEntry}
                                onDeleteRequested={this.deleteAll}
                                onDownloadRequested={this.download}
                                onDailyLogButtonPress={this.printDailyLog}/>
            
                <DataTableComponent data={this.state.filteredTableData} columns={this.columns} options={this.state.tableOptions} hoverColor={ThemeColors.tableHover}/>       
            </div>

        );

    }
}


export default withCookies(LogTab);
