import React from 'react';
import { Typography, Container } from '@material-ui/core'


//The welcome page displays when there is no logged in user. It is informational in nature only.     
export default function ExpiredPage(props) {


    return (
            
        <Container style={{paddingTop: 100}}>
            <Typography variant="h5" style={{textAlign: 'center', paddingBottom: 10}}>Your session has expired due to inactivity</Typography>
            <Typography variant="h5" style={{textAlign: 'center', paddingBottom: 40}}>Please login again</Typography>
     
        </Container>
    
    );
    
    
}



