import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Tooltip } from '@material-ui/core';

import { Member } from '../models/Member'

export const importExportHelp = () => {
   
   
   
   
    const fields = [
        
        {col: "MEMBERSHIP_ID",          desc: "Unique Membership ID",       required: true,  format: "Text", note: ""},
        {col: "LASTNAME",               desc: "Member's Last Name",         required: true,  format: "Text", note: ""},
        {col: "FIRSTNAME",              desc: "Member's First Name",        required: true,  format: "Text", note: ""},
        {col: "BARCODE",                desc: "Unique Barcode Value",       required: false, format: "Text", note: ""},
        {col: "ADDRESS",                desc: "Membership Address",         required: false, format: "Text", note: ""},
        {col: "PRIMARY_PHONE",          desc: "Membership Primary Phone",   required: false, format: "Text", note: ""},
        {col: "SECONDARY_PHONE",        desc: "Membership Alt Phone",       required: false, format: "Text", note: ""},
        {col: "EMAIL",                  desc: "Membership Emails",          required: false, format: "Text", note: "Separate multiple addresses with semicolons"},
        {col: "BIRTHDATE",              desc: "Member's Birthdate",         required: true,  format: "Date", note: ""},
        {col: "NOTES",                  desc: "Membership Notes",           required: false, format: "Text", note: ""},
        {col: "MEMBERSHIP_TYPE",        desc: "Membership Type",            required: false, format: "Text", note: ""},
        {col: "RELATION",               desc: "Member Relation",            required: false, format: "Text", note: ""},
        {col: "MEMBER_NOTES",           desc: "Notes for Member",           required: false, format: "Text", note: ""},
        {col: "GUEST_PASSES",           desc: "Membership Guest Passes",    required: false, format: "Integer", note: "If empty, the initial guest passes for new Memberships is used"},
        {col: "ENTRY_PASSES",           desc: "Member Entry Passes",        required: false, format: "Integer", note: "Use -1 for Unlimited. If empty, the initial entry passes for new Members is used"},
        {col: "EXPIRATION_DATE",        desc: "Membership Expiration Date", required: false, format: "Date", note: "If empty, the initial expiration date for new Memberships is used"},
        {col: "MEMBERSHIP_SUSPENDED",   desc: "Membership Suspend Status",  required: false, format: "Boolean", note: "If empty, false"},
        {col: "MEMBER_SUSPENDED",       desc: "Member Suspend Status",      required: false, format: "Boolean", note: "If empty, false"}
    ];
             
   
    const actions = Member.getActionsArray();

    const style = {
       table: {
            border: '1px solid gray',
            borderCollapse: 'collapse',
            fontSize: '8pt'
        },
        header: {
            textAlign: 'left',
            padding: 2,
            paddingRight: 10
        },
        cell: {
            padding: 2,
            paddingRight: 8
        },
        footer: {
            marginTop: 5,
            marginLeft: 10,
            fontSize: 11
        }
    };


    return (<div>
                <Typography variant="button">CSV (Comma Separated Value) Import/Export</Typography>
                <Typography variant="body2">A properly formatted CSV is needed to import Members. Most spreadsheet apps can save a file in CSV format.
                    The first line of the file must be the column headers. Refer to the following table for column format. Columns can appear in any order.
                    Columns that do not match one of the valid headers are ignored. Refer to the Help for more information.</Typography>

                <div style={{marginTop: 20}}/>

                <table style={{...style.table, width: '100%'}}>
                    <tr style={style.table}>
                      <th style={style.header}>Column Header</th>
                      <th style={style.header}>Description</th>
                      <th style={style.header}>Required</th> 
                      <th style={style.header}>Format</th>
                      <th style={style.header}>Note</th>
                    </tr>

                    {fields.map((field, index) => { return (<tr key={index} style={style.table}>
                                                                <td style={style.cell}>{field.col}</td>
                                                                <td style={style.cell}>{field.desc}</td>
                                                                {field.required ? <td style={{...style.cell, textAlign: 'center'}}>✓</td> : <td/>}
                                                                <td style={style.cell}>{field.format}</td>
                                                                <td style={style.cell}>{field.note}</td>
                                                            </tr>);})
                    }

                  
                    {actions.map((action, index) => {return (<tr key={index} style={style.table}>
                                                                <td style={style.cell}>{action}</td>
                                                                <td style={style.cell}>{"Member Action: " + action + " checked"}</td>
                                                                <td style={style.cell}/>
                                                                <td style={style.cell}>Boolean</td>
                                                                <td style={style.cell}>If empty, unchecked</td>
                                                             </tr>); })
                    }
                    
                </table>
                <div style={{marginTop: 15}}/>
                                
                <Typography variant="body2" style={style.footer}>Boolean (truth field): a truth value must be "true", "yes", "1", or "x" and falsity must be left blank or have a value of "false", "no", or "0" (all case insensitive)</Typography>
                <Typography variant="body2" style={style.footer}>Date field format must be YYYY-MM-DD or MM/DD/YYYY (all numeric)</Typography> 
                <Typography variant="body2" style={style.footer}>Text fields may contain commas and carriage returns but they must be surrounded by double-quotes per RFC4180. Most spreadsheet apps do this automatically.</Typography> 
                
            </div>
            
           );  
};
	

const ImportExportTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#ededed',
        color: 'black',
        maxWidth: 1000,
        fontSize: theme.typography.pxToRem(10),
        border: '2px solid #ffffff'
    }
}))(Tooltip);


export default ImportExportTooltip