import React, { Fragment, Component } from 'react';
import { withCookies } from 'react-cookie';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import { Paper, Tab, Tabs } from '@material-ui/core'

import RequestTab from '../bill_tabs/RequestTab'
import InvoiceTab from '../bill_tabs/InvoiceTab'
import BillAnalysisTab from '../bill_tabs/BillAnalysisTab'

import invoiceIcon from '../assets/invoiceIcon.png'
import requestIcon from '../assets/requestIcon.png'
import pieIcon from '../assets/pieIcon.png'


const MOBILE_WIDTH = 992;
const VERY_SMALL = 724;
const EXTREMELY_SMALL = 580;
  
const ICON_SIZE = 18; //native icon size is 20

export class BillingPage extends Component {
  
    
  
    tabPages = {
        REQUESTS:     {tabIndex: 0, icon: requestIcon,    text: "Requests",     collapseAt: EXTREMELY_SMALL},
        INVOICES:     {tabIndex: 1, icon: invoiceIcon,    text: "Invoices",     collapseAt: EXTREMELY_SMALL},
        ANALYSIS:     {tabIndex: 2, icon: pieIcon,        text: "Analysis",     collapseAt: EXTREMELY_SMALL}
    }
  
  
    state = {
        windowWidth : VERY_SMALL,
        selectedTab: this.tabPages.REQUESTS.tabIndex,
        selectedIR: null                                    //The selected InvoiceRequest 
    }

    componentDidMount() {
       this._updateSize();
       window.addEventListener("resize", this._updateSize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this._updateSize);
    }


    _updateSize = () => {
        this.setState({ windowWidth: window.innerWidth, isMobile: window.innerWidth < MOBILE_WIDTH }); 
    }

    //User switches tab - set the new tab and clear the selections from other tabs
    _switchTab = (event, newValue) => {
        this.setState({selectedTab: newValue, selectedIR: null});
    }

    //Callback when Invoice Request is selected to show Invoices, switch tab and set the prop on the InvoicesTab
    _onDisplayInvoicesByIR = (invoiceRequest) => {
        this.setState({selectedIR: invoiceRequest, selectedTab: this.tabPages.INVOICES.tabIndex});        
    }
    

    render() {
        
        //Override breakpoints for tabs in order for the reponsiveness to improve (collapses space between at "sm" size)
        const tabThemeOverride = createMuiTheme({
            breakpoints: {
              values: {
                xs: 0,
                sm: MOBILE_WIDTH,
                md: 1280,
                lg: 1280,
                xl: 1920
              }
            }
        });         
        
        
        //Selects the current tab to view
        const viewingTab = (() => {
            
            switch (this.state.selectedTab) {

                case this.tabPages.REQUESTS.tabIndex:   
                    return <RequestTab onDisplayInvoicesByIR={this._onDisplayInvoicesByIR}/>;
                    
                case this.tabPages.INVOICES.tabIndex:
                    return <InvoiceTab/>;
                    
                case this.tabPages.ANALYSIS.tabIndex:
                    return <BillAnalysisTab/>;
                
                default:
                    return <div>Tab Not Found</div>;

            }
        })();
        
        
        //Creates the text label for the tab, shrinking font, adding a <div> between icon and text, and collapsing to abbreviated text as necessary
        //to fit the width of the screen
        const tabLabel = (tab) => {
            let fontSize = 15;
            let iconSize = ICON_SIZE;

            if (this.state.windowWidth < MOBILE_WIDTH) {
                fontSize -= 1; 
                iconSize -= 2;
            }
            if (this.state.windowWidth < EXTREMELY_SMALL) {
                fontSize -= 1;
                iconSize -= 1;
            }


            if (this.state.windowWidth < VERY_SMALL)     
                return (<span style={{fontSize: fontSize}}>
                            <img src={tab.icon} alt="icon" width={iconSize} height={iconSize}/> 
                            <div/>
                            {tab.text}
                       </span>);
            else
                return (<span>
                            <img style={{verticalAlign: 'middle'}} src={tab.icon} alt="icon" width={iconSize} height={iconSize}/>
                            <span style={{verticalAlign: 'middle', paddingLeft: 8, fontSize: fontSize}}>{tab.text}</span>
                        </span>);

        };

        const style = this.state.windowWidth < EXTREMELY_SMALL ? {minWidth: 50} : {};
        
        const tabsArray = [];
        for (const index in this.tabPages) {
            const tab = this.tabPages[index];
            tabsArray.push(<Tab key={tab.tabIndex} style={style} value={tab.tabIndex} label={tabLabel(tab)}/>);
        }
        
        
        return (
            
            <Fragment >

                <Paper>
                    <MuiThemeProvider theme={tabThemeOverride}>
                        <Tabs value={this.state.selectedTab} onChange={this._switchTab} indicatorColor="primary" textColor="primary" centered={!this.state.isMobile} variant={this.state.isMobile ? 'fullWidth' : 'standard'} >

                            {tabsArray} 

                        </Tabs>
                    </MuiThemeProvider>
                </Paper>
                

                <div style={{padding: 10}}/>
           
                <div>
                    {viewingTab} 
                </div>

            </Fragment>
        );

    }
    
};


export default withCookies(BillingPage);
