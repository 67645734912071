import { PP } from '../models/PP'
import { Redeemable } from './RedeemableProduct'
import { TransactionFunctions } from '../utils/TransactionFunctions'
import { TransactionType } from '../models/Transaction'



export class Order {

    account;
    pin;
    
    transactionType;
    attendant;
    comment;
    
    membershipID;
    
    orderedProducts;    //array of Redeemables
    
    expectedTotal;
    
    constructor(account, pin, transactionType, comment, membershipID, orderedProducts, expectedTotal) {
        this.account = account;
        this.pin = pin;
        this.transactionType = transactionType.ordinal;
        this.attendant = PP.user ? PP.user.nameReversed() : null;
        this.comment= comment;
        this.membershipID = membershipID;
        this.orderedProducts = orderedProducts;
        this.expectedTotal = expectedTotal; 
    }
    
    
    static createFromCheckout(isPatron, cart, accountID, membershipID, comment) {
        
         //Get a list of redeemables from the cart
        const redeemables = cart.map(item => Redeemable.create(item.product.id, item.quantity));
        
        const expectedTotal = TransactionFunctions.totalShoppingCart(cart);
        
        const order = new Order(accountID, null, isPatron ? TransactionType.PEND_PURCH : TransactionType.PURCHASE, 
                                comment, membershipID, redeemables, expectedTotal.total);
      
     
        return order;
    }
    
    
}



export class PatronOrder {
    
    
    guestAccount;                       //Account Request object, if checking out as guest
    order;                              //An Order object
    amountToDeposit;                    //amount of funds to buy
    serviceFee;                        //amount of service fees
    totalAmountToCharge;                //total card charge
    expectedCurrency;                   //expected currency to use
        
    constructor(accountRequest, order, amountToDeposit, serviceFee, currency) {
        
        this.guestAccount = accountRequest;
        this.order = order;
        this.amountToDeposit = amountToDeposit;
        this.serviceFee = serviceFee;
        this.expectedCurrency = currency;

        this.totalAmountToCharge = order.expectedTotal + amountToDeposit + serviceFee;
        
    }

    
    
}